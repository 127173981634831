<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/pre-assessment-list']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Preassessment Quetion Edit</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="">
                    <form [formGroup]="preAssesmentForm">

                        <div class="font_medium_gilroy">

                            <div>
                                <label class="fsize16 clr_black font_bold_gilroy">Input type </label>
                                <select (change)="inputtype($event)" formControlName="input_type"
                                    class="form-control input_bdr_clr">
                                    <option [disabled]="true" selected>Select any type</option>
                                    <option *ngFor="let type of inputTypes" [value]="type">
                                        {{ type === 'option' ? 'Multiple choice' : 'Text Response' }}
                                    </option>
                                </select>
                            </div>

                            <div>
                                <label class="fsize16 clr_black font_bold_gilroy">Question</label>
                                <input type="text" class="form-control input_bdr_clr font_text"
                                    formControlName="question" maxlength="500">
                            </div>

                            <div *ngIf="showPlaceholderText">
                                <label class="fsize16 clr_black font_bold_gilroy">Placeholder Text</label>
                                <input type="text" maxlength="200" class="form-control input_bdr_clr font_text"
                                    formControlName="placeholder_text">
                            </div>


                            <div>
                                <label class="fsize16 clr_black font_bold_gilroy">Status</label>
                                <div>
                                    <span>
                                        <input type="radio" id="active" name="status" formControlName="status"
                                            value="1">
                                        <label class="marg-l-8"> Active </label>
                                    </span>

                                    <span>
                                        <input class="marg-l-8" type="radio" id="inactive" formControlName="status"
                                            name="status" value="0">
                                        <label class="marg-l-8"> Inactive</label>
                                    </span>
                                </div>
                            </div>
                        </div>


                        <div style="margin-top:100px;">
                            <button (click)="preassessmentSubmit()" class="btn submit_btn_clr">Save</button>
                            <button type="button" style="cursor: pointer;" (click)="preassessmentBackUrl()"
                                class=" marg-l-16 cancelBtn_clr fsize16">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>