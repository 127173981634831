<div class="package padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Package Management
    </div>

    <div class="" style="float:right;margin-right:16px;">

    </div>


    <div style="margin-top: 48px;">
        <div class="row m-0">

            <!-- <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" style="display:flex; margin-bottom: 20px;
           ">

                <div style="width:100%;box-shadow: 0px 6px 36px rgba(20, 24, 58, 0.06);background: #fff;">

                    <div style="display:flex; justify-content: center;  padding-top: 7rem;">

                        <div class="">
                            <button (click)="packageAdd()" style="background: #fff;
                            border: 1px solid #fff;"> <span><img src="assets/Add button.svg" alt=""></span></button>
                        </div>
                    </div>

                    <div style="display:flex; justify-content: center; align-items: center; margin-top:12px;">
                        <div>
                            Create a New Package Maneger
                        </div>
                    </div>

                </div>

            </div> -->
            <div class="col-4" style="align-items: center; display: flex; margin-bottom: 20px;">
                <div class="text-center" style="background: #fff;
                box-shadow: 0px 6px 36px rgb(25 34 59 / 21%);
                padding: 16px 0px;
                border-radius: 24px;
                width: 100%;     height: 100%;">
                    <div style="padding-top: 5rem;">
                        <img src="assets/Add button.svg" alt="" (click)="packageAdd()">
                    </div>
                    <div style="font-size: 16px;
                    font-family: Gilroy-Bold !important; padding-top: 8px;">
                        Create a New Package Maneger
                    </div>
                </div>

            </div>


            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 " style="    margin-bottom: 20px;"  *ngFor="let item of packageTotalList">
                <div style="background: #fff; box-shadow: 0px 6px 36px rgb(25 34 59 / 21%);     padding: 16px 0px;    border-radius: 24px;

                width: 100%;">
                    <div class="position-relative font_bold_gilroy"  style="    padding-bottom: 16px;
                    padding-left: 8px;"       >
                        <mat-slide-toggle (change)="statusUpdate($event,item.id)" [checked]="!item.status" ></mat-slide-toggle>
                         <span class="position-absolute" style="    right: 16px;">
                            <img  (click)="editPackageManager(item)" height="20px" src="assets/Edit_icon.png" alt="">
                            <img (click)="deletePackageManager(item)" data-toggle="modal" data-target="#exampleModal" style="padding-left: 4px; "  height="20px" src="assets/Delete_icon.png" alt="">

                        </span>

                    </div>
                    <div style="    border-bottom: 1px solid #1b1b1c26;">

                    </div>
                    <div style="  padding-top: 8px;    padding-left: 16px;
                    padding-right: 16px;  ">
                        <div class="position-relative font_bold_gilroy">
                            Package Name
                            <span class="position-absolute" style="right: 0;         color: #81848bad;
                            font-weight: 400;">{{item.package_name}}</span>
                        </div>
                        <div class="position-relative font_bold_gilroy" style="    padding-top: 8px;">
                            No. of sessions
                            <span class="position-absolute " style="right: 0;     color: #81848bad;;
                            font-weight: 400;">{{item.no_of_sessions}}</span>
                        </div>
                        <div class="position-relative font_bold_gilroy" style="    padding-top: 8px;">
                            % discount
                            <span class="position-absolute" style="right: 0;   color: #81848bad;
                            font-weight: 400;">{{item.discount}}</span>
                        </div>
                        <div class="position-relative font_bold_gilroy" style="    padding-top: 8px;">
                            Validity of package
                            <span class="position-absolute" style="right: 0;  color: #81848bad;
                            font-weight: 400;"><span>{{item.validity}}</span>
                                <span>{{item.validity_type}}</span></span>
                        </div>
                        <div class="position-relative font_bold_gilroy" style="    padding-top: 8px;">
                            Session Duration
                            <span class="position-absolute" style="right: 0;      color: #81848bad;
                            font-weight: 400;">{{item.session_duration}} minutes</span>
                        </div>
                        <div class="text-center" style="    padding-top: 10px;">
                            <button data-toggle="modal" data-target="#description" style="border: 1px solid #1b1b1c26;
                            height: 36px;
                            width: 100%;
                            outline: none;
                            font-size: 16px;
                            border-radius: 6px;" (click)="viewDescription(item)">View Description</button>

                        </div>
                    </div>

                </div>

            </div>


            <!-- <div>
                <label class="switch">
                    <input type="checkbox" checked>
                    <span class="slider"></span>
                  </label>
            </div>
            <label class="switch">
                <input type="checkbox" checked>
                <span class="slider round"></span>
              </label> -->



        </div>

    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-body">

                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                    <div>
                        Do you want to delete ? </div>

                </div>
                <div class="padd-b-30 row justify-content-center  font_bold_gilroy">
                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                        (click)="packageManagerDeleteApi()">Submit</button>
                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                        data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="description" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">

            <div class="modal-content">

                <div class="modal-body"  >


                    <div class="fsize18 font_bold_gilroy text-center "> Description ! </div>

                    <div>
                       {{description}} </div>

                </div>
                <!-- <div class="padd-b-30 row justify-content-center  font_bold_gilroy">
                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                       >Submit</button>
                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                        data-dismiss="modal">Cancel</button>
                </div> -->
            </div>
        </div>
    </div>


</div>
