import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { AbstractControl, ValidatorFn } from '@angular/forms';
declare var swal: any;
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { get } from 'jquery';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-rewards',
  templateUrl: './edit-rewards.component.html',
  styleUrls: ['./edit-rewards.component.scss']
})
export class EditRewardsComponent implements OnInit {



  showTimePicker1: boolean = false;
  selectedHour1: number;
  selectedMinute1: number;
  selectedPeriod1: string;

  showTimePicker2: boolean = false;
  selectedHour2: number;
  selectedMinute2: number;
  selectedPeriod2: string;

  hours: number[] = Array.from(Array(12).keys()).map(hour => hour + 1); // Generate array [1, 2, ..., 12]
  minutes: number[] = Array.from(Array(60).keys());



  EditrewardsId: any;
  singleRewardId: any;

  reward_category_id: any;
  listRewards: any[];

  selectedRewardId: any;
  isShown: boolean = false;
  getrewardlist: any[] = [];
  getrewardsinglelist: any;
  cancelRewardAdd_url = "/rewardslist";
  reward_name: any;
  reward_description: any;
  external_link: any;
  happinesspoints: any;
  redeem_code: any;
  isactive = '1';
  valid_from_date: any
  valid_to_date: any
  file: any;
  registerForm: FormGroup;
  submitted = false;

  periods: any;
  startTime: any;
  endTime: any;
  date: any

  today: string


  rewardImage: string | undefined;

  isNewFileUploaded: boolean = false;
  reward_image_url: any;



  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder, private http: HttpClient) {

    const current = new Date();
    this.today = `${current.getFullYear()}-${(current.getMonth() + 1).toString().padStart(2, '0')}-${current.getDate().toString().padStart(2, '0')}`;


  }

  ngOnInit(): void {

    this.registerForm = new FormGroup({
      RewardName: new FormControl('',Validators.required),
      RewardDescription: new FormControl('', Validators.required),
      ExternalLink: new FormControl("", [Validators.required, this.urlValidator()]),
      HappinessPoints: new FormControl("", Validators.required),
      RedeemCode: new FormControl(""),
      CategoryName: new FormControl(""),
      categoryID: new FormControl(""),
      FileUpload: new FormControl(""),
      status: new FormControl(""),
      startdateRequired: new FormControl(""),
      enddateRequired: new FormControl(""),
      startTimeHour: new FormControl(""),
      startTimeMinutes: new FormControl(""),
      endTimeHour: new FormControl(""),
      endTimeMinutes: new FormControl(""),
      startTimePeriod: new FormControl(""),
      endTimePeriod: new FormControl(""),
      startTimeRequired: new FormControl(""),
      endTimeRequired: new FormControl(""),



    })

    this.getlistrewardscategory()
    this.EditrewardsId = this.ApiService.rewardseditID;
    this.EditrewardsId = sessionStorage.getItem("id")
    console.log("EditrewardsId", this.EditrewardsId)

    this.getRewardlist()
    this.getSingleRewardsList()
  }


  // ================================================================================

  cancelAddreward() {
    this.router.navigate([this.cancelRewardAdd_url]);

  }


  // =================================================================================


  urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      const isValid = urlRegex.test(control.value);
      return isValid ? null : { 'invalidUrl': { value: control.value } };
    };
  }


  // =================================================================================


  async getlistrewardscategory() {
    var getapi = "/listrewardcategories";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.getrewardlist = res.result
      console.log("category list", this.getrewardlist)

    })
  }

  // =================================================================================


  openTimePicker1() {
    this.showTimePicker1 = true;
    this.startTime = this.selectedHour1 + ':' + this.selectedMinute1;
    console.log(" this.startTime  this.startTime ", this.startTime)
  }


  // =================================================================================

  openTimePicker2() {
    this.showTimePicker2 = true;
    this.endTime = this.selectedHour2 + ':' + this.selectedMinute2;
    this.periods = this.selectedPeriod2
    console.log(this.periods)

    console.log("this.endTimethis.endTimethis.endTime", this.endTime)
  }

  // =================================================================================

  period(event: any) {
    this.periods = event.target.value
    console.log(this.periods)
  }

  // ================================================================================

  convertTo12HourTime(time: string) {
    // Split the time into hours and minutes
    const splitTime = time.split(':');
    let hours = parseInt(splitTime[0]);
    let minutes = parseInt(splitTime[1]);

    // Determine AM or PM
    var period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle noon and midnight

    // Format the time
    const formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + period;

    return formattedTime;
  }


  // =================================================================================


  async getRewardlist() {
    var getapi = "/listReward";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.listRewards = res.result;
      this.singleRewardId = this.listRewards[0].id;
      console.log("singleid", this.singleRewardId)
      console.log("listrewards", this.listRewards)
    })
  }


  // =================================================================================


  async getSingleRewardsList() {
    const getapi = "/listReward/" + this.EditrewardsId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.getrewardsinglelist = res.result;
        console.log("Getlist", this.getrewardsinglelist)
        const status = res.result.isactive

        const parts = res.result.valid_from_date.split("-");                          //  converts the date string into the array of string  
        const fromvalidDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
        const part = res.result.valid_to_date.split("-");                          //  converts the date string into the array of string  
        const tovalidDate = `${part[2]}-${part[1]}-${part[0]}`;

        console.log("start_date", fromvalidDate)
        console.log("to_date", tovalidDate)



        const startTimeParts = this.convertTo12HourTime(res.result.valid_from_time).split(':');
        const endTimeParts = this.convertTo12HourTime(res.result.valid_to_time).split(':');

        this.registerForm.controls['startTimeHour'].setValue(parseInt(startTimeParts[0]));         //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['startTimeMinutes'].setValue(parseInt(startTimeParts[1]));      //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['endTimeHour'].setValue(parseInt(endTimeParts[0]));             //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['endTimeMinutes'].setValue(parseInt(endTimeParts[1]));          //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['startTimePeriod'].setValue(startTimeParts[2]);                 //  time recieving in a 12 hours and 24 hours format based on Am Pm 
        this.registerForm.controls['endTimePeriod'].setValue(endTimeParts[2]);                     //  time recieving in a 12 hours and 24 hours format based on Am Pm 

        this.registerForm.controls['categoryID'].setValue(res.result.reward_category_id)
        this.registerForm.controls['CategoryName'].setValue(res.result.category_name)
        this.registerForm.controls['RewardName'].setValue(res.result.reward_name)
        this.registerForm.controls['RewardDescription'].setValue(res.result.reward_description)
        this.registerForm.controls['ExternalLink'].setValue(res.result.external_link)
        this.registerForm.controls['HappinessPoints'].setValue(res.result.points)
        this.registerForm.controls['RedeemCode'].setValue(res.result.redeem_code)
        this.registerForm.controls['status'].setValue(`${res.result.isactive}`)
        this.registerForm.controls['FileUpload'].setValue(res.result.reward_image);
        this.registerForm.controls['startdateRequired'].setValue(fromvalidDate)
        this.registerForm.controls['enddateRequired'].setValue(tovalidDate)


        this.rewardImage = res.result.reward_image;
        this.reward_image_url = res.result.reward_image;
        // if (status === 1) {
        //   this.registerForm.controls['status'].setValue(1);
        // } else if (status === 0) {
        //   this.registerForm.controls['status'].setValue(0);
        // }

        console.log("registeredform", this.registerForm)
        console.log("IMAGE", this.rewardImage)

      }
    });
  }


  // =========================================================================================


  rewarditem(event: any) {
    this.selectedRewardId = event.target.value
    console.log("this.selectedRewardId",this.selectedRewardId)

    const selectedItem = this.getrewardlist.find(item => item.category_name == this.selectedRewardId);

    console.log("selectedItem",selectedItem)

    if (selectedItem && selectedItem.category_name !== "Lifestyle") {
      this.registerForm.controls['RewardName'].setValue(selectedItem.category_name)
    }else{
      this.registerForm.controls['RewardName'].setValue('');
    }


    if (this.selectedRewardId === "Lifestyle") {
      if (!this.registerForm.get("ExternalLink")) {
        // Add ExternalLink form control if not already added
        this.registerForm.addControl("ExternalLink", new FormControl("", Validators.required));
      }
    }
    else {
      // Clear validators for ExternalLink control if exists
      const externalLinkControl = this.registerForm.get("ExternalLink");
      if (externalLinkControl) {
        externalLinkControl.clearValidators();
        externalLinkControl.updateValueAndValidity(); // Update validity status
      }
      console.log("selected id", this.selectedRewardId)
    }

    if (this.selectedRewardId === "Support Group" || this.selectedRewardId === "Worksheet") {
      if (this.registerForm.get('RedeemCode')) {
        this.registerForm.get('RedeemCode').setValue('');
      }
    }
  }

  // ==========================================================================================


  fileupload(event: any) {

    const file = event.target.files[0];
    const maxSize = 1 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        swal({
          title: 'Error!',
          text: '1MB is only allowed to upload.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        return;
      }
      // Read the file and set reward_image with its data URL
      const reader = new FileReader();
      reader.onload = () => {
        this.rewardImage = reader.result as string;
      };
      reader.readAsDataURL(file);
      // Update the value of the FileUpload form control with the selected file
      this.registerForm.patchValue({
        FileUpload: file
      });
      this.isNewFileUploaded = true;

    }

    console.log("file selected", file)
  }


  // ===========================================================================================


  async submitAddReward() {

    this.submitted = true;
    // if (this.registerForm.invalid) {
    //   console.log("Fill every data --------------------")
    //   return;
    // }

    var formdata = new FormData();

    const startDate = moment(this.registerForm.value.startdateRequired, 'YYYY/MM/DD');
    const endDate = moment(this.registerForm.value.enddateRequired, 'YYYY/MM/DD');

    if (moment(startDate).isAfter(endDate)) {                      // Start date is after end date
      console.log('Start date must be before end date');
      swal({
        title: 'Error!',
        text: 'From Date Must be Before To Date',
        type: 'error',
      });
      return
    } else {


      const startTime12Hour = `${this.registerForm.value.startTimeHour}:${this.registerForm.value.startTimeMinutes} ${this.registerForm.value.startTimePeriod}`;   //  converting the time in 12 hours nad 24 hours format 
      const endTime12Hour = `${this.registerForm.value.endTimeHour}:${this.registerForm.value.endTimeMinutes} ${this.registerForm.value.endTimePeriod}`;           //  converting the time in 12 hours nad 24 hours format 

      const startime24Hour = moment(startTime12Hour, 'h:mm A').format('HH:mm');  //  appending the time in 12 hours nad 24 hours format 
      const endTime24Hourend = moment(endTime12Hour, 'h:mm A').format('HH:mm');  //  appending the time in 12 hours nad 24 hours format 

      console.log("startime24Hour", startime24Hour)
      console.log("endTime24Hourend", endTime24Hourend)


      const rewardId = this.getrewardlist.filter(res => res.category_name == this.registerForm.value.CategoryName)[0]?.id

      formdata.append('reward_category_id', rewardId)
      formdata.append('reward_name', this.registerForm.value.RewardName);
      formdata.append('reward_description', this.registerForm.value.RewardDescription);
      formdata.append('external_link', this.registerForm.value.ExternalLink);
      formdata.append('points', this.registerForm.value.HappinessPoints);
      formdata.append('redeem_code', this.registerForm.value.RedeemCode);
      formdata.append('isactive', this.registerForm.value.status);
      formdata.append('file', this.registerForm.value.FileUpload);
      formdata.append('valid_from_date', this.registerForm.value.startdateRequired);
      formdata.append('valid_to_date', this.registerForm.value.enddateRequired)
      formdata.append('valid_from_time', startime24Hour)
      formdata.append('valid_to_time', endTime24Hourend)

      if (this.isNewFileUploaded) {
        formdata.append('changed_file', this.reward_image_url);
      }

      // formdata.append('file', this.fileToUpload);

      console.log("formdata", formdata)

      var postapi = "/addAndUpdatereward/" + this.EditrewardsId;
      (await this.ApiService.putApi(postapi, formdata)).subscribe(
        res => {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Reward Updated successfully",
              type: "success",
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.cancelRewardAdd_url]);
            console.log("Data added successfully", res);
          }
        });
    }
  }
}

