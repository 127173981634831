import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// import * as $ from 'jquery';
declare const $: any;
declare var swal: any;

@Component({
  selector: 'app-addsubscription',
  templateUrl: './addsubscription.component.html',
  styleUrls: ['./addsubscription.component.scss']
})
export class AddsubscriptionComponent implements OnInit {

  subscriptionForm: FormGroup;


  isShown: boolean = false;
  showInputField = false; // Initialize as hidden
  checked: any = true;
  showToggle = true;
  toppings: any;
  subscriptionModule: any[] = [];
  // subscriptionName: any;
  subscription_name: any;
  subscription_description: any;
  subscription_duration: any;
  pricing: any;
  modules: any = [];
  inp_checkbox: any[] = [];
  subscriptionUrl = '/subscription';

  // Error flags
  nameError: boolean = false;
  descriptionError: boolean = false;
  durationError: boolean = false;
  pricingError: boolean = false;
  atLeastOneChecked: boolean = false;
  limitTypeError: boolean = false;



  constructor(private ApiService: ApiService, private router: Router, private formBuilder: FormBuilder) {

    this.subscriptionForm = this.formBuilder.group({
      subscription_name: ['', Validators.required],
      subscription_description: ['', Validators.required],
      subscription_duration: ['', Validators.required],
      pricing: ['', Validators.required],
    });

  }



  ngOnInit(): void {
    this.subscriptionModuleList();
  }
  async subscriptionModuleList() {
    var getapi = '/getSubscriptionModules';
    (await this.ApiService.getApi(getapi)).subscribe(
      res => {
        if (res.status == 200) {
          var moduleObj = {};
          var moduleArr = []
          for (var item of res.result) {
            moduleObj = { modules: item.module_name, limit_type: "", limit_count: '', access_limit: '', module_status: '', error: '' }
            moduleArr.push(moduleObj)
          }
          this.subscriptionModule = moduleArr;
          console.log('===', this.subscriptionModule)
        }

      }
    )
  }


  async ModuleSelect(event: any, ind: any) {
    this.subscriptionModule = this.subscriptionModule.map((item, index) => {
      if (ind == index) {
        return { ...item, module_status: event.target.checked }
      } else {
        return item
      }
    })

  }


  async ModuleLimitChange(event: any, ind: any) {
    this.subscriptionModule = this.subscriptionModule.map((item, index) => {
      if (ind == index) {
        return { ...item, access_limit: event.checked }
      } else {
        return item
      }
    })
  }


  async ModuleLimitCount(event: any, ind: any) {
    this.subscriptionModule = this.subscriptionModule.map((item, index) => {
      if (ind == index) {
        return { ...item, limit_count: Number(event.target.value) }
      } else {
        return item
      }
    })
  }


  async ModuleType(event: any, ind: any) {
    this.subscriptionModule = this.subscriptionModule.map((item, index) => {
      if (ind == index) {
        return { ...item, limit_type: event.target.value }
      } else {
        return item
      }
    })
    console.log(this.subscriptionModule)
  }


  async addSubscription() {

    if (!this.subscription_name) {
      this.nameError = true;
    } else {
      this.nameError = false;
    }

    if (!this.subscription_description) {
      this.descriptionError = true;
    } else {
      this.descriptionError = false;
    }

    if (!this.subscription_duration) {
      this.durationError = true;
    } else {
      this.durationError = false;
    }

    if (!this.pricing) {
      this.pricingError = true;
    } else {
      this.pricingError = false;
    }


    // Check if at least one checkbox is selected
    const atLeastOneChecked = this.subscriptionModule.some(item => item.module_status == 1);
    const errorMessage = document.getElementById('error-message');


    if (atLeastOneChecked == false) {
      // No checkboxes are checked, display an error message

      errorMessage.style.display = 'block';
    }

    else {
      errorMessage.style.display = 'none';


      // const isLimitTypeSelected = this.subscriptionModule.some(item => {
      //   if (item.access_limit && item.limit_type === '') {
      //     // If access_limit is selected but limit_type is not, set the error flag
      //     this.limitTypeError = true;
      //     return false;
      //   }
      //   return true;
      // });

      // if (!isLimitTypeSelected) {
      //   // Display an error message or handle the case where not all limit_types are selected
      //   return; // Stop the process, do not proceed with the form submission
      // } else {
      //   this.limitTypeError = false;



      // If all required fields are filled and at least one checkbox is selected, proceed with the form submission
      // Prepare data for submission
      const moduleData = this.subscriptionModule.map(item => {
        return {
          ...item,
          access_limit: item.access_limit ? 1 : 0,
          limit_count: item.limit_count || 0,
          limit_type: item.limit_type,
          module_status: item.module_status ? 1 : 0,

        };
      });

      const requestBody = {
        "subscription_name": this.subscription_name,
        "subscription_description": this.subscription_description,
        "subscription_duration": this.subscription_duration,
        "pricing": this.pricing,
        "isactive": this.showToggle,
        "modules": moduleData,
      };


      // Perform the API call here
      const postApi = '/addAndUpdateSubscriptionPlan';
      (await this.ApiService.postApi(postApi, requestBody)).subscribe(
        (res) => {
          if (res.status === 200) {
            this.router.navigate([this.subscriptionUrl]);
          } else {
            // Handle errors here, e.g., log the error or show an error message to the user.
            console.error('API error:', res.error);
          }
        },
        (error) => {
          // Handle errors here, e.g., log the error or show an error message to the user.
          console.error('API error:', error);
        }
      );
    }
  }

  cancel_submission() {
    this.router.navigate(['/subscription']);
  }

}