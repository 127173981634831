<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/blog_master_list']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Blog Master View</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Blog Title *</label>
                        <input disabled type="text" class="form-control input_bdr_clr font_text" />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Article *</label>
                        <!-- <input disabled type="text" class="form-control input_bdr_clr font_text" /> -->
                        <ckeditor 
                        name="myckeditor"
                        debounce="500" 
                >
              </ckeditor>  


                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Emotions *</label>
                        <input disabled type="text" class="form-control input_bdr_clr font_text" maxlength="150" />
                    </div>
                    <div class="marg-t-20" >
                        <span class="fsize16 clr_black font_bold_gilroy">Status  * :
                        </span>
                        <span>
                            <label for="vehicle1"> Active</label>
                            <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs"
                                value="1" >
                        </span>

                        <span>
                            <label class="marg-l-8" for="vehicle1">In Active</label>
                            <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs"
                                value="0" >
                        </span>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>