import { Component, NgModule, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
declare var swal: any;
import { MatDatepicker } from '@angular/material/datepicker';
// import { DataTablesModule } from 'angular-datatables';
import { event } from 'jquery';

// import { format } from 'path';

// import {NgSelectModule, NgOption} from '@ng-select/ng-select';
// import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
// import {FormGroup, FormControl} from '@angular/forms';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
})
export class AppointmentComponent implements OnInit {

  currentPage: number = 1;
  entriesPerPage: number = 50;
  totalPages: number = 0;
  dtOptions: DataTables.Settings = {
    searching: false,
    paging: false,
    info: false
  };

  appointmentDetails = '/appointment-details'

  getallappointmentlistfilter: any[] = [];
  getallappointmentdata: any;
  loading: boolean = false;

  cars: any;
  //  campaignOne: FormGroup;
  //  campaignTwo: FormGroup;
  manualSearchQuery: any;
  // showManualSearchInput: boolean = false;
  appointmentDeleteId: any;
  selectedCity: any;
  selectedCityIds: string[];
  getAllAppointmentList = [];
  getSingleAppointmentList = [];
  getAllAppointmentListNew = [];
  addapointment_Url = '/add/appointmentlist'

  today: Date = new Date()
  fromDate: Date = this.today;
  toDate: Date = this.today;
  getAppointmentId: any;
  search = ""
  // minDate: any = ''
  totalEntries: number = 0

  isConfirmationModalOpen = false;
  selectedItem: any;


  openDatePicker(picker: MatDatepicker<Date>) {
    picker.open();
  }

  constructor(private router: Router, private ApiService: ApiService) {

  }

  appointmentView_url = '/appointment-view-page';
  appointment_url_edit = '/appointment-edit-page';

  appointment_details: any;
  appointment_url = '/appointment-details';

  appointment_id: any

  async ngOnInit() {
    this.getAppointmentListApi(this.fromDate, this.toDate);

    // this.datapickerFun();
    // -----------------------------------------------------------
  }

  // displaytable() {
  //   this.showManualSearchInput = true;
  // }

  handleManualSearch(event: any) {
    this.currentPage = 1
    this.manualSearchQuery = event?.target?.value;
    console.log(this.manualSearchQuery)
  }



  exportToExcel(): void {
    console.log('hhh', this.getAllAppointmentListNew);
    const downloadArr = this.getAllAppointmentListNew.map(
      (item: any, index: any) => {
        return {
          'User Name': item.user_name,
          'Coach Name': item.coach_name,
          'App Number': item.app_number,
          'Booked DateTime': item.booked_datetime,
          'Reschedule DateTime': item.reschedule_datetime,
          'coupon_code': item.coupon_code,
          'college': item.college_name,
          'corporate': item.corporate_name,
          'Appointment Status': item.appointment_status,
          'Date': item.date,
          'Time': item.time,
        };
      }
    );
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(downloadArr);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Appointment_List');
    // XLSX.writeFile(wb, 'Thap_Appointment_List.xlsx');

    // Generate a timestamp
    const timestamp = new Date()
      .toISOString()
      .slice(0, 19)
      .replace(/[:T]/g, '-');

    // Append the timestamp to the filename
    const filename = `Thap_Appointment_List-${timestamp}.xlsx`;

    // Save the file with the timestamped filename
    XLSX.writeFile(wb, filename);
  }

  async openMeetingLink(gmeet_link: any){
    window.open(gmeet_link);
  }


  async exportToSingleExcel(item: any) {
    console.log(item);
    var postapi = '/getinvoice';
    var appintmentSinglelistData = {
      "app_id": item?.id,
      "user_id": item?.user_id
    };
      (await this.ApiService.postApi(postapi, appintmentSinglelistData)).subscribe(
        (res) => {
          const fileUrl = res?.data;
          if (fileUrl) {
            // Redirect to the file URL to trigger the download
            window.location.href = fileUrl;
          } else {
            console.error('File URL not found in response');
          }
        },
        (error) => {
          console.error('Failed to download file:', error);
        }
      );

  }


  async getAppointmentListApi(fromdate, todate) {
    this.loading = true;  // Start the loader
    const searchQuery = this.manualSearchQuery || '';
    const page = this.currentPage;
    const entries = this.entriesPerPage;
    var getapi = `/admin_appointment_list?fromDate=${fromdate}&toDate=${todate}&search=${searchQuery}&page=${page}&limit=${entries}`;
    (await this.ApiService.getApi(getapi)).subscribe(
      (res) => {
        this.getAllAppointmentList = res.data;
        this.getAppointmentId = res.data.id;
        this.totalPages = res.pageCount;
        this.totalEntries = res.totalCount;
        console.log('admin_appointment_list', this.getAllAppointmentList);
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching appointment list', error);
        this.loading = false;
      }
    );
  }
  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getAppointmentListApi(moment(this.fromDate).format('YYYY-MM-DD'), moment(this.toDate).format('YYYY-MM-DD'));
    }
  }
  onEntriesPerPageChange() {
    this.currentPage = 1; // Reset to the first page
    this.getAppointmentListApi(moment(this.fromDate).format('YYYY-MM-DD'), moment(this.toDate).format('YYYY-MM-DD'));
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.getAppointmentListApi(moment(this.fromDate).format('YYYY-MM-DD'), moment(this.toDate).format('YYYY-MM-DD'));
    }
  }

  edit_view() {
    this.router.navigate([this.appointmentView_url]);
  }

  // dateTimeConvertion(date=''){
  //   return moment(date).add(5, 'hours').add(30, 'minutes').format('DD-MM-YYYY HH:mm A')
  // }
  dateTimeConversion(date) {
    if (date) {
      const formattedDate = moment(date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY HH:mm A');
      return formattedDate;
    } else {
      return 'Invalid Date';
    }
  }
  add_appointment() {
    this.router.navigate([this.addapointment_Url])
  }


  appointment_Edit(editItems: any) {
    this.ApiService.appointmentEditId = editItems.id;

    this.router.navigate([this.appointment_url_edit]);
  }

  // fromDateChange() {

  //   this.minDate = this.fromDate
  // }

  appointment_deleted(deleteItem) {
    this.appointmentDeleteId = deleteItem.id;
  }
  async deletedApi() {
    var postapi = '/delete_appointment';
    var appintmentlistidData = {
      app_id: this.appointmentDeleteId,
    };
    (await this.ApiService.postApi(postapi, appintmentlistidData)).subscribe(
      (res) => {
        this.getAppointmentListApi(moment(this.fromDate).format('YYYY-MM-DD'), moment(this.toDate).format('YYYY-MM-DD'));
      }
    );
  }

  // datapickerFun() {
  //   if (this.fromDate != undefined && this.toDate != undefined) {
  //     console.log('no dates choose');
  //     var fromdate = moment(this.fromDate. format('YYYY-MM-DD'));
  //     var todate = moment(this.toDate. format('YYYY-MM-DD'));
  //     this.getAllAppointmentListNew =
  //       this.fromDate == undefined ||
  //         this.toDate == undefined ||
  //         this.fromDate == '' ||
  //         this.toDate == ''
  //         ? this.getAllAppointmentList
  //         : this.getAllAppointmentList.filter(
  //           (person) =>
  //             moment(person.date. format('YYYY-MM-DD')) >= fromdate &&
  //             moment(person.date. format('YYYY-MM-DD')) <= todate
  //         );
  //     console.log('what was the data', this.getAllAppointmentListNew)
  //     this.getAppointmentListApi();
  //   } else {
  //     console.log('please choose start date and end date');

  //     swal(
  //       {
  //         title: 'error!',
  //         text: 'Please choose start date and end date',
  //         type: 'error',
  //       },
  //       function () {
  //         alert('yo');
  //       }
  //     );
  //   }
  // }


  resetFilters() {
    // Clear start date and end date and filter data
    if (!this.fromDate && !this.toDate && !this.manualSearchQuery) {
      swal({
        title: 'Error!',
        text: 'Select any Filter to Proceed',
        type: 'error',
      });
    } else {

      this.fromDate = this.today;
      this.toDate = this.today;
      this.manualSearchQuery = '';
    }
    // location.reload();
  }


  datapickerFun() {
    if (this.fromDate && this.toDate) {
      if (moment(this.fromDate).isAfter(this.toDate)) {
        // Start date is after end date
        console.log('Start date must be before end date');
        swal({
          title: 'Error!',
          text: 'Start Date Must be Before End Date',
          type: 'error',
        });
        return; // Exit the function
      }
      console.log('Both dates chosen');
      var fromdate = moment(this.fromDate).format('YYYY-MM-DD');
      var todate = moment(this.toDate).format('YYYY-MM-DD');

      // Construct the query string
      var queryString = `fromDate=${fromdate}&toDate=${todate}`;
      this.getAllAppointmentList =this.getAllAppointmentList.filter(
            (person) =>
              moment(person.date).format('YYYY-MM-DD') >= fromdate &&
              moment(person.date).format('YYYY-MM-DD') <= todate
          );

      console.log('what was the data', this.getAllAppointmentList)

      // Now you can use the queryString as needed, for example, in an API request
      console.log('Query string:', queryString);

      // Call the API function passing the query string
      this.getAppointmentListApi(fromdate, todate);
      // this.displaytable();
      this.handleManualSearch(event);

    }

    else if (this.fromDate && !this.toDate) {
      console.log('End date is required');
      swal({
        title: 'Error!',
        text: 'End date is required',
        type: 'error',
      });
    }

    else if (!this.fromDate && this.toDate) {
      swal({
        title: 'Error!',
        text: 'Start date is required',
        type: 'error',
      });
    }

    else if (!this.fromDate && !this.toDate && !this.manualSearchQuery) {
      swal({
        title: 'Error!',
        text: 'Select any Filter to Proceed',
        type: 'error',
      });
    }

    else {
      this.getAppointmentListApi("", "");
    }
  }


  async markAsRead(item: any) {

    var data = {
      "id": item.id
    }

    console.log('data', item.id)

    var Noshow = `/changeAppointmentStatus/${item.id}`;
    (await this.ApiService.postApi(Noshow, data)).subscribe(
      res => {
        if (res.status == 200) {
          item.appointment_status = 'Completed';
          // alert('Mark as Readed')
        }
      }
    )
    this.closeConfirmationModal();
    console.log('ddddddd', item.appointment_status)
  }

  openConfirmationModal(item: any) {
    this.selectedItem = item;
    console.log(item)
    this.isConfirmationModalOpen = true;
    console.log('data', this.selectedItem)
    console.log('Selected', this.isConfirmationModalOpen)
  }

  closeConfirmationModal() {
    this.isConfirmationModalOpen = false;
  }

  // --------------------------------------------------


  appointment_detail_page(id: number) {
    this.router.navigate([this.appointment_url], { queryParams: { id } });
  }

  copyLinkAddress(event: MouseEvent, link: string) {
    event.preventDefault(); // Prevent the default right-click menu
  
    const tempInput = document.createElement('input'); // Create a temporary input field
    tempInput.value = link; // Set the input value to the link
    document.body.appendChild(tempInput); // Append it to the DOM
    tempInput.select(); // Select the link text
    document.execCommand('copy'); // Copy the selected text to the clipboard
    document.body.removeChild(tempInput); // Remove the temporary input
  }

}
