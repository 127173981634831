<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Coach Edit Page
    </div>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <!-- <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Title</label>
                        <input type="text" id="title" formControlName="title" class="form-control input_bdr_clr " />

                        <div *ngIf="submitted && registerForm.get('title').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('title').errors.required">Title is required</div>
                        </div>

                    </div> -->
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Name</label>
                        <input type="text" [(ngModel)]="coachName" id="fullName" formControlName="name"
                            class="form-control input_bdr_clr" />
                        <div *ngIf="submitted && registerForm.get('name').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('name').errors.required">Name is required</div>
                        </div>

                    </div>

                    <!-- <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">License Number</label>
                        <input type="text" [(ngModel)]="coach_LicenceNo" id="licenceNumber"
                            formControlName="licenceNumber" class="form-control input_bdr_clr" />

                        <div *ngIf="submitted && registerForm.get('licenceNumber').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('licenceNumber').errors.required">License Number is required
                            </div>
                        </div>
                    </div> -->



                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Area of Expertise</label>
                        <!-- <input type="text" [(ngModel)]="coach_areaOfExpertise" id="areaOfExpertise"
                            formControlName="areaOfExpertise" class="form-control input_bdr_clr" /> -->

                        <!-- <select id="multiple-checkboxes" multiple="multiple">
                                <option [value]="[item.id]" *ngFor="let item of areaExpertiseList">{{item.expertise}}</option>
                              </select> -->
                        <!-- <div style="border: 1px solid #cad1d7; border-radius:0.375rem;">
                                <select class="form-control" id="multiple-check" multiple="multiple">
                                  <option [value]="[item.id]" *ngFor="let item of areaExpertiseList">{{item.expertise}}</option>
                                </select>
                              </div> -->



                        <!-- <div style="border: 1px solid #cad1d7; border-radius:0.375rem;">
                                <select id="selectMultiple" class=" form-control input_bdr_clr" multiple data-live-search="true"  >
                                  <option [value]="[item.id]" [selected]="coach_areaOfExpertise == item.id"     *ngFor="let item of areaExpertiseList">{{item.expertise}}</option>
                                </select>
                              </div>  -->

                        <div class="form-control" style="height:48px;">
                            <mat-form-field style="height:30px; width:420px;">
                                <mat-select [formControl]="toppings" multiple>
                                    <mat-option *ngFor="let item of areaExpertiseList" [value]="item.id"
                                        (onSelectionChange)="onSelectEvent($event, item)">{{item.expertise}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        




                        <!-- 
                        <mat-select multiple placeholder="Options" [formControl]="selectedOptions">
                            <mat-option *ngFor="let op of options" [value]="op">{{ op }}</mat-option>
                        </mat-select> -->





                        <!-- 
                        <select 
                            class=" form-control input_bdr_clr ">
                            <option [value]="[item.id]" 
                                *ngFor="let item of areaExpertiseList">
                                {{item.expertise}} </option>

                        </select> -->



                        <!-- <select 
                        class=" form-control input_bdr_clr " 
                        (change)="emotionItem($event)"
                         >
                        <option >Select an Emotion</option>
                        <option [value]="[item.id]" [selected]="coach_areaOfExpertise == item.id" *ngFor="let item of areaExpertiseList">
                            {{item.expertise}} </option>

                    </select> -->









                        <!-- <div *ngIf="submitted && registerForm.get('areaOfExpertise').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('areaOfExpertise').errors.required">Area of Expertise is
                                required</div>
                        </div> -->

                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Language</label>
                      
                        <div class="form-control" style="height:48px;">
                            <mat-form-field style="height:30px; width:420px;">
                                <mat-select [formControl]="toppingsLang" multiple>
                                    <mat-option *ngFor="let item of languageList" [value]="item.id"
                                        (onSelectionChange)="onSelectEventLan($event, item)">{{item.language}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                        </div>
                        

                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Types of Therapy/Methodology</label>
                        <input type="text" [(ngModel)]="coach_typeOfTherapy" id="methodology"
                            formControlName="methodology" class="form-control input_bdr_clr" />

                        <div *ngIf="submitted && registerForm.get('methodology').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('methodology').errors.required">Types of Therapy is required
                            </div>
                        </div>

                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Introduction video</label>
                        <input type="text" [(ngModel)]="introVideo" id="introVideo" formControlName="introVideo"
                            class="form-control input_bdr_clr" />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Years of Experience</label>
                        <input type="text" [(ngModel)]="coach_Experience" id="exPerience"
                            formControlName="yearsOfExperience" class="form-control input_bdr_clr" />
                        <div *ngIf="submitted && registerForm.get('yearsOfExperience').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('yearsOfExperience').errors.required">Years of Experience is
                                required</div>
                        </div>

                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Coach type</label>
                        <select type="text" [(ngModel)]="coach_Type" id="coachType" formControlName="coachType"
                            class="form-control input_bdr_clr">
                            <option value="psychologist">Psychologist</option>
                            <option value="psychiatrist">Psychiatrist</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">First fees</label>
                        <input type="text" [(ngModel)]="first_fees" id="firstFees" formControlName="firstFees"
                            class="form-control input_bdr_clr" />
                        <div *ngIf="submitted && registerForm.get('first_fees').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('first_fees').errors.required">First fees is required</div>
                        </div>

                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Subsequent fees</label>
                        <input type="text" [(ngModel)]="subs_fees" id="subsFees" formControlName="subsFees"
                            class="form-control input_bdr_clr" />
                        <div *ngIf="submitted && registerForm.get('subs_fees').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('subs_fees').errors.required">Subsequent fees is required</div>
                        </div>

                    </div>



                    <!-- <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Accrediting Body</label>
                        <input type="text" id="accrediting" [(ngModel)]="coach_Accredting_Body"
                            formControlName="accreditingBody" class="form-control input_bdr_clr" />

                        <div *ngIf="submitted && registerForm.get('accreditingBody').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('accreditingBody').errors.required">Accrediting Body is
                                required</div>
                        </div>

                    </div> -->
                    <!-- <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy"> Upload Certificate / Training Item</label>
                        <input type="file" type="file" id="files"
                        (change)="handleFileSelect($event)" 
                            formControlName="uploadCertificate" class="form-control input_bdr_clr" />

                        <div *ngIf="submitted && registerForm.get('uploadCertificate').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('uploadCertificate').errors.required">Upload Certificate is
                                required</div>
                        </div>

                    </div> -->





                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn submit_btn_clr">Submit</button>

                    </div>


                </form>

            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>

        </div>





    </div>

    <!-- <div class="marg-t-40">
 <div class="row justify-content-center marg-0">
            <span> <button class="button_size submit_clr font_bold_gilroy"    >Submit</button>  </span> <span><button class="button_size marg-l-16 cancelBtn_clr" >Cancel</button></span>
        </div>
   </div> -->



</div>