import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isShown: boolean = false;

  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }

  constructor(private router: Router) { }
  logutSubmit_url = '/';
  ngOnInit(): void {

    let myContainer = document.getElementById('wrapper') as HTMLInputElement;
    myContainer.value = "Hello World";
    const $button = document.querySelector('#sidebar-toggle');
    const $wrapper = document.querySelector('#wrapper');

    $button.addEventListener('click', (e) => {
      e.preventDefault();
      $wrapper.classList.toggle('toggled');
    });
    // -------------------------------

    var dropdown = document.getElementsByClassName("dropdown-btn");
    var i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }


  }
  logoutConfirm() {
    this.router.navigate([this.logutSubmit_url]);
    localStorage.clear();
    // window.location.reload();
  }


  // ----------------------------------------------



}
