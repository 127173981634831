<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/community-events-list']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Community Event Edit</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form [formGroup]="registerForm">

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Event Name *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" formControlName="eventName"
                            maxlength="50">
                        <div *ngIf="submitted && registerForm.get('eventName').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('eventName').errors.required">Event Name is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                        <input type="text" class="form-control input_bdr_clr font_text"
                            formControlName="eventDescription" maxlength="500">
                        <div *ngIf="submitted && registerForm.get('eventDescription').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('eventDescription').errors.required">Event Description is
                                required
                            </div>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Topic Name</label>
                        <select (change)="onEventTypeChange($event)" formControlName="eventNameType"
                            class=" form-control input_bdr_clr ">
                            <option [disabled]="true" [selected]="true">Select any type</option>
                            <option [value]="[item.id]" *ngFor="let item of getCommunityEventList">
                                {{item.topic_name}} </option>
                        </select>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Date</label>
                        <input type="date" class="form-control input_bdr_clr font_text" formControlName="dateRequired"
                        [min]="today">
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Location *</label>
                        <input type="text" class="form-control input_bdr_clr font_text"
                            formControlName="locationRequired" maxlength="20">
                        <div *ngIf="submitted && registerForm.get('locationRequired').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('locationRequired').errors.required">Location is
                                required
                            </div>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Start Time</label>

                        <div class="time-picker">

                            <select style="height: 30px; width: 50px;" formControlName="startTimeHour"
                                [(ngModel)]="selectedHour1" (change)="openTimePicker1()">
                                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;"
                                formControlName="startTimeMinutes" (change)="openTimePicker1()"
                                [(ngModel)]="selectedMinute1">
                                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                            </select>

                            <select style="margin-left: 2px;height: 30px; width: 50px;"
                                formControlName="startTimePeriod" [(ngModel)]="selectedPeriod1"
                                (change)="period($event)">
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>

                        </div>
                    </div>



                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">End Time</label>

                        <div class="time-picker">

                            <select style="height: 30px; width: 50px;" formControlName="endTimeHour"
                                (change)="openTimePicker2()" [(ngModel)]="selectedHour2">
                                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;" formControlName="endTimeMinutes"
                                (change)="openTimePicker2()" [(ngModel)]="selectedMinute2">
                                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                            </select>

                            <select style="margin-left: 2px;height: 30px; width: 50px;" formControlName="endTimePeriod"
                                [(ngModel)]="selectedPeriod2" (change)="period($event)">
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>

                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Amount *</label>
                        <input type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            maxlength="6" class="form-control input_bdr_clr font_text" formControlName="amountRequired">
                        <div *ngIf="submitted && registerForm.get('amountRequired').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('amountRequired').errors.required">Amount is required
                            </div>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Status</label>
                        <div>
                            <span>
                                <input type="radio" id="active" formControlName="status" name="status" value="1">
                                <label for="active" class="marg-l-8"> Active </label>
                            </span>

                            <span>
                                <input class="marg-l-8" type="radio" id="inactive" formControlName="status"
                                    name="status" value="0">
                                <label class="marg-l-8"> Inactive</label>
                            </span>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Event File</label>
                        <div>
                            <input type="file" class="file-upload" accept=".png, .jpg, .jpeg"
                                (change)="fileupload($event)">
                            <img *ngIf="eventimage" height="100px" width="100px" [src]="eventimage" alt="Reward Image">
                        </div>
                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr"
                            (click)="submitAddCommunityEvent()">Save</button>
                        <button type="button" style="cursor: pointer;" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="cancelAddEvents()">Cancel</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>