import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-happiness-sos-view',
  templateUrl: './happiness-sos-view.component.html',
  styleUrls: ['./happiness-sos-view.component.scss']
})
export class HappinessSosViewComponent implements OnInit {

  emotions:any;
  show_Feelings:any;
  show_FeelingsNo:any;
  statusActive:any;
  statusInActive:any;
  noOfQuestions:any;
  sos_Viewid:any;
  isShown: boolean = false;
  constructor(private ApiService: ApiService) { }

  async ngOnInit() {
    this.sos_Viewid = this.ApiService.sosview_Id;
    var getapi = "/sos/get_sosdetails";

    (await this.ApiService.getApi(getapi)).subscribe((res) => {
   
      var myArray = res.data;
      for (var i = myArray.length; i--;) {
        var item = myArray[i];
        var ids = item.id
        if (ids ==  this.sos_Viewid) {
          this.emotions = item.emotion_name;
          this.noOfQuestions = item.no_of_question;
          this.show_Feelings = item.show_feeling_wheel;
          this.statusActive = item.is_active;
         
        }
      }

    });
  }

  statuscheck(){

  }

}
