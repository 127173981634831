import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-admin-approve',
  templateUrl: './admin-approve.component.html',
  styleUrls: ['./admin-approve.component.scss']
})
export class AdminApproveComponent implements OnInit {
  approveCoachListdata: any;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    this.getCoachUserListApi();
  }

  async getCoachUserListApi() {
    var getapi = "/approve_coach_list ";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
   
      this.approveCoachListdata = res.result;
    });

  }
  async approveCoachApi(items:any) {
   var coachUserId = items.thcoach_user_id;
   var postapi = "/approve_coach";
    var approveCoachdata = {
      "user_id": coachUserId,
      "status": "1"
    };
    (await this.ApiService.postApi(postapi, approveCoachdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              icon: 'Success!',
              text: "Approved  Succcessfully",
              type: "success"
            }, function () {
              alert('yo');
            });
          }
        }
        this.getCoachUserListApi();
      });
  }

  async cancelCoachApi(coachItem:any) {
    var coach_UserId = coachItem.thcoach_user_id;
    var postapi = "/approve_coach";
    var approveCoachdata = {
      "user_id": coach_UserId,
      "status": "3"
    };
    (await this.ApiService.postApi(postapi, approveCoachdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              icon: 'Success!',
              text: "Cancel Coach User Succcessfully",
              type: "success"
            }, function () {
              alert('yo');
            });
          }
        }
        this.getCoachUserListApi();
      });
  }


}
