import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';

@Component({
  selector: 'app-workout-viewpage',
  templateUrl: './workout-viewpage.component.html',
  styleUrls: ['./workout-viewpage.component.scss']
})
export class WorkoutViewpageComponent implements OnInit {
  datas = [];
  workoutTitle = "";
  workoutDay = "";
  workoutLevel: string = "";
  workoutRange: string = "";
  workoutQuestion: string = "";
  noOf_Question: string = "";
  questionExample: string = "";
  readMoreContent: string = "";
  showReadMore: string = "";
  isShown:boolean = false;
  constructor(private router: Router, private ApiService: ApiService) { }

  async ngOnInit() {
    var idworkout = localStorage.getItem('workoutId');
  
    var getapi = "/workout/get_daydetails";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var myArray = res.data;
      for (var i = myArray.length; i--;) {
        var item = myArray[i];
        var ids = item.id
        if (ids == idworkout) {
          this.workoutTitle = item.day_title;
          this.workoutDay = item.day;
          this.workoutLevel = item.level;
          this.workoutRange = item.range;
          this.noOf_Question = item.no_of_question;
          this.readMoreContent = item.readmore_content;
          this.showReadMore = item.show_readmore; 
        }
      }

    });
  }
}
