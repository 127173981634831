import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { CoachComponent } from './coach/coach.component';
import { WorkoutTableComponent } from './workout-table/workout-table.component';
import { WorkoutEditTableComponent } from './workout-edit-table/workout-edit-table.component';
import { CoachEditPageComponent } from './coach-edit-page/coach-edit-page.component';
import { UserTableComponent } from './user-table/user-table.component';
import { UserEditTableComponent } from './user-edit-table/user-edit-table.component';
import { UserViewPageComponent } from './user-view-page/user-view-page.component';
import { AppointmentViewPageComponent } from './appointment-view-page/appointment-view-page.component';
import { AppointmentEditPageComponent } from './appointment-edit-page/appointment-edit-page.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { HappyWorkoutEditComponent } from './happy-workout-edit/happy-workout-edit.component';
import { ContentSosEditComponent } from './content-sos-edit/content-sos-edit.component';
import { WorkoutAddComponent } from './workout-add/workout-add.component';
import { WorkoutViewpageComponent } from './workout-viewpage/workout-viewpage.component';
import { ContentSosAddComponent } from './content-sos-add/content-sos-add.component';
import { HappinessSosViewComponent } from './happiness-sos-view/happiness-sos-view.component';
import { HappinessExpeditionAddComponent } from './happiness-expedition-add/happiness-expedition-add.component';
import { HappinessExpeditionViewComponent } from './happiness-expedition-view/happiness-expedition-view.component';
import { HappinessExpeditionEditComponent } from './happiness-expedition-edit/happiness-expedition-edit.component';
// import { ExpeditionInnerTableAddComponent } from './expedition-inner-table-add/expedition-inner-table-add.component';
// import { ExpeditionInnerTableEditComponent } from './expedition-inner-table-edit/expedition-inner-table-edit.component';
// import { ExpeditionInnerTableViewComponent } from './expedition-inner-table-view/expedition-inner-table-view.component';
import { ForumManagementComponent } from './forum-management/forum-management.component';
import { ForumUserlistPageComponent } from './forum-userlist-page/forum-userlist-page.component';
import { ForumFeedlistComponent } from './forum-feedlist/forum-feedlist.component';
import { ForumAddFeedlistComponent } from './forum-add-feedlist/forum-add-feedlist.component';
import { CoachViewPageComponent } from './coach-view-page/coach-view-page.component';
import { SosFeelContentTableComponent } from './sos-feel-content-table/sos-feel-content-table.component';
import { HappinessExpenditionTableComponent } from './happiness-expendition-table/happiness-expendition-table.component';
import { HappinessWorkoutTableComponent } from './happiness-workout-table/happiness-workout-table.component';
// HappinessExpeditionEditComponent
import { QuestionWorkoutViewComponent } from './question-workout-view/question-workout-view.component';
import { QuestionWorkoutEditComponent } from './question-workout-edit/question-workout-edit.component';
import { ExerciseWorkoutEditComponent } from './exercise-workout-edit/exercise-workout-edit.component';
import { ExerciseWorkoutViewComponent } from './exercise-workout-view/exercise-workout-view.component';
import { QuestionSosEditComponent } from './question-sos-edit/question-sos-edit.component';
import { ExerciseSosEditComponent } from './exercise-sos-edit/exercise-sos-edit.component';
import { QuestionSosViewComponent } from './question-sos-view/question-sos-view.component';
import { ExerciseSosViewComponent } from './exercise-sos-view/exercise-sos-view.component';
import { QuestionExpeditionEditComponent } from './question-expedition-edit/question-expedition-edit.component';
import { QuestionExpeditionViewComponent } from './question-expedition-view/question-expedition-view.component';
import { CommunityTopicTableComponent } from './community-topic-table/community-topic-table.component';
import { CommunityTopicAddComponent } from './community-topic-add/community-topic-add.component';
import { CommunityTopicEditComponent } from './community-topic-edit/community-topic-edit.component';
import { CommunityTopicViewComponent } from './community-topic-view/community-topic-view.component';
import { CommunityFeedlistTableComponent } from './community-feedlist-table/community-feedlist-table.component';
import { CampEditExpeditionComponent } from './camp-edit-expedition/camp-edit-expedition.component';
import { CommunityUserlistTableComponent } from './community-userlist-table/community-userlist-table.component';
import { CommunityModerationTableComponent } from './community-moderation-table/community-moderation-table.component';
import { ExpeditionModuleAddComponent } from './expedition-module-add/expedition-module-add.component';
import { PaymentManagementComponent } from './payment-management/payment-management.component';
import { CouponManagementComponent } from './coupon-management/coupon-management.component';
import { CouponEditComponent } from './coupon-edit/coupon-edit.component';
import { CouponAddComponent } from './coupon-add/coupon-add.component';
import { CouponViewComponent } from './coupon-view/coupon-view.component';
import { AdminApproveComponent } from './admin-approve/admin-approve.component';
import { PostModerationTableComponent } from './post-moderation-table/post-moderation-table.component';
import { StudentPortalComponent } from './student-portal/student-portal.component';

import { StudentPortalEditComponent } from './student-portal-edit/student-portal-edit.component';
import { StudentPortalViewComponent } from './student-portal-view/student-portal-view.component';
import { StudentPortalAddComponent } from './student-portal-add/student-portal-add.component';
import { CollegeListTableComponent } from './college-list-table/college-list-table.component';
import { UpcommingAppointmentComponent } from './upcomming-appointment/upcomming-appointment.component';
import { ApproveCollegeComponent } from './approve-college/approve-college.component';
import { BlogMasterComponent } from './blog-master/blog-master.component';
import { BlogMasterAddComponent } from './blog-master-add/blog-master-add.component';
import { BlogMasterEditComponent } from './blog-master-edit/blog-master-edit.component';
import { BlogMasterViewComponent } from './blog-master-view/blog-master-view.component';
import { BlogMasterQuestioneditComponent } from './blog-master-questionedit/blog-master-questionedit.component';
import { ExercisesDatabankComponent } from './exercises-databank/exercises-databank.component';
import { ExercisesDatabankAddComponent } from './exercises-databank-add/exercises-databank-add.component';
import { ExercisesDatabankEditComponent } from './exercises-databank-edit/exercises-databank-edit.component';
import { ExercisesDatabankViewComponent } from './exercises-databank-view/exercises-databank-view.component';
import { NutritionDatabankComponent } from './nutrition-databank/nutrition-databank.component';
import { NutritionDatabankAddComponent } from './nutrition-databank-add/nutrition-databank-add.component';
import { NutritionDatabankEditComponent } from './nutrition-databank-edit/nutrition-databank-edit.component';
import { NutritionDatabankViewComponent } from './nutrition-databank-view/nutrition-databank-view.component';
import { TidbitMasterComponent } from './tidbit-master/tidbit-master.component';
import { TidbitMasterAddComponent } from './tidbit-master-add/tidbit-master-add.component';
import { TidbitMasterEditComponent } from './tidbit-master-edit/tidbit-master-edit.component';
import { TidbitMasterViewComponent } from './tidbit-master-view/tidbit-master-view.component';
// import { AssestmentResultComponent } from './assestment-result/assestment-result.component';
// import { AssessmentEditresultComponent } from './assessment-editresult/assessment-editresult.component';
// import { ResultAssesmentComponent } from './result-assesment/result-assesment.component';
import { AssestmentresultComponent } from './assestmentresult/assestmentresult.component';
import { AssestmentaddresultComponent } from './assestmentaddresult/assestmentaddresult.component';
import { AssestmenteditresultComponent } from './assestmenteditresult/assestmenteditresult.component';
import { AssestmentviewresultComponent } from './assestmentviewresult/assestmentviewresult.component';
import { AssestmentQuestionlistComponent } from './assestment-questionlist/assestment-questionlist.component';
import { AssestmentQuestionaddComponent } from './assestment-questionadd/assestment-questionadd.component';
import { AssestmentQuestioneditComponent } from './assestment-questionedit/assestment-questionedit.component';
import { CorporatePortalListComponent } from './corporate-portal-list/corporate-portal-list.component';
import { CorporatePortalAddComponent } from './corporate-portal-add/corporate-portal-add.component';
import { CorporatePortalEditComponent } from './corporate-portal-edit/corporate-portal-edit.component';
import { CorporateUserlistComponent } from './corporate-userlist/corporate-userlist.component';
import { CorporateApproveListComponent } from './corporate-approve-list/corporate-approve-list.component';
import { PackageManagerComponent } from './package-manager/package-manager.component';
import { PackageManagerAddComponent } from './package-manager-add/package-manager-add.component';
import { PackageManagerEditComponent } from './package-manager-edit/package-manager-edit.component';
import { AddapointmentmanagementComponent } from './addapointmentmanagement/addapointmentmanagement.component';
import { SettingsComponent } from './settings/settings.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { AddsubscriptionComponent } from './addsubscription/addsubscription.component';
import { EditsubscriptionComponent } from './editsubscription/editsubscription.component';
import { ReviewManagementComponent } from './review-management/review-management.component';
import { HappinessTestManagementComponent } from './happiness-test-management/happiness-test-management.component';
import { HappinessTestAddmanagementComponent } from './happiness-test-addmanagement/happiness-test-addmanagement.component';
import { HappinessTestEditmanagementComponent } from './happiness-test-editmanagement/happiness-test-editmanagement.component';
import { HappinessTestResultComponent } from './happiness-test-result/happiness-test-result.component';
import { HappinessAddTestResultComponent } from './happiness-add-test-result/happiness-add-test-result.component';
import { HappinessEditTestResultComponent } from './happiness-edit-test-result/happiness-edit-test-result.component';
import { HappinessTestCategoryResultComponent } from './happiness-test-category-result/happiness-test-category-result.component';
import { HappinessTestAddCategoryResultComponent } from './happiness-test-add-category-result/happiness-test-add-category-result.component';
import { HappinessTestEditCategoryResultComponent } from './happiness-test-edit-category-result/happiness-test-edit-category-result.component';
import { RewardslistComponent } from './rewardslist/rewardslist.component';
import { AddrewardsComponent } from './addrewards/addrewards.component';
import { EditRewardsComponent } from './edit-rewards/edit-rewards.component';
import { PreAssessmentListComponent } from './pre-assessment-list/pre-assessment-list.component';
import { AddPreAssessmentComponent } from './add-pre-assessment/add-pre-assessment.component';
import { EditPreAssessmentComponent } from './edit-pre-assessment/edit-pre-assessment.component';
import { CommunityEventsListComponent } from './community-events-list/community-events-list.component';
import { CommunityAddEventsListComponent } from './community-add-events-list/community-add-events-list.component';
import { CommunityEditEventsListComponent } from './community-edit-events-list/community-edit-events-list.component';
import { SlotManagementComponent } from './slot-management/slot-management.component';
// import { SlotManagementListComponent } from './slot-management-list/slot-management-list.component';
import { CoachSlotManagementListComponent } from './coach-slot-management-list/coach-slot-management-list.component';
import { AppointmentDetailsComponent } from './appointment-details/appointment-details.component';


const routes: Routes = [


  { path: "", component: LoginComponent },

  {
    path: 'home',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: HomeComponent, // child route component that the router renders
      },
    ],
  },


  {
    path: 'appointment',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: AppointmentComponent, // child route component that the router renders
      },
    ],
  },


  {
    path: 'coach',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CoachComponent, // child route component that the router renders
      },
    ],
  },


  {
    path: 'user-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: UserTableComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'payment',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: PaymentManagementComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'content-management',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ContentManagementComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'appointment-view-page',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: AppointmentViewPageComponent, // child route component that the router renders
      },
    ],
  },


  {
    path: 'appointment-edit-page',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: AppointmentEditPageComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'coach-edit-page',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CoachEditPageComponent, data: { shouldReuse: true } // child route component that the router renders
      },
    ],
  },
  {
    path: 'coach-view-page',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CoachViewPageComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'user-view-page',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: UserViewPageComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'user-edit-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: UserEditTableComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'workout-edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: HappyWorkoutEditComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'sos-edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ContentSosEditComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'workout-add',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: WorkoutAddComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'workout-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: WorkoutTableComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'workout-edit-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: WorkoutEditTableComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'workout-view',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: WorkoutViewpageComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'sos-add',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ContentSosAddComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'sos-view',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: HappinessSosViewComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'expedition-add-CampDetails',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: HappinessExpeditionAddComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'happiness-expedition-view',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: HappinessExpeditionViewComponent, // child route component that the router renders
      },
    ],
  },
  {
    path: 'add/appointmentlist',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: AddapointmentmanagementComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'expedition-Camp-edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: HappinessExpeditionEditComponent, // child route component that the router renders
      },
    ],
  },






  {
    path: 'forum-management',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ForumManagementComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'forum-userlist-page',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ForumUserlistPageComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'forum-feedlist',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ForumFeedlistComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'forum-add-feedlist',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ForumAddFeedlistComponent, // child route component that the router renders
      },
    ],
  },



  {
    path: 'sos-feel-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: SosFeelContentTableComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'expendition-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: HappinessExpenditionTableComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'workout-day-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: HappinessWorkoutTableComponent, // child route component that the router renders
      },
    ],
  },




  {
    path: 'question-workout-view',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: QuestionWorkoutViewComponent, // child route component that the router renders
      },
    ],
  },

  // QuestionWorkoutEditComponent

  {
    path: 'question-workout-edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: QuestionWorkoutEditComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'exercise_Workout_edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ExerciseWorkoutEditComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'exercise_Workout_view',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ExerciseWorkoutViewComponent, // child route component that the router renders
      },
    ],
  },

  {
    path: 'question-sos-edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: QuestionSosEditComponent, // child route component that the router renders
      },
    ],
  },
  {
    path: 'exercise-sos-edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ExerciseSosEditComponent, // child route component that the router renders  question-view-edit
      },
    ],
  },
  {
    path: 'question-sos-view',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: QuestionSosViewComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },

  {
    path: 'exercise-sos-view',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ExerciseSosViewComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },


  {
    path: 'question-Expedition-Edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: QuestionExpeditionEditComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },



  {
    path: 'question-Expedition-view',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: QuestionExpeditionViewComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },
  {
    path: 'expedition-module-add',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: ExpeditionModuleAddComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },

  {
    path: 'community-topic-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CommunityTopicTableComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },
  {
    path: 'community-topic-add',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CommunityTopicAddComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },
  {
    path: 'community-topic-Edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CommunityTopicEditComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },
  {
    path: 'community-topic-view',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CommunityTopicViewComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },

  {
    path: 'community-feedlist-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CommunityFeedlistTableComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },
  {
    path: 'community-userlist-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CommunityUserlistTableComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },

  {
    path: 'community-moderation-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CommunityModerationTableComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },

  {
    path: 'camp-Edit-Expedition',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CampEditExpeditionComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },

  {
    path: 'coupon-Table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CouponManagementComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },

  {
    path: 'coupon-Edit',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CouponEditComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },
  {
    path: 'coupon-Add',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CouponAddComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },
  {
    path: 'coupon-View',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: CouponViewComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },
  // CouponAddComponent
  {
    path: 'adminApprove',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: AdminApproveComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },
  {
    path: 'poster-moderation-table',
    component: HeaderComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', // child route path
        component: PostModerationTableComponent, // child route component that the router renders   sosExerciseViewPage
      },
    ],
  },

  {
    path: 'student-portal-table',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: StudentPortalComponent,
      },
    ],
  },

  // -------------------------

  {
    path: 'student-portal-Edit',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: StudentPortalEditComponent,
      },
    ],
  },

  {
    path: 'student-portal-view',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: StudentPortalViewComponent,
      },
    ],
  },
  {
    path: 'student-portal-add',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: StudentPortalAddComponent,
      },
    ],
  },
  // ---------------------------------------------------

  {
    path: 'college-portal-list',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: CollegeListTableComponent,
      },
    ],
  },
  {
    path: 'upcomming_appointment',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: UpcommingAppointmentComponent,
      },
    ],
  },

  {
    path: 'approve_College',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: ApproveCollegeComponent,
      },
    ],
  },

  {
    path: 'blog_master_list',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: BlogMasterComponent,
      },
    ],
  },
  {
    path: 'blog_master_add',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: BlogMasterAddComponent,
      },
    ],
  },
  {
    path: 'blog_master_edit',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: BlogMasterEditComponent,
      },
    ],
  },

  {
    path: 'blog_master_view',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: BlogMasterViewComponent,
      },
    ],
  },
  //

  {
    path: 'exercisesdatabank',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: ExercisesDatabankComponent,
      },
    ],
  },

  {
    path: 'exercisesAddDatabank',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: ExercisesDatabankAddComponent,
      },
    ],
  },

  {
    path: 'exercisesEditDatabank',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: ExercisesDatabankEditComponent,
      },
    ],
  },

  {
    path: 'exercises_View_Databank',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: ExercisesDatabankViewComponent,
      },
    ],
  },

  {
    path: 'nutrition_Databank',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: NutritionDatabankComponent,
      },
    ],
  },

  {
    path: 'nutrition_Add_Databank',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: NutritionDatabankAddComponent,
      },
    ],
  },

  {
    path: 'nutrition_Edit_Databank',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: NutritionDatabankEditComponent,
      },
    ],
  },

  {
    path: 'nutrition_View_Databank',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: NutritionDatabankViewComponent,
      },
    ],
  },


  {
    path: 'tidbit_master',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: TidbitMasterComponent,
      },
    ],
  },
  {
    path: 'tidbit_master_add',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: TidbitMasterAddComponent,
      },
    ],
  },

  {
    path: 'tidbit_master_edit',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: TidbitMasterEditComponent,
      },
    ],
  },


  {
    path: 'tidbit_master_view',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: TidbitMasterViewComponent,
      },
    ],
  },

  {
    path: 'assestment_results',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: AssestmentresultComponent,
      },
    ],
  },

  {
    path: 'assestment_add_results',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: AssestmentaddresultComponent,
      },
    ],
  },

  {
    path: 'assestment_edit_results',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: AssestmenteditresultComponent,
      },
    ],
  },

  {
    path: 'assestment_view_results',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: AssestmentviewresultComponent,
      },
    ],
  },

  {
    path: 'pre-assessment-list',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: PreAssessmentListComponent
      }
    ]
  },

  {
    path: 'add-pre-assessment',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: AddPreAssessmentComponent
      }
    ]
  },

  {
    path: 'edit-pre-assessment',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: EditPreAssessmentComponent
      }
    ]
  },

  {
    path: 'community-events-list',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: CommunityEventsListComponent
      }
    ]
  },

  {
    path: 'community-add-events-list',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: CommunityAddEventsListComponent
      }
    ]
  },

  {
    path: 'community-edit-events-list',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: CommunityEditEventsListComponent
      }
    ]
  },

  {
    path: 'slot-management',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: SlotManagementComponent
      }
    ]
  },


  // {
  //   path:"slot-management-list",
  //   component: HeaderComponent,
  //   children:[
  //     {
  //       path:"",
  //       component: SlotManagementListComponent
  //     }
  //   ]
  // },

  {
    path: "coach-slot-management-list",
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: CoachSlotManagementListComponent
      }
    ]
  },
  

  {
    path: "appointment-details",
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: AppointmentDetailsComponent
      }
    ]
  },


  {
    path: 'rewardslist',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: RewardslistComponent
      }
    ]
  },

  {
    path: 'addrewards',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: AddrewardsComponent
      }
    ]
  },

  {
    path: 'edit-rewards',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: EditRewardsComponent
      }
    ]
  },

  {
    path: 'happiness-test-category-result',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: HappinessTestCategoryResultComponent
      }
    ]
  },

  {
    path: 'happiness-test-add-category-result',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: HappinessTestAddCategoryResultComponent
      }
    ]
  },

  {
    path: 'happiness-test-edit-category-result',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: HappinessTestEditCategoryResultComponent
      }
    ]
  },

  {
    path: 'happiness-test-result',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: HappinessTestResultComponent
      }
    ]
  },

  {
    path: 'happiness-add-test-result',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: HappinessAddTestResultComponent
      }
    ]
  },

  {
    path: 'happiness-edit-test-result',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: HappinessEditTestResultComponent
      }
    ]
  },



  {
    path: 'happiness-test-management',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: HappinessTestManagementComponent
      }
    ]
  },

  {
    path: 'happiness-test-addmanagement',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: HappinessTestAddmanagementComponent
      }
    ]
  },

  {
    path: 'happiness-test-editmanagement',
    component: HeaderComponent,
    children: [
      {
        path: "",
        component: HappinessTestEditmanagementComponent
      }
    ]
  },



  {
    path: 'assestment_question',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: AssestmentQuestionlistComponent,
      },
    ],
  },



  {
    path: 'assestment_question_add',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: AssestmentQuestionaddComponent,
      },
    ],
  },


  {
    path: 'assestment_question_edit',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: AssestmentQuestioneditComponent,
      },
    ],
  },


  {
    path: 'corporate_List',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: CorporatePortalListComponent,
      },
    ],
  },

  {
    path: 'corporate_add',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: CorporatePortalAddComponent,
      },
    ],
  },

  {
    path: 'corporate_edit',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: CorporatePortalEditComponent,
      },
    ],
  },

  {
    path: 'corporate_Userlist',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: CorporateUserlistComponent,
      },
    ],
  },

  {
    path: 'corporate_Approvelist',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: CorporateApproveListComponent,
      },
    ],
  },

  {
    path: 'packageManager',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: PackageManagerComponent,
      },
    ],
  },

  {
    path: 'packageManagerAdd',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: PackageManagerAddComponent,
      },
    ],
  },
  {
    path: 'packageManagerEdit',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: PackageManagerEditComponent,
      },
    ],
  },




  {
    path: 'settings',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: SettingsComponent,
      },
    ],
  },


  {
    path: 'settings',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: SettingsComponent,
      },
    ],
  },


  {
    path: 'subscription',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: SubscriptionComponent,
      },
    ],
  },

  {
    path: 'review-management',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: ReviewManagementComponent
      }
    ]
  },

  {
    path: 'add_subscription',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: AddsubscriptionComponent,
      },
    ],
  },
  {
    path: 'edit_subscription/:subscriptionId', // Include the parameter in the URL
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: EditsubscriptionComponent,
      },
    ],
  },

  //

];



@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  async ngOnInit() {
    console.log('rrrrrrrrrrrrrrrrrrrrrrrrrr')
  }
}
