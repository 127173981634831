import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-happiness-test-result',
  templateUrl: './happiness-test-result.component.html',
  styleUrls: ['./happiness-test-result.component.scss']
})
export class HappinessTestResultComponent implements OnInit {

  constructor(private router: Router, private ApiService: ApiService) { }

  happinessresultAdd_url = "/happiness-add-test-result";
  happinessresultEdit_url = "/happiness-edit-test-result";
  happinessresultlist: any;
  deletehappinessId: any;
  showLoaderDiv = false;
  rangeId:any

  // =============================================================

  ngOnInit(): void {
    this.getlist_happinessResultApi();
  }

  // ==============================================================

  happinessresultadd() {
    this.router.navigate([this.happinessresultAdd_url]);
  }

  // ==============================================================

  happinessresultEdit(items: any) {
    this.ApiService.happinessresultEditId = items.range_id;
    localStorage.setItem("range_id", items.range_id)
    this.router.navigate([this.happinessresultEdit_url]);
  }

  // ===============================================================

  async getlist_happinessResultApi() {

    this.showLoaderDiv = true;

    var getapi = "/listHappinesstestresult";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.happinessresultlist = res.result;
        this.showLoaderDiv = false;
      }
      console.log("result data", this.happinessresultlist)
    });

  }

  // ==================================================================

  deleteHappiness(items: any) {
    this.deletehappinessId = items.range_id
  }

  // ==================================================================

  async exercisesDelete() {
    var deleteApi = "/deleteHappinesstestresult/" + this.deletehappinessId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      if (res.status === 200) {
        this.getlist_happinessResultApi();
        location.reload();
      }
    });
  }

}

