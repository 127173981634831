<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/camp-Edit-Expedition']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Expedition Add Camp </li>
        </ol>
    </nav>
    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>
                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">Module *</label>
                        <select [(ngModel)]="module" [ngModelOptions]="{standalone: true}"
                            class=" form-control input_bdr_clr"
                            (ngModelChange)="moduleItem($event)"
                            >
                            <option [value]="item.id" *ngFor="let item of moduleTitleList ">{{item.title}}</option>
                        </select>
                    </div>
                    <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Camp * : </span>
                        <select [(ngModel)]="camp" [ngModelOptions]="{standalone: true}"
                            class=" form-control input_bdr_clr selectpicker myFakeClass"
                            (ngModelChange)="camplistTitleFun($event)"


                            >
                            <option   [value] = "[item.camp_title , item.id ] " *ngFor = "let item of campSiteList" > {{item.camp_name}} </option>
                        </select>
                    </div>

                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">Camp Title *</label>
                        <input type="text" id="questions" class="form-control input_bdr_clr " placeholder="Camp Title"
                            [(ngModel)]="campAllTitle " [ngModelOptions]="{standalone: true}" />
                    </div>

                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                    <textarea class="form-control input_bdr_clr "  style="min-height: 100px;"
                            [(ngModel)]="descriptions " [ngModelOptions]="{standalone: true}"
                             >
                         </textarea>

                    </div>






                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">No.of questions *</label>
                        <input type="text" id="questions" class="form-control input_bdr_clr "
                            placeholder="No.of question"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            [(ngModel)]="noOfquestions" [ngModelOptions]="{standalone: true}" />
                    </div>
                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr" (click)="addCampSumbit()">Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16">Cancel</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>

</div>
