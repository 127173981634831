import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
declare const $: any;
@Component({
  selector: 'app-happiness-expedition-add',
  templateUrl: './happiness-expedition-add.component.html',
  styleUrls: ['./happiness-expedition-add.component.scss']
})
export class HappinessExpeditionAddComponent implements OnInit {

  isShown: boolean = false;
  module: any;
  camp: any;
  campTitle: any;
  noOfquestions: any;
  moduleTitle: any;
  moduleTitleList = [];
  campSiteList = [];
  campSiteTitleList = [];
  campTitles: any;
  campListTitles: any;
  campAllTitle: any;
  moduleItemsId:any;
  camp_Title:any;
  camp_ListId:any;
  descriptions:any;
  expeditionAddCampUrl_url = "/expedition-Camp-edit";
  redirectLogin_url = "/"
  
  constructor(private ApiService: ApiService, private router: Router) { }

  async ngOnInit() {
    this.getModuleListApi();
    this.getCampSiteListApi();
  }

  moduleItem(moduleitems){
    this.moduleItemsId = moduleitems;
      }


  async addCampSumbit() {

    var str = this.camp;
    var myarray = str.split(',');
 
   for(var i = 0; i < myarray.length; i++)
    {
    this.camp_ListId = myarray[1];
 }
    var postapi = "/create_expedition";
    var addCampdata = {
      "no_of_question": this.noOfquestions,
      "stress_id": this.moduleItemsId,
      "camp_id": this.camp_ListId,
      "camp_title":this.campAllTitle,
      "camp_description":this.descriptions
    };
    var stressModuleId =  this.ApiService.expeditionModuleItems;
     if(stressModuleId != this.moduleItemsId ){

      (await this.ApiService.postApi(postapi, addCampdata)).subscribe(
        res => {
    
   if (res) {
   if (res.status === 200) {
              swal({
                title: "Success!",
                text: "Expedition Added Successfully ",
                type: "success"
              }, function () {
                alert('yo');
              });
              this.ApiService.expeditionAdd_Id = res.data;
              this.ApiService.expeditionModuleItems = this.moduleItemsId;
      this.router.navigate([this.expeditionAddCampUrl_url]);
             
            }

            else if (res.status === 401) {
              this.router.navigate([this.redirectLogin_url]);
   
              swal({
                icon: 'error',
                title: 'Oops...',
                text: "Unauthorized access",
                type: "error"
              }, function () {
                alert('yo');
              });
              }

          }
        });

     }
     else if(stressModuleId == this.moduleItemsId){

      swal({
                title: "error!",
                text: "Expedition Module Alredy  Added",
                type: "error"
              }, function () {
                alert('yo');
              });
     }



  }
  // ---------------module title list get api-------------------------------------
  async getModuleListApi() {
    var getapi = "/get_expedition_title";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.moduleTitleList = res.data;

       for(let item of  this.moduleTitleList){
         var raja = item.id ;
       }

    });

  }

  async getCampSiteListApi() {

    var getapi = "/get_camp_site";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.campSiteList = res.data.reverse();

    });

  }

  camplistTitleFun(campitems){
    this.camp_Title = campitems;
 var str = this.camp_Title;
    var myarray = str.split(',');
  for(var i = 0; i < myarray.length; i++)
    {
      this.campAllTitle = myarray[0];

    }
 
   }

}
