import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-happiness-test-category-result',
  templateUrl: './happiness-test-category-result.component.html',
  styleUrls: ['./happiness-test-category-result.component.scss']
})
export class HappinessTestCategoryResultComponent implements OnInit {

  constructor(private router: Router, private ApiService: ApiService) { }

  
  happinessCategoryresultAdd_url = "/happiness-test-add-category-result";
  happinessresultEdit_url = "/happiness-test-edit-category-result";
  happinessCategoryresultlist: any;
  deletehappinessId: any;
  showLoaderDiv = false;
  rangeId:any

  // =============================================================

  ngOnInit(): void {
    this.getlist_happinessResultApi();
  }

  // ==============================================================

  happinessCategoryresultadd() {
    this.router.navigate([this.happinessCategoryresultAdd_url]);
  }

  // ==============================================================

  happinessCategoryresultEdit(items: any) {
    this.ApiService.happinessCategoryresultEditId = items.category_id;
    sessionStorage.setItem("category_id", items.category_id)
    this.router.navigate([this.happinessresultEdit_url]);
  }

  // ===============================================================

  async getlist_happinessResultApi() {

    this.showLoaderDiv = true;

    var getapi = "/listHappinesstestcategoryresult";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.happinessCategoryresultlist = res.result;
        this.showLoaderDiv = false;
      }
      console.log("result data", this.happinessCategoryresultlist)
    });

  }

  // ==================================================================

  deleteCategoryHappiness(items: any) {
    this.deletehappinessId = items.category_id
  }

  // ==================================================================

  async exercisesCategoryDelete() {
    var deleteApi = "/deleteHappinesstestcategoryresult/" + this.deletehappinessId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      if (res.status === 200) {
        this.getlist_happinessResultApi();
        location.reload();
      }
    });
  }

}

