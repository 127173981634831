<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/expedition-Camp-edit']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Question Expedition View</li>
        </ol>
    </nav>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <div>
                        <form id="question_form_Id">
                            <div class="form-group marg-t-20">

                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                                    <input disabled type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="questions" [ngModelOptions]="{standalone: true}" />
                                </div>


                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question Order*</label>
                                    <input disabled type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="questionOrder" [ngModelOptions]="{standalone: true}" />
                                </div>


                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Answer Placeholder *</label>
                                    <!-- <input type="text" id="" class="form-control input_bdr_clr "
                                        [(ngModel)]="answerPlaceholder" [ngModelOptions]="{standalone: true}" /> -->
                                        <ckeditor 
                                        name="myckeditor"
                                        [(ngModel)]="answerPlaceholder" [ngModelOptions]="{standalone: true}"
                                       
                                        debounce="500" 
                                >
                              </ckeditor>      
                                </div>


                                <div class="marg-t-20">
                                    <span class="fsize16 clr_black font_bold_gilroy">View Beliefs * :
                                    </span>
                                    <span>
                                        <label for="vehicle1"> Yes</label>
                                        <input disabled class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs" value="1"
                                            [(ngModel)]="view_Beliefs" [ngModelOptions]="{standalone: true}">
                                    </span>

                                    <span>
                                        <label class="marg-l-8" for="vehicle1">No</label>
                                        <input disabled class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs" value="0"
                                            [(ngModel)]="view_Beliefs" [ngModelOptions]="{standalone: true}">
                                    </span>
                                </div>

                                <div class="marg-t-20">
                                    <span class="fsize16 clr_black font_bold_gilroy">Collect Response * :
                                    </span>
                                    <span>
                                        <label for="vehicle1"> Yes</label>
                                        <input disabled class="marg-l-8" type="radio" id="vehicle1" name="collectResponses"
                                            value="1" [(ngModel)]="collectResponse"
                                            [ngModelOptions]="{standalone: true}">
                                    </span>

                                    <span>
                                        <label class="marg-l-8" for="vehicle1">No</label>
                                        <input disabled class="marg-l-8" type="radio" id="vehicle1" name="collectResponses"
                                            value="0" [(ngModel)]="collectResponse"
                                            [ngModelOptions]="{standalone: true}">
                                    </span>
                                </div>
                                <div class="marg-t-20" *ngIf = "viewCampId > 1">
                                    <span class="fsize16 clr_black font_bold_gilroy">Display Response From
                                        Previous Camp site * : </span>
                                    <span>
                                        <label for="vehicle1"> Yes</label>
                                        <input disabled class="marg-l-8" type="radio" id="vehicle1" name="vehicle1" value="1"
                                            (change)="responsePreviousCampSiteYes()" [(ngModel)]="responsFromPrevious"
                                            [ngModelOptions]="{standalone: true}">
                                    </span>

                                    <span>
                                        <label class="marg-l-8" for="vehicle1">No</label>
                                        <input disabled class="marg-l-8" type="radio" id="vehicle1" name="vehicle1"
                                            (change)="responsePreviousCampSiteYes()" value="0"
                                            [(ngModel)]="responsFromPrevious" [ngModelOptions]="{standalone: true}">
                                    </span>
                                </div>

                                <div *ngIf="previousCampSite_Yes_Div">
                



                                    <div *ngIf="previousCampSite_Yes_Div"  >
                                        <div class="marg-t-20" *ngIf = "viewCampId > 1">
                                            <span class="fsize16 clr_black font_bold_gilroy">Camp * : </span>
                                            <select disabled [(ngModel)]="prevCampid" [ngModelOptions]="{standalone: true}"
                                                class=" form-control input_bdr_clr"
                                            
                                               >
                                                <option [value]="[item.id] "
                                                [selected]="prevCampid == item.id"
                                                    *ngFor="let item of campSiteList">
                                                    {{item.camp_name}} </option>
                                            </select>
                                        </div>
    
                                        <div class="form-group marg-t-20" *ngIf = "viewCampId > 1">
                                            <label class="fsize16 clr_black font_bold_gilroy">Camp site Question
                                                *</label>
                               
                                            <select  disabled
                                                class=" form-control input_bdr_clr ">
                                                <option [value]="[item.id]"
                                                [selected]="prevCampQuestionId == item.id"
                                                *ngFor="let item of previousCampsiteList">
                                                    {{item.question}} </option>
                                            </select>
                                        </div>
                                    <div  *ngIf = "viewCampId >=3" >
    
                                               <div class="marg-t-20">
                                            <span class="fsize16 clr_black font_bold_gilroy">Camp   : </span>
                                            <select disabled [(ngModel)]="prevCampidTwo" [ngModelOptions]="{standalone: true}"
                                                class=" form-control input_bdr_clr"
                                              >
                                                <option [value]="[item.id]"
                                                [selected]="prevCampidTwo == item.id"
                                              
                                                    *ngFor="let item of campSiteList">
                                                    {{item.camp_name}} </option>
                                            </select>
                                        </div>
    
                                        <div class="form-group marg-t-20">
                                            <label class="fsize16 clr_black font_bold_gilroy">Camp site Question 
                                                </label>
                                              
                                            <select  disabled
                                         class=" form-control input_bdr_clr">
                                                <option [value]="[item.id] "
                                                [selected]="prevCampQuestionIdTwo == item.id"
                                                    *ngFor="let item of previousCampsiteListTwo">
                                                    {{item.question}} </option>
                                            </select>
                                        </div>
    
                                        </div>
    
    
    
    
    
    
                                    </div>


                                </div>



                            </div>

                        </form>
                    </div>


                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>