import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var swal: any;
@Component({
  selector: 'app-community-topic-add',
  templateUrl: './community-topic-add.component.html',
  styleUrls: ['./community-topic-add.component.scss']
})
export class CommunityTopicAddComponent implements OnInit {
  topicsName: any;
  descriptions: any;
  base64textString: any;
  selectedFile: any;
  isShown: boolean = false;
  categoryImageURL: any;
  type_Type: any;

  eventimage: string | undefined;


  community_topicPage = "/community-topic-table";
  registerForm: FormGroup;
  submitted = false;
  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.registerForm = this.fb.group(
      {
        topicname: ["", Validators.required],
        topicImage: ["", Validators.required],
        topicDescription: ["", Validators.required],
      },
    );


  }



  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    this.type_Type = file.type;

    if (files && file) {
      this.selectedFile = file;  // Store the selected file
      console.log("this.selectedFile", this.selectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        this.eventimage = reader.result as string;
      };
      reader.readAsDataURL(file);  // Read the file as a data URL for display
    }
  }


  async CommunityTopicSubmit() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var communityAdddata = new FormData();
    communityAdddata.append('topic_name', this.topicsName);
    communityAdddata.append('description', this.descriptions);
    communityAdddata.append('image', this.selectedFile);

    console.log("communityAdddata", communityAdddata)

    var postapi = "/admin_add_community";
    (await this.ApiService.postApi(postapi, communityAdddata)).subscribe(
      res => {

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Community Created Successfully ",
            type: "success"
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.community_topicPage]);
        }
      });
  }

  getimage(event: any) {

    this.categoryImageURL = event.base64;

  }
  cancelBtn() {
    this.router.navigate([this.community_topicPage]);
  }

}
