import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
declare var swal: any;
@Component({
  selector: 'app-assestment-questionedit',
  templateUrl: './assestment-questionedit.component.html',
  styleUrls: ['./assestment-questionedit.component.scss']
})
export class AssestmentQuestioneditComponent implements OnInit {

  isShown: boolean = false;
  // container: any;
  container: {
    no: string,
    question: string,
    never: string,
    order_no: string,
    fairly_often: string,
    almost_never: string,
    sometimes: string,
    very_often: string,
    id: string
  }[] = [{ no: "", id: "", question: "", order_no: "", never: "", fairly_often: "", almost_never: "", sometimes: "", very_often: "" }];
  keyValue: any;
  getAllEmotion: any;
  emotionsId: any;
  assestment_List: any;
  assestmentQuestionEditId: any;
  assestmentDeleteId: any;
  assestmentBack_url = "/assestment_question"
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.assestmentQuestionEditId = this.ApiService.assestment_questionEditId;
    this.assestmentQuestionEditId = localStorage.getItem("assestmentQuestionEditIds")
    this.getEmotionListApi();
    this.getlist_assestmentList();
  }

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getAllEmotion = res.data
    });
  }

  async assestmentUpdateSubmit() {
    console.log('this.container');

    this.container = this.container.map((item) => {
      console.log(item.order_no);

      var data = { ...item, no: item.order_no }
      delete data.order_no
      return data;
    })
    console.log('this.container', this.container);


    var putApi = "/add_and_update_assessment/" + this.assestmentQuestionEditId;
    var exerciseUpdatedata = {
      "emotion_id": this.emotionsId,
      "assessment": this.container
    };

    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Assessment Question Updated Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.assestmentBack_url]);
          }
          else if (res.status === 401) {
          }
        }
      });
  }

  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;
    console.log(this.emotionsId)

  }
  // --------------------

  async addBenefits() {
    this.container.push({ no: "", id: "", question: "", order_no: "", never: "", fairly_often: "", almost_never: "", sometimes: "", very_often: "" });
    // this.container.push({no:"",id:"",question: "", never: "", fairly_often: "", almost_never: "", sometimes: "", very_often: "" });
    this.container.map((item) => {
      return { question: item.question, never: item.never, fairly_often: item.fairly_often, almost_never: item.almost_never, sometimes: item.sometimes, very_often: item.very_often }
    })
    // this.container  = allTitle
  }

  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }
  }

  async getlist_assestmentList() {
    var getapi = "/list_assessment/" + this.assestmentQuestionEditId;
    console.log("dsdsds",getapi);
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.assestment_List = res.result;
        this.assestment_List = res.result.emotion_id;
        console.log("asasasasasas",this.assestment_List);
        // 
        this.container = res.result.assessment;

        this.emotionsId = res.result.emotion_id;
      }
    });

  }

  assestmentEditDelete(item: any) {
    this.assestmentDeleteId = item.id
  }

  delete_submit() {
    this.container = this.container.map((item, index) => {
      if (item.id == this.assestmentDeleteId) {
        return { ...item, delete: this.assestmentDeleteId };
      }
      else {
        return item;
      }
    })
  }

  assessmentBackUrl() {
    this.router.navigate([this.assestmentBack_url]);
  }

  // ngOnDestroy(){
  //   window.location.reload();
  // }


}

// ngOnDestroy(){

// }
