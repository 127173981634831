<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Expedition Table
    </div>

    <div class="marg-t-30">
        <!-- <div class="f-right marg-t-20 marg-b-20">
            <button class="add_btn" (click)="expeditionAdd()">Add</button>

        </div> -->
        <div class="">
            <table class="row-border hover table w-100" datatable style=" background-color: #fff; border-radius: 8px;"
                *ngIf="moduleTitleList.length > 0">
                <thead>
                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>Module</th>

                        <th style="width:150px">Action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr class=" font_medium_gilroy" *ngFor="let item of moduleTitleList ; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.title}}</td>

                        <td>
                            <!-- <span class="cursor_pointer" (click)=" expedition_ViewPage(item)"><img height="22px" width="22px"
                                class="" src="assets/View_icon.png" alt=""></span>  -->

                            <button style="background: #000;
                                    color: #fff;
                                    border-radius: 4px; font-size:14px; height:30px; width:94px; padding:4px;"
                                data-toggle="modal" class="cursor_pointer" data-target="#exampleModal"
                                (click)="editModule(item)">Edit
                                Module</button>

                            <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px" class=""
                                    src="assets/Edit_icon.png" alt="" (click)="expedition_EditPage(item)"></span>

                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <form [formGroup]="registerForm">

                                <div class="fsize18 font_bold_gilroy "> Edit Module Details </div>

                                <div style="margin-top: 8px;">
                                    <span class="font_bold_gilroy"> Title : </span>
                                    <input class="form-control" [(ngModel)]="editTitle"
                                    formControlName="title" type="text">

                                        <div *ngIf="submitted && registerForm.get('title').errors"
                                        class="alert alert-danger">
                                        <div
                                            *ngIf="registerForm.get('title').errors.required">
                                            Title is
                                            required</div>
                                    </div>

                                </div>

                                <div style="margin-top: 10px;">
                                    <label class="font_bold_gilroy"> Description : </label>

                                    <div>
                                        <ckeditor [(ngModel)]="editDescription" formControlName="editdescription"
                                            name="myckeditor" debounce="500">
                                        </ckeditor>

                                    </div>

                                    <div *ngIf="submitted && registerForm.get('editdescription').errors"
                                    class="alert alert-danger">
                                    <div
                                        *ngIf="registerForm.get('editdescription').errors.required">
                                        Description is
                                        required</div>
                                </div>





                                </div>

                                <div class="row" style="margin: 20px 0px 0px 0px;">

                                    <div class="font_bold_gilroy ">
                                        Status :
                                    </div>

                                    <div style="margin-left: 12px;">
                                        <span> <input type="radio" [(ngModel)]="activeStatus"
                                            formControlName="status" id="html" name="status"
                                                value="1"> Active
                                        </span>

                                        <span style="margin-left: 4px;"> <input type="radio" [(ngModel)]="activeStatus"
                                            formControlName="status" id="css" name="status"
                                                value="0"> InActive
                                        </span>
                                    </div>
                                    <!-- -------------------------------------------------------------------------------------------- -->
                                    <div *ngIf="submitted && registerForm.get('status').errors"
                                    class="alert alert-danger">
                                    <div
                                        *ngIf="registerForm.get('status').errors.required">
                                        Status is
                                        required</div>
                                </div>

                                </div>

                            </form>
                        </div>
                        <div style="margin-top:14px;"
                            class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red"
                                (click)="editModuleSaveApi()">Save</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
