<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/sos-edit']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Exercise SOS Edit</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Answer label</label>
                        <input type="text" [(ngModel)]="answerLabel" [ngModelOptions]="{standalone: true}"
                            id="questions" class="form-control input_bdr_clr font_text " />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Placeholder</label>
                        <!-- <input type="text"   [(ngModel)]="place_holder" [ngModelOptions]="{standalone: true}"
                            class="form-control input_bdr_clr font_text" /> -->

                        <ckeditor name="myckeditor" [(ngModel)]="place_holder" [ngModelOptions]="{standalone: true}"
                            debounce="500">
                        </ckeditor>



                    </div>
                    <!-- <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">File Upload</label>
                        <input type="file" style="padding: 3px;"  [(ngModel)]="file_upload" [ngModelOptions]="{standalone: true}"
                        (change)="handleFileSelect($event)" id="filePicker"
                            class="form-control input_bdr_clr font_text" />
                    </div> -->
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Position</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="position_Exercise"
                            [ngModelOptions]="{standalone: true}" />
                    </div>



                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>