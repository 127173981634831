import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { ChangeDetectorRef } from '@angular/core';
// import $ from 'jquery';
// import * as $ from 'jquery';
import { Router } from '@angular/router';
declare const $: any;
declare var swal: any;
@Component({
  selector: 'app-happy-workout-edit',
  templateUrl: './happy-workout-edit.component.html',
  styleUrls: ['./happy-workout-edit.component.scss']
})
export class HappyWorkoutEditComponent implements OnInit {
  @Input() name: string;

  getallcategory: any[]
  category_id: any

  category_name_String: string;
  allcategory: any[] = [];

  registerForm: FormGroup;
  submitted: boolean = false;
  show_text_div = false;
  show_image_div = false;
  workoutEditLevel: string = "";
  workoutEditRange: string = "";
  workoutEditDay: string = "";
  workoutEditTitle: string = "";
  noOf_Question: any;
  readMoreContentEdit: string = "";
  category_name: any;
  category_name_string: any;
  showReadMoreEdit = false;
  showReadMoreValue: any = "0";
  // ----
  count = 0;
  show_questionOne_div = false;
  show_questionTwo_div = false;
  show_questionThree_div = false;
  show_questionFour_div = false;
  show_questionFive_div = false;
  isShown: boolean = false;
  noOfcountid: any;
  // ----------------------
  position_questionType_Text = false;
  show_questionOneType_Text = false;
  questionType_Text_question = false;
  show_questionOneType_image = false;
  show_questionOneType_audio = false;
  show_answerOneType_Text = false;
  show_answerOneType_image = false;
  show_answerOneType_audio = false;
  type_Text_placeholder = false;
  showFeelingType_Text = false;
  placeholderAdd_exercise = "";
  workoutQuestion_All = [];
  workoutExercise_All = [];
  showaddExerciceBtn = false;
  showaddExerciceTable = false;
  answer_Label = "";
  positionExercise = "";
  base64textString: String = "";
  questionDeleteId = "";
  exerciseDeleteId = "";
  countAll: any;
  questionView_url = '/question-workout-view';
  questionEdit_url = '/question-workout-edit';
  exerciseView_url = '/exercise_Workout_view';
  exerciseEdit_url = '/exercise_Workout_edit';
  positions_question = "";
  workoutEdit_ids: any;
  workoutAdd_ids: any;
  showReadMandatory = false;
  yesShowReadmores = false;
  noshowReadMore = false;
  redirectLogin_url = '/';
  backurls = "/workout-day-table";

  questionTypeRegForm: FormGroup;
  submittedQuesType = false;
  questionsTypeValues: any;
  questionTypeTextForm: FormGroup;
  typeAffirmationForm: FormGroup;
  typeMinddumpForm: FormGroup;
  submittedAffirmation = false;
  submittedMinddump = false;
  exerciseRegForm: FormGroup;
  submittedExercise = false;
  // ------------
  constructor(private formBuilder: FormBuilder, private fb: FormBuilder, private ApiService: ApiService, private router: Router, private cdr: ChangeDetectorRef) { }
  async ngOnInit() {

    this.workoutEdit_ids = this.ApiService.edit_id;
    this.workoutEdit_ids = localStorage.getItem("workout_EditId")
    //
    this.workoutAdd_ids = this.ApiService.workoutAdd_id;
    // -------------------------------------------------

    // $(".modal").on("hidden.bs.modal", function(){
    //   $(".modal-body").html("");
    // });

    this.getcategoryListApi();


    $(function () {
      $('#colorselector').change(function () {
        $('.colors').hide();
        $('#' + $(this).val()).show();
      });
    });


    $("input:checkbox").on('click', function () {
      var $box = $(this);
      if ($box.is(":checked")) {
        var group = "input:checkbox[name='" + $box.attr("name") + "']";
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });

    this.registerForm = this.fb.group(
      {
        title: ['', Validators.required],
        category: [''],
        noOfquestion: ['', Validators.required]

      },
    );

    var idworkout = localStorage.getItem('inserkeyId');
    var idworkoutEdit = localStorage.getItem('EditId');


    var getapi = "/workout/get_daydetails";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {



      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Your session has been expired!",
          type: "error"
        }, function () {
          alert('yo');
        });
      }

      var myArray = res.data;
      let selectedItem = null;

      for (var i = myArray.length; i--;) {
        var item = myArray[i];
        var ids = item.id;
        var idnum = item.id;
        if (ids == this.workoutAdd_ids || idnum == this.workoutEdit_ids) {
          this.category_name = item.test_category_id;
          this.category_name_string = item.category_name;
          this.workoutEditTitle = item.day_title;
          this.workoutEditDay = item.day;
          this.workoutEditLevel = item.level;
          this.workoutEditRange = item.range;
          this.noOf_Question = item.no_of_question;
          this.readMoreContentEdit = item.readmore_content;
          this.showReadMoreValue = item.show_readmore ? "1" : "0";
          selectedItem = item; // Store the selected item
          console.log("item_------------------", item);
          break; // No need to continue once the item is found
        }
      }

      this.populateCategories(selectedItem);
      console.log(this.category_name, "category")
      // console.log("myArray", myArray);
      this.workoutQuestionSave();

    })

    // ------------------------------------------------
    var idworkout = localStorage.getItem('inserkeyId');
    // var getapi = "/workout/get_daydetails_byId?"+"day_id="+idworkout;
    // (await this.ApiService.getApi(getapi)).subscribe((res) => {

    //   this.workoutEditTitle = res.data[0].day_title;
    //   this.workoutEditDay = res.data[0].day;
    //   this.workoutEditLevel = res.data[0].level;
    //   this.workoutEditRange = res.data[0].range;
    //   this.noOf_Question = res.data[0].no_of_question;
    //   this.readMoreContentEdit = res.data[0].readmore_content;
    //    this.showReadMoreEdit = res.data[0].show_readmore;
    // });
    //  --------------------------------

    var number = 100;

    var select = '';
    var val = '';
    for (var day = 1; day <= 100; day++) {
      select += '<option val=' + day + '>' + day + '</option>';
    }
    $('select#myid').html(select);

    var selectLevel = '';
    var val = '';
    for (var day = 1; day <= 10; day++) {
      selectLevel += '<option val=' + day + '>' + day + '</option>';
    }
    $('select#workoutLevel').html(selectLevel);


    var selectRange = '';
    var val = '';
    for (var day = 1; day <= 100; day++) {
      selectRange += '<option val=' + day + '>' + day + '</option>';
    }
    $('select#workoutRange').html(selectRange);
    // -----------------

    // -----------------getDay Detail--------------
    // var getapi = "/workout/get_questions?day_id=1&type=question";

    if (this.workoutAdd_ids != "") {
      var workout_Dayids = this.workoutAdd_ids;

    }
    else if (this.workoutEdit_ids != "") {
      var workout_Dayids = this.workoutEdit_ids;

    }

    this.getQuestionApi();
    this.getExerciseApi();
    // this.showreadFunction();
    // var getapi = "/workout/get_questions?" + "day_id=" + workout_Dayids + "&type=question";
    // (await this.ApiService.getApi(getapi)).subscribe((res) => {
    //   this.workoutQuestion_All = res.data;
    //   if (this.workoutQuestion_All.length > 0) {
    //     this.showaddExerciceBtn = true;
    //   }
    //   else if (this.workoutQuestion_All.length = 0) {
    //     this.showaddExerciceBtn = false;
    //   }

    // });
    // -------------------Get Exercise-------------------------------

    // var getapi = "/workout/get_questions?" + "day_id=" + workout_Dayids + "&type=exercise";
    // (await this.ApiService.getApi(getapi)).subscribe((res) => {
    //   this.workoutExercise_All = res.data;
    //   if (this.workoutExercise_All.length > 0) {
    //     this.showaddExerciceTable = true;
    //   }
    //   else if (this.workoutExercise_All.length = 0) {
    //     this.showaddExerciceTable = false;
    //   }
    // });

    // -----------------------------------
    // this.workoutQuestionSave();

    // -----------------------------Add Question Validation------------------------------------------------------------

    this.questionTypeRegForm = this.fb.group(
      {
        questionType: ["", Validators.required],

      },
    );

    this.questionTypeTextForm = this.fb.group(
      {
        questions: ["", Validators.required],
        placeholdertext: ["", Validators.required],
        showFeeling: ["", Validators.required],
        textPosition: ["", Validators.required],
      },
    );


    this.typeAffirmationForm = this.fb.group(
      {
        questions: ["", Validators.required],
        placeholdertext: ["", Validators.required]
      },
    );

    this.typeMinddumpForm = this.fb.group(
      {
        questionsMinddump: ["", Validators.required],
        positionMinddump: ["", Validators.required]
      },
    );


    this.typeMinddumpForm = this.fb.group(
      {
        questionsMinddump: ["", Validators.required],
        positionMinddump: ["", Validators.required]
      },
    );

    this.exerciseRegForm = this.fb.group(
      {
        placeholderExercise: ["", Validators.required],
        answerLabel: ["", Validators.required],
        exercisefileUpload: ["", Validators.required],
        exercisePosition: ["", Validators.required],
      },
    );



    //



    // -------------------------------------------------






  }
  async populateCategories(selectedItem: any) {
    var getapi = "/listHappinesstestcategories";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.getallcategory = res.result
      this.allcategory = res.result;
      this.getallcategory = res.result;

      if (selectedItem) {
        console.log('Before setting:', this.category_name);
        setTimeout(() => {
          this.cdr.detectChanges();
          this.category_name = selectedItem.test_category_id;
          console.log('After setting:', this.category_name);
        }, 500);

      }
    });


  }
  async getQuestionApi() {
    if (this.workoutAdd_ids != "") {
      var workout_Dayids = this.workoutAdd_ids;

    }
    else if (this.workoutEdit_ids != "") {
      var workout_Dayids = this.workoutEdit_ids;
    }
    var getapi = "/workout/get_questions?" + "day_id=" + workout_Dayids + "&type=question" + "&id=" + this.workoutEdit_ids;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.workoutQuestion_All = res.data;

      let filteredItem = this.workoutQuestion_All.find(item => item.test_category_id == this.category_id);
      if (filteredItem) {
        this.category_name = filteredItem.category_name;
      }

      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Your session has been expired!",
          type: "error"
        }, function () {
          alert('yo');
        });
      }

      for (let item of this.workoutQuestion_All) {
        this.ApiService.workout_day_ids = item.workout_day_id;

      }
      if (this.workoutQuestion_All.length > 0) {
        this.showaddExerciceBtn = true;
      }
      else if (this.workoutQuestion_All.length = 0) {
        this.showaddExerciceBtn = false;
      }

    });
  }

  async getExerciseApi() {

    if (this.workoutAdd_ids != "") {
      var workout_Dayids = this.workoutAdd_ids;

    }
    else if (this.workoutEdit_ids != "") {
      var workout_Dayids = this.workoutEdit_ids;
    }


    var getapi = "/workout/get_questions?" + "day_id=" + workout_Dayids + "&type=exercise";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.workoutExercise_All = res.data;
      console.log("this.workoutExercise_All", this.workoutExercise_All)

      let filteredItem = this.workoutExercise_All.find(item => item.test_category_id === this.category_id);
      if (filteredItem) {
        this.category_name = filteredItem.category_name;
      }

      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Your session has been expired!",
          type: "error"
        }, function () {
          alert('yo');
        });
      }

      for (let item of this.workoutExercise_All) {
        this.ApiService.workout_exercise_dayid = item.workout_day_id;

      }

      if (this.workoutExercise_All.length > 0) {
        this.showaddExerciceTable = true;
      }
      else if (this.workoutExercise_All.length = 0) {
        this.showaddExerciceTable = false;
      }
    });
  }

  dayid(e) {
    var getedid = e.target.value;

    var getvalday = parseInt(getedid);
    if (getvalday >= 1 && getvalday <= 10) {
      $('#workoutLevel').val('1');
    }
    if (getvalday >= 11 && getvalday <= 20) {
      $('#workoutLevel').val('2');
    }

    if (getvalday >= 21 && getvalday <= 30) {
      $('#workoutLevel').val('3');
    }

    if (getvalday >= 31 && getvalday <= 40) {
      $('#workoutLevel').val('4');
    }

    if (getvalday >= 41 && getvalday <= 50) {
      $('#workoutLevel').val('5');
    }

    if (getvalday >= 51 && getvalday <= 60) {
      $('#workoutLevel').val('6');
    }

    if (getvalday >= 61 && getvalday <= 70) {
      $('#workoutLevel').val('7');
    }

    if (getvalday >= 71 && getvalday <= 80) {
      $('#workoutLevel').val('8');
    }

    if (getvalday >= 81 && getvalday <= 90) {
      $('#workoutLevel').val('9');
    }

    if (getvalday >= 91 && getvalday <= 100) {
      $('#workoutLevel').val('10');
    }
    $('#workoutRange').val(getedid);
  }

  async onSubmit() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }


    if (this.workoutAdd_ids != "") {
      var workout_Dayids = this.workoutAdd_ids;

    }
    else if (this.workoutEdit_ids != "") {
      var workout_Dayids = this.workoutEdit_ids;

    }

    var keyids = localStorage.getItem('inserkeyId');
    var putApi = "/workout/update_daydetails/" + workout_Dayids;
    var workout_Day = $("#workoutDay").val();
    var workout_Level = $("#workoutLevel").val();
    var workout_Range = $("#workoutRange").val();
    var no_Of_Question = $("#noOfQuestion").val();
    //
    var workOutEditdata = {
      "day": this.workoutEditDay,
      "no_of_question": this.noOf_Question,
      "level": workout_Level,
      "range": workout_Range,
      // ---------------------
      "show_readmore": this.showReadMoreValue,
      "readmore_content": this.readMoreContentEdit,
      "day_title": this.workoutEditTitle,
      "is_active": 1,
      "test_category_id": this.category_name
    };
    (await this.ApiService.putApi(putApi, workOutEditdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            //
            swal({
              title: "Success!",
              text: "Workout Edit Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });

            this.router.navigate([this.backurls]);

          }
          // else if (res.status === 200) {
          //     return [{ status: res.status, json: res }]
          // }
        }
      });

  }
  // ---------
  questionOne(e) {

    var questionValue = e.target.value;
    if (questionValue == '1') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = false;
      this.show_questionThree_div = false;
      this.show_questionFour_div = false;
      this.show_questionFive_div = false;
    }
    if (questionValue == '2') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = true;
      this.show_questionThree_div = false;
      this.show_questionThree_div = false;
      this.show_questionFour_div = false;
    }
    if (questionValue == '3') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = true;
      this.show_questionThree_div = true;
      this.show_questionFour_div = false;
      this.show_questionFive_div = false;
    }
    if (questionValue == '4') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = true;
      this.show_questionThree_div = true;
      this.show_questionFour_div = true;
      this.show_questionFive_div = false;
    }

    if (questionValue == '5') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = true;
      this.show_questionThree_div = true;
      this.show_questionFour_div = true;
      this.show_questionFive_div = true;
    }
  }

  add_Questions() {
    this.count++;

    this.countAll = this.count;
    var fakeArray = this.noOf_Question;
    if (fakeArray >= this.count) {

    } else {

      (document.getElementById('addQuestion') as HTMLButtonElement).disabled = true;
    }
    this.showaddExerciceBtn = true;
    // var addBtnValue = $("#addQuestion").val();
    // localStorage.setItem('coundclickId', addBtnValue);
  }
  // ----------------------------

  questionType_One(e) {
    var questionTypeOneValue = e.target.value;
    console.log(questionTypeOneValue, '..questionTypeOneValue');
    this.questionsTypeValues = e.target.value

    if (questionTypeOneValue == 'text') {
      this.questionType_Text_question = true;
      this.type_Text_placeholder = true;
      this.showFeelingType_Text = true;
      this.position_questionType_Text = true;
    }
    if (questionTypeOneValue == 'minddump') {
      this.questionType_Text_question = true;
      this.type_Text_placeholder = false;
      this.showFeelingType_Text = false;
      this.position_questionType_Text = true;
    }
    if (questionTypeOneValue == 'affirmation') {
      this.questionType_Text_question = true;
      this.type_Text_placeholder = true;
      this.showFeelingType_Text = false;
      this.position_questionType_Text = false;
    }
  }

  answerType_One(e) {

    var answertypeOneValue = e.target.value;
    if (answertypeOneValue == 'text') {
      this.show_answerOneType_Text = true;
      this.show_answerOneType_image = false;
      this.show_answerOneType_audio = false;

    }
    if (answertypeOneValue == 'image') {
      this.show_answerOneType_image = true;
      this.show_answerOneType_Text = false;
      this.show_answerOneType_audio = false;

    }
    if (answertypeOneValue == 'audio') {
      this.show_answerOneType_audio = true;
      this.show_answerOneType_Text = false;
      this.show_answerOneType_image = false;
    }


  }
  async workoutQuestionSave() {

    this.submittedQuesType = true;

    if (this.questionTypeRegForm.invalid) {
      return;
    }

    if (this.questionsTypeValues == 'text') {
      if (this.questionTypeTextForm.invalid) {
        return;
      }
    }
    if (this.questionsTypeValues == 'affirmation') {
      this.submittedAffirmation = true;
      if (this.typeAffirmationForm.invalid) {
        return;
      }
    }

    if (this.questionsTypeValues == 'minddump') {
      this.submittedMinddump = true;
      if (this.typeMinddumpForm.invalid) {
        return;
      }
    }

    // --------------------------------------------------------

    // this.loop_len = this.noOfQuestionCount;
    var postapi = "/workout/create_questions";
    var idworkout = localStorage.getItem('inserkeyId');
    var question_type = $("#workoutQuestionType" + this.count).val();
    var questionWorkout = $("#questionsWorkout" + this.count).val();
    var show_Feelings = $("#showFeeling" + this.count).val();
    var placeholders = $("#placeholder_answer" + this.count).val();
    var postionAll = $("#positions" + this.count).val();

    // if(postionAll = '0') {
    //    this.positions_question = 'No'

    // }
    // else if(postionAll = '1'){
    //   this.positions_question = 'Yes'

    // }

    // if (this.count >= postionAll) {
    //   var raaa = postionAll = this.count;

    // }

    if (this.workoutAdd_ids != "") {
      var workout_Dayids = this.workoutAdd_ids;

    }
    else if (this.workoutEdit_ids != "") {
      var workout_Dayids = this.workoutEdit_ids;

    }

    if (question_type == "affirmation") {
      postionAll = "1"
    }

    // --------------------------------------------
    var workoutQuestiondata = {
      "question": questionWorkout,
      "workout_day_id": workout_Dayids,
      "placeholder": placeholders,
      "position": postionAll,
      "question_type": question_type,
      "type": "question",
      "is_active": 1,
      "show_feelings": show_Feelings,
    };

    (await this.ApiService.postApi(postapi, workoutQuestiondata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {


            $("#exampleModalquestion .close").click()
            swal({
              title: "Success!",
              text: "Question updated successfully ",
              type: "success",
              imageWidth: 400,
              imageHeight: 400,
            },
              function () {
                alert('yo');
              });
            $("#question_form_Id").trigger("reset");
          }
        }
        this.getQuestionApi();
      });
    window.location.reload();
  }
  async addExerciseSave() {
    // $("#addExerciseModel").trigger('reset');
    //     $('#addExerciseModel').on('hidden.bs.modal', function () {
    //       $('.modal-body').find('lable,input,textarea').val('');

    // });

    // $("#addExerciseModels1").on("click", function () {

    //   $('#form-id')[0].reset();
    // });
    // ------------------------------------------------------------------------------------


    this.submittedExercise = true;


    if (this.exerciseRegForm.invalid) {
      return;
    }



    if (this.workoutAdd_ids != "") {
      var workout_Dayids = this.workoutAdd_ids;
    }
    else if (this.workoutEdit_ids != "") {
      var workout_Dayids = this.workoutEdit_ids;
    }
    var idworkout = localStorage.getItem('inserkeyId');
    var postapi = "/workout/create_questions";
    var workoutQuestiondata = {
      "workout_day_id": workout_Dayids,
      "placeholder": this.placeholderAdd_exercise,
      "answer_label": this.answer_Label,
      "position": this.positionExercise,
      "type": "exercise",
      "is_active": 1,
      "file": this.base64textString
    };
    (await this.ApiService.postApi(postapi, workoutQuestiondata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {

            $("#addExerciseModel .close").click()
            swal({
              title: "Success!",
              text: "Exercise updated successfully",
              type: "success",
              imageWidth: 400,
              imageHeight: 400,
            }, function () {
              alert('yo');
            });
            this.placeholderAdd_exercise = "";
            this.answer_Label = "";
            this.positionExercise = "";
            this.base64textString = "";

          }
        }
        this.getExerciseApi();
      });

  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];


    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);

  }
  questionView(viewitem: any) {
    this.ApiService.workoutQuestionView_id = viewitem.id;
    this.router.navigate([this.questionView_url]);

  }
  questionEdit(editItem: any) {
    this.ApiService.workoutQuestion_id = editItem.id;
    this.router.navigate([this.questionEdit_url]);
    // localStorage.setItem('questionEditId', editItem.id);
  }
  deleteQuestion(deleteitem: any) {
    this.questionDeleteId = deleteitem.id;
  }
  async questionSubmit() {
    var deleteApi = "/workout/remove_questions/" + this.questionDeleteId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      this.getQuestionApi();
      // this.ngOnInit();
    });

  }
  exerciseView(viewitem: any) {
    this.ApiService.exerciseWorkoutView_id = viewitem.id;
    this.router.navigate([this.exerciseView_url]);
    localStorage.setItem('exerciseViewId', viewitem.id);
  }
  exerciseEdit(editItem: any) {
    this.ApiService.workoutExerciseId = editItem.id;

    // localStorage.setItem('exerciseEditId', editItem.id);
    this.router.navigate([this.exerciseEdit_url]);
  }
  delete_Exercise(deleteId: any) {
    this.exerciseDeleteId = deleteId.id;
  }

  async exerciseUpdateSubmit() {
    var deleteApi = "/workout/remove_questions/" + this.exerciseDeleteId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      this.getExerciseApi();
    });
  }
  addexerbtn() {
  }

  // showreadFunction() {
  //   if (this.showReadMoreEdit == true) {
  //     this.showReadMandatory = false;
  //   }
  //   else if (this.yesShowReadmores == false) {
  //     this.showReadMandatory = true;
  //   }
  // }

  // showreadNoFunction() {
  //   if (this.noshowReadMore == false) {
  //     this.showReadMandatory = false;
  //   }
  //   else if (this.noshowReadMore == true) {
  //     this.showReadMandatory = false;
  //   }
  // }

  showReadFunction(): void {
    if (this.showReadMoreValue === "1") {
      this.showReadMandatory = false;
    }
  }

  showReadNoFunction(): void {
    if (this.showReadMoreValue === "0") {
      this.showReadMandatory = true;
    }
  }



  // to get the list by its id 

  get_category_list(event: any) {
    this.category_id = event?.target?.value;
    console.log("this.category_id", this.category_id)
  }

  //================================================

  async getcategoryListApi() {
    var getapi = "/listHappinesstestcategories";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.getallcategory = res.result
      this.allcategory = res.result;
      console.log("adddd", this.getallcategory)
    });
  }

  // ================================================

}
