
  <div class="row">
  <div class="body_bar col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-3 circle-section">
    
    <div class="container pb-5  ">
      
      <div class="row justify-content-center" style="align-items: center; height: 100vh;" >
        
        <div class="col-lg-5 col-md-6 col-sm-6 col-xs-12">  
          <div class="card cardSize shadow border-0 ">
            <img class="img-fluid" src="assets/img/Characters/happy.png">
            <div class="card-body px-lg-5 ">
              <div class="text-center text-muted mb-3 mt-4">
                <small class="login">The Happiness Admin</small>
              </div>
              <div class="text-center text-muted mb-4">
                <small class="login_text">Happiness Admin Portal</small>
              </div>
              <form [formGroup]="registerForm" role="form">
                <div class="form-group mb-3">
                  <div class=" marg-l-15">
                    <input class="form-control emailInput" placeholder=" Email id" id="emailid" type="email"
                    formControlName="email"
                    maxlength="150"
                    >
                    <div *ngIf="submitted && registerForm.get('email').errors"
                    class="alert alert-danger">
                    <div
                        *ngIf="registerForm.get('email').errors.required">
                        Email is
                        required</div>
                </div>
                  </div>




                </div>

                <div class="form-group mb-3">
                  <div class=" marg-l-15">
                    <input class="form-control emailInput" placeholder="Password" id="password" type="password"
                    maxlength="150" formControlName="password"
                    >
                    <div *ngIf="submitted && registerForm.get('password').errors"
                    class="alert alert-danger">
                    <div
                        *ngIf="registerForm.get('password').errors.required">
                        Password is
                        required</div>
                </div>


                  </div>
                </div>
        
                
                <div class=" marg-t-18 marg-l-15">
                  <button type="button" (click)="submit_btn()"     class="btn submit_button">Login</button>
                </div>
  
                <!-- <div class="row mt-3">
                  <div class="col-6">
     <a href="" class=" blue" >Forgot password?</a>
                        </div>
                  <div class="col-6 text-right">
                    <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/signup']" class=" blue">
                       Signup
                     </a>
                  </div>
                </div> -->
                
              </form>
            </div>
          </div>
         
        </div>
         </div>
    
  </div>

  </div>
</div>


