<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/student-portal-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">College Portal Add</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <form [formGroup]="registerForm">

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">College Name *</label>
                            <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="collegeName"
                                formControlName="collegename" maxlength="150" />

                            <div *ngIf="submitted && registerForm.get('collegename').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('collegename').errors.required">College Name is required
                                </div>
                            </div>

                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">College Address *</label>
                            <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="collegeAddress"
                                formControlName="collegeaddress" maxlength="150" />

                            <div *ngIf="submitted && registerForm.get('collegeaddress').errors"
                                class="alert alert-danger">
                                <div *ngIf="registerForm.get('collegeaddress').errors.required">College Address is
                                    required</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">College Code *</label>
                            <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="college_Code"
                                formControlName="collegecode" maxlength="150" />

                            <div *ngIf="submitted && registerForm.get('collegecode').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('collegecode').errors.required">College Code is
                                    required</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Active*</label>
                            <mat-slide-toggle style="padding-left: 20px;" (change)="toggleIsActive()">
                            </mat-slide-toggle>
                        </div>

                    </form>



                    <!-- <div class="form-group" style="margin-top:20px" >
                        <label class="fsize16 clr_black font_bold_gilroy">Status *</label>
                
                            <div>
                                <span>
                                    <input  type="radio" id="vehicle1" 
                                       name="vehicle1" value="1">
                                    <label class="marg-l-8" > Active </label>
                                    
                                </span>
        
                                <span>
                                    <input class="marg-l-8" type="radio" id="vehicle1" 
                                   name="vehicle1" value="0">
                                    <label class="marg-l-8" > In Active</label>
                                   
                                </span>

                            </div>
              </div> -->
                    <!-- ----------------------------------------------- -->

                    <div class="form-container" style="float: right; ">
                        <div class="form-group">

                            <button style="min-width:120px;" class="btn btn-primary click"
                                (click)="createCollege()">Create College </button>
                        </div>
                    </div>


                    <!-- ------------------------------Department--------------------------------------- -->
                    <div *ngIf="departmentDivshow">

                        <div *ngFor="let ele of container; let i = index" style="margin-top:50px;">

                            <div>
                                <div>
                                    <label class="fsize16 clr_black font_bold_gilroy">Department *</label>
                                </div>
                                <input type="text" [(ngModel)]="newArray[i].values"
                                    [ngModelOptions]="{standalone: true}"
                                    class="form-control input_bdr_clr font_text" />
                            </div>

                            <div style="margin-top:20px;">
                                <div>
                                    <label class="fsize16 clr_black font_bold_gilroy">Batch Duration *</label>
                                </div>

                                <select class="form-control input_bdr_clr font_text" [(ngModel)]="newArray[i].textValue"
                                    [ngModelOptions]="{standalone: true}">
                                    <option value="1">1 Year</option>
                                    <option value="2">2 Year</option>
                                    <option value="3">3 Year</option>
                                    <option value="4">4 Year</option>
                                    <option value="5">5 Year</option>
                                    <option value="6">6 Year</option>
                                    <option value="7">7 Year</option>
                                    <option value="8">8 Year</option>
                                </select>
                                <!-- <input class="form-control input_bdr_clr font_text" type="text" [(ngModel)]="newArray[i].textValue" [ngModelOptions]="{standalone: true}" /> -->
                            </div>
                            <!-- <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        [(ngModel)]="newArray[i].textValue"
      ></textarea> -->

                        </div>

                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px;"
                            *ngIf="addAndRemoveBtns">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addDepartments()">Add More</button>

                                <button class="btn btn-warning" (click)="removebtn()">remove</button>
                            </div>
                        </div>
                    </div>

                    <!-- <button (click)="addContainer()">Add More</button> -->


                    <!-- <div class="form-group" style="margin-top:80px;">
                        <label class="fsize16 clr_black font_bold_gilroy">Department *</label>
                        <input type="text"  disabled  id="btnExcel" class="form-control input_bdr_clr font_text" 
                        [(ngModel)]="collegeDepartment" [ngModelOptions]="{standalone: true}"
                      maxlength="150" />

                    </div>
                    <div class="form-group" style="margin-top:20px;" >
                        <label class="fsize16 clr_black font_bold_gilroy">Batch Year *</label>
                        <input type="text" disabled  id="btnExcel1" placeholder="Please Enter The Duration"  class="form-control input_bdr_clr font_text" 
                        [(ngModel)]="batchYear" [ngModelOptions]="{standalone: true}"
                        
                        maxlength="150" />

                    </div>

<div style="margin-bottom: 20px;" id="more-email"></div>
<div style="margin-bottom: 20px;" id="more-email"></div>          -->



                    <!-- <div class="form-container" style="float: right ; ">
<div class="form-group">
            <button id="addEmail" style="min-width:90px; margin-right: 20px; "  (click)="addDepartments()"  class="btn btn-primary">Add </button> 
            <button id="removeEmail" style="min-width:90px;"   class="btn btn-warning">Remove </button>
             </div>
    </div> -->



                    <div class="form-group " style="margin-top:120px ;" *ngIf="submitBtnShow">
                        <button type="submit" class="btn  submit_btn_clr" (click)="subitedd()">Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="cancelBtn()">Cancel</button>
                    </div>

                    <!-- ----------------------------------------------------------------------------------------->













                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>