<div class="padd-0-25 marg-t-70 ">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/workout-day-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Workout View</li>
        </ol>
    </nav>



    <!-- <div class="marg-t-30 fsize27 font_bold_gilroy">
        Happiness Workout - View
    </div> -->

    <!-- <div class=" padd-t-20">
<ol class="breadCrumb_flex fsize24">
            <li class="breadcrumb-item">
                <a [routerLinkActive]="['active']" (click)="isShown = false" [routerLink]="['/dashboard']">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Signup</li>
        </ol>
  </div> -->



    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Title</label>
                        <input type="text" disabled [(ngModel)]="workoutTitle" [ngModelOptions]="{standalone: true}"
                            id="questions" class="form-control input_bdr_clr font_text " />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Day</label>
                        <input type="text" disabled [(ngModel)]="workoutDay" [ngModelOptions]="{standalone: true}"
                            id="questions" class="form-control input_bdr_clr font_text " />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Level</label>
                        <input type="text" disabled [(ngModel)]="workoutLevel" [ngModelOptions]="{standalone: true}"
                            class="form-control input_bdr_clr font_text" />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Range</label>
                        <input type="text" disabled [(ngModel)]="workoutRange" [ngModelOptions]="{standalone: true}"
                            class="form-control input_bdr_clr font_text" />
                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">No.Of.Questions</label>
                        <input type="text" [(ngModel)]="noOf_Question" [ngModelOptions]="{standalone: true}" disabled
                            class="form-control input_bdr_clr font_text" />
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Read More Content</label>
                        <!-- <input type="text" disabled [(ngModel)]="readMoreContent" [ngModelOptions]="{standalone: true}"
                            id="questions" class="form-control input_bdr_clr font_text" /> -->


                            <ckeditor 
                            name="myckeditor"
                            [(ngModel)]="readMoreContent" [ngModelOptions]="{standalone: true}"
                          
                            debounce="500" 
                    >
                  </ckeditor> 



                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Show Read More</label>
                        <input type="text" disabled [(ngModel)]="showReadMore" [ngModelOptions]="{standalone: true}"
                            id="questions" class="form-control input_bdr_clr font_text" />
                    </div>


                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>