import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var swal: any;
@Component({
  selector: 'app-coupon-management',
  templateUrl: './coupon-management.component.html',
  styleUrls: ['./coupon-management.component.scss']
})
export class CouponManagementComponent implements OnInit {
  couponEdit_Url = "/coupon-Edit";
  couponAdd_Url = "/coupon-Add";
  couponView_Url = "/coupon-View"
  getCoupenlist = [];
  coupenExpiry: any;
  couponAlldata = [];
  deleteId: any;
  showLoaderDiv = false;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.getCouponListApi();
  }

  async getCouponListApi() {
    this.showLoaderDiv = true;
    var getapi = "/coupon_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.getCoupenlist = res.data;
        this.showLoaderDiv = false;
        for (let item of this.getCoupenlist) {
          var coupenExpiryDate = item.expiry;
          this.coupenExpiry = coupenExpiryDate.slice(0, 10);
          var goalsDate = moment(coupenExpiryDate).format('DD-MM-YYYY');

          this.couponAlldata.push({ ...item, expiry: goalsDate });


        }



      }
      // this.router.navigate([this.redirectLogin_url]);
      // swal({
      //   icon: 'error',
      //   title: 'Oops...',
      //   text: "Unauthorized access",
      //   type: "error"
      // }, function () {
      //   alert('yo');
      // });
    });
  }
  coupon_edit(editItem: any) {
    this.ApiService.couponEditId = editItem.id;
    localStorage.setItem("couponEditIds", editItem.id)
    this.router.navigate([this.couponEdit_Url]);
  }
  coupenAdd() {
    this.router.navigate([this.couponAdd_Url]);
  }
  coupon_view(viewItem: any) {
    this.ApiService.couponViewId = viewItem.id
    this.router.navigate([this.couponView_Url]);
  }
  deleteCoupon(deleteItem: any) {
    this.deleteId = deleteItem.id

  }

  async deleteCouponApi() {

    var postapi = "/delete_coupon";
    var coupondeleteId = {
      "id": this.deleteId
    };
    (await this.ApiService.postApi(postapi, coupondeleteId)).subscribe(
      res => {

        if (res.status === 200) {

          swal({
            title: "Success!",
            text: "Coupon Deleted successfully",
            type: "success",
          }, function () {
            alert('yo');
          });

        }
        window.location.reload();
      });

  }

}
