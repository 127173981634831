<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/pre-assessment-list']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Preassessment Quetion Add</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <form [formGroup]="registerForm">

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Input type *</label>
                        <select (change)="inputtype($event)" class="form-control input_bdr_clr"
                            formControlName="inputType">
                            <option [disabled]="true" [selected]="true">Select any type</option>
                            <option *ngFor="let type of inputTypes" [value]="type">
                                {{ type === 'option' ? 'Multiple choice' : 'Text Response' }}
                            </option>
                        </select>
                        <div *ngIf="isShown && registerForm.get('inputType').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('inputType').errors.required">Input type is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="question"
                            formControlName="questionRequired" maxlength="500">
                        <div *ngIf="isShown && registerForm.get('questionRequired').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('questionRequired').errors.required">Questions is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="answer_type === 'input'">
                        <label class="fsize16 clr_black font_bold_gilroy">Placeholder Text</label>
                        <input type="text" class="form-control input_bdr_clr font_text" maxlength="200"
                            [(ngModel)]="answer_input" [ngModelOptions]="{standalone: true}">
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Status</label>
                        <div>
                            <span>
                                <input type="radio" id="active" [(ngModel)]="isactive" name="status" value="1"
                                    formControlName="status" [checked]="isactive === '1'">
                                <label for="active" class="marg-l-8"> Active </label>
                            </span>

                            <span>
                                <input class="marg-l-8" type="radio" id="inactive" [(ngModel)]="isactive" name="status"
                                    value="0" formControlName="status">
                                <label class="marg-l-8"> Inactive</label>
                            </span>
                        </div>
                    </div>


                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr"
                            (click)="preassessmentAddSubmit()">Save</button>
                        <button type="button" style="cursor: pointer;" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="preassessmentBackUrl()">Cancel</button>
                    </div>

                </form>

            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>