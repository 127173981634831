<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        College management
    </div>

    <div class="marg-t-30">
        <div class="" style="background-color: #fff; border-radius: 8px;">

            <table class="row-border hover table w-100" datatable *ngIf="datas.length > 0" >
                <thead>
                <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;" >
                    <th>S.No</th>
               
                     <th>Name</th>
                
                    <th>College Name</th>
                    <th>Department</th> 
            
                    <th>Designation</th>
                    <!-- <th class="text-center">Action</th> -->
                </tr>
            </thead>
            <tbody >
                <tr *ngFor="let item of datas; let i=index" class="font_medium_gilroy">
                    <td>{{i+1}}</td>
             
                    <td  >{{item.thusr_name}}</td>
                    <td>{{item.college_name}}</td>
                    <td>{{item.department}}</td>
                    <!-- <td>{{item.address}}</td> -->
                   
                  
                    <td>{{item.designation}}</td>
                    <!-- <td class="text-center">{{item.thcoach_no_of_trainees}}</td> -->
                    <!-- <td class="text-center" >

                        <span class="cursor_pointer" (click)="coach_ViewPage(item)"  ><img height="22px" width="22px" class="bgr_black"
                                src="assets/View_icon.png" alt=""></span> <span class="marg-l-10 cursor_pointer"
                            (click)="edit_coach(item)"><img (click)="onSelect(item)" height="22px" width="22px"
                                class="bgr_black" src="assets/Edit_icon.png" alt=""></span>


                       

                        <span class="marg-l-10 cursor_pointer"> <button type="button" class="border_clr_none cursor_pointer"
                                data-toggle="modal" data-target="#exampleModal"> <img height="22px" width="22px"
                                (click)="delete_coach(item)"
                                    class="bgr_black" src="assets/Delete_icon.png" alt=""> </button>

                        </span>


                    </td> -->
                </tr>
            </tbody>



            </table>

        </div>

    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-body">

                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                </div>
                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal" (click)="deleteCoachApi()"  >Submit</button>
                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue" data-dismiss="modal" >Cancel</button>
                </div>
            </div>
        </div>
    </div>


</div>