<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/tidbit_master']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page"> Tidbit Master Add</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <div class="">
                    <form>
                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Emotion *</label>

                            <select (change)="emotionItem($event)" class=" form-control input_bdr_clr">
                                <option [disabled]="true" [selected]="true">Select an Emotion</option>
                                <option [value]="[item.themotion_id]" *ngFor="let item of getAllEmotion">
                                    {{item.themotion_name}} </option>
                            </select>

                        </div>


                        <div *ngFor="let ele of container; let i = index">
                            <div class="form-group marg-t-20">
                                <label class="fsize16 clr_black font_bold_gilroy">Display order *</label>
                                <input [(ngModel)]="container[i].order_no" [ngModelOptions]="{standalone: true}"
                                    type="text" id="questions" class="form-control input_bdr_clr "
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                            </div>
                            <div class="form-group ">
                                <label class="fsize16 clr_black font_bold_gilroy">Image *</label>
                                <input (change)="handleFileSelectOne($event,ele,i)" type="file" accept="image/*"
                                    id="questions" class="form-control input_bdr_clr " />
                            </div>
                        </div>



                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add</button>

                                <button *ngIf="keyValue>1" (click)="removeBenefits()"
                                    class="btn btn-warning">remove</button>
                            </div>
                        </div>



                        <div class="" style="margin-top: 80px;">
                            <span class="fsize16 clr_black font_bold_gilroy">Status * :
                            </span>
                            <span>
                                <label for="vehicle1"> Active</label>
                                <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs"
                                    [(ngModel)]="is_active" [ngModelOptions]="{standalone: true}" value="1">
                            </span>

                            <span>
                                <label class="marg-l-8" for="vehicle1">In Active</label>
                                <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs"
                                    [(ngModel)]="is_active" [ngModelOptions]="{standalone: true}" value="2">
                            </span>
                        </div>





                        <div class="form-group marg-t-40">
                            <button type="button" (click)="submitAddTidbit()"
                                class="btn  submit_btn_clr">Submit</button>
                            <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                                (click)="blogCancelBtn()">Cancel</button>
                        </div>
                    </form>
                </div>


                <div>

                </div>


            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>




    </div>

</div>