<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Payment Management
    </div>


    <div class="marg-t-30">
        <div class="" style="background-color: #fff; border-radius: 8px;">

            <table class="row-border hover table w-100" datatable *ngIf="datas.length > 0">
                <thead>
                    <tr class="font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>User Name</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Coach Name</th>
                        <th>Amount</th>
                        <th>Payment ID</th>
                        <th>Payment Status</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let data of datas; let i=index" class=" font_medium_gilroy">
                        <td>{{i+1}}</td>
                        <td>{{data.thusr_name}}</td>
                        <td>{{data.cdate}}</td>
                        <td>{{data.type}}</td>
                        <td>{{data.coach_name}}</td>
                        <td>{{data.amount}}</td>
                        <td>{{data.payment_id}}</td>
                        <td><span class="green" *ngIf="data.payment_status==1">Success</span>
                            <span class="red" *ngIf="data.payment_status==0">Pending</span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                (click)="deleteUserApi()">Submit</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

</div>