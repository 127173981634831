import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-happiness-expedition-view',
  templateUrl: './happiness-expedition-view.component.html',
  styleUrls: ['./happiness-expedition-view.component.scss']
})
export class HappinessExpeditionViewComponent implements OnInit {
  module = '';
  campName = "";
  camp_Title = "";
  noOfQuestion = "";
  isShown: boolean = false;
  getExpeditionByid_List = [];
  expedition_ViewId: any;
  descriptions:any;
  redirectLogin_url = "/"

  // innerTableAdd_url ='/expedition-inner-table-add';
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.expedition_ViewId = this.ApiService.expeditionViewId;
    this.expeditionGetByIdListApi();
  }

  async expeditionGetByIdListApi() {

    var postapi = "/admin_single_expedition";
    var getQuestiondata = {
      "exp_id": this.expedition_ViewId
    };
    (await this.ApiService.postApi(postapi, getQuestiondata)).subscribe(
      res => {
    
        if (res) {
          if (res.status === 200) {
            this.getExpeditionByid_List = res.data;
            for (let item of this.getExpeditionByid_List) {
              this.module = item.title;
              this.campName = item.camp_name;
              this.noOfQuestion = item.no_of_question;
              this.camp_Title = item.camp_title;
              this.descriptions = item.camp_description;
            }
          }
         else if (res.status === 401) {
            this.router.navigate([this.redirectLogin_url]);
  
            swal({
              icon: 'error',
              title: 'Oops...',
              text: "Unauthorized access",
              type: "error"
            }, function () {
              alert('yo');
            });
          }
        }
      });
  }




}
