<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/community-events-list']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" style="cursor: pointer;" aria-current="page">Events Add
            </li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form [formGroup]="registerForm">

                    <!-- <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Reward Type *</label>
                        <select (change)="rewarditem($event)" class=" form-control input_bdr_clr ">
                            <option [disabled]="true" [selected]="true">Select any type</option>
                            <option [value]="[item.id]" *ngFor="let item of getrewardlist">
                                {{item.category_name}} </option>
                        </select>
                    </div> -->

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Event Name *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="name"
                            formControlName="Name" maxlength="50" />

                        <div *ngIf="submitted && registerForm.get('Name').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('Name').errors.required">Event Name is required</div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="description"
                            formControlName="Descrip" maxlength="500"/>

                        <div *ngIf="submitted && registerForm.get('Descrip').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('Descrip').errors.required">Event description is
                                required
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Topic Name *</label>
                        <select class=" form-control input_bdr_clr " formControlName="eventType"
                            (change)="onEventTypeChange($event)">
                            <option [disabled]="true" [selected]="true">Select any type</option>
                            <option [value]="[item.id]" *ngFor="let item of getCommunityEventList">
                                {{item.topic_name}} </option>
                        </select>
                        <div *ngIf="submitted && registerForm.get('Descrip').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('Descrip').errors.required">Topic Name is
                                required
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Date *</label>
                        <input type="date" class="form-control input_bdr_clr font_text" [(ngModel)]="date" [min]="today"
                            formControlName="dateRequired" />

                        <div *ngIf="submitted && registerForm.get('dateRequired').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('dateRequired').errors.required">Date is
                                required
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Location *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="location"
                            formControlName="locationRequired" maxlength="20" />

                        <div *ngIf="submitted && registerForm.get('locationRequired').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('locationRequired').errors.required">Location is
                                required
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Start Time *</label>

                        <div class="time-picker">

                            <select style="height: 30px; width: 50px;" [(ngModel)]="selectedHour1"
                                formControlName="timeRequired" (change)="openTimePicker1()">
                                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;" [(ngModel)]="selectedMinute1"
                                formControlName="timeRequired" (change)="openTimePicker1()">
                                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;" formControlName="timeRequired"
                                [(ngModel)]="selectedPeriod1" (change)="periodAm($event)">
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>

                        <div *ngIf="submitted && registerForm.get('timeRequired').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('timeRequired').errors.required">Start time is required</div>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">End Time *</label>

                        <div class="time-picker">

                            <select style="height: 30px; width: 50px;" [(ngModel)]="selectedHour2"
                                formControlName="timeRequired" (change)="openTimePicker2()">
                                <option *ngFor="let hour of hours" [value]="hour">{{ hour }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;" [(ngModel)]="selectedMinute2"
                                formControlName="timeRequired" (change)="openTimePicker2()">
                                <option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</option>
                            </select> :

                            <select style="margin-left: 2px;height: 30px; width: 50px;" formControlName="timeRequired"
                                [(ngModel)]="selectedPeriod2" (change)="periodPm($event)">
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>

                        </div>

                        <div *ngIf="submitted && registerForm.get('timeRequired').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('timeRequired').errors.required">End time is required</div>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Amount *</label>
                        <input type="text"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            maxlength="6" class="form-control input_bdr_clr font_text" [(ngModel)]="amount"
                            formControlName="Amount" />

                        <div *ngIf="submitted && registerForm.get('Amount').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('Amount').errors.required">Amount is required
                            </div>
                        </div>
                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Status</label>
                        <div>
                            <span>
                                <input type="radio" id="active" [(ngModel)]="isactive"
                                    [ngModelOptions]="{standalone: true}" name="status" value="1"
                                    [checked]="isactive === '1'">
                                <label for="active" class="marg-l-8"> Active </label>
                            </span>

                            <span>
                                <input class="marg-l-8" type="radio" id="inactive" [(ngModel)]="isactive"
                                    [ngModelOptions]="{standalone: true}" name="inactive" value="0">
                                <label class="marg-l-8"> Inactive</label>
                            </span>
                        </div>
                        <!-- <div *ngIf="submitted && registerForm.get('statusRequired').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('statusRequired').errors.required">Status is
                                required
                            </div>
                        </div> -->
                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Event File *</label>
                        <div>
                            <input type="file" class="file-upload" accept=".png, .jpg, .jpeg"
                                (change)="fileupload($event)" formControlName="fileMandatory" />

                        </div>

                        <div *ngIf="submitted && registerForm.get('fileMandatory').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('fileMandatory').errors.required">Event File is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr" style="cursor: pointer;"
                            (click)="submitAddrewards()">Save</button>
                        <button type="button" style="cursor: pointer;" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="cancelAddrewards()">Cancel</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>