<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/sos-edit']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Question SOS Edit</li>
        </ol>
    </nav>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <div>
                        <form id="question_form_Id">

                            <div class="">
                                <span class="fsize16 clr_black font_bold_gilroy">ShowCognitiveBiases * :
                                </span>
                                <span>
                                    <label for="vehicle1"> Yes</label>
                                    <input class="marg-l-8 " [(ngModel)]="showCognitive"
                                        [ngModelOptions]="{standalone: true}" type="radio" id="showCognitive_yes"
                                        value="1">
                                </span>

                                <span>
                                    <label class="marg-l-8" for="vehicle1"> No</label>
                                    <input class="marg-l-8" [(ngModel)]="showCognitive"
                                        [ngModelOptions]="{standalone: true}" type="radio" id="showCognitive_no"
                                        value="0">
                                </span>
                            </div>

                            <div class="form-group marg-t-20">
                                <label class="fsize16 clr_black font_bold_gilroy">Question type
                                    *</label>
                                <select (change)="questionOne()" id="questionTypes" [(ngModel)]="question_Type"
                                    [ngModelOptions]="{standalone: true}"
                                    class=" form-control input_bdr_clr">
                                    <option selected disabled val="">Select Question type</option>

                                    <option value="text">Text</option>
                                    <option value="audio">Audio</option>
                                    <option value="affirmation">Affirmation</option>
                                    <option value="grid">Grid</option>
                                    <option value="multi_response">Multi-ResponseQuestion
                                    </option>
                                    <option value="review">Review</option>
                                    <option value="yes_or_no">Yes / No</option>
                                </select>
                            </div>

                            <div *ngIf="show_questionText_div">

                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="questions" [ngModelOptions]="{standalone: true}" />
                                </div>


                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Answer
                                        PlaceholderText *</label>
                                    <!-- <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="answer_placeholder_text" [ngModelOptions]="{standalone: true}" /> -->

                                    <ckeditor name="myckeditor" [(ngModel)]="answer_placeholder_text"
                                        [ngModelOptions]="{standalone: true}" debounce="500">
                                    </ckeditor>
                                </div>
                            </div>


                            <div *ngIf="show_questionAudio_div">
                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                                    <input type="text" [(ngModel)]="questions" [ngModelOptions]="{standalone: true}"
                                        id="questions" class="form-control input_bdr_clr " />
                                </div>

                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Audio *</label>
                                    <input type="file" style="padding: 3px;" id="questions"
                                        class="form-control input_bdr_clr " 
                                        [(ngModel)]="base64textString" [ngModelOptions]="{standalone: true}"
                                        />
                                </div>
                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Answer
                                        PlaceholderText *</label>
                                    <!-- <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="answer_placeholder_text" [ngModelOptions]="{standalone: true}" /> -->
                                    <ckeditor name="myckeditor" [(ngModel)]="answer_placeholder_text"
                                        [ngModelOptions]="{standalone: true}" debounce="500">
                                    </ckeditor>
                                </div>
                            </div>

                            <div *ngIf="affirmation_div">
                                <div class="form-group marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="questions" [ngModelOptions]="{standalone: true}" />
                                </div>


                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Answer
                                        PlaceholderText *</label>
                                    <!-- <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="answer_placeholder_text" [ngModelOptions]="{standalone: true}" /> -->

                                    <ckeditor name="myckeditor" [(ngModel)]="answer_placeholder_text"
                                        [ngModelOptions]="{standalone: true}" debounce="500">
                                    </ckeditor>



                                </div>
                            </div>


                            <div *ngIf="grid_div">

                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="questions" [ngModelOptions]="{standalone: true}" />
                                </div>

                            </div>

                            <div *ngIf="multi_response_div">
                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="questions" [ngModelOptions]="{standalone: true}" />
                                </div>

                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Answer
                                        PlaceholderText 1 *</label>
                                    <!-- <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="answer_placeholder_text" [ngModelOptions]="{standalone: true}" /> -->
                                    <ckeditor name="myckeditor" [(ngModel)]="answer_placeholder_text"
                                        [ngModelOptions]="{standalone: true}" debounce="500">
                                    </ckeditor>
                                </div>
                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Answer
                                        PlaceholderText 2 *</label>
                                    <!-- <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="answer_placeholder_text1" [ngModelOptions]="{standalone: true}" /> -->
                                    <ckeditor name="myckeditor" [(ngModel)]="answer_placeholder_text1"
                                        [ngModelOptions]="{standalone: true}" debounce="500">
                                    </ckeditor>
                                </div>
                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Answer
                                        PlaceholderText 3 *</label>
                                    <!-- <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="answer_placeholder_text2" [ngModelOptions]="{standalone: true}" /> -->

                                    <ckeditor name="myckeditor" [(ngModel)]="answer_placeholder_text2"
                                        [ngModelOptions]="{standalone: true}" debounce="500">
                                    </ckeditor>

                                </div>
                            </div>


                            <div *ngIf="review_div">
                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question 1</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="questions" [ngModelOptions]="{standalone: true}" />
                                </div>
                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question 2</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="question_one" [ngModelOptions]="{standalone: true}" />
                                </div>
                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question 3</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="question_two" [ngModelOptions]="{standalone: true}" />
                                </div>
                            </div>


                            <div *ngIf="Yes_or_No_div">

                                <!-- <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Question *</label>
                                    <input type="text" id="questions" class="form-control input_bdr_clr "
                                        [(ngModel)]="questions" [ngModelOptions]="{standalone: true}" />
                                </div> -->


                                <div class="row marg_0">
                                    <input type="radio" (change)="yesOrNo_questionType()" class="h-20 w-20" value="Yes"
                                        name="yes/No" [(ngModel)]="questionYesRadioBtn"
                                        [ngModelOptions]="{standalone: true}" />
                                    <span class="marg-l-8">Yes</span>

                                    <input type="radio" (change)="yesOrNo_questionType()" class="h-20 w-20 marg-l-10"
                                        value="No" name="yes/No" [(ngModel)]="questionYesRadioBtn"
                                        [ngModelOptions]="{standalone: true}" />
                                    <span class="marg-l-8">No</span>
                                </div>

                                <div class="form-group marg-t-20" *ngIf="yesQuestion_ShowDiv">
                                    <div class="form-group">
                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                            *</label>
                                        <input type="text" id="questions" class="form-control input_bdr_clr "
                                            [(ngModel)]="questions" [ngModelOptions]="{standalone: true}" />
                                    </div>

                                    <div class="marg-t-20">
                                          <input type="radio" id="html" name="fav_language"
                                            [(ngModel)]="showToastMessage" [ngModelOptions]="{standalone: true}"
                                            value="1">
                                          <label for="html">Show Toast Message</label><br>
                                          <input type="radio" id="css" name="fav_language" [(ngModel)]="collectResponse"
                                            [ngModelOptions]="{standalone: true}" value="0">
                                          <label for="css">Collect Response</label><br>
                                    </div>

                                    <div class="marg-t-20">
                                        <label class="fsize16 clr_black font_bold_gilroy">Yes
                                            Placeholder text *</label>
                                        <!-- <input type="text" id="questions" class="form-control input_bdr_clr "
                                            [(ngModel)]="answer_placeholder_text"
                                            [ngModelOptions]="{standalone: true}" /> -->
                                        <ckeditor name="myckeditor" [(ngModel)]="answer_placeholder_text"
                                            [ngModelOptions]="{standalone: true}" debounce="500">
                                        </ckeditor>
                                    </div>
                                </div>
                                <div class="form-group marg-t-20" *ngIf="noQuestion_ShowDiv">


                                    <!-- <div class="row marg_0">

                                            <input type="checkbox" (change)="no_yesOrNo_YesquestionType()"
                                                class="h-20 w-20" value="1" name="no"
                                                [(ngModel)]="question_No_YesRadioBtn"
                                                [ngModelOptions]="{standalone: true}" />
                                            <span class="marg-l-8">Yes</span>

                                            <input type="checkbox" (change)="no_yesOrNo_NoquestionType()"
                                                class="h-20 w-20 marg-l-10" value="0" name="no"
                                                [(ngModel)]="question_No_NoRadioBtn"
                                                [ngModelOptions]="{standalone: true}" />
                                            <span class="marg-l-8">No</span>

                                        </div>
                                      
                                        <div class="form-group marg-t-20" *ngIf="showYes_NoquestionDiv">
                                            <div class="form-group">
                                                <label class="fsize16 clr_black font_bold_gilroy">Question
                                                    *</label>
                                                <input type="text" id="questions"
                                                    class="form-control input_bdr_clr "
                                                    [(ngModel)]="no_no_question"
                                                    [ngModelOptions]="{standalone: true}" />
                                            </div>

                                          
                                            <div class="marg-t-20">
                                                  <input type="radio" id="html" name="fav_language"
                                                    [(ngModel)]="no_radiobtn"
                                                    [ngModelOptions]="{standalone: true}" value="1">
                                                  <label for="html">Show Toast Message</label><br>
                                                  <input type="radio" id="css" name="fav_language"
                                                    [(ngModel)]="no_radiobtn"
                                                    [ngModelOptions]="{standalone: true}" value="0">
                                                  <label for="css">Collect Response</label><br>
                                            </div>


                                            <div class="marg-t-20">
                                                <label class="fsize16 clr_black font_bold_gilroy">Yes
                                                    Placeholder text *</label>
                                   

                                                <ckeditor name="myckeditor"
                                                    [(ngModel)]="no_no_answer_placeholder"
                                                    [ngModelOptions]="{standalone: true}" debounce="500">
                                                </ckeditor>
                                            </div>
                                        </div>



                                        <div *ngIf = "showNo_NoquestionDiv">

                                    

                                          <div class="form-group">
                                            <label class="fsize16 clr_black font_bold_gilroy">Question
                                                *</label>
                                            <input type="text" id="questions"
                                                class="form-control input_bdr_clr "
                                                [(ngModel)]="no_no_question"
                                                [ngModelOptions]="{standalone: true}" />
                                        </div>

                                        <div class="marg-t-20">
                                              <input type="radio" id="html" name="fav_language"
                                                [(ngModel)]="no_radiobtn"
                                                [ngModelOptions]="{standalone: true}" value="1">
                                              <label for="html">Show Toast Message</label><br>
                                              <input type="radio" id="css" name="fav_language"
                                                [(ngModel)]="no_radiobtn"
                                                [ngModelOptions]="{standalone: true}" value="0">
                                              <label for="css">Collect Response</label><br>
                                        </div>


                                        <div class="marg-t-20">
                                            <label class="fsize16 clr_black font_bold_gilroy">No Placeholder
                                                text *</label>
                                            <ckeditor name="myckeditor"
                                                [(ngModel)]="no_no_answer_placeholder"
                                                [ngModelOptions]="{standalone: true}" debounce="500">
                                            </ckeditor>


                                        </div>

                                    </div> -->



                                    <div class="row marg_0">

                                        <input type="radio" (change)="responseNoquestionType($event)" class="h-20 w-20"
                                            value="0" name="no" [(ngModel)]="questionNoResponseRadioBtn"
                                            [ngModelOptions]="{standalone: true}" />
                                        <span class="marg-l-8">Get Response</span>

                                        <input type="radio" (change)="responseNoquestionType($event)"
                                            class="h-20 w-20 marg-l-10" value="1" name="no"
                                            [(ngModel)]="questionNoResponseRadioBtn"
                                            [ngModelOptions]="{standalone: true}" />
                                        <span class="marg-l-8">Logic Question</span>

                                    </div>

                                    <div class="form-group marg-t-20" *ngIf="getResponseShowDiv">
                                        <div class="form-group">
                                            <label class="fsize16 clr_black font_bold_gilroy">Question
                                                *</label>
                                            <input type="text" id="questions" class="form-control input_bdr_clr "
                                                [(ngModel)]="responseQuestions" [ngModelOptions]="{standalone: true}" />
                                        </div>


                                        <div class="marg-t-20">
                                              <input type="radio" id="html" name="fav_language"
                                                [(ngModel)]="no_radiobtn" (change)="toastmsgChange($event)"
                                                [ngModelOptions]="{standalone: true}" value="1">
                                              <label for="html">Show Toast Message</label><br>
                                              <input type="radio" id="css" name="fav_language" [(ngModel)]="no_radiobtn"
                                                (change)="collectResChange($event)"
                                                [ngModelOptions]="{standalone: true}" value="0">
                                              <label for="css">Collect Response</label><br>
                                        </div>


                                        <div class="marg-t-20">
                                            <label class="fsize16 clr_black font_bold_gilroy">
                                                Response Placeholder text *</label>


                                            <ckeditor name="myckeditor" [(ngModel)]="answer_placeholder_text"
                                                [ngModelOptions]="{standalone: true}" debounce="500">
                                            </ckeditor>
                                        </div>
                                    </div>

                                    <div *ngIf="logicQuestionShowDiv">


                                        <div class="form-group marg-t-20">
                                            <label class="fsize16 clr_black font_bold_gilroy">Question
                                                *</label>
                                            <input type="text" id="questions" class="form-control input_bdr_clr "
                                                [(ngModel)]="responseQuestions" [ngModelOptions]="{standalone: true}" />
                                        </div>

                                        <div class="marg-t-20">
                                            <input type="radio" (change)="logicNoquestionType($event)"
                                                class="h-20 w-20" value="1" name="logicno"
                                                [(ngModel)]="logicNo_YesRadiobtn"
                                                [ngModelOptions]="{standalone: true}" />
                                            <span class="marg-l-8">Yes</span>

                                            <input type="radio" (change)="logicNoquestionType($event)"
                                                class="h-20 w-20 marg-l-10" value="0" name="logicno"
                                                [(ngModel)]="logicNo_YesRadiobtn"
                                                [ngModelOptions]="{standalone: true}" />
                                            <span class="marg-l-8">No</span>


                                        </div>

                                        <div *ngIf="yesLogicQuestionDiv">


                                            <div class="marg-t-20">
                                                  <input type="radio" id="html" name="fav_language"
                                                    [(ngModel)]="no_radiobtn" (change)="toastmsgChange($event)"
                                                    [ngModelOptions]="{standalone: true}" value="1">
                                                  <label for="html">Show Toast Message</label><br>
                                                  <input type="radio" id="css" name="fav_language"
                                                    [(ngModel)]="no_radiobtn" (change)="collectResChange($event)"
                                                    [ngModelOptions]="{standalone: true}" value="0">
                                                  <label for="css">Collect Response</label><br>
                                            </div>


                                            <div class="marg-t-20">
                                                <label class="fsize16 clr_black font_bold_gilroy">Yes
                                                    Placeholder
                                                    text *</label>
                                                <ckeditor name="myckeditor" [(ngModel)]="no_logic_placeholder"
                                                    [ngModelOptions]="{standalone: true}" debounce="500">
                                                </ckeditor>


                                            </div>

                                        </div>

                                        <div *ngIf="noLogicQuestionDiv">

                                            <div class="marg-t-20">
                                                  <input type="radio" id="html" name="fav_language"
                                                    [(ngModel)]="no_radiobtn" (change)="toastmsgChange($event)"
                                                    [ngModelOptions]="{standalone: true}" value="1">
                                                  <label for="html">Show Toast Message</label><br>
                                                  <input type="radio" id="css" name="fav_language"
                                                    [(ngModel)]="no_radiobtn" (change)="collectResChange($event)"
                                                    [ngModelOptions]="{standalone: true}" value="0">
                                                  <label for="css">Collect Response</label><br>
                                            </div>


                                            <div class="marg-t-20">
                                                <label class="fsize16 clr_black font_bold_gilroy">No
                                                    Placeholder
                                                    text *</label>
                                                <ckeditor name="myckeditor" [(ngModel)]="no_logic_placeholder"
                                                    [ngModelOptions]="{standalone: true}" debounce="500">
                                                </ckeditor>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr"
                            (click)="questionUpdateSubmit()">Submit</button>
                        <button type="button" class="btn  cancelBtn_clr "
                            style="height: 40px;margin-left: 20px;font-size: 16px;"
                            (click)="questionCancel()">Cancel</button>
                    </div>

                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>