<div class="padd-0-25">
    <div *ngIf="showLoaderDiv">
        <div id="pause" class="d-flex align-items-center justify-content-center">
            <div id="spinner"></div>
        </div>
    </div>

    <div class="row">
        <div class="mx-4">
            <div class="marg-t-70   fsize27 font_bold_gilroy" style="margin-top: 6rem;">
                User Management
            </div>
        </div>
        <div class="mx-3">
            <div class="marg-t-70" style="float:right;  ">
                <!-- <button type="button" class="btn btn-secondary" (click)="tableToCSV()" -->
                <button type="button" class="btn btn-secondary" (click)="exportToExcel()"
                    style="background-color:#6CC3B8; border: 1px solid #6cc3b8; height:48px; width:210px;color:#fff;margin-right:6px;margin-top:20px;border-radius: 6px; margin-bottom:40px;"
                    data-toggle="tooltip" data-placement="top"
                    title="Export all user details on this page table to CSV document">
                    <span><img height="30px" src="../assets/file-excel.svg" alt=""></span> Download User
                </button>
            </div>
        </div>
    </div>


    <!-- <div class="row">
  <div class="col-3">

    <div class="marg-t-70   fsize27 font_bold_gilroy" style="margin-top: 6rem;">
      User Management
  </div>

  </div>
  <div class="col-3">
    <div class="marg-t-70" style="float:right;  ">
      <button type="button" class="btn btn-secondary" (click)="tableToCSV()"
          style="background-color:#6CC3B8; border: 1px solid #6cc3b8; height:48px; width:210px;color:#fff;margin-right:6px;margin-top:20px;border-radius: 6px; margin-bottom:40px;"
          data-toggle="tooltip" data-placement="top"
          title="Export all user details on this page table to CSV document">
          <span><img height="30px" src="../assets/file-excel.svg" alt=""></span> Download User
      </button>
  </div>
  </div>
  <div class="col-6"></div>
</div> -->


    <div>
        <div class="" style="background-color: #fff; border-radius: 8px;">
            <div *ngIf="loading" class="loader-container">
                <div class="loader"></div>
            </div>
            <div style="display: flex; padding-top: 30px;margin-right: 40px;justify-content: end;">
                <input type="text" placeholder="Search..." (input)="searchTable($event)"  class="form-control"
                    style="margin-bottom: 10px; width:30%;">
            </div>
            <table id="myTable" datatable class="row-border hover table w-100" [dtOptions]="dtOptions" style="padding: 0px 30px;"
                *ngIf="!loading && datas.length > 0">
                <thead>
                    <tr class="font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Registered Date</th>
                        <th>DOB</th>
                        <th>Location</th>
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>

                    <tr *ngFor="let data of datas; let i=index" class=" font_medium_gilroy">
                        <td>{{(currentPage - 1) * entriesPerPage + i + 1}}</td>
                        <td>{{data.thusr_name}}</td>
                        <td>{{data.thusr_mobile}}</td>
                        <td>{{data.thusr_email}}</td>
                        <td>{{data.registered_date}}</td>
                        <td>{{data.thusr_dob}}</td>
                        <td>{{data.thusr_location}}</td>
                        <td>
                            <!-- <span (click)="edit_view(data)"><img height="22px" width="22px" class="bgr_black"
                                  src="assets/View_icon.png" alt=""></span> -->

                            <span class="marg-l-10 cursor_pointer" (click)="edit_user(data)"><img height="22px"
                                    width="22px" class="bgr_black" src="assets/Edit_icon.png" alt=""></span>

                            <span class="marg-l-10 cursor_pointer"> <button type="button"
                                    style="outline: none; border: none; " class="border_clr_none cursor_pointer"
                                    data-toggle="modal" data-target="#exampleModal"> <img height="22px" width="22px"
                                        style="outline: none; border: none; " class="bgr_black"
                                        (click)="delete_User(data)" src="assets/Delete_icon.png" alt=""> </button>

                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="!loading && datas.length > 0"
                style="display:flex;justify-content: space-between; padding: 20px;">
                <div style="margin:0px 0px 0px 35px ;">
                    Show
                    <select [(ngModel)]="entriesPerPage" (change)="onEntriesPerPageChange()" style="margin-left: 5px;">
                        <!-- <option value="10">10</option> -->
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    Entries
                    of
                    {{totalEntries}}
                    total
                </div>
                <div class="pagination-controls" style="display: flex;gap: 20px;">
                    <button (click)="prevPage()" [disabled]="currentPage === 1">Previous</button>
                    <span>Page {{currentPage}} of {{totalPages}}</span>
                    <button (click)="nextPage()" [disabled]="currentPage === totalPages">Next</button>
                </div>
            </div>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                            <div>
                                Do you want to delete ? </div>
                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                (click)="deleteUserApi()">Confirm</button>
                            <button type="button" data-dismiss="modal"
                                class="btn_submit_cancel marg-l-10 bgr_clr_blue">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>