<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/exercisesdatabank']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Exercise Recommendation Add</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">

                <div class="">
                    <form>
                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Emotion *</label>
                            <select (change)="emotionItem($event)" class=" form-control input_bdr_clr">
                                <option [disabled]="true" [selected]="true">Select an Emotion</option>
                                <option [value]="[item.themotion_id]" *ngFor="let item of getAllEmotion">
                                    {{item.themotion_name}} </option>
                            </select>



                        </div>

                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Result *</label>
                            <select (change)="resultItem($event)" class=" form-control input_bdr_clr ">
                                <option [disabled]="true" [selected]="true">Select an Result</option>
                                <option [value]="[item.id]" *ngFor="let item of getResultAll">{{item.result}}
                                </option>

                            </select>

                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Display Order*</label>
                            <div>
                                <input type="text" class="form-control input_bdr_clr"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    [(ngModel)]="ordernoNew" [ngModelOptions]="{standalone: true}" maxlength="10" />
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Excercise Name*</label>
                            <div>
                                <input type="text" class="form-control input_bdr_clr"
                                    [(ngModel)]="exercise_name" [ngModelOptions]="{standalone: true}" maxlength="100" />
                            </div>
                        </div>

                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Category *</label>
                            <select (change)="categoryItem($event)" class=" form-control input_bdr_clr ">
                                <option [disabled]="true" [selected]="true">Select an Category</option>
                                <option [value]="[item.id]" *ngFor="let item of categoryAllList">
                                    {{item.category}} </option>
                            </select>
                        </div>



                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Exercise Thumbnail *</label>
                            <input type="file" accept="image/*" id="questions" class="form-control input_bdr_clr"
                                (change)="handleFileSelectOne($event)" />

                        </div>

                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Exercise Type *</label>
                            <select class=" form-control input_bdr_clr " (change)="questionOne($event)">
                                <option value="" disabled selected>Select Exercise Type</option>
                                <option value="1">Image</option>
                                <option value="2">Video</option>
                            </select>
                        </div>

                        <div class="form-group marg-t-20" *ngIf="showVideoDiv">
                            <label class="fsize16 clr_black font_bold_gilroy">Video url *</label>
                            <input type="text" id="questions" class="form-control input_bdr_clr "
                                [(ngModel)]="exercis_videoUrl" [ngModelOptions]="{standalone: true}" />
                        </div>

                        <div class="form-group marg-t-20" *ngIf="showImageDiv">
                            <label class="fsize16 clr_black font_bold_gilroy"> Exercise Image upload*</label>
                            <input type="file" id="questions" accept="image/*" class="form-control input_bdr_clr"
                                (change)="handleFileSelect($event)" />
                        </div>

                        <!-- <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy">Benefits for exercise*</label>
                     
                            <ckeditor name="myckeditor" [(ngModel)]="exercise_benefit"
                                [ngModelOptions]="{standalone: true}" debounce="500">
                            </ckeditor>
                        </div> -->


                        <!-- ------------------------------------------------------------------------------------------------ -->

                        <div>

                            <div *ngFor="let ele of container; let i = index" style="margin-top:30px;">


                                <div>
                                    <label class="fsize16 clr_black font_bold_gilroy">Benefits Display Order*</label>
                                    <div>
                                        <input type="text" class="form-control input_bdr_clr"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            [(ngModel)]="newArray[i].no" [ngModelOptions]="{standalone: true}"
                                            maxlength="10" />
                                    </div>
                                </div>


                                <div class="marg-t-20">
                                    <label class="fsize16 clr_black font_bold_gilroy">Benefits for exercise *</label>
                                </div>
                                <ckeditor id="benefit" name="myckeditor" [(ngModel)]="newArray[i].values"
                                    [ngModelOptions]="{standalone: true}" debounce="500">
                                </ckeditor>
                            </div>

                            <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                                <div class="form-group">
                                    <button style="margin-right:20px;" class="btn btn-primary"
                                        (click)="addBenefits()">Add Benefit</button>

                                    <button *ngIf="keyValue>1" (click)="removeBenefits()"
                                        class="btn btn-warning">remove</button>
                                </div>
                            </div>
                        </div>


                        <div class="form-group" style="margin-top:100px;">
                            <button type="button" class="btn  submit_btn_clr"
                                (click)="submitAddExercise()">Submit</button>
                            <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                                (click)="exerciseBackBtn()">Cancel</button>
                        </div>
                    </form>
                </div>

            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            </div>
        </div>

    </div>

</div>