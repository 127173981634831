import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';

declare const $: any;
declare var swal: any;
@Component({
  selector: 'app-payment-management',
  templateUrl: './payment-management.component.html',
  styleUrls: ['./payment-management.component.scss']
})
export class PaymentManagementComponent implements OnInit {

  datas = [];
  constructor(private router: Router, private ApiService: ApiService) { }
  userEdit_url = '/user-edit-table';
  availabiltyView_url = '/user-view-page';
  deleteUserId: any;
  ngOnInit() {
    this.getUserListApi();
  }
  async getUserListApi() {
    var getapi = "/admin_payment_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.datas = res.data;
      console.log("this.datas", this.datas)
    });
  }




  edit_user(editItem: any) {
    this.ApiService.adminUser_id = editItem.thusr_id

    this.router.navigate([this.userEdit_url]);
    // localStorage.setItem('userEditId', JSON.stringify(editItem.thusr_id));
  }
  edit_view(editItem: any) {

    this.router.navigate([this.availabiltyView_url]);
    localStorage.setItem('userviewId', JSON.stringify(editItem.thusr_id));

  }
  delete_User(deleteItems: any) {
    this.deleteUserId = deleteItems.thusr_id;

  }
  async deleteUserApi() {
    var postapi = "/admin_delete_user";
    var adminUserdata = {
      "id": this.deleteUserId
    };
    (await this.ApiService.postApi(postapi, adminUserdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              icon: 'Success!',
              text: "User Deleted Succcessfully",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.getUserListApi();
          }
        }
      });
  }




}
