import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';

// import $ from 'jquery';
import * as $ from 'jquery';
declare var swal: any;
@Component({
  selector: 'app-workout-add',
  templateUrl: './workout-add.component.html',
  styleUrls: ['./workout-add.component.scss']
})
export class WorkoutAddComponent implements OnInit {
  getallcategory: any[]
  category_id: any
  registerForm: FormGroup;
  startvalue: "";
  show_day_div = false;
  show_questionOne_div = false;
  show_questionTwo_div = false;
  show_questionThree_div = false;
  show_questionFour_div = false;
  show_questionFive_div = false;
  questiondata = "";
  questionOnes = "";
  questionTwo = "";
  getquestiontype: any;
  questionThree = "";
  questionFour = "";
  questionDeleteId = "";
  // -------------------------------------
  position_questionType_Text = false;
  show_questionOneType_Text = false;
  questionType_Text_question = false;
  show_questionOneType_image = false;
  show_questionOneType_audio = false;
  show_answerOneType_Text = false;
  show_answerOneType_image = false;
  show_answerOneType_audio = false;
  type_Text_placeholder = false;
  showFeelingType_Text = false;
  placeholderAdd_exercise = "";
  workoutQuestion_All = [];
  showaddExerciceBtn = false;
  questionView_url = '/question-workout-view';
  questionEdit_url = '/question-workout-edit';
  answer_Label = "";
  positionExercise = "";
  count = 0;
  myFiles: string[] = [];
  base64textString: String = "";
  noOfQuestionCount: any;
  fakeArray = new Array();
  allcount: any;
  noOfCount: any;
  loop_len: any;
  isShown: boolean = false;
  cancelbtnWorkout_url = "/workout-day-table";
  backUrl = "/workout-day-table"
  workoutEdit_url = "/workout-edit";
  insertkeyAddid = "";
  // showReadmores = false;
  showReadMandatory = false;
  // yesShowReadmores = false;
  // noShowReadmores = false;
  showReadMoreValue: any = "1";
  texteditors: any;

  readmore_Content: any;
  submitted = false;

  constructor(private fb: FormBuilder, private ApiService: ApiService, private router: Router) { }

  async ngOnInit() {
    // ---------------------
    $(function () {
      $('#colorselector').change(function () {
        $('.colors').hide();
        $('#' + $(this).val()).show();
      });
    });


    $('.panel-collapse').on('show.bs.collapse', function () {
      $(this).siblings('.panel-heading').addClass('active');
    });

    $('.panel-collapse').on('hide.bs.collapse', function () {
      $(this).siblings('.panel-heading').removeClass('active');
    });

    this.registerForm = this.fb.group(
      {
        level: ["", Validators.required],
        title: ["", Validators.required],
        category: ["", Validators.required],
        noOfQuestion: ["", Validators.required],
        // showReadMore: ["", Validators.required],
        // readMore: ["", Validators.required],
      },
    );

    var number = 100;

    var select = '';
    var val = '';
    for (var day = 1; day <= 100; day++) {
      select += '<option val=' + day + '>' + day + '</option>';
    }
    $('select#myid').html(select);

    var selectLevel = '';
    var val = '';
    for (var day = 1; day <= 10; day++) {
      selectLevel += '<option val=' + day + '>' + day + '</option>';
    }
    $('select#mylevel').html(selectLevel);


    var selectRange = '';
    var val = '';
    for (var day = 1; day <= 100; day++) {
      selectRange += '<option val=' + day + '>' + day + '</option>';
    }
    $('select#rangeid').html(selectRange);

    // -----------------------------------------------

    // var getapi = "/workout/get_questions?day_id=1&type=question";

    // (await this.ApiService.getApi(getapi)).subscribe((res) => {
    //   this.workoutQuestion_All = res.data;

    // });

    // var getapi = "/workout/get_questions?day_id=1&type=exercise";
    // (await this.ApiService.getApi(getapi)).subscribe((res) => {

    // });
    // -------------------------------
    $("input:checkbox").on('click', function () {
      var $box = $(this);
      if ($box.is(":checked")) {
        var group = "input:checkbox[name='" + $box.attr("name") + "']";
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });


    this.getcategoryListApi()


  }
  onChangeProp({ editor }) {
    this.texteditors = editor.getData()
  }
  dayid(e) {
    var getedid = e.target.value;

    var getvalday = parseInt(getedid);
    if (getvalday >= 1 && getvalday <= 10) {
      $('#mylevel').val('1');
    }
    if (getvalday >= 11 && getvalday <= 20) {
      $('#mylevel').val('2');
    }

    if (getvalday >= 21 && getvalday <= 30) {
      $('#mylevel').val('3');
    }

    if (getvalday >= 31 && getvalday <= 40) {
      $('#mylevel').val('4');
    }

    if (getvalday >= 41 && getvalday <= 50) {
      $('#mylevel').val('5');
    }

    if (getvalday >= 51 && getvalday <= 60) {
      $('#mylevel').val('6');
    }

    if (getvalday >= 61 && getvalday <= 70) {
      $('#mylevel').val('7');
    }

    if (getvalday >= 71 && getvalday <= 80) {
      $('#mylevel').val('8');
    }

    if (getvalday >= 81 && getvalday <= 90) {
      $('#mylevel').val('9');
    }

    if (getvalday >= 91 && getvalday <= 100) {
      $('#mylevel').val('10');
    }
    $('#rangeid').val(getedid);
  }
  async workout_addSubmit() {
    var globearry = []
    for (var i = 1; i <= this.getquestiontype; i++) {
      var getquestion = $("#questions" + i).val();
      var getquestiontype = $("#questionTypes" + i).val();
      var answartype = $("#answerTypes" + i).val();
      var examples = $("#examples" + i).val();
      var myobj = { "question": getquestion, "question_type": getquestiontype, "answer_type": answartype, "guide_text": examples };
      globearry.push(myobj);
    }


    var days = $("#webcampics").val();
    var newqst = this.questionOnes;
    var newqst1 = this.questionTwo;
    var newqst2 = this.questionThree;
    var newqst3 = this.questionFour;
    var raaaj = [];
    raaaj.push(newqst1, newqst2, newqst3);

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.showaddExerciceBtn = true;
    var postapi = "/workout/create_daydetails";
    //
    var title = $("#workoutTitle").val();
    var days = $("#myid").val();
    var levels = $("#mylevel").val();
    var ranges = $("#rangeid").val();
    var noOfQuestion = $("#workoutNo_of_question").val();
    var showMoreContent = $("#showReadMore").val();
    var readmore_Content = $("#readMoreContent").val();

    // if (this.yesShowReadmores == true) {
    //   var showReadMoreValue = "1"
    // }
    // else if (this.noShowReadmores == true) {
    //   var showReadMoreValue = "0"
    // }


    var workoutADDdata = {
      "no_of_question": noOfQuestion,
      "day": days,
      "level": levels,
      "range": ranges,
      "show_readmore": this.showReadMoreValue ? '1' : "0",
      "readmore_content": this.texteditors,
      //  "readmore_content": readmore_Content,
      "day_title": title,
      "is_active": 1,
      "test_category_id": this.category_id
    };

    console.log("workoutADDdata", workoutADDdata);

    (await this.ApiService.postApi(postapi, workoutADDdata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Workout added succcessfully",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.ApiService.workoutAdd_id = res.data.insertId;
            this.router.navigate([this.backUrl]);
          }
        }
      });
    // ----------------------------------------

    var count = 1;
    var countEl = $("#workoutNo_of_question").val();
    this.noOfQuestionCount = countEl;
    this.noOfCount = localStorage.setItem('countno', this.noOfQuestionCount);
  }
  levelOne() {
  }

  questionOne(e) {
    this.getquestiontype = e.target.value;
    var questionValue = e.target.value;
    if (questionValue == '1') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = false;
      this.show_questionThree_div = false;
      this.show_questionFour_div = false;
      this.show_questionFive_div = false;
    }
    if (questionValue == '2') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = true;
      this.show_questionThree_div = false;
      this.show_questionThree_div = false;
      this.show_questionFour_div = false;
    }
    if (questionValue == '3') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = true;
      this.show_questionThree_div = true;
      this.show_questionFour_div = false;
      this.show_questionFive_div = false;
    }
    if (questionValue == '4') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = true;
      this.show_questionThree_div = true;
      this.show_questionFour_div = true;
      this.show_questionFive_div = false;
    }

    if (questionValue == '5') {
      this.show_questionOne_div = true;
      this.show_questionTwo_div = true;
      this.show_questionThree_div = true;
      this.show_questionFour_div = true;
      this.show_questionFive_div = true;
    }

  }
  questionType_One(e) {
    var questionTypeOneValue = e.target.value;

    if (questionTypeOneValue == 'text') {
      this.questionType_Text_question = true;
      this.type_Text_placeholder = true;
      this.showFeelingType_Text = true;
      this.position_questionType_Text = true;
    }
    if (questionTypeOneValue == 'mind dump') {
      this.questionType_Text_question = true;
      this.type_Text_placeholder = false;
      this.showFeelingType_Text = false;
      this.position_questionType_Text = true;

    }

  }
  answerType_One(e) {

    var answertypeOneValue = e.target.value;
    if (answertypeOneValue == 'text') {
      this.show_answerOneType_Text = true;
      this.show_answerOneType_image = false;
      this.show_answerOneType_audio = false;

    }
    if (answertypeOneValue == 'image') {
      this.show_answerOneType_image = true;
      this.show_answerOneType_Text = false;
      this.show_answerOneType_audio = false;

    }
    if (answertypeOneValue == 'audio') {
      this.show_answerOneType_audio = true;
      this.show_answerOneType_Text = false;
      this.show_answerOneType_image = false;
    }


  }
  async workoutQuestionSave() {
    this.loop_len = this.noOfQuestionCount;
    var postapi = "/workout/create_questions";

    var question_type = $("#workoutQuestionType" + this.count).val();
    var questionWorkout = $("#questionsWorkout" + this.count).val();
    var show_Feelings = $("#showFeeling" + this.count).val();
    var placeholders = $("#placeholder_answer" + this.count).val();
    var postionAll = $("#positions" + this.count).val();

    // if (this.count >= postionAll) {
    //   var raaa = postionAll = this.count;
    //  }




    var workoutQuestiondata = {
      "question": questionWorkout,
      "workout_day_id": "1",
      "placeholder": placeholders,
      "position": postionAll,
      "question_type": question_type,
      "type": "question",
      "is_active": 1,
      "show_feelings": show_Feelings
    }
    console.log(workoutQuestiondata);

    (await this.ApiService.postApi(postapi, workoutQuestiondata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
          }
        }
      });
  }

  deleteQuestion(deleteitem: any) {
    this.questionDeleteId = deleteitem.id
  }

  async questionSubmit() {
    var deleteApi = "/workout/remove_questions/" + this.questionDeleteId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {

    });

  }

  questionView(viewitem: any) {
    this.router.navigate([this.questionView_url]);
    localStorage.setItem('questionViewId', viewitem.id);
  }
  questionEdit(editItem: any) {
    this.router.navigate([this.questionEdit_url]);
    localStorage.setItem('questionEditId', editItem.id);
  }
  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];


    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);

  }

  // to get the list by its id 

  get_category_list(event: any) {
    this.category_id = event.target.value;
    console.log("this.category_id", this.category_id)
  }

  //================================================

  async getcategoryListApi() {
    var getapi = "/listHappinesstestcategories";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getallcategory = res.result
      console.log("adddd", this.getallcategory)
    });
  }

  // ================================================

  async addExerciseSave() {
    var postapi = "/workout/create_questions";
    var workoutQuestiondata = {
      "workout_day_id": "1",
      "placeholder": this.placeholderAdd_exercise,
      "answer_label": this.answer_Label,
      "position": this.positionExercise,
      "type": "exercise",
      "is_active": 1,
      "file": this.base64textString
    };
    (await this.ApiService.postApi(postapi, workoutQuestiondata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
          }
        }
      });
  }

  add_Questions() {
    this.count++;

    var fakeArray = this.noOfQuestionCount;
    if (fakeArray >= this.count) {

    } else {

    }
  }

  cancelBtnWorkout() {
    this.router.navigate([this.cancelbtnWorkout_url]);
  }


  // showreadFunction() {
  //   if (this.yesShowReadmores == true) {
  //     this.showReadMandatory = false;
  //   }
  //   else if (this.yesShowReadmores == false) {
  //     this.showReadMandatory = true;
  //   }
  // }

  // showreadNoFunction() {
  //   if (this.noShowReadmores == true) {
  //     this.showReadMandatory = false;
  //   }
  //   else if (this.noShowReadmores == false) {
  //     this.showReadMandatory = true;
  //   }
  // }


}
