import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;

@Component({
  selector: 'app-happiness-test-add-category-result',
  templateUrl: './happiness-test-add-category-result.component.html',
  styleUrls: ['./happiness-test-add-category-result.component.scss']
})
export class HappinessTestAddCategoryResultComponent implements OnInit {

  constructor(private router: Router, private ApiService: ApiService) { }

  isShown: boolean = false;
  getallcategory: any[];
  container: any[] = [1];
  newArray: {
    category_range_id: any,
    description: string,
  }[] = [{ category_range_id: "", description: "" }];
  keyValue: any;
  back_url = "/happiness-test-category-result";
  happinessCategoryresultitems: any[];
  category_ID: any;
  levelID: any;
  categorylistresult: any;
  category_range_Id: any;

  hasError: boolean = false;
  canSubmit: boolean = false;

  ngOnInit(): void {
    this.getCategoryresultListApi();
    this.getcategoryListApi();
  }

  // ==================================================================== //

  happinessCategoryresultCancelBtn() {
    this.router.navigate([this.back_url]);
  }

  // ==================================================================== //

  async addBenefits() {

    // if (this.newArray.length >= 4) {
    //   swal({
    //     title: 'Error!',
    //     text: 'Only Four data for each category',
    //     type: 'error'
    //   });
    //   return;
    // }

    this.keyValue = this.container.length + 1;
    this.newArray.push({ category_range_id: this.levelID, description: "" });
    this.container.push(this.keyValue);
    var allTitle = this.newArray.map((item, index) => {
      if (item.description != undefined && item.description != '') {
        return { category_range_id: item.category_range_id, description: item.description }
      }
    })
  }

  // ==================================================================== //

  async removeBenefits(index: any) {
    // if (this.container.length > 1) {
    //   this.container.pop();
    // }
    if (index >= 0 && index < this.container.length) {
      this.container.splice(index, 1);
      this.newArray.splice(index, 1);
    }
  }

  // ===================================================================== //

  async happinessCategoryresultSubmit() {


    // if (this.newArray.length < 4) {
    //   swal({
    //     title: 'Error!',
    //     text: 'Four data are mandatory',
    //     type: 'error'
    //   });
    //   return;
    // }

    // const selectedIDs = this.newArray.map(item => item.category_range_id);
    // const uniqueIDs = new Set(selectedIDs);
    // console.log("sameejdfe",uniqueIDs)
    // if (selectedIDs.length !== uniqueIDs.size) {
    //     swal({
    //         title: 'Error!',
    //         text: 'Please select unique IDs in each column',
    //         type: 'error'
    //     });
    //     return;
    // } 

    const allContent = this.newArray.map(item => {
      return {
        category_range_id: item.category_range_id,
        description: item.description
      };
    });


    var postapi = "/addAndUpdateHapinessTestCategoryResult";
    var exercisesAdd = {
      "category_id": this.category_ID,
      "content": allContent
    };
    (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
      res => {
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Result Added successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.back_url]);
        }
      },

      (error) => {
        // if (error.error.message.emotion_id != undefined || error.error.message.result_id != undefined) {
        var errMsg = '';
        if (typeof error.error.message === 'object') {
          for (var i in error.error.message) {
            errMsg += error.error.message[i] + '<br />';
          }
        } else {
          errMsg = error.error.message
        }

        swal({
          title: "error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });

        // }
        // else {
        //   swal({
        //     title: "error!",
        //     text: error.error.message,
        //     type: "error",
        //   }, function () {
        //     alert('yo');
        //   });
        // }
      }
    )

  }

  // ======================================================================= //


  async getcategoryListApi() {
    var getapi = "/listHappinesstestcategories";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getallcategory = res.result
      console.log("adddd", this.getallcategory)
    });
  }


  // ======================================================================= //

  async getCategoryresultListApi() {
    var getapi = "/listHappinesstestcategoriesrange";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.happinessCategoryresultitems = res.result
      this.category_range_Id = this.happinessCategoryresultitems[0].id
      console.log("list data ", this.happinessCategoryresultitems)
      console.log("category_range_id", this.category_range_Id)
    });
  }

  // ======================================================================== //

  categoryItem(e: any) {
    this.category_ID = e.target.value;
    console.log("id", this.category_ID)
  }

  levelItem(event: any, index: number) {
    const selectedLevelID = event.target.value;
    console.log("assssssssss", selectedLevelID)

    // for (let i = 0; i < this.newArray.length; i++) {
    //   if (i !== index && this.newArray[i]?.category_range_id === selectedLevelID) {
    //     this.hasError = true;
    //     swal({
    //       title: 'Error!',
    //       text: 'You cannot select the same ID for multiple rows',
    //       type: 'error'
    //     });
    //     // Clear the selected ID in the newArray
    //     this.newArray[index].category_range_id = selectedLevelID;
    //     console.log("newArray after levelItem change:", this.newArray);
    //     return;
    //   }
    // }

    if (this.newArray[index]) {
      this.newArray[index].category_range_id = selectedLevelID ;
    }
    console.log("newArray after levelItem change:", this.newArray);
  }

}


