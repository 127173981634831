<div class="padd-0-25">

    <div *ngIf="showLoaderDiv">

        <div id="pause" class="d-flex align-items-center justify-content-center">
            <div id="spinner"></div>
        </div>

    </div>




    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Community Management Topic
    </div>

    <div class="marg-t-30">
        <div class="f-right marg-t-20 marg-b-20">
            <button class="add_btn" (click)="communityAdd()">Add</button>

        </div>
        <div class="">
            <table class="w-100" datatable style="background-color: #fff; border-radius: 8px;"
                *ngIf="getAdminListCommunity.length > 0">
                <thead>
                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>Topic Name </th>


                        <th>Description</th>

                        <th style="width:150px">Action</th>

                    </tr>
                </thead>
                <tbody>

                    <tr class=" font_medium_gilroy" *ngFor="let item of getAdminListCommunity; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.topic_name}}</td>
                        <td [innerHTML]="item.description"> </td>

                        <td>
                            <!-- <span class="cursor_pointer"><img height="22px" width="22px" class=""
                                    src="assets/View_icon.png" alt="" (click)="community_TopicView(item)"></span> -->


                            <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px" class=""
                                    src="assets/Edit_icon.png" alt="" (click)="community_TopicEdit(item)"></span>

                            <span class="marg-l-10 ">

                                <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                    data-target="#exampleModal"> <img height="22px" width="22px" class=""
                                        src="assets/Delete_icon.png" alt="" (click)="communityTopicDelete(item)">
                                </button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                            <div style="margin-top: 8px;">Are you sure you want to delete this Community Topic.</div>

                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                (click)="communityTopicDeleteApi()">Delete</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>