import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
@Component({
  selector: 'app-community-feedlist-table',
  templateUrl: './community-feedlist-table.component.html',
  styleUrls: ['./community-feedlist-table.component.scss']
})
export class CommunityFeedlistTableComponent implements OnInit {
  isShown: boolean = false;
  getFeedList = [];
  community_Ids: any;
  postDates: any;
  deleteId:any;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    this.community_Ids = this.ApiService.communityId_view;
    this.getcommunityFeedListApi();
  }
  async getcommunityFeedListApi() {
    var postapi = "/community_feed_list";
    var communityFeeddata = {
      "community_id": this.community_Ids
    };
    (await this.ApiService.postApi(postapi, communityFeeddata)).subscribe(
      res => {
      
        this.getFeedList = res.data;

        for (let item of this.getFeedList) {
          var postdate = item.cdate.slice(0, 10);

          var date = postdate;
          var d = new Date(date.split("/").reverse().join("-"));
          var dd = d.getDate();
          var mm = d.getMonth() + 1;
          var yy = d.getFullYear();
  this.postDates = dd + "/" + mm + "/" + yy;
   }

      });
  }
  deletedItem(deleteitems:any){
    this.deleteId = deleteitems.id;

  }


  async getcommunityDeleteApi() {
    var postapi = "/community_feed_delete";
    var communityFeeddata = {
      "id": this.deleteId
    };
    (await this.ApiService.postApi(postapi, communityFeeddata)).subscribe(
      res => {

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: " Community Feedlist Deleted Sucessfully ",
            type: "success"
          }, function () {
            alert('yo');
          });

        }
        this.getcommunityFeedListApi();
      });
  }



}
