import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
declare var window: any;
declare var $: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-exercises-databank-edit',
  templateUrl: './exercises-databank-edit.component.html',
  styleUrls: ['./exercises-databank-edit.component.scss']
})
export class ExercisesDatabankEditComponent implements OnInit {
  isShown: boolean = false;
  exercises_Backurl = "/exercisesdatabank";
  showVideoDiv = false;
  showImageDiv = false;
  exercise_benefit: any;
  questionType: any;
  base64textString: any;
  base64textStringTwo: any;
  type_Type: any;
  exerciseEditIds: any;
  getAllEmotion: any;
  getResultAll: any;
  emotionsId = "";
  resultId: any;
  exercis_videoUrl: any;
  container: any[] = [1];
  newArray: ({
    benefit: string,

  })[] = [{ benefit: "" }];
  keyValue: any;
  exerciseAllList: any;
  benefitUpdate: any;
  benefitUpdateId: any;
  delete_Id: any;
  benitAdd: any;
  dtOptions: any
  display_order: any;
  exerciseArrayList: any;
  ordernoNew: any;
  exercise_name:any;
  order_New: any;
  categoryAllList: any;
  category_id: any;
  registerForm: FormGroup;
  submitted = false;
  editRegForm: FormGroup;
  editSubmitted = false;
  exerciseRegForm: FormGroup;
  exerciseSubmitted = false;
  videoUrlRegForm: FormGroup;
  formModal: any;
  editFormModel: any;

  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.exerciseEditIds = localStorage.getItem("exerciseEdit_id");
    this.exerciseEditIds = this.ApiService.exerciseDatabankEditId;
    console.log(this.exerciseEditIds, '...........>>>>>>', this.exerciseEditIds);
    if (this.exerciseEditIds == undefined) {
      this.exerciseEditIds = localStorage.getItem("exerciseEdit_id");
      console.log('ids,,,,...');

    }


    this.getEmotionListApi();
    this.getResultApi();
    this.getExerciseDatabank();
    this.categoryListApi();

    this.registerForm = this.fb.group(
      {
        displayorder: ["", Validators.required],
        benefits: ["", Validators.required],
        // answerplaceholders: ["", Validators.required],
      },
    );

    this.editRegForm = this.fb.group(
      {
        editdisplayorder: ["", Validators.required],
        editbenefits: ["", Validators.required],

      },
    );

    this.exerciseRegForm = this.fb.group(
      {
        emotion: ["", Validators.required],
        result: ["", Validators.required],
        displayOrder: ["", Validators.required],
        category: ["", Validators.required],
        exerciseType: ["", Validators.required],
        exerciseName:["",Validators.required]

        // videourl: ["", Validators.required],

      },
    );

    // this.videoUrlRegForm

    this.videoUrlRegForm = this.fb.group(
      {
        videourl: ["", Validators.required],
      },
    );

    this.formModal = new window.bootstrap.Modal(
      document.getElementById('exampleModalAdd')

    );

    this.editFormModel = new window.bootstrap.Modal(
      document.getElementById('exampleModal')

    );





  }
  exerciseBackBtn() {
    this.router.navigate([this.exercises_Backurl]);
  }
  questionOne(e) {
    this.questionType = e.target.value;
    if (this.questionType == '1') {
      this.showVideoDiv = false;
      this.showImageDiv = true;

    }
    if (this.questionType == '2') {
      this.showImageDiv = false;
      this.showVideoDiv = true;


    }
  }
  // ------------------------------------------------------
  // --------------------------------------------
  async getExerciseDatabank() {
    var getapi = "/list_exercise/" + this.exerciseEditIds;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.exerciseAllList = res.result
        this.emotionsId = res.result.emotion;
        this.resultId = res.result.result;
        this.ordernoNew = res.result.no;
        this.exercise_name = res.result.exercise_name;
        this.base64textStringTwo = res.result.thumbnail;
        this.base64textString = res.result.exercise_image
        this.exercise_benefit = res.result.exercise_benefits;
        this.exercis_videoUrl = res.result.exercise_video
        var questiontype = res.result.exercise_type;
        if (questiontype == "image") {
          this.questionType = '1'
          this.showVideoDiv = false;
          this.showImageDiv = true;
        }
        if (questiontype == "video") {
          this.questionType = '2'
          this.showImageDiv = false;
          this.showVideoDiv = true;
        }

        this.category_id = res.result.category_id;


      }
    });
  }

  async exerciseUpdate() {



    this.exerciseSubmitted = true;

    if (this.exerciseRegForm.invalid) {
      return;
    }


    if (this.questionType == '2') {
      this.exerciseSubmitted = true;
      if (this.videoUrlRegForm.invalid) {
        return;
      }
    }


    // this.exercise_benefit = this.newArray.map((item,index)=>{
    //   if(item.values!=undefined && item.values != '' && item.values!= null ){
    //  return {id:index+1,benefit:item.values}
    //   }
    // })

    //     this.exerciseAllList.benefits = this.exerciseAllList.benefits.map((item, index) => {
    //       if (item.id == this.benefitUpdateId) {
    //         item.benefit = this.benefitUpdate;
    //         item.no = this.display_order;
    // console.log(this.display_order,'this.display_order');  }
    //       else {
    //         return item;
    //       }
    //     })


    var putApi = "/add_and_update_exercise/" + this.exerciseEditIds;
    var exerciseUpdatedata = {

      "emotion_id": this.emotionsId,
      "exercise_result": this.resultId,
      "no": this.ordernoNew,
      "exercise_name":this.exercise_name,
      "category_id": this.category_id,
      "exercise_thumbnail": this.base64textStringTwo,
      "exercise_type": this.questionType,
      "exercise_image": this.base64textString,
      "exercise_video": this.exercis_videoUrl,
      "exercise_benefits": this.exerciseAllList.benefits
    };
    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Exercise Updated Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.exercises_Backurl]);
          }
          else if (res.status === 401) {

          }
        }
      });

  }


  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    this.type_Type = file.type
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
  }

  // ---------------------------------------------------------------

  handleFileSelectOne(evt) {
    var files = evt.target.files;
    var file = files[0];
    this.type_Type = file.type
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoadedOne.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoadedOne(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringTwo = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
  }


  // ----------------Emotion List Api---------------------------

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.getAllEmotion = res.data
    });
  }
  // ----------------Result List Api---------------------------

  async getResultApi() {
    var getapi = "/list_range";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getResultAll = res.result
    });
  }
  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;

  }
  resultItem(e: any) {
    this.resultId = e.target.value;

  }
  // --------------------------------------

  async addBenefits() {

    this.keyValue = this.container.length + 1;
    // this.newArray.push({ key: this.keyValue, values: '' });
    //   this.newArray.push({  values: string});
    // this.exercise_benefit = this.newArray.map((item)=>{
    //     if(item.values!=undefined && item.values != '' && item.values != null){


    //       return {id:item.id,benefit:item.values}
    //     }
    //   })

    this.container.push(this.keyValue);

  }

  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }

  }

  edit_update(items: any) {
  
    var ids = items.id;
    this.benefitUpdate = items.benefit;
    this.benefitUpdateId = items.id;
    this.display_order = items.no;
    this.editFormModel.show();
  }
  submitUpdate() {
 this.editSubmitted = true;

 if (this.editRegForm.invalid) {
      return;
    }

    // var data =  { ...item,no:item.order_no}
    // delete data.order_no
    // return data;

    // this.exerciseArrayList
    this.exerciseAllList.benefits.map((item, index) => {
      if (item.id == this.benefitUpdateId) {
        item.benefit = this.benefitUpdate;
        item.no = this.display_order;

        // var data = { ...item, no: item.order_no }
        // delete data.order_no
        // return data;
      }
    })

    // this.exerciseAllList.benefits = this.exerciseArrayList
    $("#exampleModal .close").click()
    this.editFormModel.hide();

    console.log(this.exerciseAllList.benefits, 'exerciseArray');
  }
  exerciseEditDelete(item: any) {
    this.delete_Id = item.id;
  }

  delete_submit() {

    this.exerciseAllList.benefits = this.exerciseAllList.benefits.map((item, index) => {
      if (item.id == this.delete_Id) {
        return { ...item, delete: this.delete_Id };
      }
      else {
        return item;
      }
    })

  }

  add_submit() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }


    if (this.order_New != undefined && this.benitAdd != undefined) {
      this.exerciseAllList.benefits.push({ no: this.order_New, benefit: this.benitAdd });
      swal({
        title: "success!",
        text: "Exercise Successfully Added",
        type: "success"
      }, function () {
        alert('yo');
      });
    }
    else {
      swal({
        title: "error!",
        text: "Please fill all the details ",
        type: "error"
      }, function () {
        alert('yo');
      });
    }
    // -------------------------------------------------------

    $("#exampleModalAdd .close").click()
    this.formModal.hide();



  }


  async categoryListApi() {
    var getapi = "/category_list/1";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.categoryAllList = res.result;
      }
    });
  }

  categoryItem(e: any) {
    this.category_id = e.target.value;
    console.log(this.category_id, 'this.category_id.....');
  }


  addBenefitPopUp() {
    this.formModal.show();
  }




}
