import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.scss']
})
export class AppointmentDetailsComponent implements OnInit {

  back_appointment = '/home'

  getAllAppointmentList: any;

  getallAppointment_payment: any;
  getallAppointment_appointment: any;
  getallAppointment_session_details: any = [];

  getAppointmentId: any;
  isShown: boolean = false;

  joined_time: any;
  left_time: any


  appointment_id: any


  constructor(private api: ApiService, private router: Router, private Activated_route: ActivatedRoute, private location: Location) { }

  ngOnInit(): void {

    this.Activated_route.queryParams.subscribe(params => {
      this.appointment_id = +params['id']; // The '+' converts the string to a number
      console.log("Fetched appointment ID:", this.appointment_id);
      this.getAppointmentListApi();
    });

    console.log("this.appointment_id", this.appointment_id)
    this.getAppointmentListApi();
  }

  // List the data 
  goBack() {
    this.location.back();  // Navigates to the previous page in the browser history
  }
  async getAppointmentListApi() {

    const getapi = `/admin_appointment_list?appointment_id=${this.appointment_id}`;
    (await this.api.getApi(getapi)).subscribe((res) => {
      this.getAllAppointmentList = res.appointment_details;
      this.getallAppointment_appointment = res.appointment_details.appointment
      this.getallAppointment_payment = res.appointment_details.payment
      this.getallAppointment_session_details = res.appointment_details.session_details

      this.getAppointmentId = res?.data?.id
      console.log('admin_appointment_list', this.getAllAppointmentList)
      console.log('this.getallAppointment_appointment', this.getallAppointment_appointment)
      console.log('this.getallAppointment_payment', this.getallAppointment_payment)
      console.log('this.getallAppointment_session_details', this.getallAppointment_session_details)
    });
  }


  // Filter to hide the data when all the below details are null 

  get validAppointments() {
    return this.getallAppointment_session_details.filter(item =>
      item.joined_datetime || item.left_datetime || item.joined_by || item.left_by
    );
  }

  // To redirect the page to the home 

  back_appointments() {
    this.router.navigate([this.back_appointment])
  }

}
