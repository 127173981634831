import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forum-userlist-page',
  templateUrl: './forum-userlist-page.component.html',
  styleUrls: ['./forum-userlist-page.component.scss']
})
export class ForumUserlistPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
