<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/workout-day-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Workout Add</li>
        </ol>
    </nav>

    <!--
    <div class="marg-t-30 fsize27 font_bold_gilroy">
        Happiness Workout - Add
    </div> -->
    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <div class="baxshadows ">
                    <form [formGroup]="registerForm">
                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Title *</label>
                            <input id="workoutTitle" formControlName="title" maxlength="150" type="text"
                                class="form-control input_bdr_clr " />

                            <div *ngIf="submitted && registerForm.get('title').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('title').errors.required">Title is required</div>
                            </div>
                        </div>


                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Category *</label>
                            <select class=" form-control input_bdr_clr " formControlName="category"
                                (change)="get_category_list($event)">
                                <option [disabled]="true" [selected]="true">Select any type</option>
                                <option [value]="[item.id]" *ngFor="let item of getallcategory">
                                    {{item.category_name}} </option>
                            </select>
                            <div *ngIf="submitted && registerForm.get('title').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('title').errors.required">Category is required</div>
                            </div>
                        </div>



                        <div class="row marg_0">
                            <div class="form-group">
                                <label class="fsize16 clr_black font_bold_gilroy">Day *</label>
                                <select id="myid" class=" form-control input_bdr_clr" formControlName="level"
                                    (change)="dayid($event)" style="min-width: 130px;">

                                </select>
                                <div *ngIf="submitted && registerForm.get('level').errors" class="alert alert-danger">
                                    <div *ngIf="registerForm.get('level').errors.required">day is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="fsize16 clr_black font_bold_gilroy"
                                    style="margin-left: 16px;">Level</label>
                                <select id="mylevel" class=" form-control input_bdr_clr"
                                    style="min-width: 130px;margin-left: 14px;" disabled>
                                    <option selected disabled val="">Select Day</option>

                                </select>
                                <!-- <div *ngIf="submitted && registerForm.get('level').errors" class="alert alert-danger">
                                    <div *ngIf="registerForm.get('level').errors.required">Level is required</div>
                                </div> -->
                            </div>

                            <div class="form-group">
                                <label class="fsize16 clr_black font_bold_gilroy"
                                    style="margin-left: 28px;">Range</label>
                                <select id="rangeid" class=" form-control input_bdr_clr"
                                    style="min-width: 120px;margin-left: 28px;" disabled>

                                </select>
                                <!-- <div *ngIf="submitted && registerForm.get('level').errors" class="alert alert-danger">
                                    <div *ngIf="registerForm.get('level').errors.required">Range is required</div>
                                </div> -->
                            </div>

                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">No.Of.Questions *</label>
                            <div>
                                <input class=" form-control input_bdr_clr" type="text" id="workoutNo_of_question"
                                    maxlength="150" formControlName="noOfQuestion"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    name="">
                            </div>

                            <div *ngIf="submitted && registerForm.get('noOfQuestion').errors"
                                class="alert alert-danger">
                                <div *ngIf="registerForm.get('noOfQuestion').errors.required">No.Of.Questions is
                                    required</div>
                            </div>


                        </div>
                        <div>
                            <span class="fsize16 clr_black font_bold_gilroy">Show Read More * : </span>
                            <span>
                                <label for="showReadMoreYes">Yes</label>
                                <input class="marg-l-8" type="radio" [(ngModel)]="showReadMoreValue"
                                    id="showReadMoreYes" name="showReadMore" value="1">
                            </span>
                            <span>
                                <label class="marg-l-8" for="showReadMoreNo">No</label>
                                <input class="marg-l-8" type="radio" [(ngModel)]="showReadMoreValue" id="showReadMoreNo"
                                    name="showReadMore" value="0">
                            </span>


                            <!-- <div *ngIf="submitted && registerForm.get('readMore').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('readMore').errors.required">Read More is required</div>
                            </div> -->


                        </div>



                        <div class="marg-t-20">
                            <span class="fsize16 clr_black font_bold_gilroy">Read More Content

                                <span *ngIf="showReadMandatory">*</span>
                                :</span> <span class="marg-l-8">
                                <!-- <input type="text" class="input_bdr_bottom " id="readMoreContent" style="width: 400px;"> -->
                                <div class="marg-t-20">

                                    <ckeditor name="myckeditor" [(ngModel)]="texteditors" id="readMoreContent"
                                        debounce="500" (change)="onChangeProp($event)">
                                    </ckeditor>

                                </div>

                            </span>


                            <!-- <div *ngIf="submitted && registerForm.get('readMoreContent').errors"
                                class="alert alert-danger">
                                <div *ngIf="registerForm.get('readMoreContent').errors.required">Read More Content is
                                    required</div>
                            </div> -->

                        </div>

                        <div class="form-group marg-t-40">
                            <span> <button type="button" class="btn  submit_btn_clr"
                                    (click)='workout_addSubmit()'>Submit</button></span>
                            <span class="marg-l-16"> <button type="button" class="cancel_Btn"
                                    (click)="cancelBtnWorkout()">Cancel</button></span>
                        </div>

                    </form>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>