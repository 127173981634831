import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // production
  //  base:any= "https://api.thap.in";
  // staging
  base: any = "https://stagingapi.thap.in/thapV5";
  // base: any = "https://v5api.thap.in/thapV5";

  //  http://3.228.209.205/
  //  https://fatneedle.com/credo_admin/admin_api

  // base:any= " https://fatneedle.com/credo_admin/admin_api/api";
  apiURL: string = this.base;
  // apiURL: string = "http://127.0.0.1:8000/api";
  httpOptions = {
    headers: new HttpHeaders({
      "Access-Control-Allow-Origin": '*',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization',
      'Authorization': 'Bearer ' +  (localStorage.getItem('token'))
      // 'Authorization':'Bearer' + (localStorage.getItem('tokens'))
    }),
  };
  // -------------editId Values---------------
  edit_id = "";
  workoutAdd_id = "";
  workoutQuestion_id = "";
  sosEdit_id = ""
  sos_Add_id = "";
  sosview_Id = ""
  workout_day_ids = "";
  workout_exercise_dayid = "";
  workoutExerciseId = "";
  sosQuestionAdd_Id = "";
  sosQuestionEdit_Id = "";
  sosExerciseEdit_Id = "";
  sosExerciseAdd_Id = "";
  sosQuestionViewEdit_Id = "";
  sosQuestionViewAdd_Id = "";
  sosExerciseViewEdit_Id = "";
  sosExerciseViewAdd_Id = "";
  sosEmotionName = ";"
  expeditionEditId = "";
  expeditionAdd_Id = "";
  expeditionViewId: any;
  questionExpeditionEditId: any;
  questionExpeditionViewId: any;
  workoutQuestionView_id: any;
  questionExpedition_ids: any;
  questionCampIds: any;
  expeditionModuleItems: any;
  expeditionCampId: any;
  expeditionModuleTitle: any;
  expeditionscampTiltes: any;
  communityId_view: any;
  communityId_Edit: any;
  exerciseWorkoutView_id: any;
  appointmentEditId: any;
  appointment_detail_id:any;
  adminUser_id: any;
  adminCoachId: any;
  couponEditId: any;
  couponViewId: any;
  adminStudent_id: any;
  adminStudent_viewid: any;
  collegeId: any;
  departmentId: any;
  exerciseDatabankEditId: any;
  exerciseDatabankViewId: any;
  nutritionEditId: any;
  assestmentEditId: any;
  assestment_questionEditId: any;
  happinessresultEditId: any;
  happinessCategoryresultEditId: any;
  happiness_managementId: any;
  rewardseditID:any;
  preassessmentEditID:any;
  communityEventsEditID:any;
  blogEditId: any;
  tidbit_EditId: any;
  corporate_EditId: any;





  // setHeaders:{

  // }

  /* httpOptions = {
     headers: new HttpHeaders({
       "content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
       "Access-Control-Allow-Origin" : '*',
       'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
       'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization'

     })
   };*/
  // -------new--------------------
  
  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();

  // -----------------------------

  constructor(private httpClient: HttpClient, private router: Router) { }

  async postApi(Api_url, data: any): Promise<Observable<any>> {

    return this.httpClient.post(this.apiURL + Api_url, data);

  }
  
  async getApi(Api_url): Promise<Observable<any>> {
    return this.httpClient.get(this.apiURL + Api_url);
  }
  async deleteApi(Api_url): Promise<Observable<any>> {

    return this.httpClient.delete(this.apiURL + Api_url);
  }
  async putApi(Api_url, data: any): Promise<Observable<any>> {

    return this.httpClient.put(this.apiURL + Api_url, data);
  }


  getToken() {
    return localStorage.getItem('token')
  }

  /*getToken(){
    return localStorage.getItem('token')
  }
 loggedIn(){
   return !!localStorage.getItem('token')
 }
 logoutUser(){
localStorage.removeItem('token')
this.router.navigate(['/login'])
 }
 Restrictmodule(){

  return !!localStorage.getItem('hide')
}*/
  // ------
  // setData(updatedData) {
  //   this.sharedData.next(updatedData);
  // }

}
