import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-appointment-view-page',
  templateUrl: './appointment-view-page.component.html',
  styleUrls: ['./appointment-view-page.component.scss']
})
export class AppointmentViewPageComponent implements OnInit {
  isShown: boolean = false;
  getAppointmentListByIdData = [];
  appointmentNo: any;
  appointment_Id: any;
  coachName: any;
  userName: any;
  appointmentDate: any;
  appointmentTime: any;
  appointmentStatus: any;
  constructor(private ApiService: ApiService) { }
 
 async ngOnInit() {
    var postapi = "/view_appointment";




  }


async  appointmentView(){
    this.appointment_Id = this.ApiService.appointmentEditId
    var postapi = "/admin_view_appointment";
    var appintmentlistidData = {
      "app_id": "4"
    };
    (await this.ApiService.postApi(postapi, appintmentlistidData)).subscribe(
      res => {
        this.getAppointmentListByIdData = res.data;
        for (let item of this.getAppointmentListByIdData) {
          this.appointmentNo = item.id;
          this.coachName = item.thusr_name;
          this.appointmentDate = item.date;
          this.appointmentTime = item.time;
          this.appointmentStatus = item.appointment_status;
        }
      });
  }




}
