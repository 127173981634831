<div class="padd-0-25 marg-t-70">
    <!-- <div class="marg-t-70 fsize27 font_bold_gilroy">
         SOS - View
    </div> -->

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/sos-feel-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page"> SOS - View</li>
        </ol>
    </nav>


    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <!-- -------------------------------------------------------- -->
                <form>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Emotions *</label>
                        <input disabled type="text" [(ngModel)]="emotions" [ngModelOptions]="{standalone: true}"
                            id="questions" class="form-control input_bdr_clr " />
                    </div>

         <div class="marg-t-20 form-group">
                        <span class="fsize16 clr_black font_bold_gilroy">Show Feeling * : </span>
                        <span>
                            <label for="vehicle1"> Yes</label>
                            <input class="marg-l-8 " [(ngModel)]="show_Feelings" [ngModelOptions]="{standalone: true}"
                                type="checkbox" id="showReadMore" name="fooby[1][]" value="1">
                        </span>

                        <span>
                            <label class="marg-l-8" for="vehicle1"> No</label>
                            <input class="marg-l-8" type="checkbox" id="showReadMore" name="fooby[1][]" value="0"
                            [(ngModel)]="show_FeelingsNo" [ngModelOptions]="{standalone: true}"
                            
                            >
                        </span>
                    </div>


                    <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Status * : </span>
                        <span>
                            <label for="vehicle1"> Active </label>
                      
                            <input class="marg-l-8 " [(ngModel)]="statusActive" [ngModelOptions]="{standalone: true}"
                                type="checkbox" id="showReadMore" name="vehicle1" value="1" (click)="statuscheck()">
                        </span>

                        <span>
                            <label class="marg-l-8" for="vehicle1"> Inactive</label>
                            <input class="marg-l-8" [(ngModel)]="statusInActive" [ngModelOptions]="{standalone: true}"
                                type="checkbox" id="vehicle1" name="vehicle1" value="0">
                        </span>
                    </div>

                        <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy">No.of questions *</label>
                            <input disabled type="text" class="form-control input_bdr_clr "
                            [(ngModel)]="noOfQuestions" [ngModelOptions]="{standalone: true}"
                            />
                        </div>
                  

                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>

</div>