import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import * as moment from 'moment';
@Component({
  selector: 'app-upcomming-appointment',
  templateUrl: './upcomming-appointment.component.html',
  styleUrls: ['./upcomming-appointment.component.scss'],
})
export class UpcommingAppointmentComponent implements OnInit {
  dtOptions: DataTables.Settings = {
    paging: false,
    info: false,
    searching: false
  };

  appointmentDeleteId: any;
  selectedCity: any;
  selectedCityIds: string[];
  getAllAppointmentList: any;
  upcommingAppointment: any[];
  designersOverForty: any = [];
  raja = [];
  appt_status = '';
  upcomingappointment: any;
  loading: boolean = false;
  currentPage: number = 1;
  entriesPerPage: number = 50;
  totalPages: number = 0;
  totalEntries: number = 0;
  searchValue: string = ''

  appointment_url = '/appointment-details';


  constructor(private router: Router, private ApiService: ApiService) { }

  appointmentView_url = '/appointment-view-page';
  appointment_url_edit = '/appointment-edit-page';
  async ngOnInit() {
    this.getAppointmentListApi();
  }
  
  async openMeetingLink(gmeet_link){
    window.open(gmeet_link);
  }

  async getAppointmentListApi() {
    this.loading = true;
    // const search = this.upcomingappointment || "" ;
    const page = this.currentPage;
    const entries = this.entriesPerPage;
    const searchValue = this.searchValue
    var getapi = `/admin_appointment_list?upcomming=true&search=${searchValue}&page=${page}&limit=${entries}`;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var getAppointmentList = res.data;
      this.totalPages = res.pageCount;
      this.totalEntries = res.totalCount;

      console.log(getAppointmentList);
      // Waiting
      // this.getAllAppointmentList = getAppointmentList.filter(
      //   (x) => x.appointment_status != 'Waiting'
      // );
      // console.log(this.getAllAppointmentList);
      // var today_Dates = moment();

      this.upcommingAppointment = getAppointmentList;
      console.log(this.upcommingAppointment);
      this.loading = false;
    },
      (error) => {
        console.error('Error fetching appointment list', error);
        this.loading = false;
      }


      // this.upcommingAppointment = this.getAllAppointmentList.filter(
      //   (person) =>
      //     moment(person.date + ' ' + person.time, 'DD-MM-YYYY HH:mm:ss') >=
      //     today_Dates
      // );

      // this.designersOverForty = this.getAllAppointmentList.filter((person) => {
      //   return moment(person.date,"DD-MM-YYYY") >= today_Dates ;
      // });

      // this.raja =  this.designersOverForty
    );
  }
  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getAppointmentListApi();
    }
  }
  searchTable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue
    this.getAppointmentListApi()

  }
  onEntriesPerPageChange() {
    this.currentPage = 1;
    this.getAppointmentListApi();
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.getAppointmentListApi();
    }
  }

  edit_view() {
    this.router.navigate([this.appointmentView_url]);
  }
  appointment_Edit(editItems: any) {
    this.ApiService.appointmentEditId = editItems.id;

    this.router.navigate([this.appointment_url_edit]);
  }
  //  async getAppointmentListApi(){

  //   }
  appointment_deleted(deleteItem) {
    this.appointmentDeleteId = deleteItem.id;
  }
  async deletedApi() {
    var postapi = '/delete_appointment';
    var appointmentlistidData = {
      app_id: this.appointmentDeleteId,
    };
    (await this.ApiService.postApi(postapi, appointmentlistidData)).subscribe(
      (res) => {
        this.getAppointmentListApi();
      }
    );
  }

  copyLinkAddress(event: MouseEvent, link: string) {
    event.preventDefault(); // Prevent the default right-click menu
  
    const tempInput = document.createElement('input'); // Create a temporary input field
    tempInput.value = link; // Set the input value to the link
    document.body.appendChild(tempInput); // Append it to the DOM
    tempInput.select(); // Select the link text
    document.execCommand('copy'); // Copy the selected text to the clipboard
    document.body.removeChild(tempInput); // Remove the temporary input
  }


  async downloadlist(item: any) {
    console.log(item);
    var postapi = '/getinvoice';
    var upcomminglist = {
      "app_id": item?.id,
      "user_id": item?.user_id
    };
    (await this.ApiService.postApi(postapi, upcomminglist)).subscribe(
      (res) => {

        const Data = res?.data[0];
        const Base64pdf = 'data:application/pdf;base64,' + Data;
        const downloadLink = document.createElement("a");
        const fileName = item.app_number + ".pdf";

        console.log('sucesssssssss', Base64pdf)

        console.log("Success", Data)

        // const fileName = URL.createObjectURL(newBlob);
        downloadLink.href = Base64pdf;
        downloadLink.download = fileName;
        downloadLink.click();
        // window.open('data:application/pdf;base64,' + res?.data?.[0]);
      }
    )

  }

  appointment_detail_page(id: number) {
    this.router.navigate([this.appointment_url], { queryParams: { id } });
  }

}
