<div class="padd-0-25">

  <div class="marg-t-70 fsize27 font_bold_gilroy" style="color: black;">
        Work Out Edit Table 
    </div>



    <div class="marg-t-40">
        <div class="row marg-0">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
              <div class="h-64">
              <div class="h-24 fsize16 clr_black font_bold_gilroy">
                  Mood
             </div>

             <div>
                 <input  type="text" class="h-40 input_bdrs">
             </div>
            </div>

          </div>
           
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

            <div class="h-64">
                <div class="h-24 fsize16 clr_black font_bold_gilroy">
                    Level
               </div>
  
               <div>
                   <input  type="text" class="h-40 input_bdrs">
               </div>
              </div>

        </div>


        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

            <div class="h-64">
                <div class="h-24 fsize16 clr_black font_bold_gilroy">
                    Question
               </div>
  
               <div>
                   <input  type="text" class="h-40 input_bdrs">
               </div>
              </div>

        </div>

        </div>
    </div>

    <div class="marg-t-40">

        <div class="row justify-content-center marg-0">
            <span> <button class="button_size submit_clr font_bold_gilroy">Submit</button>  </span> <span><button class="button_size marg-l-16 cancelBtn_clr" >Cancel</button></span>
        </div>

    </div>

   

 

</div>