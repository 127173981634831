<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
       User List
    </div>
 
 <div class="marg-t-30" >
  <div class="bdr_clr_table">
            <table class="w-100">
                <tr class="bgr_th font_bold_gilroy">
                    <th>S.No</th>
                    <th>User Id</th>
                    <th>User Name</th>
                    <th>Date Joined</th>
                    <th>Current Status</th>
                </tr>
                <tr class="bgr_td font_medium_gilroy" >
                    <td>1</td>
                    <td>1</td>
                    <td>Rajaa</td>
                    <td>22/12/2021</td>
                    <td>Delete | Leave</td>
                </tr>
            </table>

            </div>

    </div>
</div>