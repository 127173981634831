import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-exercise-sos-edit',
  templateUrl: './exercise-sos-edit.component.html',
  styleUrls: ['./exercise-sos-edit.component.scss']
})
export class ExerciseSosEditComponent implements OnInit {
  answerLabel = "";
  place_holder = "";
  file_upload = "";
  position_Exercise = "";
  base64textString: String = "";
  isShown: boolean = false;
  sos_ExerciseAddIds: any;
  sos_ExerciseEditids: any;
  exercise_EditSubmit_url ="/sos-edit"
  redirectLogin_url = "/"
  

  constructor(private ApiService: ApiService , private router: Router) { }

  async ngOnInit() {
    this.sos_ExerciseAddIds = this.ApiService.sosExerciseAdd_Id;
    this.sos_ExerciseEditids = this.ApiService.sosExerciseEdit_Id;

    var getapi = "/sos/get_questions_byId?sos_id=" + this.sos_ExerciseAddIds + "&type=exercise&id=" + this.sos_ExerciseEditids;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
       if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Unauthorized access",
          type: "error"
        }, function () {
          alert('yo');
        });
        }

      var myArray = res.data;
      for (let item of myArray) {
        var ids = item.id;
        if (ids == this.sos_ExerciseEditids) {
          this.place_holder = item.answer_placeholder1;
          this.position_Exercise = item.position;
          this.answerLabel = item.answer_label;
        }
      }

    });



  // this.getSosExerciseListApi;
  }
  async exerciseSubmit() {

    var putApi = "/sos/update_questions/"+this.sos_ExerciseEditids
    var workOutEditdata = {
      "sos_id": this.sos_ExerciseAddIds,
      "answer_placeholder1": this.place_holder,
      "answer_label": this.answerLabel,
      "position": this.position_Exercise,
      "type": "exercise",
      "is_active": 1,
      "audio": this.base64textString
    };
    (await this.ApiService.putApi(putApi, workOutEditdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {

           
            swal({
              title: "Success!",
              text: "SOS Exercise Update Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });


            this.router.navigate([this.exercise_EditSubmit_url]);

            this.place_holder = "";
            this.answerLabel = "";
            this.position_Exercise = "";
            this.base64textString = "";
          }
          else if (res.status === 401) {
            this.router.navigate([this.redirectLogin_url]);
            swal({
              icon: 'error',
              title: 'Oops...',
              text: "Unauthorized access",
              type: "error"
            }, function () {
              alert('yo');
            });
            }
        }
      });

  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
 
   if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);

  }

  async getSosExerciseListApi() {



  }


}
