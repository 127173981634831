<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Appointment View Page 
    </div>

    <div class="marg-t-30">

        <nav aria-label="breadcrumb ">
            <ol class="breadcrumb fsize27 padd_0 ">
                <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                        [routerLink]="['/appointment']">Back</a></li>
                <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Appnt Edit</li>
            </ol>
        </nav>

     
        <div class="content_bgr_clr padd-20" >
            <div class="row marg-0">

                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

                    <div class="font_bold_gilroy">
                        <span >Appointment Number : 123</span>
                        
                    </div>
                    
                    <div class="marg-t-30 font_bold_gilroy"> 
                        <span>Appointment Date  : 4/1/2022</span>
                        
                    </div>


                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

                        <div class="font_bold_gilroy">  
                            
                           
                            <span>Coach Name : Rajal</span>
                        </div>

                        <div class="marg-t-30 font_bold_gilroy">
                            <span>Appointment Time : 3.00 pm</span>
                        </div>
                      
                        
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

                        <div class="font_bold_gilroy">
                           
                            <div class="font_bold_gilroy">   <span>User Name : Rajal k</span></div>

                        </div>
                      

                        <div class="marg-t-30 font_bold_gilroy">

                             <span>  Status of Appointment : pending</span>
                             
                          
                        </div>
                        
                    </div>


            </div>
    
        </div>

        <!-- <div class="marg-t-30">

            <div class="row justify-content-center">
        
                <button class="btn_sizes bgr_clr_red font_bold_gilroy">Appointment History </button>

                <button class="btn_sizes bgr_clr_blue marg-l-10 font_bold_gilroy">Forum Participation</button>
        
            </div>
        
        </div> -->


      
    </div>

</div>