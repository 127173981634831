<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/blog_master_list']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Blog Master Edit</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form [formGroup]="registerForm">
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Blog Title*</label>
                        <input type="text" formControlName="blogtitle" [(ngModel)]="blogTitle"
                            class="form-control input_bdr_clr font_text" />


                        <div *ngIf="submitted && registerForm.get('blogtitle').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('blogtitle').errors.required"> Blog Title is required</div>
                        </div>

                    </div>

                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy"> Emotion*</label>
                        <select  [(ngModel)]="blogemotions" formControlName="blog_emotions" (change)="emotionItem($event)"
                            class=" form-control input_bdr_clr ">
                            <!-- <option [disabled]="true" [selected]="true">Select an Emotion</option> -->
                            <option [value]="[item.themotion_id]" [selected]="emotionsId == item.themotion_id"
                                *ngFor="let item of getAllEmotion">
                                {{item.themotion_name}} </option>

                        </select>

                        <div *ngIf="submitted && registerForm.get('blog_emotions').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('blog_emotions').errors.required"> Emotion is required</div>
                        </div>

                    </div>

                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy"> Blog Thumbnail*</label>
                        <div class="row m-0">
                            <input  type="file" accept="image/*" id="questions" style="height:44px;width:380px;"
                            class="input_bdr_clr" (change)="handleFileSelect($event)" />
                            <div style="margin-left:10px;">
                                <img height="40px" width="40px" src="{{base64textString}}" alt="">
                            </div>
                        </div>
                     
                    </div>

                    <!-- <div *ngIf="submitted && registerForm.get('blogThumbnail').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('blogThumbnail').errors.required"> Thumbnail is required</div>
                    </div> -->


                    <!-- 
                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">Article *</label>
                        <ckeditor [(ngModel)]="article"
                        [ngModelOptions]="{standalone: true}" name="myckeditor" debounce="500">
                        </ckeditor>
                    </div> -->


                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">Article *</label>
                        <div>

                            <ckeditor [(ngModel)]="article" formControlName="blogarticle" name="myckeditor"
                                debounce="500">
                            </ckeditor>

                            <!-- <textarea  [(ngModel)]="article"
                            [ngModelOptions]="{standalone: true}" name="" id="" maxlength="3000" class="form-control input_bdr_clr" style="min-height:100px"   >
                        </textarea> -->
                        </div>

                    </div>
                    <div *ngIf="submitted && registerForm.get('blogarticle').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('blogarticle').errors.required"> Article is required</div>
                    </div>


                    <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Status * :
                        </span>
                        <span>
                            <label for="vehicle1"> Active</label>
                            <input class="marg-l-8"  [(ngModel)]="blogStatus" formControlName="blogStatus" type="radio"
                                id="vehicle1" name="viewBeliefs" value="1">
                        </span>

                        <span>
                            <label class="marg-l-8" for="vehicle1">In Active</label>
                            <input class="marg-l-8"  [(ngModel)]="blogStatus" formControlName="blogStatus" type="radio"
                                id="vehicle1" name="viewBeliefs" value="2">
                        </span>

                        <!-- --------------------------------------------------------------------- -->

                        <div *ngIf="submitted && registerForm.get('blogStatus').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('blogStatus').errors.required"> Status is required</div>
                        </div>


                    </div>

                    <div class="form-group" style="margin-top:100px;">
                        <button type="button" (click)="blogMasterUpdate()" class="btn submit_btn_clr">Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="blogCancelBtn()">Cancel</button>
                    </div>

                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>