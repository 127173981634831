import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-sos-feel-content-table',
  templateUrl: './sos-feel-content-table.component.html',
  styleUrls: ['./sos-feel-content-table.component.scss']
})
export class SosFeelContentTableComponent implements OnInit {
  sosQuestionAll = [];
  sos_url = '/sos-edit';
  sos_add = '/sos-add';
  sosView_url = '/sos-view';
  deleteids: any;
  showCogitive_Biases = "";
  constructor(private router: Router, private ApiService: ApiService) { }

  async ngOnInit() {

    var getapi = "/sos/get_sosdetails";
  (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.sosQuestionAll = res.data;
    });
  }
  sosAdd() {
    this.router.navigate([this.sos_add]);
  }
  sos_Edit(selectedItem: any) {
 
    // localStorage.setItem('sosId', JSON.stringify(selectedItem.id));
    this.ApiService.sosEdit_id = selectedItem.id;
    localStorage.setItem("sosEditIds",selectedItem.id)

    this.ApiService.sosEmotionName = selectedItem.emotion_name;
    this.router.navigate([this.sos_url]);
 }

  sos_ViewPage(selectedItem: any) {
    this.ApiService.sosview_Id = selectedItem.id;

    this.router.navigate([this.sosView_url]);
    localStorage.setItem('sosViewId', JSON.stringify(selectedItem.id));
  }
  deleteSos(selectedSos: any) {
    this.deleteids = selectedSos.id;
  }
  async deletedItem() {
    var deleteApi = "/sos/remove_sosdetails/"+ this.deleteids;
 (await this.ApiService.deleteApi(deleteApi)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            window.location.reload();
          }
        }
      });
  }

}
