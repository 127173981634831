<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Appointment Edit Page
    </div>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <form>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Appointment Number</label>
                        <input type="text" disabled [(ngModel)]="appointmentNo" [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr " />

                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Coach Name</label>
                        <input type="text" disabled [(ngModel)]="coachName" [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr" />

                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">User Name</label>
                        <input type="text" disabled [(ngModel)]="userName" [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr" />

                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Appointment Date </label>
                        <input type="text" [(ngModel)]="appointmentDate" [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr" />

                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Appointment Time </label>
                        <input type="text" [(ngModel)]="appointmentTime" [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr" />

                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Status of Appointment</label>
                        <input type="text"[(ngModel)]="appointmentStatus" [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr" />

                    </div>

                    <div class="form-group marg-t-40">
                        <button class="btn  submit_btn_clr" (click)="appointmentReshedule()" >Submit</button>

                    </div>


                </form>



            </div>

            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>

        </div>





    </div>

    <!-- <div class="marg-t-40">
 <div class="row justify-content-center marg-0">
            <span> <button class="button_size submit_clr font_bold_gilroy"    >Submit</button>  </span> <span><button class="button_size marg-l-16 cancelBtn_clr" >Cancel</button></span>
        </div>
   </div> -->



</div>