<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/student-portal-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">College Portal Edit</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <form [formGroup]="registerForm">

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">College Name *</label>
                            <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="collegeName"
                                formControlName="collegename" maxlength="150" />

                            <div *ngIf="submitted && registerForm.get('collegename').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('collegename').errors.required">College Name is required
                                </div>
                            </div>

                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">College Address *</label>
                            <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="collegeAddress"
                                formControlName="collegeaddress" maxlength="150" />

                            <div *ngIf="submitted && registerForm.get('collegeaddress').errors"
                                class="alert alert-danger">
                                <div *ngIf="registerForm.get('collegeaddress').errors.required">College Address is
                                    required</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">College Code *</label>
                            <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="college_code"
                                formControlName="collegecode" maxlength="150" />

                            <div *ngIf="submitted && registerForm.get('collegecode').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('collegecode').errors.required">College Address is required
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Active *</label>
                            <mat-slide-toggle style="padding-left: 20px;" [checked]="is_active === 1" 
                                (change)="toggleIsActive()">
                            </mat-slide-toggle>
                        </div>


                    </form>

                    <!-- <div class="form-group" style="margin-top:20px">
                        <label class="fsize16 clr_black font_bold_gilroy">Status *</label>

                        <div>
                            <span>
                                <input type="radio" id="vehicle1" name="vehicle1" value="1">
                                <label class="marg-l-8"> Active </label>

                            </span>

                            <span>
                                <input class="marg-l-8" type="radio" id="vehicle1" name="vehicle1" value="0">
                                <label class="marg-l-8"> In Active</label>

                            </span>

                        </div>




                    </div> -->
                    <!-- ----------------------------------------------- -->

                    <div class="form-container" style="float: right; ">
                        <div class="form-group">

                            <button style="min-width:120px; margin-right: 20px;" class="btn btn-primary click"
                                (click)="editCollege()">Edit College </button>


                        </div>
                    </div>


                    <!-- ------------------------------Department--------------------------------------- -->


                    <table id="myTable" datatable class="row-border hover table w-100"
                        *ngIf="getDepartment_list.length > 0">
                        <thead>
                            <tr class="font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                <th>S.No.
                                </th>
                                <th> College Name </th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>

                            <tr *ngFor="let data of getDepartment_list; let i=index" class=" font_medium_gilroy">
                                <td>{{i+1}}</td>

                                <td>{{data.department}}</td>



                                <td style="width:120px ;">

                                    <!-- <span><img height="22px" width="22px" class="bgr_black"
                                            src="assets/View_icon.png" alt=""></span>  -->


                                    <span class="marg-l-10" data-toggle="modal" data-target="#editexampleModal"
                                        (click)="editDept_Icon(data)"><img height="22px" width="22px" class="bgr_black"
                                            src="assets/Edit_icon.png" alt=""></span>

                                    <span class="marg-l-10"> <button type="button" class="border_clr_none"> <img
                                                height="22px" (click)="removebtn(data)" data-toggle="modal"
                                                data-target="#editStudentDeletePop" src="assets/Delete_icon.png" alt=""
                                                width="22px" class="bgr_black"> </button>

                                    </span>

                                </td>
                            </tr>
                        </tbody>
                    </table>


                    <!-- -------------Pop up Show----------------------------------------- -->

                    <div class="modal fade" id="editexampleModal" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">

                                <div class="modal-body">

                                    <div>
                                        <div>
                                            <label class="fsize16 clr_black font_bold_gilroy">Department *</label>
                                        </div>
                                        <input type="text" [(ngModel)]="editDepartMentName"
                                            [ngModelOptions]="{standalone: true}"
                                            class="form-control input_bdr_clr font_text" />
                                    </div>

                                    <div style="margin-top:20px;">
                                        <div>
                                            <label class="fsize16 clr_black font_bold_gilroy">Batch Duration*</label>
                                        </div>
                                        <!-- ----------------------------------------- -->
                                        <select class="form-control input_bdr_clr font_text"
                                            [(ngModel)]="editBatchDuration" [ngModelOptions]="{standalone: true}">
                                            <option value="1">1 Year</option>
                                            <option value="2">2 Year</option>
                                            <option value="3">3 Year</option>
                                            <option value="4">4 Year</option>
                                            <option value="5">5 Year</option>
                                            <option value="6">6 Year</option>
                                            <option value="7">7 Year</option>
                                            <option value="8">8 Year</option>
                                        </select>
                                        <!-- ------------------------------------------ -->
                                        <!-- <input class="form-control input_bdr_clr font_text" type="text" [(ngModel)]="editBatchDuration" [ngModelOptions]="{standalone: true}" /> -->
                                    </div>

                                </div>
                                <div class="row justify-content-center  font_bold_gilroy"
                                    style="padding-bottom:20px; padding-top:20px;">
                                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                        (click)="subitedd()">Submit</button>
                                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                        data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- ----------------------------------End------------------------------- -->
                    <div class="form-container" style="float: right; margin-right: 12px;">
                        <div class="form-group">
                            <button style="min-width:120px;" class="btn btn-primary click" (click)="addDepartMentBtn()">
                                Add DepartMent </button>
                        </div>
                    </div>


                    <!-- ---------------------------------------------------------------------------------------------- -->
                    <div *ngIf="departmentShowDiv">


                        <!-- <div *ngFor="let ele of container; let i = index" style="margin-top:50px;">

                            <div>
                                <div>
                                    <label class="fsize16 clr_black font_bold_gilroy">Department *</label>
                                </div>

                           <div>
                 <input type="text" [(ngModel)]="departMentName"
                                        [ngModelOptions]="{standalone: true}"
                                        class="form-control input_bdr_clr font_text" />
                                </div>
                        </div>

                            <div style="margin-top:20px;">
                                <div>
                                    <label class="fsize16 clr_black font_bold_gilroy">Batch Duration *</label>
                                </div>

                                <input class="form-control input_bdr_clr font_text" type="text"
                                    [(ngModel)]="deptDuration" [ngModelOptions]="{standalone: true}" />
                            </div>
                        </div> -->




                        <div *ngFor="let ele of container; let i = index" style="margin-top:50px;">

                            <div>
                                <div>
                                    <label class="fsize16 clr_black font_bold_gilroy">Department *</label>
                                </div>
                                <input type="text" [(ngModel)]="newArray[i].values"
                                    [ngModelOptions]="{standalone: true}"
                                    class="form-control input_bdr_clr font_text" />
                            </div>

                            <div style="margin-top:20px;">
                                <div>
                                    <label class="fsize16 clr_black font_bold_gilroy">Batch Duration *</label>
                                </div>
                                <select class="form-control input_bdr_clr font_text" [(ngModel)]="newArray[i].textValue"
                                    [ngModelOptions]="{standalone: true}">
                                    <option value="1">1 Year</option>
                                    <option value="2">2 Year</option>
                                    <option value="3">3 Year</option>
                                    <option value="4">4 Year</option>
                                    <option value="5">5 Year</option>
                                    <option value="6">6 Year</option>
                                    <option value="7">7 Year</option>
                                    <option value="8">8 Year</option>
                                </select>

                                <!-- <input class="form-control input_bdr_clr font_text" type="text" [(ngModel)]="newArray[i].textValue" [ngModelOptions]="{standalone: true}" /> -->
                            </div>


                        </div>



                        <div *ngIf="addAndRemoveBtn" class="form-container"
                            style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addDepartments()">Add More</button>

                                <button class="btn btn-warning" (click)="removemorebtn()">Remove</button>
                            </div>
                        </div>



                    </div>




                    <!-- <button (click)="addContainer()">Add More</button> -->


                    <!-- <div class="form-group" style="margin-top:80px;">
                        <label class="fsize16 clr_black font_bold_gilroy">Department *</label>
                        <input type="text"  disabled  id="btnExcel" class="form-control input_bdr_clr font_text" 
                        [(ngModel)]="collegeDepartment" [ngModelOptions]="{standalone: true}"
                      maxlength="150" />

                    </div>
                    <div class="form-group" style="margin-top:20px;" >
                        <label class="fsize16 clr_black font_bold_gilroy">Batch Year *</label>
                        <input type="text" disabled  id="btnExcel1" placeholder="Please Enter The Duration"  class="form-control input_bdr_clr font_text" 
                        [(ngModel)]="batchYear" [ngModelOptions]="{standalone: true}"
                        
                        maxlength="150" />

                    </div>

<div style="margin-bottom: 20px;" id="more-email"></div>
<div style="margin-bottom: 20px;" id="more-email"></div>          -->



                    <!-- <div class="form-container" style="float: right ; ">
<div class="form-group">
            <button id="addEmail" style="min-width:90px; margin-right: 20px; "  (click)="addDepartments()"  class="btn btn-primary">Add </button> 
            <button id="removeEmail" style="min-width:90px;"   class="btn btn-warning">Remove </button>
             </div>
    </div> -->



                    <div class="form-group " style="margin-top:120px ;">
                        <button type="submit" class="btn  submit_btn_clr" (click)="submitBtn()">Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="cancelBtn()">Cancel</button>
                    </div>

                    <!-- ----------------------------------------------------------------------------------------->
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>

    <!-- --------Model Pop Up------------------ -->

    <div class="modal fade" id="editStudentDeletePop" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-body">

                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                    <div class="font_regular_gilroy">
                        Do you Want to Delete College ?
                    </div>

                </div>
                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                        (click)="studentEditDeleteApi()">Submit</button>
                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                        data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>




</div>