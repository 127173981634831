import { Injectable,Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService } from './service/api.service'; 
@Injectable({
  providedIn: 'root'
})

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {

  constructor(private injector:Injector,private ApiService: ApiService) { }
  intercept(req, next) {
    
    let apiService=this.injector.get(ApiService)
   
    let tokenizedReq = req.clone({
      setHeaders:{
        Authorization:`'Bearer' ${apiService.getToken()}`
      }
    })
    return next.handle(tokenizedReq)
  }
 
}
