import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare const $: any;
@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.scss']
})
export class ContentManagementComponent implements OnInit {

  show_day_div = false;
  show_Sos_div = false;
  show_Expedition_div = false;
  workoutQuestionAll = [];
  sosQuestionAll = [];
  expeditionQuestionAll = [];
  workoutquestionid = '';
  constructor(private router: Router, private ApiService: ApiService) { }
  availabilty_url = '/workout-edit';
  availabilty_add = '/workout-add';
  sos_url = '/sos-edit';
  expedition_Edit_url = '/happiness-expedition-edit';
  sos_add = '/sos-add';
  expedition_add = '/happiness-expedition-add';
  workoutView_url = '/workout-view';
  sosView_url = '/sos-view';
  expedition_View_url = '/happiness-expedition-view';
  questionAll = "";
  questionarray = [];
  workoutQuestion = "";
  questionsArray = [];
  questionarrays = "";
  
  async ngOnInit() {
    var getapi = "/workout_questions_all";

    (await this.ApiService.getApi(getapi)).subscribe((res) => {
  this.workoutQuestionAll = res.data;
      for (var product of this.workoutQuestionAll) {
      var raju = JSON.parse(product.thwrk_qstn_question);
        for (var i = 0; i < raju.length; i++) {
          var raa = raju[i].question;
           this.questionsArray = raa;
       
        }
      }
  var questionAll = res.data
  });

 // -------------------
    var getapi = "/sos_questions_all";

    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.sosQuestionAll = res.data;
    });
    // -------------------------------

    var getapi = "/get_expedition_questions";

    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.expeditionQuestionAll = res.data;
    });


  }

  edit_happyWorkOut(editItem: any) {
    this.router.navigate([this.availabilty_url]);
    localStorage.setItem('EditId', JSON.stringify(editItem.thwrk_qstn_id));
  }

  sos_Edit(selectedItem: any) {
    this.router.navigate([this.sos_url]);
    localStorage.setItem('sosId', JSON.stringify(selectedItem.thsos_qstn_id));
  }

  expedition_EditPage() {
    this.router.navigate([this.expedition_Edit_url]);
  }
  workout_ViewPage(selectedItem: any) {
    this.router.navigate([this.workoutView_url]);
    localStorage.setItem('workoutId', JSON.stringify(selectedItem.thwrk_qstn_id));
  }

  sos_ViewPage(selectedItem: any) {
    this.router.navigate([this.sosView_url]);
    localStorage.setItem('sosId', JSON.stringify(selectedItem.thsos_qstn_id));
  }
  expedition_ViewPage(selectedItem: any) {
    this.router.navigate([this.expedition_View_url]);
    localStorage.setItem('expeditionId', JSON.stringify(selectedItem.thexpdn_qstn_id));
  }

  daydiv() {
    this.show_day_div = true;
    this.show_Sos_div = false;
    this.show_Expedition_div = false;
  }
 sOsdiv() {
    this.show_Sos_div = true;
    this.show_day_div = false;
    this.show_Expedition_div = false;
  }
 expeditionDiv() {
    this.show_Sos_div = false;
    this.show_day_div = false;
    this.show_Expedition_div = true;
  }
  workOutAdd() {
    this.router.navigate([this.availabilty_add]);
  }
  sosAdd() {
    this.router.navigate([this.sos_add]);
  }

  expeditionAdd() {
    this.router.navigate([this.expedition_add]);
  }

}
