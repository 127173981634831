import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var swal: any;
@Component({
  selector: 'app-coupon-view',
  templateUrl: './coupon-view.component.html',
  styleUrls: ['./coupon-view.component.scss']
})
export class CouponViewComponent implements OnInit {
  isShown: boolean = false;
  couponTitle: any;
  couponDescription: any;
  couponCode: any;
  coupenValue: any;
  coupenType: any;
  coupenExpiry: any;
  coupen_Id: any;
  getCouponData = [];
  couponAlldata = [];

  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.coupen_Id = this.ApiService.couponViewId;
    this.getView_CouponApi();
   
  }
  async getView_CouponApi() { 
    var postapi = "/view_coupon";
    var couponView_Id = {
      "id": this.coupen_Id
    };
    (await this.ApiService.postApi(postapi, couponView_Id)).subscribe(
      res => {
        this.getCouponData = res.data;
        for (let item of this.getCouponData) {
          this.couponTitle = item.title;
          this.couponDescription = item.description;
          this.couponCode = item.code;
          this.coupenValue = item.value;
          this.coupenType = item.type;
          var coupenExpiryDate = item.expiry;
          this.coupenExpiry = coupenExpiryDate.slice(0, 10);
          var goalsDate = moment(coupenExpiryDate).format('DD-MM-YYYY');
          this.couponAlldata.push({ ...item, expiry: goalsDate });
        
        }

      });

  }



}
