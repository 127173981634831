import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-tidbit-master',
  templateUrl: './tidbit-master.component.html',
  styleUrls: ['./tidbit-master.component.scss']
})
export class TidbitMasterComponent implements OnInit {
  blogAdd_url = "/tidbit_master_add";
  blogEdit_url = "/tidbit_master_edit";
  blogView_url = "/tidbit_master_view";
  tidbitList:any;
  deleteTidbitId:any;
  showLoaderDiv = false;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.getlist_tidbitMaster();
  }

  blogAddMaster(){
    this.router.navigate([this.blogAdd_url]);
  }

  blogMasterEdit(item:any){
  this.ApiService.tidbit_EditId = item.emotion_id;
  localStorage.setItem("tidbitEmotionId",item.emotion_id)

    this.router.navigate([this.blogEdit_url]);
  }

  blogMasterView(){
    this.router.navigate([this.blogView_url]);
  }
// ------------------------------------------


async getlist_tidbitMaster() {
  this.showLoaderDiv = true;
  var getapi = "/list_tidbit";
  (await this.ApiService.getApi(getapi)).subscribe((res) => {
    console.log(res);
    if (res.status === 200) {
      this.tidbitList = res.result;
      this.showLoaderDiv = false;
    }
  });

}
deleteItems(items: any) {
  this.deleteTidbitId = items.emotion_id;
}
async tidbitDelete() {
  var deleteApi = "/delete_tidbit/" + this.deleteTidbitId;
  (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
    if (res.status === 200) {
      this.getlist_tidbitMaster();
      window.location.reload();
    }
  });
}





}
