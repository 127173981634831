import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
enum CheckBoxType { APPLY_FOR_JOB, MODIFY_A_JOB, NONE };
declare const $: any;
declare var swal: any;
// declare var angular: any;
@Component({
  selector: 'app-content-sos-edit',
  templateUrl: './content-sos-edit.component.html',
  styleUrls: ['./content-sos-edit.component.scss']
})
export class ContentSosEditComponent implements OnInit {
  registerForm: FormGroup;
  showCogRegForm: FormGroup;
  questionRegForm: FormGroup;
  audioTypeRegForm: FormGroup;
  affirmationTypeRegForm: FormGroup;
  gridTypeRegForm: FormGroup;
  multiResRegForm: FormGroup;
  reviewRegForm: FormGroup;
  yesOrnoRegForm: FormGroup;
  sosRegForm: FormGroup;
  // 
  submitted = false;
  submitted1 = false
  show_questionText_div = false;
  show_questionImage_div = false;
  show_questionAudio_div = false;
  affirmation_div = false;
  grid_div = false;
  multi_response_div = false;
  review_div = false;
  Yes_or_No_div = false;
  isShown: boolean = false;
  YesQuestion_ShowDiv = false;
  NoQuestion_ShowDiv = false;
  sos_Edit_cancelBtn = "/sos-feel-table";
  // ----------------------------------------
  show_feelingswheel: any;
  show_cognitiveBiases: any;
  question_Types: any;
  questions: any;
  noOfquestions: any;
  statusActive: any;
  answer_PlaceholderText: any;
  sos_editId: any;
  answer_placeholder_text = ""
  answer_placeholder_text1 = "";
  answer_placeholder_text2 = "";
  question_One = "";
  question_two = "";
  yesPlaceholderText = "";
  noQuestionText = "";
  noPlaceholderText = "";
  sos_addId: any;
  mood_Type: any;
  base64textString: String = "";
  questionNoRadioBtn: any;
  questionYesRadioBtn: any;

  question_Type = "";
  getAllEmotion = [];
  emotionUpdateId: any;
  showToastMessage = "";
  collectResponse = "";
  placeholder_exercise = "";
  answer_label = "";
  positionExercise = "";
  show_Feelings: any;
  statusInActive: any;
  sosGetQuestion_List = [];
  sosGetExercise_List = [];
  showCognitive: any;
  sosQuestionEditPage = "/question-sos-edit";
  sosExerciseEditPage = "/exercise-sos-edit";
  sosQuestionViewPage = "/question-sos-view";
  sosExerciseViewPage = "/exercise-sos-view";
  answerLabel = "";
  questionDeleteId: any;
  exerciseDeleteId: any;
  showaddQuestionBtn = false;
  showExerciseBts = false;
  emotions_Name: any;
  emotions_ids: any;
  showYes_NoquestionDiv = false;
  showNo_NoquestionDiv = false;
  redirectLogin_url = "/"
  // -----------------
  check_box_type = CheckBoxType;
  currentlyChecked: CheckBoxType;
  no_yes_question: any;
  noYesShowToastMessage: any;
  noYesCollectResponse: any;
  noYesAnswerPlaceholder: any;
  no_no_question: any;
  no_no_ShowToastMessage: any;
  no_no_collectResponse: any;
  no_no_answer_placeholder: any;
  no_radiobtn: any;
  question_NoRadioBtn: any;

  showtost: any;
  showcollect: any;
  getResponseShowDiv = false;
  logicQuestionShowDiv = false;
  yesLogicQuestionDiv = false;
  noLogicQuestionDiv = false;
  no_LogicQuestion: any;
  no_logic_placeholder: any;
  logicNoRadiobtn: any;
  questionNoResponseRadioBtn: any;
  responseQuestions: any;
  no_responseAnswerPlaceholder: any;
  sosSubmitted = false;
  backurls = "/sos-feel-table";
  sosExerciseForm: FormGroup;
  sosExerciseSubmitted = false;

  constructor(private formBuilder: FormBuilder, private fb: FormBuilder, private ApiService: ApiService, private router: Router) { }
  ngOnInit() {
    this.sos_addId = this.ApiService.sos_Add_id;

    this.sos_editId = this.ApiService.sosEdit_id;
    this.sos_editId = localStorage.getItem("sosEditIds")
    var rajan = this.ApiService.sosEmotionName;

    this.sosRegForm = this.fb.group(
      {
        emotion: ["", Validators.required],
        numberQuestion: ["", Validators.required],
      },
    );


    this.sosExerciseForm = this.fb.group(
      {
        exercisePlaceholder: ["", Validators.required],
        questionLabel: ["", Validators.required],
        exerciseFileUpload: ["", Validators.required],
        exercisePosition: ["", Validators.required],
      },
    );




    // showCogRegForm


    this.showCogRegForm = this.fb.group(
      {
        name: ["", Validators.required],
        questionType: ["", Validators.required],

      },
    );



    this.registerForm = this.fb.group(
      {
        emotion: ["", Validators.required],
        emotionImage: ["", Validators.required],
        question: ["", Validators.required],
        questionType: ["", Validators.required],
        answerType: ["", Validators.required],
        examples: ["", Validators.required],
      },
    );

    this.questionRegForm = this.fb.group(
      {
        textQuestion: ["", Validators.required],
        ansPlaceholderTextOne: ["", Validators.required],
      },
    );

    this.audioTypeRegForm = this.fb.group(
      {
        audioQuestion: ["", Validators.required],
        audiofile: ["", Validators.required],
        // audioAnswerPlaceholder: ["", Validators.required],
      },
    );

    this.affirmationTypeRegForm = this.fb.group(
      {
        affirmationQuestion: ["", Validators.required],
        affirmationPlaceholder: ["", Validators.required],
        // 
      },
    );

    this.gridTypeRegForm = this.fb.group(
      {
        gridQuestion: ["", Validators.required],
      },
    );

    this.multiResRegForm = this.fb.group(
      {
        multiResQuestion: ["", Validators.required],
        multiAnsTextOne: ["", Validators.required],
        multiAnsTextTwo: ["", Validators.required],
        multiAnsTextThree: ["", Validators.required],

      },
    );

    this.reviewRegForm = this.fb.group(
      {
        reviewQuestionOne: ["", Validators.required],
        reviewQuestionTwo: ["", Validators.required],
        reviewQuestionThree: ["", Validators.required],
      },
    );
    this.yesOrnoRegForm = this.fb.group(
      {
        yesOrnoRadiobtn: ["", Validators.required],

      },
    );

    // yesOrnoRegForm

    var selectRange = '';
    var val = '';
    for (var day = 1; day <= 10; day++) {
      selectRange += '<option val=' + day + '>' + day + '</option>';
    }
    $('select#sosRange').html(selectRange);

    //  -----------------------


    // angular.module('app', []).controller('appc', ['$scope',
    //   function($scope) {
    //     $scope.selected = 'other';
    //   }
    // ]);




    // ----------------------------------------
    $("input:checkbox").on('click', function () {

      var $box = $(this);
      if ($box.is(":checked")) {

        var group = "input:checkbox[name='" + $box.attr("name") + "']";

        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });

    // ------------------------

    //  --------------------

    if (this.sos_addId != "") {
      var sos_AllId = this.sos_addId;
    }
    else if (this.sos_editId != "") {
      var sos_AllId = this.sos_editId;
    }


    this.getSosDetailsAllApi();
    this.sosGetQuestionList();
    this.sosExerciseList();
    this.sosGetEmotionListApi();
    // ------------------------------------------------------------

    $('.franquiaCheckbox').click(function () {
      $(this).siblings('input:checkbox').prop('checked', false);
    });


  }

  async getSosDetailsAllApi() {


    var getapi = "/sos/get_sosdetails";

    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Unauthorized access",
          type: "error"
        }, function () {
          alert('yo');
        });
      }


      var myArray = res.data;
      for (var i = myArray.length; i--;) {
        var item = myArray[i];
        var ids = item.id;


        var idnum = item.id;
        if (ids == this.sos_addId) {
          this.mood_Type = item.emotion_name + "," + item.emotion;
          this.noOfquestions = item.no_of_question;
          this.show_Feelings = item.show_feeling_wheel;
          var currentStatus = item.status;

          if (currentStatus == "1") {

            this.statusActive = item.status;
          }
          else if (currentStatus = ! "1") {
            this.statusInActive = item.status;

          }
        }

        else if (idnum == this.sos_editId) {
          this.mood_Type = item.emotion_name + "," + item.emotion;

          this.noOfquestions = item.no_of_question;
          this.show_Feelings = item.show_feeling_wheel;
          var currentStatus = item.status;

          if (currentStatus == "1") {

            this.statusActive = item.status;
          }
          else if (currentStatus = ! "1") {
            this.statusInActive = item.status;

          }
        }
        // if (ids == this.sos_editId) {

        // this.mood_Type = item.emotion;
        // var emotionIds = item.emotion;
        // this.mood_Type = emotionIds;


        // if (emotionIds == "7") {
        //   this.mood_Type = "Panic Attack";
        // }
        // else if (emotionIds == "6") {
        //   this.mood_Type = "Lonely";
        // }
        // else if (emotionIds == "5") {
        //   this.mood_Type = "Overwhelmed";
        // }
        // else if (emotionIds == "4") {
        //   this.mood_Type = "Empty";
        // }
        // else if (emotionIds == "3") {
        //   this.mood_Type = "Sad";
        // }
        // else if (emotionIds == "2") {
        //   this.mood_Type = "Stressed";
        // }
        // else if (emotionIds == "1") {
        //   this.mood_Type = "Angry";
        // }

        // this.question_Types = item.question_type;            
        // this.questions = item.question;
        // this.answer_PlaceholderText = item.answer_placeholder;
        // this.answer_placeholder_text1 = item.question_placeholder_one;
        // this.answer_placeholder_text2 = item.question_placeholder_two;
        // }
      }
      // this.sosQuestionSubmit();
      // this.sosGetQuestionList();
    });

  }

  statuscheck() {

  }


  emotionItem(emotionItem) {
    this.emotionUpdateId = emotionItem;
    var str = this.emotionUpdateId;
    var myarray = str.split(',');


    for (var i = 0; i < myarray.length; i++) {
      this.emotions_Name = myarray[0];
      this.emotions_ids = myarray[1];

    }


  }

  async sos_UpdateSubmit() {
    this.submitted = true;
    this.sosSubmitted = true;
    // 

    if (this.sosRegForm.invalid) {
      return;
    }


    if (this.sos_addId != "") {
      var sos_ids = this.sos_addId;
    }
    else if (this.sos_editId != "") {
      var sos_ids = this.sos_editId;
    }
    // ----------------------------------
    var str = this.mood_Type;
    var myarray = str.split(',');


    for (var i = 0; i < myarray.length; i++) {
      this.emotions_Name = myarray[0];
      this.emotions_ids = myarray[1];

    }


    // --------------------------

    var putapi = "/sos/update_sosdetails/" + sos_ids;
    var sosUpdatedata = {
      "no_of_question": this.noOfquestions,
      "emotion": this.emotions_ids,
      "show_feeling_wheel": 1,
      "status": this.statusActive,
      "is_active": 1
    };

    (await this.ApiService.putApi(putapi, sosUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "SOS Edit Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });

            this.router.navigate([this.backurls]);

          }
          else if (res.status === 401) {
            this.router.navigate([this.redirectLogin_url]);
            swal({
              icon: 'error',
              title: 'Oops...',
              text: "Unauthorized access",
              type: "error"
            }, function () {
              alert('yo');
            });
          }
        }
      });
  }
  questionOne(e) {
    var questionValue = e.target.value;

    if (questionValue == 'text') {
      this.show_questionText_div = true;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'image') {
      this.show_questionText_div = false;
      this.show_questionImage_div = true;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'audio') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = true;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'affirmation') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = true;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'grid') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = true;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'multi_response') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = true;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }

    if (questionValue == 'review') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = true;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'yes_or_no') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = true;
      this.YesQuestion_ShowDiv = false;
      this.NoQuestion_ShowDiv = false;
    }

  }

  yesOrNo_questionType(e) {

    var questionValue = e.target.value;
    if (questionValue == 'Yes') {
      this.YesQuestion_ShowDiv = true;
      this.NoQuestion_ShowDiv = false;
    }
    if (questionValue == 'No') {
      this.YesQuestion_ShowDiv = false;
      this.NoQuestion_ShowDiv = true;
    }
  }
  yesOrNo_questionYesType(e) {
    var questionValue = e.target.value;
    if (questionValue == 'Yes') {
      this.YesQuestion_ShowDiv = true;
      this.NoQuestion_ShowDiv = false;
    }
  }
  yesOrNo_questionNoType(e) {
    var questionValue = e.target.value;
    if (questionValue == 'No') {
      this.YesQuestion_ShowDiv = false;
      this.NoQuestion_ShowDiv = true;
    }

  }

  responseNoquestionType(e) {

    var questionValue = e.target.value;
    if (questionValue == '0') {
      this.getResponseShowDiv = true;
      this.logicQuestionShowDiv = false;
    }
    if (questionValue == '1') {
      this.getResponseShowDiv = false;
      this.logicQuestionShowDiv = true;
    }

  }
  logicNoquestionType(e) {
    var questionValue = e.target.value;
    if (questionValue == '0') {
      this.noLogicQuestionDiv = true;
      this.yesLogicQuestionDiv = false;

    }
    if (questionValue == '1') {
      this.noLogicQuestionDiv = false;
      this.yesLogicQuestionDiv = true;
    }
  }

  sosEditCancelBtn() {
    this.router.navigate([this.sos_Edit_cancelBtn]);
  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];


    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);

  }

  async sosGetEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getAllEmotion = res.data;
      // this.mood_Type = res.themotion_name
    });
  }

  show_tostFun() {

  }


  checkboxchange(e) {
    this.showToastMessage = e.target.value

  }
  checkboxchange2(e) {
    this.collectResponse = e.target.value

  }

  toastmsgChange(e) {
    var raja = e.target.value
    if (raja == 1) {
      this.showtost = "1";
      this.showcollect = "0"
    }
  }
  collectResChange(e) {
    var pandi = e.target.value
    if (pandi == 0) {
      this.showtost = "0";
      this.showcollect = "1"
    }

  }



  async sosQuestionSubmit() {

    this.submitted1 = true;

    if (this.showCogRegForm.invalid) {
      return;
    }


    if (this.question_Type == 'text') {
      if (this.questionRegForm.invalid) {
        return;
      }
    }
    else if (this.question_Type == 'audio') {
      if (this.audioTypeRegForm.invalid) {
        return;
      }
    }
    else if (this.question_Type == 'affirmation') {
      if (this.affirmationTypeRegForm.invalid) {
        return;
      }
    }
    else if (this.question_Type == 'grid') {
      if (this.gridTypeRegForm.invalid) {
        return;
      }
    }

    else if (this.question_Type == 'multi_response') {
      if (this.multiResRegForm.invalid) {
        return;
      }
    }

    else if (this.question_Type == 'review') {
      if (this.reviewRegForm.invalid) {
        return;
      }
    }
    else if (this.question_Type == 'yes_or_no') {
      if (this.yesOrnoRegForm.invalid) {
        return;
      }
    }

    // 




    //  if(this.showToastMessage  == "" &&  this.collectResponse == ""){
    //   this.showToastMessage = "0";
    //   this.collectResponse = "0";
    //  }

    // if(raja == '0' && raja !='1' && raja !="" ){
    //   this.showToastMessage = "1";
    // }
    //  if (raja == '1' && raja != '0' && raja != ""){
    //   this.collectResponse = "1";
    // }

    // if(this.collectResponse == "1"){
    //   this.showToastMessage = "0";
    // }

    // var rajal :any

    // rajal = this.showToastMessage

    // if(rajal == true ){

    //   this.showToastMessage = "1";

    // }
    // if(raja == "" || raja == false ){
    //   this.showToastMessage = "0";
    //   this.collectResponse = "0";
    // }


    var postapi = "/sos/create_questions";
    if (this.sos_addId != "") {
      var sos_Dayids = this.sos_addId;
    }
    else if (this.sos_editId != "") {
      var sos_Dayids = this.sos_editId;
    }

    // if( this.showToastMessage != "1" && this.showToastMessage == "" ){
    //   this.showToastMessage = "0"
    // }

    // if( this.collectResponse == ""){
    //   this.collectResponse = "0"
    // }

    if (this.showToastMessage == "") {
      this.showToastMessage = "0"
    }
    if (this.collectResponse == "") {
      this.collectResponse = "0"
    }
    var sosQuestionData = {
      "sos_id": sos_Dayids,
      "question_type": this.question_Type,
      "question1": this.questions,
      "question2": this.question_One,
      "question3": this.question_two,
      "answer_placeholder1": this.answer_placeholder_text,
      "answer_placeholder2": this.answer_placeholder_text1,
      "answer_placeholder3": this.answer_placeholder_text2,
      "position": 1,
      "show_cognitive_biases": this.showCognitive,
      "audio": this.base64textString,
      "selected_yes_no_type": this.questionYesRadioBtn,
      "show_toast": this.showtost,
      "collect_response": this.showcollect,
      "type": "question",
      "is_active": 1,
      "no_question_type": "",
      "no_question": this.responseQuestions,
      "no_checkbox": this.no_radiobtn,
      "no_placeholder": this.no_logic_placeholder,
      "logic_question": this.questionNoResponseRadioBtn,
      "logic_yes_or_no": this.logicNoRadiobtn,
    };

    (await this.ApiService.postApi(postapi, sosQuestionData)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {

            $("#exampleModalquestion .close").click()

            swal({
              title: "Success!",
              text: "Question updated successfully ",
              type: "success",
              imageWidth: 400,
              imageHeight: 400,
            },
              function () {
                alert('yo');
              });

            this.question_Type = "";
            this.questions = "";
            this.answer_placeholder_text = "";
            this.question_One = "";
            this.question_two = "";
            this.answer_placeholder_text = "";
            this.answer_placeholder_text1 = "";
            this.answer_placeholder_text2 = "";
            this.showCognitive = "";
            this.base64textString = "";
            this.questionYesRadioBtn = "";


          }
          else if (res.status === 401) {
            this.router.navigate([this.redirectLogin_url]);
            swal({
              icon: 'error',
              title: 'Oops...',
              text: "Unauthorized access",
              type: "error"
            }, function () {
              alert('yo');
            });
          }
        }
        this.sosGetQuestionList();
      });
  }

  async addExerciseSubmit() {

    this.sosExerciseSubmitted = true;
    if (this.sosExerciseForm.invalid) {
      return;
    }


    var postapi = "/sos/create_questions";


    if (this.sos_addId != "") {
      var sos_Dayids = this.sos_addId;
    }
    else if (this.sos_editId != "") {
      var sos_Dayids = this.sos_editId;
    }

    var sosExerciseData = {
      "sos_id": sos_Dayids,
      "question_type": "",
      "question1": "",
      "question2": "",
      "question3": "",
      "answer_placeholder1": this.placeholder_exercise,
      "answer_placeholder2": "",
      "answer_placeholder3": "",
      "position": this.positionExercise,
      "show_cognitive_biases": 0,
      "audio": this.base64textString,
      "selected_yes_no_type": "",
      "show_toast": "1",
      "collect_response": "1",
      "type": "exercise",
      "is_active": 1,
      "answer_label": this.answerLabel,
    };

    (await this.ApiService.postApi(postapi, sosExerciseData)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {

            $("#addExerciseModel .close").click()

            swal({
              title: "Success!",
              text: "Exercise updated successfully",
              type: "success",
              imageWidth: 400,
              imageHeight: 400,
            }, function () {
              alert('yo');
            });

            this.placeholder_exercise = "";
            this.positionExercise = "";
            this.base64textString = "";
            this.answerLabel = "";

          }
          else if (res.status === 401) {
            this.router.navigate([this.redirectLogin_url]);

            swal({
              icon: 'error',
              title: 'Oops...',
              text: "Unauthorized access",
              type: "error"
            }, function () {
              alert('yo');
            });
          }
        }
        this.sosExerciseList();
      });


  }

  // --------------Sos Get Question-----------------------

  async sosGetQuestionList() {

    if (this.sos_addId != "") {
      var sos_Dayids = this.sos_addId;

    }
    else {
      var sos_Dayids = this.sos_editId;
    }
    //  var getapi = "/sos/get_questions_byId?sos_id="+ sos_Dayids+"&type=question&id="+this.sos_editId;
    var getapi = "/sos/get_questions?sos_id=" + sos_Dayids + "&type=question";


    // var getapi = "/sos/get_questions?day_id="+this.sos_addId+"&type=question";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {


      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Unauthorized access",
          type: "error"
        }, function () {
          alert('yo');
        });
      }

      this.sosGetQuestion_List = res.data;

      if (this.sosGetQuestion_List.length > 0) {
        this.showaddQuestionBtn = true;
      }
      else if (this.sosGetQuestion_List.length = 0) {
        this.showaddQuestionBtn = false;
      }

    });

  }

  async sosExerciseList() {

    if (this.sos_addId != "") {
      var sos_Dayids = this.sos_addId;

    }
    else if (this.sos_editId != "") {
      var sos_Dayids = this.sos_editId;
    }
    var getapi = "/sos/get_questions?sos_id=" + sos_Dayids + "&type=exercise";

    // var getapi = "/sos/get_questions?day_id="+this.sos_addId+"&type=exercise"; 
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.sosGetExercise_List = res.data;

      if (this.sosGetExercise_List.length > 0) {
        this.showExerciseBts = true;
      }
      else if (this.sosGetExercise_List.length = 0) {
        this.showExerciseBts = false;
      }
    });

  }
  questionSos_edit(editItem: any) {
    this.ApiService.sosQuestionEdit_Id = editItem.id;
    this.ApiService.sosQuestionAdd_Id = editItem.sos_id
    this.router.navigate([this.sosQuestionEditPage]);
  }
  exerciseSos_edit(exerciseEdit: any) {
    this.ApiService.sosExerciseEdit_Id = exerciseEdit.id;
    this.ApiService.sosExerciseAdd_Id = exerciseEdit.sos_id
    this.router.navigate([this.sosExerciseEditPage]);
  }
  questionSos_view(viewItem: any) {

    this.ApiService.sosQuestionViewEdit_Id = viewItem.id;
    this.ApiService.sosQuestionViewAdd_Id = viewItem.sos_id;
    this.router.navigate([this.sosQuestionViewPage]);

  }
  exerciseSos_view(exeViewId: any) {

    this.ApiService.sosExerciseViewEdit_Id = exeViewId.id;
    this.ApiService.sosExerciseViewAdd_Id = exeViewId.sos_id;
    this.router.navigate([this.sosExerciseViewPage]);

  }

  delete_Question(deleteId: any) {
    this.questionDeleteId = deleteId.id;
  }

  async questionDeleteSubmit() {
    var deleteApi = "/sos/remove_questions/" + this.questionDeleteId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      this.sosGetQuestionList();
    });
  }

  delete_Exercise(deleteIdExe: any) {
    this.exerciseDeleteId = deleteIdExe.id;
  }

  async exerciseDeleteSubmit() {
    var deleteApi = "/sos/remove_questions/" + this.exerciseDeleteId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      this.sosExerciseList();
    });
  }




}
