import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'happinessAdmin';

  openSideNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  /* Set the width of the side navigation to 0 */
  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  async ngOnInit() {
    if (!localStorage.getItem('token') && window.location.hash != '#/') {
      window.location.href = '#/'
    }


    var autoLogoutTimer;
    var count = 0;
    resetTimer();
    $(document).on('mouseover mousedown touchstart click keydown mousewheel DDMouseScroll wheel scroll', document, function (e) {
      resetTimer();
    });
    function resetTimer() {
      count = 0
      clearInterval(autoLogoutTimer)
      autoLogoutTimer = setInterval(idleLogout, 2 * 60 * 60 * 1000)
    }
    function idleLogout() {
      // count += 1
      // console.log(count)

      var inactivityTimeout = 2 * 60 * 60 * 1000;

      if (++count >= inactivityTimeout) {
        localStorage.clear()
        count = 0
        window.location.href = "#/"
      }
    }


  }
}
