<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Community Management
    </div>

    <div class="marg-t-20">
        <div class="row marg-0">
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <button type="button" class="buttons_clr bgr_clr_orange" (click) = "topics()" > Topic </button>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <button class="buttons_clr bgr_clr_blue" (click) = "moderation()" > Moderation </button>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
            </div>

        </div>
    </div>
    <!-- ---Topics Content------ -->

    <div *ngIf = "show_topic_div">

    <div class="padd-0-25">
        <div class="marg-t-70 fsize27 font_bold_gilroy">
            Community Topics
        </div>

        <div class="marg-t-40">

            <div class="row marg-0">
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <!-- -------------------------------------------------------- -->

                    <form>


                        <div>

                            <div class="form-group">
                                <label class="fsize16 clr_black font_bold_gilroy">Topic Name</label>
                                <input type="text" [(ngModel)]="topicsName" [ngModelOptions]="{standalone: true}"  id="questions" class="form-control input_bdr_clr " />
                            </div>

                             <div class="form-group">
                                <label class="fsize16 clr_black font_bold_gilroy">Image</label>
                                <input type="file"  (change)="handleFileSelect($event)"  class="form-control input_bdr_clr padd-3 " />
                            </div>
                            <div class="form-group">
                                <label class="fsize16 clr_black font_bold_gilroy">Description</label>
                                
                                <textarea class="form-control input_bdr_clr h-90 "  [(ngModel)]="descriptions" [ngModelOptions]="{standalone: true}" ></textarea>

                                <!-- <input type="text" class="form-control input_bdr_clr " /> -->
                            </div>

                            <div class="fsize16 clr_black font_bold_gilroy" >
                                 Active | In Active
                            </div>

                            <div class="marg-t-20">
                           
                                <label class="switch">
                                    <input type="checkbox" checked  >
                                    <span class="slider round"></span>
                                  </label>


                            </div>

                        </div>

                        <div class="form-group marg-t-40">
                            <button type="submit" class="btn  submit_btn_clr" (click) = "CommunityTopicSubmit()"  >Submit</button>
                        </div>
                    </form>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                </div>
            </div>
        </div>

        <div class="marg-t-40 fsize16 clr_black  font_bold_gilroy">
            Total No of Users : 100
        </div>
    
        <div class="marg-t-20">
            <div class="row marg-0">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <button class="buttons_clr bgr_clr_orange" (click)= "feedList()" > Feed </button>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <button class="buttons_clr bgr_clr_blue" (click)= "userList()"  > User List </button>
                </div>
    
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                </div>
    
            </div>
        </div>

    </div>

</div>

<!-- ---Moderation Content-- -->

<div *ngIf = "show_moderation_div" >
<div class="marg-t-30" >
    <div class="bdr_clr_table">
              <table class="w-100">
                  <tr class="bgr_th font_bold_gilroy">
                      <th>S.No</th>
                      <th>Comments</th>
                      <th>Post</th>
                      <th>poster Name</th>
                      <th>Reporter</th>
                      <th>Action</th>
                  </tr>
                  <tr class="bgr_td font_medium_gilroy" >
                      <td>1</td>
                      <td>Comments</td>
                      <td>Post</td>
                      <td>Rajaa</td>
                      <td>Reporter</td>
                      <td>Delete | Leave</td>
                  </tr>
              </table>
  
              </div>
  
      </div>
</div>



</div>