import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
@Component({
  selector: 'app-user-view-page',
  templateUrl: './user-view-page.component.html',
  styleUrls: ['./user-view-page.component.scss']
})
export class UserViewPageComponent implements OnInit {
  userName = "";
  user_MobileNo = "";
  user_Email = "" ;
  user_Id = "";
  constructor(private ApiService: ApiService) { }

  async ngOnInit(){

    var idworkout = JSON.parse(localStorage.getItem('userviewId'));
    var getapi = "/getusers";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res);
      var user_ViewAllData = res.result;
      for (var i = user_ViewAllData.length; i--;) {
        var item = user_ViewAllData[i];

        var ids = item.thusr_id
        if (ids == idworkout) {
          this.userName = item.thusr_name;
           this.user_MobileNo = item.thusr_mobile ;
           this.user_Email = item.thusr_email ;
           this.user_Id = item.thusr_id;
    
        }
      }

    });
  }

}
