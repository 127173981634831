import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-blog-master-add',
  templateUrl: './blog-master-add.component.html',
  styleUrls: ['./blog-master-add.component.scss']
})
export class BlogMasterAddComponent implements OnInit {
  isShown: boolean = false;
  blogCancel_url = "/blog_master_list";
  getAllEmotion: any;
  emotionsId: any;
  article: any;
  blogTitle: any;
  blogStatus: any;
  type_Type: any;
  base64textString: any;
  registerForm: FormGroup;
  submitted = false;
  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.registerForm = this.fb.group(
      {
        blogtitle: ["", Validators.required],
        blogEmotion: ["", Validators.required],
        blogThumbnail: ["", Validators.required],
        blogarticle: ["", Validators.required],
        blogStatus: ["", Validators.required],
      },
    );

    this.getEmotionListApi();
  }

  blogCancelBtn() {
    this.router.navigate([this.blogCancel_url]);
  }

  async submitAddBlog() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var postapi = "/add_and_update_blog";
    var exercisesAdd = {
      "thumbnail": this.base64textString,
      "title": this.blogTitle,
      "content": this.article,
      "emotion": this.emotionsId,
      "isactive": this.blogStatus
    };
    (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
      res => {


        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Blog Added successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.blogCancel_url]);
        }

      },

      (error) => {

        var errMsg = '';
        for (var i in error.error.message) {
          errMsg += error.error.message[i] + '<br />';
        }
        swal({
          title: "error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });



      }



    )
  }

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getAllEmotion = res.data
    });
  }

  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;

  }
  // --------------------
  // Image Base 64

  handleFileSelect(evt) {
    var files = evt.target.files;

    var file = files[0];
    this.type_Type = file.type

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
  }



}
