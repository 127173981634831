<div class="padd-0-40">


    <div *ngIf="showLoaderDiv">

        <div id="pause" class="d-flex align-items-center justify-content-center">
            <div id="spinner"></div>
        </div>

    </div>


    <div class="marg-t-70 fsize27 font_bold_gilroy header-container">
        <h3>Coach Slot List</h3>
        <div class="button-group">
            <button type="submit" class="btn-back" (click)="back_management()">Back</button>
            <button type="submit" class="btn-update-schedule" (click)="slotcreate()">+ Update Schedule</button>
        </div>
    </div>


    <div class="marg-t-30">
        <div class="" style="background-color: #fff; border-radius: 8px;">
            <div *ngIf="loading" class="loader-container">
                <div class="loader"></div>
            </div>
            <table class="row-border hover table w-100" datatable *ngIf="!loading && coach_slot_list.length > 0">
                <thead>
                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>Date</th>
                        <th>Start-Time</th>
                        <th>End-Time</th>
                        <th>Cut-Off Time</th>
                        <th>Duration</th>
                        <th>Mode</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of coach_slot_list; let i = index" class="font_medium_gilroy">
                        <td>{{i+1}}</td>
                        <td>{{item.slot_start_date}}</td>
                        <td>{{item.slot_start_time}}</td>
                        <td>{{item.slot_end_time}}</td>
                        <td>{{ item.cutoff_time }}{{ item.cutoff_time != 0 ? ' hrs' : '' }}</td>
                        <td>{{item.duration}}</td>
                        <td>{{item.therapy_mode}}</td>
                        <td>
                            {{ item.slot_status === 1 ? 'Available' : item.slot_status === 2 ? 'Waiting' :
                            item.slot_status === 3 ? 'Booked' : item.slot_status === 4 ? 'Deleted' : '-' }}
                        </td>
                        <td><span class="marg-l-10 cursor_pointer"> <button type="button"
                                    class="border_clr_none cursor_pointer" data-toggle="modal"
                                    data-target="#exampleModal">
                                    <img height="20px" width="20px" (click)="delete_id(item)" class="bgr_black"
                                        src="assets/Delete_icon.png" alt=""> </button>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="!loading && coach_slot_list.length == 0">
                <div class="text-center padd_10 p-2">No Data Available in Table</div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">

                    <div class="fsize18 font_bold_gilroy "> Delete Confirmation ! </div>
                    <div class="fsize18 font_bold_gilroy "> Are you sure ! you want to delete this slot ? </div>

                </div>

                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                    <button type="button" style="cursor: pointer;" class="btn_submit_cancel bgr_clr_red"
                        data-dismiss="modal" (click)="delete_slot()">Delete</button>
                    <button type="button" style="cursor: pointer;" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                        data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>

</div>