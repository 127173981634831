<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/blog_master_list']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Blog Master Add</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <div class="">
                    <form [formGroup]="registerForm">
                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy"> Blog Title *</label>
                            <input type="text" id="questions" [(ngModel)]="blogTitle"
                            formControlName="blogtitle" class="form-control input_bdr_clr " />

                            <div *ngIf="submitted && registerForm.get('blogtitle').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('blogtitle').errors.required"> Blog Title is required</div>
                            </div>

                        </div>

                        <label class="fsize16 clr_black font_bold_gilroy"> Emotion*</label>
                        <select formControlName="blogEmotion" (change)="emotionItem($event)" class=" form-control input_bdr_clr ">
                            <option [disabled]="true" [selected]="true">Select an Emotion</option>
                            <option [value]="[item.themotion_id]" *ngFor="let item of getAllEmotion">
                                {{item.themotion_name}} </option>
                        </select>
                        <div *ngIf="submitted && registerForm.get('blogEmotion').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('blogEmotion').errors.required"> Emotion is required</div>
                        </div>


                        <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy"> Blog Thumbnail*</label>
                            <input formControlName="blogThumbnail" type="file" accept="image/*" id="questions" class="form-control input_bdr_clr"
                                (change)="handleFileSelect($event)" />
                        </div>
                       
                        <div *ngIf="submitted && registerForm.get('blogThumbnail').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('blogThumbnail').errors.required"> Thumbnail is required</div>
                        </div>


                        <!-- 
                        <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy">Article *</label>
                            <ckeditor id="articles"  [(ngModel)]="article"
                            [ngModelOptions]="{standalone: true}" name="myckeditor" debounce="500">
                            </ckeditor>
                        </div> -->

                        <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy">Article *</label>
                            <div>


                                <ckeditor name="myckeditor" formControlName="blogarticle" [(ngModel)]="article"
                                    debounce="500">
                                </ckeditor>

                                <!-- <div *ngIf="submitted && registerForm.get('blogThumbnail').errors" class="alert alert-danger">
                                    <div *ngIf="registerForm.get('blogThumbnail').errors.required"> Thumbnail is required</div>
                                </div> -->

                                <!-- <textarea  id="articles"  [(ngModel)]="article"
                                [ngModelOptions]="{standalone: true}" name="" id="" maxlength="3000" class="form-control input_bdr_clr" style="min-height:100px"   ></textarea> -->
                            </div>
                            
                            <div *ngIf="submitted && registerForm.get('blogarticle').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('blogarticle').errors.required"> Article is required</div>
                            </div>



                        </div>

                        <div class="marg-t-20">
                            <span class="fsize16 clr_black font_bold_gilroy">Status * :
                            </span>
                            <span>
                                <label for="vehicle1"> Active</label>
                                <input [(ngModel)]="blogStatus"  class="marg-l-8"
                                    type="radio" id="vehicle1" name="viewBeliefs" value="1" formControlName="blogStatus">
                            </span>

                            <span>
                                <label class="marg-l-8" for="vehicle1">In Active</label>
                                <input [(ngModel)]="blogStatus"  class="marg-l-8"
                                    type="radio" id="vehicle1" name="viewBeliefs" value="0" formControlName="blogStatus">
                            </span>

                            <div *ngIf="submitted && registerForm.get('blogStatus').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('blogStatus').errors.required"> Status is required</div>
                            </div>



                        </div>

                        <div class="form-group marg-t-40">
                            <button type="button" (click)="submitAddBlog()" class="btn  submit_btn_clr">Submit</button>
                            <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                                (click)="blogCancelBtn()">Cancel</button>
                        </div>
                    </form>
                </div>
                <div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>

    </div>

</div>