import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var swal: any;
@Component({
  selector: 'app-student-portal-add',
  templateUrl: './student-portal-add.component.html',
  styleUrls: ['./student-portal-add.component.scss']
})
export class StudentPortalAddComponent implements OnInit {
  isShown: boolean = false;
  collegeName: any;
  collegeAddress = "";
  collegeIds: any;
  collegeDepartment = "";
  batchYear: any;
  college_Code = "";
  isActive : any;
  collegeDepartment1: any;
  newArray1: any = {};
  newArray2: any;
  studentPortaladd_url = "/student-portal-table";
  container: any[] = [1];
  newArray: any = [{ key: 1, values: '' }];
  departmentDivshow = false;
  addAndRemoveBtns = false
  registerForm: FormGroup;
  submitted = false;
  submitBtnShow = false

  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder) { }


  setObj(index: number) {

  }

  ngOnInit() {
    const that = this;
    $(document).ready(function () {

      $("#addEmail").on("click", function () {
        if (!(that.collegeDepartment == "" || that.collegeDepartment == undefined)) {
          that.addDepartments();
          $("#more-email").append("<div style='margin-top:20px 'class='raja'><label for='exampleInputEmail1' style = 'color:#000; font-weight: 500;' >Department</label><input type='text' [(ngModel)]='collegeDepartment' [ngModelOptions]='{standalone: true}'  onclick='setObj(event,  )'  class='form-control'  /></div><div style='margin-top:20px ' class='raja'><label for='exampleInputEmail1' style = 'color:#000; font-weight: 500;   ' >Batch Year</label><input type='text' class='form-control' id='btnExcel1' [(ngModel)]='batchYear' [ngModelOptions]='{standalone: true}'  /></div>  ");
        }


      });
      $("#removeEmail").on("click", function () {
        $("#more-email").children().last().remove();
      });

    });
    // -----------------------------------------

    this.registerForm = this.fb.group(
      {
        collegename: ["", Validators.required],
        collegeaddress: ["", Validators.required],
        collegecode: ["",Validators.required]
      },
    );




  }

  async submitadd() {

    var postapi = "/add_college";
    var addCampdata = {
      "name": this.collegeName,
      "address": this.collegeAddress,
      "college_code": this.college_Code

    };

    (await this.ApiService.postApi(postapi, addCampdata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {

            swal({
              title: "Success!",
              text: "Add college Success ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.studentPortaladd_url]);

          }
          else if (res.status === 401) {

          }

        }
      });

  }


  toggleEnable(id) {
    let textbox: HTMLButtonElement = document.getElementById(id) as HTMLButtonElement | null;
    if (textbox.disabled) {
      // If disabled, do this 
      textbox.disabled = false;
    } else {
      // Enter code here
      textbox.disabled = true;
    }
  }


  toggleIsActive() {
    this.isActive = !this.isActive; // Toggle the isActive variable
  }

  async createCollege() {

    this.submitted = true;



    if (this.registerForm.invalid) {
      return;
    }


    this.departmentDivshow = true;
    this.submitBtnShow = true;
    this.addAndRemoveBtns = true;
    var postapi = "/add_college";
    var addCampdata = {
      "name": this.collegeName,
      "address": this.collegeAddress,
      "college_code":this.college_Code,
      "is_active": this.isActive ? 1 : 0  
    };

    (await this.ApiService.postApi(postapi, addCampdata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {

            this.ApiService.collegeId = res.college_id;
            console.log("College Data",res)


            swal({
              title: "Success!",
              text: "Add college Success ",
              type: "success"
            }, function () {
              alert('yo');
            });
          }
          else if (res.status === 401) {
          }

        }
      });

  }
  // ---------------
  async addDepartments() {
    this.collegeIds = this.ApiService.collegeId;
    let keyValue = this.container.length + 1;
    var rajaa = this.newArray[this.newArray.length - 1];
    this.newArray.push({ key: keyValue, values: '' });


    this.container.push(keyValue);
    var postapi = "/add_department";
    var dep = [
      {
        "cdate": "2021-01-01",
        "college_id": this.collegeIds,
        "duration": rajaa.textValue,
        "department": rajaa.values,
      },
      {
        "cdate": "2021-01-01",
        "college_id": this.collegeIds,
        "duration": rajaa.textValue,
        "department": rajaa.values,
        "year": "2002"
      },
    ]
    dep[dep.length - 1];

    (await this.ApiService.postApi(postapi, dep[dep.length - 1])).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Department Added success",
              type: "success"
            }, function () {
              alert('yo');
            });

            this.ApiService.departmentId = res.department_id;


          }
          else if (res.status === 401) {
          }

        }
      });

  }

  manage() {

    // var bt = document.getElementById('btSubmit');
    // document.getElementById("btnExcel").disabled = false;


  }

  // -------------------

  addContainer() {
    let keyValue = this.container.length + 1;

    var rajaa = this.newArray[this.newArray.length - 1];

    this.newArray.push({ key: keyValue, values: '' });

    this.container.push(keyValue);
  }
  async removebtn() {
    this.container.pop();
    var postapi = "/delete_department";
    var deptId = this.ApiService.departmentId;
    var removeDepartmentId = {
      "department_id": deptId

    };
    (await this.ApiService.postApi(postapi, removeDepartmentId)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Department Deleted success",
              type: "success"
            }, function () {
              alert('yo');
            });

this.addAndRemoveBtns = false;

          }
          else if (res.status === 401) {
          }

        }
      });

  }

  async subitedd() {
    this.collegeIds = this.ApiService.collegeId;
    let keyValue = this.container.length + 1;
    console.log(this.newArray[this.newArray.length - 1], 'keyvaluss');
    var rajaa = this.newArray[this.newArray.length - 1];
    var deptValues = rajaa.values;
    var durationsValue = rajaa.textValue;
    var postapi = "/add_department";
    var dep = [
      {
        "cdate": "2021-01-01",
        "college_id": this.collegeIds,
        "duration": durationsValue,
        "department": deptValues


      },
      {
        "cdate": "2021-01-01",
        "college_id": this.collegeIds,
        "duration": durationsValue,
        "department": deptValues
      },
    ]
    dep[dep.length - 1];
    console.log(dep[dep.length - 1], 'dep');
    // -----------------------------------------------------------

    (await this.ApiService.postApi(postapi, dep[dep.length - 1])).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Department Added success",
              type: "success"
            }, function () {
              alert('yo');
            });

            this.ApiService.departmentId = res.department_id;
            console.log(this.ApiService.departmentId, 'departmentidss');
            this.router.navigate([this.studentPortaladd_url]);

          }
          else if (res.status === 401) {
          }

        }
      });

  }

  cancelBtn() {
    this.router.navigate([this.studentPortaladd_url]);


  }



}
