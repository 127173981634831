import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var swal: any;
@Component({
  selector: 'app-user-edit-table',
  templateUrl: './user-edit-table.component.html',
  styleUrls: ['./user-edit-table.component.scss']
})
export class UserEditTableComponent implements OnInit {
  registerForm: FormGroup;
  isShown: boolean = false;
  submitted = false;
  userName = "";
  user_MobileNo = "";
  user_Email = "";
  happiness_Point: any;
  userEdit_url = "/user-table";
  userId:any;
  thusr_dob:any;
  thusr_location:any;
  constructor(private formBuilder: FormBuilder, private fb: FormBuilder, private ApiService: ApiService, private router: Router) { }
  availabilty_url = '/coach-edit-page';
  async ngOnInit() {
     this.userId = this.ApiService.adminUser_id;
     this.userId = localStorage.getItem("userEdit_Ids");
    //
    this.registerForm = this.fb.group(
      {
        name: ["", Validators.required],
        mobile: ["", Validators.required],
        email: ["",[Validators.required, Validators.email]],
        happinessPoints: ["", Validators.required],
        dob: ["", Validators.required],
        location: ["", Validators.required],

      },
    );

    // var idworkout = JSON.parse(localStorage.getItem('userEditId'));
    var getapi = "/get_user_details";
    var editdata = {
      "user_id":  this.userId,

    };
    (await this.ApiService.postApi(getapi,editdata)).subscribe((res) => {
      console.log(res);
      var user_AllData = res.result;
      for (var item of user_AllData) {

        var ids = item.thusr_id
        if (ids == this.userId) {
          this.userName = item.thusr_name;
          this.user_MobileNo = item.thusr_mobile;
          this.user_Email = item.thusr_email;
          this.happiness_Point = item.thusr_happiness_points;
          this.thusr_dob = moment(item?.thusr_dob, 'DD-MM-YYYY').format('YYYY-MM-DD');
          this.thusr_location = item.thusr_location;

          //  this.coach_typeOfTherapy = item.thcoach_types_of_therapy;

        }
      }

    });






  }
  async onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    var postapi = "/admin_edit_user";

    var adminUserdata = {
      "user_id": this.userId,
      "name":  this.userName,
      "mobile": this.user_MobileNo,
      "email": this.user_Email,
      "happiness_points": this.happiness_Point,
      "thusr_dob" : this.thusr_dob,
      "thusr_location": this.thusr_location
    };
    (await this.ApiService.postApi(postapi, adminUserdata)).subscribe(
      res => {


        if (res) {
          if (res.status === 200) {

            swal({
              icon: 'Success!',
              text: "User Update Succcessfully",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.userEdit_url]);
          }

        }
      });


  }





}
