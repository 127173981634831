<div class="">
  <div class=" ">

    <div class="marg-t-70 fsize30">
      <div class="row marg-0">

        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div class="bdr_green  font_regular_gilroy">
            <div class="padd-30-20 fsize20">No of coaches</div>
            <div style="border-top: 1px solid rgba(0,0,0,.125);"></div>
            <div class="padd-20-30 fsize16">{{coachCount}}</div>

          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div class="bdr_blue  font_regular_gilroy">
            <div class="padd-30-20 fsize20">No of Users</div>
            <div style="border-top: 1px solid rgba(0,0,0,.125);"></div>
            <div class="padd-20-30 fsize16">{{userCount}}</div>

          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div class="bdr_red  font_regular_gilroy">
            <div class="padd-30-20 fsize20">Today Session</div>
            <div style="border-top: 1px solid rgba(0,0,0,.125);"></div>
            <div class="padd-20-30 fsize16">{{todayAppointmentCount}}</div>

          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
          <div class="bdr_yellow  font_regular_gilroy">

            <!-- <h5>Upcoming Session</h5>
            <h4>0</h4> -->

            <div class="padd-30-20 fsize20">Upcoming Session</div>
            <div style="border-top: 1px solid rgba(0,0,0,.125);"></div>
            <div class="padd-20-30 fsize16">{{upcomingAppointmentCount}}</div>


          </div>
        </div>


      </div>
      <div style="margin: 40px;
      display: flex;
      flex-direction: column;">
         <div style="
         width: auto;
         display: flex;
         margin: 20px;
         flex-direction: row;
         justify-content: space-between;">
          <div>
            Inventory summary
          </div>
          <button type="button" class="btn btn-secondary" (click)="exportToExcel()"
                    style="background-color:#6CC3B8; border: 1px solid #6cc3b8; height:48px; width:210px;color:#fff;margin-right:6px;margin-top:20px;border-radius: 6px; margin-bottom:40px;"
                    data-toggle="tooltip" data-placement="top"
                    title="Export all user details on this page table to CSV document">
                    <span><img height="30px" src="../assets/file-excel.svg" alt=""></span> Export
                </button>
          <div class="date-range-container">
            <mat-form-field style="width: 150px;">
              <mat-label style="font-size: 16px;">Start Date</mat-label>
              <input matInput [matDatepicker]="picker1" [(ngModel)]="startDate" (dateChange)="onDateChange()" style="font-size: 20px;">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          
            <mat-form-field style="width: 150px;">
              <mat-label style="font-size: 16px;">End Date</mat-label>
              <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate" (dateChange)="onDateChange()" style="font-size: 20px;">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div style="
        width: auto;
        display: flex;
        flex-direction: row;">
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <div class="font_regular_gilroy"
              style="border-width: thin;
              height: 150px;
              border-color: black;
              border-radius: 20px;
              background-color: white;
              border-style: solid;">
              <div class="padd-30-20 fsize20">Available</div>
              <div style="border-top: 1px solid rgba(0,0,0,.125);"></div>
              <div class="padd-20-30 fsize16">{{inventory.available}}</div>

            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <div class="font_regular_gilroy"
              style="border-width: thin;
              height: 150px;
              border-color: black;
              border-radius: 20px;
              background-color: rgb(215, 255, 215);
              border-style: solid;">
              <div class="padd-30-20 fsize20">Booked</div>
              <div style="border-top: 1px solid rgba(0,0,0,.125);"></div>
              <div class="padd-20-30 fsize16">{{inventory.booked}}</div>

            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <div class="font_regular_gilroy"
              style="border-width: thin;
              height: 150px;
              border-color: black;
              border-radius: 20px;
              background-color: rgb(255, 215, 215);
              border-style: solid;">
              <div class="padd-30-20 fsize20">Unused</div>
              <div style="border-top: 1px solid rgba(0,0,0,.125);"></div>
              <div class="padd-20-30 fsize16">{{inventory.unused}}</div>

            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            <div class="font_regular_gilroy"
              style="border-width: thin;
              height: 150px;
              border-color: black;
              border-radius: 20px;
              background-color: rgb(215, 244, 255);
              border-style: solid;">

              <!-- <h5>Upcoming Session</h5>
              <h4>0</h4> -->

              <div class="padd-30-20 fsize20">Utilization</div>
              <div style="border-top: 1px solid rgba(0,0,0,.125);"></div>
              <div class="padd-20-30 fsize16">{{inventory.utilization}}%</div>


            </div>
          </div>
        </div>
      </div>

      <div style="margin: 40px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;">
        <div style="width: 65%;">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Coach</th>
                <th>Available</th>
                <th>Booked</th>
                <th>30 min</th>
                <th>60 min</th>
                <th>Unused</th>
                <th>Utilization</th>
                <th>Revenue</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let coach of coaches">
                <td>{{ coach.coach_name }}</td>
                <td>{{ coach.available_slots }}</td>
                <td>{{ coach.booked_sessions }}</td>
                <td>{{ coach.thirty_min_sessions }}</td>
                <td>{{ coach.one_hour_sessions }}</td>
                <td>{{ coach.unused_slots }}</td>
                <td>{{ coach.utilization }}%</td>
                <td>₹{{ coach.total_revenue }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="    background-color: beige;
        max-width: 30%;
        display: flex;
        flex-direction: column;
        height: fit-content;
        border-radius: 20px;">
          <div style="
          height: fit-content;
          width: fit-content;">
            <div style="margin:10px">
              <div>
                Weekly summary
              </div>
              <div class="dropdown-container" style="font-size: 16px;">
                <label for="month" >Select Month:</label>
                  <select id="month" [(ngModel)]="selectedMonth" (change)="onMonthChange($event)">
                    <option *ngFor="let month of months" [value]="month.value">
                      {{ month.name }}
                    </option>
                  </select>
              </div>
              <div class="dropdown-container" style="font-size: 16px;">
                <label for="year">Select Year:</label>
                  <select id="year" [(ngModel)]="selectedYear" (change)="onYearChange($event)">
                    <option *ngFor="let year of years" [value]="year.name">
                      {{ year.name }}
                    </option>
                  </select>
              </div>
              <div class="table-container" style="background-color: white; width: fit-content;">
                <table>
                  <thead>
                    <tr>
                      <th>Week</th>
                      <th>Available </th>
                      <th>Booked </th>
                      <th>Unused </th>
                      <th>Utilization </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of weeklyData">
                      <td>{{ data.week_number }}</td>
                      <td>{{ data.available }}</td>
                      <td>{{ data.booked }}</td>
                      <td>{{ data.unused }}</td>
                      <td>{{ data.utilization }}%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div style="
          height: fit-content;
          width: fit-content;">
            <div style="margin:10px">
              <div>
                Coach summary
              </div>
              <div class="dropdown-container" style="font-size: 16px;">
                <label for="coach" >Select Coach:</label>
                  <select id="coach" [(ngModel)]="selectedCoachId" (change)="onCoachNameChange($event)">
                    <option *ngFor="let coach of coachList" [value]="coach.thcoach_user_id">
                      {{ coach.thusr_name }}
                    </option>
                  </select>
              </div>
              <div class="table-container" style="background-color: white; width: fit-content;">
                <table>
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Number of sessions </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of coachRetentionList">
                      <td>{{ data.clientName }}</td>
                      <td>{{ data.occurrences }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->

    <!-- <div class="marg-t-30" style="margin-left:20px;"> -->

      <!-- <div class="row"> -->
      <!-- <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"> -->
      <!-- <div class="" style="padding: 10px;">

        <div class="fsize20 font_clr_black font_regular_gilroy">
          Today Session
        </div>

        <div class="marg-t-20">
          <div style=" background-color: #fff; border-radius: 8px;">
            <div *ngIf="loading" class="loader-container">
              <div class="loader"></div>
            </div>
            <table class="font_regular_gilroy TFtable row-border hover table w-100" datatable style="width: 100%;"
              *ngIf="!loading && todaySessions.length > 0">
              <thead>
                <tr class="" style="border-bottom: 2px solid #dee2e6;">
                  <th>S.No</th>
                  <th>User Name</th>
                  <th>User Role</th>
                  <th>User Id</th>
                  <th style="text-align: center;">User Email</th>
                   <th>Contact No</th> -->
      <!-- </tr>
              </thead>

              <tbody>
                <tr class="red" *ngFor="let item of todaySessions; let i=index">
                  <td>{{i+1}}</td>
                  <td>{{item.user_name}}</td>
                  <td>Admin</td> -->
      <!-- <td>123</td> -->
      <!-- <td>{{item.user_id}}</td>
                  <td style="text-align: center;">{{item.thusr_email}}</td> -->
      <!-- <td>{{item.user_id}}</td> -->

      <!-- </tr>
              <tbody>
            </table>
            <div *ngIf="!loading && todaySessions.length == 0">
              <div class="text-center padd_10">No Data Available in Table</div>
            </div>

          </div>
        </div>
      </div>  -->

      <!-- <div class="" style="padding: 10px;"> -->
        <!-- <div class="fsize20 font_clr_black font_regular_gilroy">
          Upcoming Session
        </div> -->

        <!-- <div class="marg-t-20">

          <div style="background-color: #fff; border-radius: 8px;">
            <div *ngIf="loading" class="loader-container">
              <div class="loader"></div>
            </div>
            <table class="font_regular_gilroy TFtable row-border hover table w-100" datatable style="width: 100%;"
              *ngIf="upCommingSessions.length > 0">
              <thead>
                <tr class="" style="border-bottom: 2px solid #dee2e6;">
                  <th>S.No</th>
                  <th>User Name</th>
                  <th>User Role</th>
                  <th>User Id</th>
                  <th style="text-align:center;">User Email</th>
                  <th>Contact No</th>

                </tr>
              </thead>

              <tbody>
                <tr class="red" *ngFor="let item of upCommingSessions; let i=index">
                  <td>{{i+1}}</td>
                  <td>{{item.user_name}}</td>
                  <td>Admin</td>
                  <td>{{item.user_id}}</td>
                  <td style="text-align:center;">{{item.thusr_email}}</td>
                  <td>{{item.user_id}}</td>

                </tr>
              </tbody>


            </table>

            <div *ngIf="!loading && upCommingSessions.length == 0">
              <div class="text-center padd_10">No Data Available in Table</div>
            </div>

          </div>


        </div> -->
        <!-- <div class="marg-t-20" style="background-color: #fff; border-radius: 8px; padding: 10px 0;">

          <div style="display: flex; padding-top: 30px;margin-right: 40px;justify-content: space-between;">
            <div class="fsize20 font_clr_black font_regular_gilroy" style="margin-left: 25px;">
              Upcoming Session
            </div>
            <input type="text" placeholder="Search..." (input)="searchTable($event)" class="form-control"
              style="margin-bottom: 10px; width:25%;">
          </div>
          <div *ngIf="loading" class="loader-container">
            <div class="loader"></div>
          </div>
          <table class="font_regular_gilroy TFtable row-border hover table w-100" datatable [dtOptions]="dtOptions"
            *ngIf="!loading && upcommingAppointment.length > 0" style="width: 100%;">
            <thead>
              <tr class="" style="border-bottom: 2px solid #dee2e6;">
                <th>S.No</th> -->
                <!-- <th>Appt No</th> -->
                <!-- <th>Order ID</th> -->
                <!-- <th>Coach Name</th>
                <th> User Name</th>
                <th> User Id</th> -->
                <!-- <th> User type</th> -->
                <!-- <th>College/Corporate</th> -->
                <!-- <th>Code</th> -->
                <!-- <th>Amount</th> -->
                <!-- <th> Email</th>
                <th>Contact No</th>
                <th>Appt Date</th> -->
                <!-- <th>Next Session</th> -->
                <!-- <th>Duration</th> -->
                <!-- <th>Payment Method</th> -->
                <!-- <th> Appt  Time</th> -->
                <!-- <th>Status</th>
                <th>G-Meet</th> -->
                <!-- <th>Booked Date</th> -->
                <!-- <th>Action</th>
                      <th>View</th> -->
              <!-- </tr>
            </thead>
            <tbody>
              <tr class="red" *ngFor="let item of upcommingAppointment  ;let i = index">
                <td>{{(currentPage - 1) * entriesPerPage + i + 1}}</td> -->

                <!-- <td>{{item.app_number}}</td> -->
                <!-- <td>{{item.order_id}}</td> -->
                <!-- <td>{{item.coach_name}}</td>
                <td>{{item.user_name}}</td>
                <td>{{item.user_id}}</td> -->
                <!-- <td>{{item.user_type}}</td> -->
                <!-- <td>{{item.college_name}} {{item.corporate_name}}</td> -->
                <!-- <td>{{item.coupon_code !== null ? item.coupon_code :'N/A'}}</td> -->
                <!-- <td>{{item.amount}}</td> -->
                <!-- <td>{{item.user_email}}</td>
                <td>{{item.user_mobile}}</td>
                <td>{{item.date}} {{item.time}}</td> -->
                <!-- <td style="text-align: center;">{{item.follow_up_date === '00-00-0000 12:00 AM' ? '-' :
                          item.follow_up_date }}</td>
                      <td>{{item.duration}}</td> -->
                <!-- <td>{{item.payment_method}}</td> -->
                <!-- <td>{{item.time}}</td> -->
                <!-- <td>
                  <span>{{item.appointment_status}}</span>
                  <span>
                    <span *ngIf="item.appointment_status == 'Cancelled' ">

                      <span *ngIf="item.mby == item.coach_id">-By Coach</span>
                      <span *ngIf="item.mby == item.user_id">By User</span>

                    </span>
                  </span>
                </td>
                <td>
                  <span *ngIf="item.gmeet_link" (click)="openMeetingLink(item.gmeet_link)">
                    <img  style=" width: 40px;"
                      src="assets/gmeet_logo.png"/>
                  </span>
                </td> -->
                <!-- <td>{{(item.booked_datetime)?item.booked_datetime : "N/A"}}</td> -->
                <!-- <td>
                          <span class="downloadlist" (click)="downloadlist(item)"><img class="download_cursor"
                                  src="assets/download.png"
                                  style="height: 30px; width: 30px; padding: 5px; padding-left: 5px;"></span>
                      </td>
                      <td>
                          <span class="download_cursor" (click)="appointment_detail_page(item.id)">
                              <img class="download"
                                  style="height: 20px; width: 20px; margin-left: 10px; margin-top: 10px;"
                                  src="assets/View_icon.png">
                          </span>
                      </td> -->
              <!-- </tr>
            </tbody>
          </table>
          <div *ngIf="!loading && upcommingAppointment.length > 0"
            style="display:flex;justify-content: space-between; padding: 20px;">
            <div *ngIf="!loading && upcommingAppointment.length > 0" style="margin:0px 0px 0px 35px ;">
              Show
              <select [(ngModel)]="entriesPerPage" (change)="onEntriesPerPageChange()" style="margin-left: 5px;"> -->
                <!-- <option value="5">5</option> -->
                <!-- <option value="10">10</option> -->
                <!-- <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              Entries
              of
              {{totalEntries}}
              total
            </div>
            <div class="pagination-controls" style="display: flex;gap: 20px;">
              <button (click)="prevPage()" [disabled]="currentPage === 1">Previous</button>
              <span>Page {{currentPage}} of {{totalPages}}</span>
              <button (click)="nextPage()" [disabled]="currentPage === totalPages">Next</button>
            </div>
          </div>
          <div *ngIf="!loading && upcommingAppointment.length == 0">
            <div class="text-center padd_10">No Data Available in Table</div>
          </div>

        </div> -->


        <!-- <div class="">

        <div class="" style="padding: 10px;">
          <div class="fsize20 font_clr_black font_regular_gilroy">
            Forum Management
          </div>

          <div class="marg-t-20">

            <div style=" background-color: #fff; border-radius: 8px;">

              <table class=" font_regular_gilroy TFtable " datatable style="width: 100%;"
                *ngIf="communityModeration.length > 0">
                <thead>
                  <tr class="" style="border-bottom: 2px solid #dee2e6;">
                    <th>S.No</th>
                    <th>User Name</th>
                    <th>User Role</th>
                    <th>User Id</th>
                    <th>User Email</th>
                    <th>Contact No</th>

                  </tr>
                </thead>


                <tbody>
                  <tr class="red" *ngFor="let item of communityModeration; let i=index">
                    <td>{{i+1}}</td>
                    <td>{{item.thusr_name}}</td>
                    <td>Admin</td>
                    <td>{{item.thusr_id}}</td>
                    <td>{{item.thusr_email}}</td>
                    <td>{{item.thusr_mobile}}</td>

                  </tr>



                </tbody>
              </table>

              <div *ngIf="communityModeration.length == 0">
                <div class="text-center padd_10">No Data Available in Table</div>
              </div>

            </div>


          </div>


        </div>
      </div> -->



        <!-- <div class="row marg-t-20">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

    <div class="box_card">

            <div class="fsize20 font_clr_black font_bold_gilroy">
              Forum Management
            </div>

            <div class="marg-t-20">
              <table class="bdr_clr" style="width: 100%;">
                <tr class="bgr_clr_th font_bold_gilroy">
                  <th>S.No</th>
                  <th>User Name</th>
                  <th>User Role</th>
                  <th>User Id</th>
                  <th>User Email</th>
                  <th>Contact No</th>

                </tr>
                <tr class="font_medium_gilroy">
                  <td>1</td>
                  <td>Raja</td>
                  <td>Admin</td>
                  <td>123</td>
                  <td>Raja@gmail.com</td>
                  <td>4674657455</td>

                </tr>
                <tr class="font_medium_gilroy">
                  <td>2</td>
                  <td>Raja</td>
                  <td>Admin</td>
                  <td>123</td>
                  <td>Raja@gmail.com</td>
                  <td>4674657455</td>

                </tr>
              </table>

            </div>

          </div> 

        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

        </div>

      </div> -->

      </div>

    </div>
  </div>