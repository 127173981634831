<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/tidbit_master']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Tidbit Master View</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <div class="">
                    <form>
                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Image Upload*</label>
                            <input type="file" id="questions" class="form-control input_bdr_clr " />
                        </div>
                        <div class="marg-t-20">
                            <span class="fsize16 clr_black font_bold_gilroy">Status * :
                            </span>
                            <span>
                                <label for="vehicle1"> Active</label>
                                <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs" value="1">
                            </span>

                            <span>
                                <label class="marg-l-8" for="vehicle1">In Active</label>
                                <input class="marg-l-8" type="radio" id="vehicle1" name="viewBeliefs" value="0">
                            </span>
                        </div>

                        <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy">Display order *</label>
                            <input type="text" id="questions" class="form-control input_bdr_clr " />
                        </div>



                        <div class="form-group marg-t-40">
                            <button type="submit" class="btn  submit_btn_clr">Submit</button>
                            <button type="button" class=" marg-l-16 cancelBtn_clr fsize16">Cancel</button>
                        </div>
                    </form>
                </div>
                <div>

                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>

</div>