import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';

declare const $: any;
declare var swal: any;

@Component({
  selector: 'app-review-management',
  templateUrl: './review-management.component.html',
  styleUrls: ['./review-management.component.scss']
})
export class ReviewManagementComponent implements OnInit {

  constructor(private router: Router, private api: ApiService) { }

  review_list: any[] = [];
  deleteid: any;
  id: any;
  status: number;
  getdata: any;
  message: ''
  user_name: any;
  showLoaderDiv = false;

  items = [
    { name: 'approve', buttonClicked: false, message: 'approve' },
    { name: 'reject', buttonClicked: false, message: 'reject' },
  ];


  ngOnInit(): void {
    this.getreview_list();

  }

  async getreview_list() {
    var getapi = '/listReviewAdmin';
    (await this.api.getApi(getapi)).subscribe(
      res => {
        if (res.status == 200) {
          this.review_list = res.result;
          // this.Status = this.review_list;
        }
        // console.log('ststussu', this.status)
        console.log('list of data', this.review_list)
      }
    )
  }

  async handleButtonClick(item: any, status: number) {
    item.buttonClicked = true;

    item.status = status;

    var updatestatus = {
      "id": item.id,
      "status": item.status,
    };

    console.log('status', item.status)
    console.log('id', item.id)

    const updateapi = `/updateReviewStatus/${item.id}`;
    (await this.api.postApi(updateapi, updatestatus)).subscribe(
      res => {
        if (res.status == 200) {

          alert('updated successfully')
          item.buttonsHidden = true;

          // item.message = (item.status === 1) ? 'Approved' : 'Rejected';
        }
        console.log('mesagv jh', item.message)
      }
    );
  }

  async delete_id(deleteReview: number) {
    this.deleteid = deleteReview;
  }

  async deleteReview() {

    var delapi = `/deleteReview/${this.deleteid}`;

    (await this.api.deleteApi(delapi)).subscribe(
      res => {
        if (res.status == 200) {
          this.getreview_list();
          console.log('id', this.deleteid)
        }
      }
    )
  }

}
