import { Component, OnInit, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { OwlDateTimeComponent } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCalendar, MatDatepickerContent } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { MatCalendarCellCssClasses } from '@angular/material/datepicker';


declare var $;
declare var swal: any;
@Component({
  selector: 'app-slot-management',
  templateUrl: './slot-management.component.html',
  styleUrls: ['./slot-management.component.scss']
})
export class SlotManagementComponent implements OnInit {

  encapsulation: ViewEncapsulation.None


  daysSelected: any[] = [];
  event: any;


  selectedDates: string = ''; // String to store selected dates

  selectedButton: string = 'single_date'; // Track selected button
  selectedMode: string = 'video';
  selectedCutoff: string = '1';

  initialSelectedDates: string = '';

  slot_management_url = '/coach-slot-management-list'

  showTimePicker1: boolean = false;
  selectedHour1: number;
  selectedMinute1: number;
  selectedPeriod1: string;

  showTimePicker2: boolean = false;
  selectedHour2: number;
  selectedMinute2: number;
  selectedPeriod2: string;

  selectedDuration: string = '00:30:00';
  timeSelections: any[] = [
    {
      startHour: '',
      startMinute: '',
      startPeriod: '',
      endHour: '',
      endMinute: '',
      endPeriod: ''
    }
  ];


  hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  minutes = ['00','15','30','45'];


  today: string;

  periodRequiredAm: any;
  periodRequiredPm: any;
  startTime: any;
  endTime: any;

  datas: any[] = [];
  showLoaderDiv = false;
  minDate: Date;
  thusrName: any
  coach_id: any

  thcoach_user_id: any

  coach_type: any
  coach_name:any;

  thcoach_type: any;
  cutoffOptions: number[] = [];



  @ViewChild('calendar') calendar: MatCalendar<Date>


  constructor(private router: Router, private ApiService: ApiService, private route: ActivatedRoute) {

  }


  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.coach_id = params['coach_id'],
        this.coach_type = params['coach_type'],
        this.coach_name = params['coach_name']
        console.log('Received thusr_id:', this.coach_id);
      console.log('Received coach_type:', this.coach_type);
      console.log('Received coach_name:', this.coach_name);
      // You can now use this.thusrName in your component logic
    });

    this.getCutoffTime();

    this.getCoachListApi();
  }



  async getCutoffTime() {
    var getApi = `/cutoffTime/${this.coach_id}`;
    (await this.ApiService.getApi(getApi)).subscribe((res) => {
      this.thcoach_type = res.result.thcoach_type;
      this.cutoffOptions = res.result.cutoff.map(option => option.time);
      console.log("this.thcoach_type", this.thcoach_type)
      console.log("this.cutoff", this.cutoffOptions)
    })
  }

  isCutoffAvailable(time: number): boolean {
    return this.cutoffOptions.includes(time);
  }


  isSelected = (event: any) => {
    if (!event) {
      return null;
    }

    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);

    return this.daysSelected.includes(date) ? "selected" : null;
  };


  select = (event: any, calendar: any) => {
    if (!event) {
      return;
    }

    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);

    if (this.selectedButton === 'multiple_date') {
      // Handle multiple dates selection
      const index = this.daysSelected.indexOf(date);

      if (index === -1) {
        // Date not found, add it
        this.daysSelected.push(date);
      } else {
        // Date found, remove it
        this.daysSelected.splice(index, 1);
      }

      // Update selectedDates as comma-separated string
      this.selectedDates = this.daysSelected.join(',');

      if (!this.initialSelectedDates && this.daysSelected.length > 0) {
        this.initialSelectedDates = this.daysSelected[0];
      }

    } else {
      // Handle single date selection
      this.daysSelected = [date];
      this.selectedDates = date;
    }

    if (!this.initialSelectedDates) {
      this.initialSelectedDates = date;
    }


    calendar.updateTodaysDate();

    console.log('Selected Dates:', this.selectedDates);
    console.log('Initial Selected Dates:', this.initialSelectedDates);

  };


  async getCoachListApi() {
    this.showLoaderDiv = true;
    var getapi = "/getcoaches";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.datas = res.result.reverse();
      console.log("this.datas", this.datas)
      this.showLoaderDiv = false;
    });
  }


  handleDateChange(event: any) {
    console.log('Selected Date: ', event);
  }


  selectButton(button: string) {
    this.selectedButton = button;
    if (button !== 'multiple_date') {
      this.selectedDates = '';
    }
    console.log("Selected Button:", this.selectedButton);
  }

  selectMode(mode: string) {
    this.selectedMode = mode;
    this.selectDuration('00:30:00');
  }

  selectDuration(duration: string) {
    this.selectedDuration = duration; // '01:00:00' for 60 minutes, '00:30:00' for 30 minutes
    this.timeSelections.forEach((selection, index) => {
      this.validateTimeSelection(index);
    });
  }

  selectCutoff(cutoff: string) {
    this.selectedCutoff = cutoff;
    console.log("selectedCutoff--", this.selectedCutoff)

  }


  myFilter = (d: Date | null): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // set to start of the day 
    return d ? d >= today : false;
  };


  addTimeSelection() {

    this.timeSelections.push({
      startHour: '',
      startMinute: '',
      startPeriod: '',
      endHour: '',
      endMinute: '',
      endPeriod: ''
    });
  }

  removeTimeSelection(index: number) {
    if (index >= 0 && index < this.timeSelections.length) {
      this.timeSelections.splice(index, 1);
    }
  }


  onStartTimeChange(index: number, field: string, value: any) {
    this.timeSelections[index][field] = value;
    this.validateTimeSelection(index);
    console.log("this.timeSelections", this.timeSelections)
  }


  validateTimeSelection(index: number) {
    const timeSelection = this.timeSelections[index];
    let durationMinutes;

    // Determine duration in minutes based on selectedDuration
    if (this.selectedDuration === '01:00:00') {
        durationMinutes = 60;
    } else if (this.selectedDuration === '00:30:00') {
        durationMinutes = 30;
    } else {
        return false; // Return false if duration is not recognized
    }

    const startHour = parseInt(timeSelection.startHour, 10);
    const startMinute = parseInt(timeSelection.startMinute, 10);
    const startPeriod = timeSelection.startPeriod;

    let endHour = parseInt(timeSelection.endHour, 10);
    let endMinute = parseInt(timeSelection.endMinute, 10);
    let endPeriod = timeSelection.endPeriod;

    let startTotalMinutes = (startHour % 12) * 60 + startMinute + (startPeriod === 'PM' ? 720 : 0);
    let endTotalMinutes = (endHour % 12) * 60 + endMinute + (endPeriod === 'PM' ? 720 : 0);

    // Calculate allowed end time
    let allowedEndTime = startTotalMinutes + durationMinutes;
    let allowedEndHour = Math.floor(allowedEndTime / 60) % 12 || 12;
    let allowedEndMinute = allowedEndTime % 60;
    let allowedEndPeriod = allowedEndTime >= 720 ? 'PM' : 'AM';

    // Special case handling for transition from 11 PM to 12 AM
    if (startHour === 11 && startPeriod === 'PM' && endHour === 12 && endPeriod === 'AM') {
      return true; // Valid transition from 11:00 PM to 12:00 AM
    }

    // Normal case handling for other scenarios
    if (startHour === 11) {
      if (startPeriod === 'AM' && allowedEndHour >= 12) {
        allowedEndPeriod = 'PM';
      } else if (startPeriod === 'PM' && allowedEndHour >= 12) {
        allowedEndPeriod = 'AM';
      }
    } else if (startHour === 12 && durationMinutes === 60) {
      if (startPeriod === 'AM') {
        allowedEndPeriod = 'AM'; // Ensure end period remains AM
      } else if (startPeriod === 'PM') {
        allowedEndPeriod = 'PM'; // Ensure end period remains PM
      }
    } else {
      // Ensure end time remains in the same AM/PM period as the start time
      if (allowedEndPeriod !== startPeriod) {
        allowedEndTime = startTotalMinutes + (startPeriod === 'AM' ? 720 : 0);
        allowedEndHour = Math.floor(allowedEndTime / 60) % 12 || 12;
        allowedEndMinute = allowedEndTime % 60;
        allowedEndPeriod = startPeriod;
      }
    }

    // Check if the end time is invalid
    if (endTotalMinutes !== allowedEndTime || endPeriod !== allowedEndPeriod) {
      return false; // Return false indicating invalid time selection
    }

    return true; // Return true indicating valid time selection
  }




  periodPm(event: any, index: number) {
    this.timeSelections[index].endPeriod = event.target.value;
    this.validateTimeSelection(index);
    console.log("this.timeSelections", this.timeSelections)
  }

  periodAm(event: any, index: number) {
    this.timeSelections[index].startPeriod = event.target.value;
    this.validateTimeSelection(index);
    console.log("this.timeSelections", this.timeSelections)

  }


  convertTo24HourFormat(hour: number, period: string): string {
    if (period === 'PM' && hour < 12) {
      hour += 12;
    }
    if (period === 'AM' && hour === 12) {
      hour = 0;
    }
    return hour < 10 ? '0' + hour : hour.toString();
  }

  padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }


  async slotbooking() {
    if (this.selectedDates.length === 0) {
      swal({
        title: 'Error!',
        text: 'Please select a date to proceed.',
        type: 'error',
      });
      return;
    }

    // Check if any time selections are invalid
    const invalidTimeSelection = this.timeSelections.some(selection => {
      return !selection.startHour || !selection.startMinute || !selection.startPeriod ||
        !selection.endHour || !selection.endMinute || !selection.endPeriod;
    });

    if (invalidTimeSelection) {
      swal({
        title: 'Error!',
        text: 'Please provide slot information to proceed.',
        type: 'error',
      });
      return;
    }

    const now = moment();
    const currentDate = moment().format('YYYY-MM-DD');
    const datesArray = Array.isArray(this.selectedDates) ? this.selectedDates : [this.selectedDates];

    // Validate that no times are in the past for the current date
    const pastTimeSelected = datesArray.some(date => {
      if (moment(date).format('YYYY-MM-DD') === currentDate) {
        return this.timeSelections.some(selection => {
          const startTime12Hour = `${selection.startHour}:${this.padZero(selection.startMinute)} ${selection.startPeriod}`;
          const endTime12Hour = `${selection.endHour}:${this.padZero(selection.endMinute)} ${selection.endPeriod}`;

          const startTime24Hour = moment(startTime12Hour, 'h:mm A').format('HH:mm');
          const endTime24Hour = moment(endTime12Hour, 'h:mm A').format('HH:mm');

          const startTime = moment(`${currentDate} ${startTime24Hour}`, 'YYYY-MM-DD HH:mm');
          const endTime = moment(`${currentDate} ${endTime24Hour}`, 'YYYY-MM-DD HH:mm');

          return startTime.isBefore(now) || endTime.isBefore(now);
        });
      }
      return false;
    });

    if (pastTimeSelected) {
      swal({
        title: 'Error!',
        text: 'You cannot select a past time for the current date.',
        type: 'error',
      });
      return;
    }

    // Validate time selections for duration mismatch
    const invalidDurationSelection = this.timeSelections.some((selection, index) => !this.validateTimeSelection(index));

    if (invalidDurationSelection) {
      swal({
        title: 'Error!',
        text: 'End time does not match the expected time based on start time',
        type: 'error',
      });
      return;
    }

    // Format time selections for API
    const formattedTimes = this.timeSelections.map(selection => {
      const startTime12Hour = `${selection.startHour}:${this.padZero(selection.startMinute)} ${selection.startPeriod}`;
      const endTime12Hour = `${selection.endHour}:${this.padZero(selection.endMinute)} ${selection.endPeriod}`;

      const startTime24Hour = moment(startTime12Hour, 'h:mm A').format('HH:mm');
      const endTime24Hour = moment(endTime12Hour, 'h:mm A').format('HH:mm');

      return { start_time: startTime24Hour, end_time: endTime24Hour };
    });

    const slotbooking = {
      date_type: this.selectedButton,
      times: formattedTimes,
      dates: this.selectedDates,
      duration: this.selectedDuration,
      cutoff_time: this.selectedCutoff,
      coach_id: this.coach_id,
      specific_time: this.initialSelectedDates,
      therapy_mode: this.selectedMode
    };

    console.log("slotbooking---------", slotbooking);

    const api = "/admin_update_availability";

    // Make API call
    (await this.ApiService.postApi(api, slotbooking)).subscribe(
      res => {
        if (res) {
          if (res.status === "success") {
            swal({
              icon: 'success',
              title: 'Success',
              text: 'Slot Created Successfully',
            });
            this.router.navigate([this.slot_management_url]);
          } else if (res.status === 404) {
            swal({
              icon: 'error',
              title: 'Oops...',
              text: 'Slot already exists!',
            });
          }
        }
      }
    );
  }

  back_management() {
    this.router.navigate([this.slot_management_url])
  }


}



