import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-exercise-sos-view',
  templateUrl: './exercise-sos-view.component.html',
  styleUrls: ['./exercise-sos-view.component.scss']
})
export class ExerciseSosViewComponent implements OnInit {
  answerLabel = "";
  place_holder = "";
  file_upload = "";
  position_Exercise = "";
  base64textString: String = "";
  isShown: boolean = false;
  sos_ExerciseAddIds: any;
  sos_ExerciseEditids: any;
  exercise_EditSubmit_url ="/sos-edit"
  constructor(private ApiService: ApiService , private router: Router) { }

 async ngOnInit() {
    this.sos_ExerciseAddIds = this.ApiService.sosExerciseViewAdd_Id;
    this.sos_ExerciseEditids = this.ApiService.sosExerciseViewEdit_Id;

    var getapi = "/sos/get_questions_byId?sos_id=" + this.sos_ExerciseAddIds + "&type=exercise&id=" + this.sos_ExerciseEditids;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var myArray = res.data;
      for (let item of myArray) {
        var ids = item.id;
        if (ids == this.sos_ExerciseEditids) {
          this.place_holder = item.answer_placeholder1;
          this.position_Exercise = item.position;
          this.answerLabel = item.answer_label;
        }
      }

    });




  }

}
