import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-nutrition-databank-add',
  templateUrl: './nutrition-databank-add.component.html',
  styleUrls: ['./nutrition-databank-add.component.scss']
})
export class NutritionDatabankAddComponent implements OnInit {
  isShown: boolean = false;
  nutritionBack_url = "/nutrition_Databank";
  description: any;
  getAllEmotion = [];
  emotionsId: any;
  nutritionRange: any;
  container: any[] = [1];
  newArray: {
    no: string,
    title: string,
    description: string,
    diet_type: string
  }[] = [{ no: "", title: "", description: "", diet_type: "" }];
  keyValue: any
  getResultAll = [];
  resultId: any;
  categoryAllList: any;
  category_ids: any;
  dietTypes: any;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit() {
    this.getEmotionListApi();
    this.getResultApi();
    this.categoryListApi();
  }

  nutritionBackBtn() {
    this.router.navigate([this.nutritionBack_url]);
  }

  async submitAddNutrition() {
    var allTitle = this.newArray.map((item) => {
      if (item.title != undefined && item.title != '') {
        return { no: item.no, title: item.title, description: item.description, diet_type: item.diet_type }
      }
    })

    console.log(allTitle, 'allTitle....>>>')

    var orderArr = false;
    for (var item of allTitle) {
      if (!item) {
        orderArr = true;
        break;
      }
      if (item.description == '' || item.no == '' || item.diet_type == '' || item.title == '') {
        orderArr = true;
        break;
      }
    }

    if (this.emotionsId != undefined && this.resultId != undefined && !orderArr ) {

      var postapi = "/add_and_update_nutrition";
      var exercisesAdd = {
        "emotion_id": this.emotionsId,
        "result_id": this.resultId,
        "category_id": this.category_ids,
        "diet_type": this.dietTypes,
        "slides": allTitle
      };
      (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
        res => {


          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Nutrition Added successfully",
              type: "success",
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.nutritionBack_url]);
          }

        }, (error) => {
          if (error.error.message.emotion_id != undefined || error.error.message.result_id != undefined) {
            var errMsg = '';
            for (var i in error.error.message) {
              errMsg += error.error.message[i] + '<br />';
            }
            swal({
              title: "error!",
              text: errMsg,
              type: "error",
            }, function () {
              alert('yo');
            });

          }
          else {
            swal({
              title: "error!",
              text: error.error.message,
              type: "error",
            }, function () {
              alert('yo');
            });
          }
        })

    }
    else {

      swal({
        title: "error!",
        text: "Please Fill The All Details",
        type: "error",
      }, function () {
        alert('yo');
      });

    }



  }
  // ----------------Emotion List Api---------------------------

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getAllEmotion = res.data
    });
  }
  // --------------------
  emotionItem(e: any) {

    this.emotionsId = e.target.value;

  }
  // ---------------------------------------


  // ----------------Result List Api---------------------------

  async getResultApi() {
    var getapi = "/list_range";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getResultAll = res.result
    });
  }
  // --------------------
  resultItem(e: any) {
    this.resultId = e.target.value;

  }
  async addBenefits() {
    this.keyValue = this.container.length + 1;
    this.newArray.push({ no: "", title: "", description: "", diet_type: "" });
    this.container.push(this.keyValue);
    var allTitle = this.newArray.map((item) => {
      if (item.title != undefined && item.title != '') {
        return { no: item.no, title: item.title, description: item.description, diet_type: item.diet_type }
      }
    })
  }

  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }
  }
  // ----------------------------------------
  async categoryListApi() {
    var getapi = "/category_list/2";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.categoryAllList = res.result;
      }
    });
  }
  // -------------------------------------------------

  categoryItem(e: any) {
    this.category_ids = e.target.value;
    console.log(this.category_ids, 'this.category_ids');
  }



}
