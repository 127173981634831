<div class="padd-0-25">
    <div class="marg-t-40 fsize27 font_bold_gilroy">
        SOS:I Feel
    </div>

    <div class="marg-t-30">
        <div class="f-right marg-t-20 marg-b-20">
            <button class="add_btn" (click)="sosAdd()">Add</button>

        </div>
        <div class="">
            <table class="row-border hover table w-100" datatable *ngIf="sosQuestionAll.length > 0"
                style=" background-color: #fff; border-radius: 8px;">
                <thead>
                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>Show FeelingsWheel</th>
                        <th>Emotions </th>
                        <th>No.Of Questions</th>
                        <!-- <th>Status  </th> -->
                        <th style="width:150px; text-align:center;">Action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr class=" font_medium_gilroy" *ngFor="let item of sosQuestionAll ; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.show_feeling_wheel}}</td>
                        <td> {{item.emotion_name}}</td>
                        <td>{{item.no_of_question}}</td>
                        <!-- <td>{{item.status}}</td> -->
                        <td style="text-align: center;">
                            <!-- <span class="cursor_pointer" (click)=" sos_ViewPage(item)"><img height="22px" width="22px"
                                    class="" src="assets/View_icon.png" alt=""></span> -->

                            <span (click)="sos_Edit(item)" class="marg-l-10 cursor_pointer"><img height="22px"
                                    width="22px" class="" src="assets/Edit_icon.png" alt=""></span>

                            <span class="marg-l-10 ">

                                <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                    data-target="#exampleModal" (click)="deleteSos(item)"> <img height="22px"
                                        width="22px" class="" src="assets/Delete_icon.png" alt=""> </button>
                            </span>
                        </td>
                    </tr>
                </tbody>

            </table>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                            <div class="fsize16 " style="margin-top: 8px;">
                                Are you sure you want to delete this SOS ?
                            </div>

                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                (click)="deletedItem()">Submit</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>