<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Content Management
    </div>

    <div class="marg-t-20">


        <div class="row marg-0">

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

                <button class="buttons_clr bgr_clr_orange" (click)="daydiv()"> The Happiness Workout </button>

            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <button class="buttons_clr bgr_clr_blue" (click)="sOsdiv()"> SOS: I Feel </button>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">

                <button class="buttons_clr bgr_clr_gray" (click)="expeditionDiv()"> The Happiness Expendition </button>

            </div>

        </div>
    </div>




    <div class="marg-t-30" *ngIf="show_day_div">
        <div class="f-right marg-t-20 marg-b-20">
            <button class="add_btn" (click)="workOutAdd()">Add</button>
        </div>
        <div class="bdr_clr_table">
            <table class="w-100">
                <tr class="bgr_th font_bold_gilroy">
                    <th>Day</th>
                    <th>Level</th>
                    <th>Range</th>
                    <th>No.of.Questions </th>
                    <th>Questions</th>
                    <th>Action</th>

                </tr>
                <tr id="workoutquestionid" class="bgr_td font_medium_gilroy" *ngFor="let item of workoutQuestionAll">
                    <td>1</td>
                    <td>{{item.thwrk_qstn_level}}</td>

                    <td>{{item.thwrk_qstn_range}}</td>
                    <td>1</td>
                    <td> {{questionsArray}} </td>

                    <td> <span class="cursor_pointer"><img height="26px" width="26px" (click)="workout_ViewPage(item)"
                                class="" src="assets/View_icon.png" alt=""></span> <span
                            (click)="edit_happyWorkOut(item)" class="marg-l-10 cursor_pointer"><img height="26px"
                                width="26px" class="" src="assets/Edit_icon.png" alt=""></span>

                        <span class="marg-l-10">

                            <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                data-target="#exampleModal"> <img height="26px" width="26px" class=""
                                    src="assets/Delete_icon.png" alt=""> </button>
                        </span>
                    </td>
                </tr>





            </table>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red"
                                data-dismiss="modal">Submit</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>




        </div>

    </div>
    <!-- -----------------------------Sos-------------------------------- -->

    <div class="marg-t-30" *ngIf="show_Sos_div">
        <div class="f-right marg-t-20 marg-b-20">
            <button class="add_btn" (click)="sosAdd()">Add</button>

        </div>
        <div class="bdr_clr_table">
            <table class="w-100">
                <tr class="bgr_th font_bold_gilroy">
                    <th>S.No</th>
                    <th>Emotion</th>
                    <th style="width:400px">Question</th>
                    <th>Action</th>

                </tr>
                <tr class="bgr_td font_medium_gilroy" *ngFor="let item of sosQuestionAll ; index">
                    <td>1</td>
                    <td>{{item.thsos_qstn_mood}}</td>
                    <td>{{item.thsos_qstn_question}}</td>
                    <td> <span class="cursor_pointer" (click)=" sos_ViewPage(item)"><img height="26px" width="26px"
                                class="" src="assets/View_icon.png" alt=""></span> <span (click)="sos_Edit(item)"
                            class="marg-l-10 cursor_pointer"><img height="26px" width="26px" class=""
                                src="assets/Edit_icon.png" alt=""></span>

                        <span class="marg-l-10 ">

                            <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                data-target="#exampleModal"> <img height="26px" width="26px" class=""
                                    src="assets/Delete_icon.png" alt=""> </button>
                        </span>
                    </td>
                </tr>
            </table>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red"
                                data-dismiss="modal">Submit</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>




        </div>

    </div>


    <div class="marg-t-30" *ngIf="show_Expedition_div">
        <div class="f-right marg-t-20 marg-b-20">
            <button class="add_btn" (click)="expeditionAdd()">Add</button>

        </div>
        <div class="bdr_clr_table">
            <table class="w-100">
                <tr class="bgr_th font_bold_gilroy">
                    <th>S.No</th>
                    <th>Emotion Name</th>
                    <th>About the Mountain</th>
                    <th>Things to look out for</th>
                    <th>No.of.days</th>
                    <th>Action</th>
                </tr>
                <tr class="bgr_td font_medium_gilroy" *ngFor="let item of expeditionQuestionAll ; index">
                    <td>1</td>
                    <td>{{item.thexpdn_qstn_mood}}</td>
                    <td>Question</td>
                    <td>Things to look</td>
                    <td>2</td>
                    <td> <span class="cursor_pointer" (click)="expedition_ViewPage(item)"><img height="26px"
                                width="26px" class="" src="assets/View_icon.png" alt=""></span> <span
                            (click)="expedition_EditPage()" class="marg-l-10 cursor_pointer"><img height="26px"
                                width="26px" class="" src="assets/Edit_icon.png" alt=""></span>

                        <span class="marg-l-10 ">

                            <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                data-target="#exampleModal"> <img height="26px" width="26px" class=""
                                    src="assets/Delete_icon.png" alt=""> </button>
                        </span>
                    </td>
                </tr>
            </table>

            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">

                        <div class="modal-body">

                            <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                        </div>
                        <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                            <button type="button" class="btn_submit_cancel bgr_clr_red"
                                data-dismiss="modal">Submit</button>
                            <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>




        </div>

    </div>






</div>