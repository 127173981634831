import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tidbit-master-view',
  templateUrl: './tidbit-master-view.component.html',
  styleUrls: ['./tidbit-master-view.component.scss']
})
export class TidbitMasterViewComponent implements OnInit {
  isShown: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
