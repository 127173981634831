<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/assestment_results']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Assessment Results Add </li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <div class="">
                    <form>
                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy"> Emotion*</label>
                            <select (change)="emotionItem($event)"
                                class=" form-control input_bdr_clr ">
                                <option [disabled]="true" [selected]="true">Select an Emotion</option>
                                <option [value]="[item.themotion_id]" *ngFor="let item of getAllEmotion">
                                    {{item.themotion_name}} </option>

                            </select>
                        </div>
                        <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy">Result *</label>

                            <select (change)="resultItem($event)"
                                class=" form-control input_bdr_clr ">
                                <option [disabled]="true" [selected]="true">Select an Result</option>
                                <option [value]="[item.id]" *ngFor="let item of getResultAll">{{item.result}}
                                </option>

                            </select>

                            <!-- <input type="text" id="questions" class="form-control input_bdr_clr " /> -->


                        </div>




                        <!-- *ngFor="let ele of container; let i = index" -->
                        <div *ngFor="let ele of container; let i = index">


                            <!-- <div class="form-group marg-t-20">
                                <label class="fsize16 clr_black font_bold_gilroy">Slides No *</label>
                                <input type="text" id="questions" [(ngModel)]="newArray[i].title"
                                    [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr " />
                            </div> -->

                            <div class="form-group marg-t-20">
                                <label class="fsize16 clr_black font_bold_gilroy">Display Order *</label>
                                <input type="text" [(ngModel)]="newArray[i].no" [ngModelOptions]="{standalone: true}" class="form-control input_bdr_clr" />
                             </div>


                            <div>
                                <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                                <ckeditor [(ngModel)]="newArray[i].description" [ngModelOptions]="{standalone: true}"
                                    name="myckeditor" debounce="500">
                                </ckeditor>
                            </div>



                        </div>

                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add</button>

                                <button *ngIf="keyValue>1" (click)="removeBenefits()"
                                    class="btn btn-warning">remove</button>
                            </div>
                        </div>



                        <div class="form-group" style="margin-top:100px;">
                            <button type="submit" (click)="assestmentSubmit()"
                                class="btn  submit_btn_clr">Submit</button>
                            <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                                (click)="assestmentCancelBtn()">Cancel</button>
                        </div>
                    </form>
                </div>


                <div>

                </div>


            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>





    </div>

</div>
