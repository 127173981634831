<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/sos-feel-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page"> SOS - Add</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <!-- -------------------------------------------------------- -->
                <form  [formGroup]="registerForm">
                    <!-- <div class="form-group">
                        <div class="row marg-0">
                            <div class="fsize16 clr_black font_bold_gilroy"> Show FeelingsWheel *</div>

                            <div>
                                <input type="checkbox" [(ngModel)]="showFeelingwheel" id="show_feelingWheel"
                                     [ngModelOptions]="{standalone: true}"
                                     (change)="onlyOneValue($event)"
                                    style="margin-left: 26px;" class="h-20 w-20  radio" name="fooby[1][]"
                                    value="Show FeelingsWheel">
                            </div>

                        </div>
                    </div> -->

                    <!-- <div class="row marg-0">

                        <div class="fsize16 clr_black font_bold_gilroy"> Show CognitiveBiases *</div>

                        <div>
                            <input type="checkbox" [(ngModel)]="showCognitiveBiases" id="show_cognitiveBiases"
                                [ngModelOptions]="{standalone: true}" class="h-20 w-20 marg-l-16 radio"
                                name="fooby[1][]" value="Show CognitiveBiases"
                                (change)="onlyOneValue($event)"
                                >
                        </div>

                    </div> -->

                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">Emotions *</label>
                        <select [(ngModel)]="mood_Type"
                            class=" form-control input_bdr_clr "
                            formControlName="emotion"
                            (ngModelChange)="emotionItem($event)">
                            <!-- <option selected disabled val="">Select Emotions type</option> -->
                            <option [ngValue]="item" *ngFor="let item of getAllEmotion">{{item.themotion_name}}</option>
                        </select>


                        <div *ngIf="submitted && registerForm.get('emotion').errors"
                        class="alert alert-danger">
                        <div *ngIf="registerForm.get('emotion').errors.required">
                            Emotions is
                            Required</div>
                    </div>


                    </div>

                    <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Show Feeling Wheel* : </span>
                        <span>
                            <label for="vehicle1"> Yes</label>
                            <input class="marg-l-8 "  formControlName="showFeel"
                            type="radio" id="yesShowFeeling" name="showFeel" value="1">
                        </span>

                        <span>
                            <label class="marg-l-8" for="vehicle1"> No</label>
                            <input class="marg-l-8"
                            formControlName="showFeel"
                          
                            
                             type="radio" id="noShowFeeling" name="showFeel" value="0">
                        </span>


                  <div *ngIf="submitted && registerForm.get('showFeel').errors"
                        class="alert alert-danger">
                        <div *ngIf="registerForm.get('showFeel').errors.required">
                            Show Feeling Wheel is
                            Required</div>
                    </div>


                    </div>

                    <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Status * : </span>
                        <span>
                            <label for="vehicle1"> Active </label>
                            <input class="marg-l-8" type="radio" id="vehicle1" [(ngModel)]="status"
                            formControlName="status" name="vehicle1" value="1">
                        </span>

                        <span>
                            <label class="marg-l-8" for="vehicle1"> Inactive</label>
                            <input class="marg-l-8" type="radio" id="vehicle1" [(ngModel)]="status"
                            formControlName="status" name="vehicle1" value="0">
                        </span>

                        <div *ngIf="submitted && registerForm.get('status').errors"
                        class="alert alert-danger">
                        <div *ngIf="registerForm.get('status').errors.required">
                            status is
                            Required</div>
                    </div>


                    </div>



                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">No.of questions *</label>
                        <input type="text" id="questions" class="form-control input_bdr_clr "
                        maxlength="150" formControlName="noOfquest"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            [(ngModel)]="noOfquestions"  />


                            <div *ngIf="submitted && registerForm.get('noOfquest').errors"
                            class="alert alert-danger">
                            <div *ngIf="registerForm.get('noOfquest').errors.required">
                                No.of questions is
                                Required</div>
                        </div>
                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr" (click)='sosAddSubmit()'>Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)='sosAddCancel()'>Cancel</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>

</div>