import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
@Component({
  selector: 'app-college-list-table',
  templateUrl: './college-list-table.component.html',
  styleUrls: ['./college-list-table.component.scss']
})
export class CollegeListTableComponent implements OnInit {
  datas = [];
  licenceNo = '';
  roleData = [];
  // constructor(private router: Router, private ApiService: ApiService) { }
  coachEdit_url = '/coach-edit-page';
  coach_View_url = '/coach-view-page';
  deleteCoachId: any;
  constructor(private router: Router, private ApiService: ApiService) { }

  async ngOnInit() {
    this.getCoachListApi();
      }
     async getCoachListApi(){
      var postapi = "/faculty_list";
      var admindata = {
        college_id: undefined


      };

      (await this.ApiService.postApi(postapi,admindata)).subscribe((res) => {
    
        this.datas = res.data.reverse();
    
      });
    
     }

    
    
      edit_coach(editItem: any) {
        this.ApiService.adminCoachId = editItem.thcoach_id
        this.router.navigate([this.coachEdit_url]);
        // localStorage.setItem('coach_EditId', JSON.stringify(editItem.thcoach_id));
      }
    
      coach_ViewPage(viewItem: any) {
        this.router.navigate([this.coach_View_url]);
        localStorage.setItem('coach_view', JSON.stringify(viewItem.thcoach_id));
      }
    
      onSelect(selectedItem: any) {
      
        localStorage.setItem('Selected item Id', JSON.stringify(selectedItem.thcoach_id));
        localStorage.setItem('Selected item Email', JSON.stringify(selectedItem.thcoach_email));
        localStorage.setItem('Selected item Password', JSON.stringify(selectedItem.thcoach_password));
        // You get the Id of the selected item here
      }
      delete_coach(deleteItems: any) {
        this.deleteCoachId = deleteItems.thcoach_user_id;
       
      }
      async deleteCoachApi() {
        var postapi = "/admin_delete_coach";
        var adminUserdata = {
          "id": this.deleteCoachId
        };
        (await this.ApiService.postApi(postapi, adminUserdata)).subscribe(
          res => {
            if (res) {
              if (res.status === 200) {
                swal({
                  icon: 'Success!',
                  text: "Coach Deleted Succcessfully",
                  type: "success"
                }, function () {
                  alert('yo');
                });
                this.getCoachListApi();
              }
            }
          });
      }

}
