import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
declare var swal: any;

@Component({
  selector: 'app-rewardslist',
  templateUrl: './rewardslist.component.html',
  styleUrls: ['./rewardslist.component.scss']
})
export class RewardslistComponent implements OnInit {

  rewardsEdit_Url = "/edit-rewards";
  rewardsAdd_Url = "/addrewards";
  getRewardlist: any[];
  deleteId: any;
  showLoaderDiv = false;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.getRewardListApi();

  }

  // ===================================================================================

  async getRewardListApi() {
    // this.showLoaderDiv = true;
    var getapi = "/listReward";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.getRewardlist = res.result;
        this.showLoaderDiv = false;
        console.log("Listrewardlist", this.getRewardlist);
      }
    });
  }

  // =====================================================================================

  rewards_edit(editItem: any) {
    this.ApiService.rewardseditID = editItem.id;
    sessionStorage.setItem("id", editItem.id)
    this.router.navigate([this.rewardsEdit_Url]);
    console.log("id",this.ApiService.rewardseditID)
  }

  // =====================================================================================

  rewardAdd() {
    this.router.navigate([this.rewardsAdd_Url]);
  }

// ======================================================================================


  deleterewards(deleteItem: any) {
    this.deleteId = deleteItem.id
    console.log("deletingid",this.deleteId)
  }

  // =====================================================================================
  async deleteRewardslist() {

    var deleteApi = "/deleteReward/" + this.deleteId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      
      if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Rewards Deleted successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          console.log("deleted",res)
        }
        this.getRewardListApi();
        location.reload();
      });
  }
}
