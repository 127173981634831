<div class="padd-0-40">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Upcoming Appointment
    </div>

    <div class="marg-t-30">
        <div class="">

            <!-- <table class="w-100">
                <tr class="bgr_th font_bold_gilroy">
                    <th>S.No</th>
                    <th>Appointment No</th>
                    <th>Coach Name</th>
                    <th> User Name</th>
                    <th>Appointment Date</th>
                    <th> Appointment Time</th>
                    <th>Action</th>
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>1</td>
                    <td>1</td>
                    <td>Raja</td>
                    <td>rajal</td>
                    <td>4/01/2022</td>
                    <td>11.00Am</td>
                    <td><span (click)="edit_view()"><img height="26px" width="26px" class="bgr_black"
                                src="assets/View_icon.png" alt=""></span> <span (click)="appointment_Edit()"
                            class="marg-l-10"><img height="26px" width="26px" class="bgr_black"
                                src="assets/Edit_icon.png" alt=""></span>

                        <span class="marg-l-10"> <button type="button" class="border_clr_none" data-toggle="modal"
                                data-target="#exampleModal"> <img height="26px" width="26px" class="bgr_black"
                                    src="assets/Delete_icon.png" alt=""> </button>

                        </span>
                    </td>
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>2</td>
                    <td>1</td>
                    <td>Raja</td>
                    <td>rajal</td>
                    <td>4/01/2022</td>
                    <td>12.00Am</td>
                    <td><span (click)="edit_view()"><img height="26px" width="26px" class="bgr_black"
                                src="assets/View_icon.png" alt=""></span> <span (click)="appointment_Edit()"
                            class="marg-l-10"><img height="26px" width="26px" class="bgr_black"
                                src="assets/Edit_icon.png" alt=""></span>

                        <span class="marg-l-10"> <button type="button" class="border_clr_none" data-toggle="modal"
                                data-target="#exampleModal"> <img height="26px" width="26px" class="bgr_black"
                                    src="assets/Delete_icon.png" alt=""> </button>

                        </span>
                    </td>
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>3</td>
                    <td>1</td>
                    <td>Raja</td>
                    <td>rajal</td>
                    <td>4/01/2022</td>
                    <td>12.00Am</td>
                    <td><span (click)="edit_view()"><img height="26px" width="26px" class="bgr_black"
                                src="assets/View_icon.png" alt=""></span> <span (click)="appointment_Edit()"
                            class="marg-l-10"><img height="26px" width="26px" class="bgr_black"
                                src="assets/Edit_icon.png" alt=""></span>

                        <span class="marg-l-10"> <button type="button" class="border_clr_none" data-toggle="modal"
                                data-target="#exampleModal"> <img height="26px" width="26px" class="bgr_black"
                                    src="assets/Delete_icon.png" alt=""> </button>

                        </span>
                    </td>
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>4</td>
                    <td>1</td>
                    <td>Raja</td>
                    <td>rajal</td>
                    <td>4/01/2022</td>
                    <td>12.00Am</td>
                    <td><span (click)="edit_view()"><img height="26px" width="26px" class="bgr_black"
                                src="assets/View_icon.png" alt=""></span> <span (click)="appointment_Edit()"
                            class="marg-l-10"><img height="26px" width="26px" class="bgr_black"
                                src="assets/Edit_icon.png" alt=""></span>

                        <span class="marg-l-10"> <button type="button" class="border_clr_none" data-toggle="modal"
                                data-target="#exampleModal"> <img height="26px" width="26px" class="bgr_black"
                                    src="assets/Delete_icon.png" alt=""> </button>

                        </span>
                    </td>
                </tr>
                <tr class="bgr_td font_medium_gilroy">
                    <td>5</td>
                    <td>1</td>
                    <td>Raja</td>
                    <td>rajal</td>
                    <td>4/01/2022</td>
                    <td>11.00Am</td>
                    <td><span (click)="edit_view()"><img height="26px" width="26px" class="bgr_black"
                                src="assets/View_icon.png" alt=""></span> <span (click)="appointment_Edit()"
                            class="marg-l-10"><img height="26px" width="26px" class="bgr_black"
                                src="assets/Edit_icon.png" alt=""></span>

                        <span class="marg-l-10"> <button type="button" class="border_clr_none" data-toggle="modal"
                                data-target="#exampleModal"> <img height="26px" width="26px" class="bgr_black"
                                    src="assets/Delete_icon.png" alt=""> </button>

                        </span>
                    </td>
                </tr>

            </table> -->

            <!-- --------------------------------------->
            <div class="marg-t-20">


                <div style="background-color: #fff; border-radius: 8px; padding: 10px 0;">
                  
                    <div style="display: flex; padding-top: 30px;margin-right: 40px;justify-content: end;">
                        <input type="text" placeholder="Search..." (input)="searchTable($event)" class="form-control"
                            style="margin-bottom: 10px; width:30%;">
                    </div>
                    <div *ngIf="loading" class="loader-container">
                        <div class="loader"></div>
                    </div>
                    <table class="font_regular_gilroy TFtable row-border hover table w-100" datatable
                        [dtOptions]="dtOptions" *ngIf="!loading && upcommingAppointment.length > 0"
                        style="width: 100%;">
                        <thead>
                            <tr class="" style="border-bottom: 2px solid #dee2e6;">
                                <th>S.No</th>
                                <!-- <th>Appt No</th> -->
                                <!-- <th>Order ID</th> -->
                                <th>Coach Name</th>
                                <th>Payment id</th>
                                <th> User Name</th>
                                <!-- <th> User type</th> -->
                                <!-- <th>College/Corporate</th> -->
                                <!-- <th>Code</th> -->
                                <th>Amount</th>
                                <th> Email</th>
                                <th>Contact No</th>
                                <th>Appt Date</th>
                                <th>Next Session</th>
                                <th>Duration</th>
                                <!-- <th>Payment Method</th> -->
                                <!-- <th> Appt  Time</th> -->
                                <th>Status</th>
                                <!-- <th>Booked Date</th> -->
                                <th>Action</th>
                                <th>Mode</th>
                                <th>G-Meet</th>
                                <th>View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="red" *ngFor="let item of upcommingAppointment  ;let i = index">
                                <td>{{(currentPage - 1) * entriesPerPage + i + 1}}</td>

                                <!-- <td>{{item.app_number}}</td> -->
                                <!-- <td>{{item.order_id}}</td> -->
                                <td>{{item.coach_name}}</td>
                                <td>{{item.order_id !== null ? item.order_id : 'N/A'}}</td>
                                <td>{{item.user_name}}</td>
                                <!-- <td>{{item.user_type}}</td> -->
                                <!-- <td>{{item.college_name}} {{item.corporate_name}}</td> -->
                                <!-- <td>{{item.coupon_code !== null ? item.coupon_code :'N/A'}}</td> -->
                                <td>{{item.amount}}</td>
                                <td>{{item.user_email}}</td>
                                <td>{{item.user_mobile}}</td>
                                <td>{{item.date}} {{item.time}}</td>
                                <td style="text-align: center;">{{item.follow_up_date === '00-00-0000 12:00 AM' ? '-' :
                                    item.follow_up_date }}</td>
                                <td>{{item.duration}}</td>
                                <!-- <td>{{item.payment_method}}</td> -->
                                <!-- <td>{{item.time}}</td> -->
                                <td>
                                    <span>{{item.appointment_status}}</span>
                                    <span>
                                        <span *ngIf="item.appointment_status == 'Cancelled' ">

                                            <span *ngIf="item.mby == item.coach_id">-By Coach</span>
                                            <span *ngIf="item.mby == item.user_id">By User</span>

                                        </span>
                                    </span>
                                </td>
                                <!-- <td>{{(item.booked_datetime)?item.booked_datetime : "N/A"}}</td> -->
                                <td>
                                    <span class="downloadlist" (click)="downloadlist(item)"><img class="download_cursor"
                                            src="assets/download.png"
                                            style="height: 30px; width: 30px; padding: 5px; padding-left: 5px;"></span>
                                </td>
                                <td>
                                    {{item.therapy_mode}}
                                </td>
                                <td>
                                    <span *ngIf="item.gmeet_link" (click)="openMeetingLink(item.gmeet_link)" (contextmenu)="copyLinkAddress($event, item.gmeet_link)">
                                        <img style="width: 40px;" src="assets/gmeet_logo.png"/>
                                      </span>
                                      
                                  </td>
                                <td>
                                    <span class="download_cursor" (click)="appointment_detail_page(item.id)">
                                        <img class="download"
                                            style="height: 20px; width: 20px; margin-left: 10px; margin-top: 10px;"
                                            src="assets/View_icon.png">
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!loading && upcommingAppointment.length > 0"
                        style="display:flex;justify-content: space-between; padding: 20px;">
                        <div *ngIf="!loading && upcommingAppointment.length > 0" style="margin:0px 0px 0px 35px ;">
                            Show
                            <select [(ngModel)]="entriesPerPage" (change)="onEntriesPerPageChange()"
                                style="margin-left: 5px;">
                                <!-- <option value="5">5</option> -->
                                <!-- <option value="10">10</option> -->
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            Entries
                            of
                            {{totalEntries}}
                            total
                        </div>
                        <div class="pagination-controls" style="display: flex;gap: 20px;">
                            <button (click)="prevPage()" [disabled]="currentPage === 1">Previous</button>
                            <span>Page {{currentPage}} of {{totalPages}}</span>
                            <button (click)="nextPage()" [disabled]="currentPage === totalPages">Next</button>
                        </div>
                    </div>
                    <div *ngIf="!loading && upcommingAppointment.length == 0">
                        <div class="text-center padd_10">No Data Available in Table</div>
                    </div>
                </div>
            </div>


        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">

                    <div class="modal-body">

                        <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                        <div class="font_regular_gilroy">
                            Do you Want to Delete Appointment ?
                        </div>

                    </div>
                    <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                        <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                            (click)="deletedApi()">Submit</button>
                        <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                            data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div>

        <!-- <label>Your first ng-select</label>
        <ng-select  bindLabel="name" placeholder="Select city">
        </ng-select>
        <p>
            Selected city: {{selectedCity | json}}
        </p>
        <hr />  -->

        <!-- <div class="font_clr_white text-center fsize30 mt-5">
            <ngb-datepicker #dp (dateSelect)="onDateSelection($event)"
                [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden">
            </ngb-datepicker>

            <ng-template #t let-date let-focused="focused">
                <span class="custom-day" [class.focused]="focused"
                    [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)"
                    (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                </span>
            </ng-template>
        </div> -->



        <!-- <mat-form-field class="example-form-field" appearance="fill">
            <mat-label>First campaign</mat-label>
            <mat-date-range-input
              [formGroup]="campaignOne"
              [rangePicker]="campaignOnePicker"
              [comparisonStart]="campaignTwo.value.start"
              [comparisonEnd]="campaignTwo.value.end">
              <input matStartDate placeholder="Start date" formControlName="start">
              <input matEndDate placeholder="End date" formControlName="end">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
          </mat-form-field>  -->





    </div>

</div>