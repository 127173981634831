<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/sos-feel-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page"> SOS - Edit</li>
        </ol>
    </nav>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">

                <div style="display: flex; float: right;">
                    <span style="margin-right: 14px;"> <button data-toggle="modal" data-target="#addExerciseModel"
                            class="submit_btn_clr">Add Exercise</button></span>

                    <span> <button class="submit_btn_clr" data-toggle="modal" data-target="#exampleModalquestion"
                            id="addQuestion" value="1"> Add
                            Question</button></span>
                </div>
                <form class="marg-t-40" [formGroup]="sosRegForm">
                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy marg-t-20">Emotions *</label>
                        <select [(ngModel)]="mood_Type" formControlName="emotion" (ngModelChange)="emotionItem($event)"
                            class=" form-control input_bdr_clr ">
                            <option selected disabled val="">Select Emotions type</option>
                            <option [value]="[item.themotion_name,item.themotion_id]"
                                *ngFor="let item of getAllEmotion">
                                {{item.themotion_name}}</option>
                        </select>

                        <div *ngIf="sosSubmitted && sosRegForm.get('emotion').errors" class="alert alert-danger">
                            <div *ngIf="sosRegForm.get('emotion').errors.required">
                                Emotions is
                                Required</div>
                        </div>




                    </div>

                    <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Show Feeling * : </span>
                        <span>
                            <label for="vehicle1"> Yes</label>
                            <input class="marg-l-8 " [(ngModel)]="show_Feelings" [ngModelOptions]="{standalone: true}"
                                type="checkbox" id="showReadMore" name="fooby[1][]" value="1">
                        </span>

                        <span>
                            <label class="marg-l-8" for="vehicle1"> No</label>
                            <input class="marg-l-8" type="checkbox" id="showReadMore" name="fooby[1][]" value="0">
                        </span>
                    </div>

                    <div class="marg-t-20">
                        <span class="fsize16 clr_black font_bold_gilroy">Status * : </span>
                        <span>
                            <label for="vehicle1"> Active </label>

                            <input class="marg-l-8 " [(ngModel)]="statusActive" [ngModelOptions]="{standalone: true}"
                                type="checkbox" id="showReadMore" name="vehicle1" value="1" (click)="statuscheck()">
                        </span>

                        <span>
                            <label class="marg-l-8" for="vehicle1"> Inactive</label>
                            <input class="marg-l-8" [(ngModel)]="statusInActive" [ngModelOptions]="{standalone: true}"
                                type="checkbox" id="vehicle1" name="vehicle1" value="0">
                        </span>
                    </div>

                    <div class="form-group marg-t-20">
                        <label class="fsize16 clr_black font_bold_gilroy">No.of questions *</label>
                        <input type="text" id="questions" class="form-control input_bdr_clr " maxlength="150"
                            formControlName="numberQuestion" [(ngModel)]="noOfquestions" />

                        <div *ngIf="sosSubmitted && sosRegForm.get('numberQuestion').errors" class="alert alert-danger">
                            <div *ngIf="sosRegForm.get('numberQuestion').errors.required">
                                Emotions is
                                Required</div>
                        </div>

                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr" (click)="sos_UpdateSubmit()">Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16">Cancel</button>
                    </div>
                </form>
                <!-- --------------------------------------------------------------------------- -->


                <div>
                    <!-- Button trigger modal -->

                    <!-- Modal -->
                    <div class="modal fade" data-toggle="modal" id="addExerciseModel" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="" style="padding: 10px;">
                                    <span class="modal-title font_size22 clr_black font_bold_gilroy">Add
                                        Exercise</span>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form [formGroup]="sosExerciseForm" name="form2" id="form-id">
                                        <div>

                                            <div class="form-group">
                                                <label class="fsize16 clr_black font_bold_gilroy">Placeholder *
                                                </label>
                                                <!-- <input type="text" id="examples1" class="form-control input_bdr_clr "
                                                    [(ngModel)]="placeholder_exercise"
                                                    [ngModelOptions]="{standalone: true}" /> -->

                                                <ckeditor name="myckeditor" [(ngModel)]="placeholder_exercise"
                                                    formControlName="exercisePlaceholder" debounce="500">
                                                </ckeditor>
                                            </div>

                                            <div *ngIf="sosExerciseSubmitted && sosExerciseForm.get('exercisePlaceholder').errors"
                                                class="alert alert-danger">
                                                <div *ngIf="sosExerciseForm.get('exercisePlaceholder').errors.required">
                                                    Placeholder is
                                                    Required</div>
                                            </div>






                                            <div class="form-group">
                                                <label class="fsize16 clr_black font_bold_gilroy">Question
                                                    label *</label>
                                                <input type="text" id="questions1" class="form-control input_bdr_clr "
                                                    [(ngModel)]="answerLabel" formControlName="questionLabel" />


                                                <div *ngIf="sosExerciseSubmitted && sosExerciseForm.get('questionLabel').errors"
                                                    class="alert alert-danger">
                                                    <div *ngIf="sosExerciseForm.get('questionLabel').errors.required">
                                                        Question label is
                                                        Required</div>
                                                </div>

                                            </div>

                                            <div class="form-group" style="margin-bottom: 12px;">

                                                <label class="fsize16 clr_black font_bold_gilroy">File Upload *
                                                </label>

                                                <input type="file" class="form-control input_bdr_clr margin_b_14 h-40"
                                                    style="padding: 4px;" id="filePicker"
                                                    (change)="handleFileSelect($event)"
                                                    formControlName="exerciseFileUpload">

                                                <div *ngIf="sosExerciseSubmitted && sosExerciseForm.get('exerciseFileUpload').errors"
                                                    class="alert alert-danger">
                                                    <div
                                                        *ngIf="sosExerciseForm.get('exerciseFileUpload').errors.required">
                                                        File Upload is
                                                        Required</div>
                                                </div>



                                            </div>

                                            <div>
                                                <span class="fsize16 clr_black font_bold_gilroy">Position * : </span>
                                                <span>
                                                    <label for="vehicle1"> First</label>
                                                    <input class="marg-l-8" type="radio" id="vehicle1" name="vehicle1"
                                                        value="1" [(ngModel)]="positionExercise"
                                                        formControlName="exercisePosition">
                                                </span>

                                                <span>
                                                    <label class="marg-l-8" for="vehicle1"> Last</label>
                                                    <input class="marg-l-8" type="radio" id="vehicle1" name="vehicle1"
                                                        value="0" [(ngModel)]="positionExercise"
                                                        formControlName="exercisePosition">
                                                </span>



                                                <div *ngIf="sosExerciseSubmitted && sosExerciseForm.get('exercisePosition').errors"
                                                    class="alert alert-danger">
                                                    <div
                                                        *ngIf="sosExerciseForm.get('exercisePosition').errors.required">
                                                        Position is
                                                        Required</div>
                                                </div>


                                            </div>

                                        </div>
                                    </form>



                                    <div class="marg-t-20">
                                        <button type="button" class="btn  model_SaveBtn" id="addExerciseModels1"
                                            (click)="addExerciseSubmit()">Save</button>
                                        <button type="button" class="btn marg-l-16 model_CancelBtn">Cancel</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div>
                    <!-- Button trigger modal -->

                    <!-- Modal -->
                    <div class="modal fade" id="exampleModalquestion" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="" style="padding: 10px;">
                                    <span class="modal-title font_size22 clr_black font_bold_gilroy">Add
                                        Question</span>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div>
                                        <form id="question_form_Id">
                                            <form [formGroup]="showCogRegForm">
                                                <div class="marg-t-20 form-group">
                                                    <span class="fsize16 clr_black font_bold_gilroy">ShowCognitiveBiases
                                                        * :
                                                    </span>
                                                    <span>
                                                        <label for="vehicle1"> Yes</label>
                                                        <input formControlName="name" class="marg-l-8 "
                                                            [(ngModel)]="showCognitive" type="radio"
                                                            id="showCognitive_yes" value="1">
                                                    </span>

                                                    <span>
                                                        <label class="marg-l-8" for="vehicle1"> No</label>
                                                        <input formControlName="name" class="marg-l-8"
                                                            [(ngModel)]="showCognitive" type="radio"
                                                            id="showCognitive_no" value="0">
                                                    </span>



                                                    <div *ngIf="submitted1 && showCogRegForm.get('name').errors"
                                                        class="alert alert-danger">
                                                        <div *ngIf="showCogRegForm.get('name').errors.required">
                                                            ShowCognitiveBiases is
                                                            required</div>
                                                    </div>

                                                </div>


                                                <div class="form-group marg-t-20">
                                                    <label class="fsize16 clr_black font_bold_gilroy">Question type
                                                        *</label>
                                                    <select formControlName="questionType"
                                                        (change)="questionOne($event)" id="questionTypes"
                                                        [(ngModel)]="question_Type"
                                                        class=" form-control input_bdr_clr ">
                                                        <option selected disabled val="">Select Question type</option>

                                                        <option value="text">Text</option>

                                                        <option value="audio">Audio</option>
                                                        <option value="affirmation">Affirmation</option>
                                                        <option value="grid">Grid</option>
                                                        <option value="multi_response">Multi-ResponseQuestion
                                                        </option>
                                                        <option value="review">Review</option>
                                                        <option value="yes_or_no">Yes / No</option>
                                                    </select>

                                                    <div *ngIf="submitted1 && showCogRegForm.get('questionType').errors"
                                                        class="alert alert-danger">
                                                        <div *ngIf="showCogRegForm.get('questionType').errors.required">
                                                            Question type is required</div>
                                                    </div>


                                                </div>
                                            </form>

                                            <div *ngIf="show_questionText_div">

                                                <form [formGroup]="questionRegForm">


                                                    <div class="form-group marg-t-20">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            *</label>
                                                        <input type="text" id="questions" formControlName="textQuestion"
                                                            class="form-control input_bdr_clr "
                                                            [(ngModel)]="questions" />
                                                    </div>
                                                    <div *ngIf="submitted1 && questionRegForm.get('textQuestion').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="questionRegForm.get('textQuestion').errors.required">
                                                            Question is required</div>
                                                    </div>


                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Answer
                                                            PlaceholderText *</label>
                                                        <!-- <input type="text" id="questions"
                                                        class="form-control input_bdr_clr "
                                                        [(ngModel)]="answer_placeholder_text"
                                                        [ngModelOptions]="{standalone: true}" /> -->

                                                        <ckeditor formControlName="ansPlaceholderTextOne"
                                                            name="myckeditor" [(ngModel)]="answer_placeholder_text"
                                                            debounce="500">
                                                        </ckeditor>
                                                    </div>

                                                    <div *ngIf="submitted1 && questionRegForm.get('ansPlaceholderTextOne').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="questionRegForm.get('ansPlaceholderTextOne').errors.required">
                                                            Answer PlaceholderText is required</div>
                                                    </div>

                                                </form>
                                            </div>


                                            <div *ngIf="show_questionAudio_div">
                                                <form [formGroup]="audioTypeRegForm">


                                                    <div class="form-group marg-t-20">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            *</label>
                                                        <input type="text" [(ngModel)]="questions"
                                                            formControlName="audioQuestion" id="questions"
                                                            class="form-control input_bdr_clr " />
                                                    </div>

                                                    <div *ngIf="submitted1 && audioTypeRegForm.get('audioQuestion').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="audioTypeRegForm.get('audioQuestion').errors.required">
                                                            Question is required</div>
                                                    </div>


                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Audio
                                                            *</label>
                                                        <input formControlName="audiofile" type="file"
                                                            style="padding: 3px;" id="questions"
                                                            class="form-control input_bdr_clr "
                                                            (change)="handleFileSelect($event)" />
                                                    </div>

                                                    <div *ngIf="submitted1 && audioTypeRegForm.get('audiofile').errors"
                                                        class="alert alert-danger">
                                                        <div *ngIf="audioTypeRegForm.get('audiofile').errors.required">
                                                            Audio is required</div>
                                                    </div>


                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Answer
                                                            PlaceholderText *</label>
                                                        <ckeditor name="myckeditor"
                                                            [(ngModel)]="answer_placeholder_text"
                                                            [ngModelOptions]="{standalone: true}" debounce="500">
                                                        </ckeditor>
                                                    </div>
                                                    <!-- 
                                                    formControlName="audioAnswerPlaceholder "
                                                    <div *ngIf="submitted1 && audioTypeRegForm.get('audioAnswerPlaceholder').errors"
                                                    class="alert alert-danger">
                                                    <div *ngIf="audioTypeRegForm.get('audioAnswerPlaceholder').errors.required">
                                                        Answer PlaceholderText is required</div>
                                                </div> -->





                                                </form>

                                            </div>

                                            <div *ngIf="affirmation_div">

                                                <form [formGroup]="affirmationTypeRegForm">

                                                    <div class="form-group marg-t-20">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            *</label>
                                                        <input type="text" id="questions"
                                                            formControlName="affirmationQuestion"
                                                            class="form-control input_bdr_clr "
                                                            [(ngModel)]="questions" />
                                                    </div>

                                                    <div *ngIf="submitted1 && affirmationTypeRegForm.get('affirmationQuestion').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="affirmationTypeRegForm.get('affirmationQuestion').errors.required">
                                                            Question is required</div>
                                                    </div>


                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Answer
                                                            PlaceholderText *</label>

                                                        <ckeditor name="myckeditor"
                                                            [(ngModel)]="answer_placeholder_text"
                                                            formControlName="affirmationPlaceholder" debounce="500">
                                                        </ckeditor>
                                                    </div>

                                                    <div *ngIf="submitted1 && affirmationTypeRegForm.get('affirmationPlaceholder').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="affirmationTypeRegForm.get('affirmationPlaceholder').errors.required">
                                                            Answer PlaceholderText is required</div>
                                                    </div>


                                                </form>


                                            </div>


                                            <div *ngIf="grid_div">

                                                <form [formGroup]="gridTypeRegForm">

                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            *</label>
                                                        <input type="text" id="questions"
                                                            class="form-control input_bdr_clr " [(ngModel)]="questions"
                                                            formControlName="gridQuestion" />
                                                    </div>

                                                    <div *ngIf="submitted1 && gridTypeRegForm.get('gridQuestion').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="gridTypeRegForm.get('gridQuestion').errors.required">
                                                            Question is required</div>
                                                    </div>

                                                </form>


                                            </div>

                                            <div *ngIf="multi_response_div">

                                                <form [formGroup]="multiResRegForm">

                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            *</label>
                                                        <input type="text" id="questions"
                                                            class="form-control input_bdr_clr " [(ngModel)]="questions"
                                                            formControlName="multiResQuestion" />
                                                    </div>

                                                    <div *ngIf="submitted1 && multiResRegForm.get('multiResQuestion').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="multiResRegForm.get('multiResQuestion').errors.required">
                                                            Question is required</div>
                                                    </div>


                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Answer
                                                            PlaceholderText 1 *</label>

                                                        <ckeditor name="myckeditor"
                                                            [(ngModel)]="answer_placeholder_text"
                                                            formControlName="multiAnsTextOne" debounce="500">
                                                        </ckeditor>
                                                    </div>

                                                    <div *ngIf="submitted1 && multiResRegForm.get('multiAnsTextOne').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="multiResRegForm.get('multiAnsTextOne').errors.required">
                                                            Answer PlaceholderText 1 is required</div>
                                                    </div>


                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Answer
                                                            PlaceholderText 2 *</label>
                                                        <ckeditor name="myckeditor"
                                                            [(ngModel)]="answer_placeholder_text1"
                                                            formControlName="multiAnsTextTwo" debounce="500">
                                                        </ckeditor>
                                                    </div>

                                                    <!--  -->

                                                    <div *ngIf="submitted1 && multiResRegForm.get('multiAnsTextTwo').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="multiResRegForm.get('multiAnsTextTwo').errors.required">
                                                            Answer PlaceholderText 2 is required</div>
                                                    </div>


                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Answer
                                                            PlaceholderText 3 *</label>
                                                        <ckeditor name="myckeditor"
                                                            [(ngModel)]="answer_placeholder_text2" debounce="500"
                                                            formControlName="multiAnsTextThree">
                                                        </ckeditor>


                                                    </div>
                                                    <!--  -->
                                                    <div *ngIf="submitted1 && multiResRegForm.get('multiAnsTextThree').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="multiResRegForm.get('multiAnsTextThree').errors.required">
                                                            Answer PlaceholderText 3 is required</div>
                                                    </div>

                                                </form>
                                            </div>


                                            <div *ngIf="review_div">


                                                <form [formGroup]="reviewRegForm">

                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            1</label>
                                                        <input type="text" id="questions"
                                                            class="form-control input_bdr_clr " [(ngModel)]="questions"
                                                            formControlName="reviewQuestionOne" />
                                                    </div>

                                                    <div *ngIf="submitted1 && reviewRegForm.get('reviewQuestionOne').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="reviewRegForm.get('reviewQuestionOne').errors.required">
                                                            Question 1 is required</div>
                                                    </div>


                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            2</label>
                                                        <input type="text" id="questions"
                                                            class="form-control input_bdr_clr "
                                                            [(ngModel)]="question_One"
                                                            formControlName="reviewQuestionTwo" />
                                                    </div>

                                                    <div *ngIf="submitted1 && reviewRegForm.get('reviewQuestionTwo').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="reviewRegForm.get('reviewQuestionTwo').errors.required">
                                                            Question 2 is required</div>
                                                    </div>




                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            3</label>
                                                        <input type="text" id="questions"
                                                            class="form-control input_bdr_clr "
                                                            [(ngModel)]="question_two"
                                                            formControlName="reviewQuestionThree" />
                                                    </div>

                                                    <div *ngIf="submitted1 && reviewRegForm.get('reviewQuestionThree').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="reviewRegForm.get('reviewQuestionThree').errors.required">
                                                            Question 3 is required</div>
                                                    </div>

                                                </form>
                                            </div>

                                            <div *ngIf="Yes_or_No_div">

                                                <form [formGroup]="yesOrnoRegForm">

                                                    <div class="row marg_0">
                                                        <input type="radio" (change)="yesOrNo_questionYesType($event)"
                                                            class="h-20 w-20" value="Yes" name="yes/No"
                                                            [(ngModel)]="questionYesRadioBtn"
                                                            formControlName="yesOrnoRadiobtn" />
                                                        <span class="marg-l-8">Yes</span>

                                                        <input type="radio" (change)="yesOrNo_questionNoType($event)"
                                                            class="h-20 w-20 marg-l-10" value="No" name="yes/No"
                                                            [(ngModel)]="questionYesRadioBtn"
                                                            formControlName="yesOrnoRadiobtn" />
                                                        <span class="marg-l-8">No</span>
                                                    </div>


                                                    <div *ngIf="submitted1 && yesOrnoRegForm.get('yesOrnoRadiobtn').errors"
                                                        class="alert alert-danger">
                                                        <div
                                                            *ngIf="yesOrnoRegForm.get('yesOrnoRadiobtn').errors.required">
                                                            Radio Button is required</div>
                                                    </div>

                                                </form>

                                                <div class="form-group marg-t-20" *ngIf="YesQuestion_ShowDiv">
                                                    <div class="form-group">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Question
                                                            *</label>
                                                        <input type="text" id="questions"
                                                            class="form-control input_bdr_clr " [(ngModel)]="questions"
                                                            [ngModelOptions]="{standalone: true}" />
                                                    </div>

                                                    <!-- <div class="marg-t-20">
                                                          <input type="radio" id="html" name="fav_language"
                                                            [(ngModel)]="showToastMessage" [ngModelOptions]="{standalone: true}"
                                                            value="1" (change)="checkboxchange($event)" >
                                                          <label for="html">Show Toast Message</label><br>
                                                          <input type="radio" id="css" name="fav_language" [(ngModel)]="collectResponse"
                                                            [ngModelOptions]="{standalone: true}" value="0" (change)="checkboxchange2($event)" >
                                                          <label for="css">Collect Response</label><br>
                                                    </div> -->
                                                    <!-- --------------------------------------------------------------------------------------------------- -->
                                                    <div class="marg-t-20">
                                                          <input type="radio" id="html" name="fav_language"
                                                            [(ngModel)]="showToastMessage"
                                                            (change)="toastmsgChange($event)"
                                                            [ngModelOptions]="{standalone: true}" value="1">
                                                          <label for="html">Show Toast Message</label><br>
                                                          <input type="radio" id="css" name="fav_language"
                                                            [(ngModel)]="collectResponse"
                                                            (change)="collectResChange($event)"
                                                            [ngModelOptions]="{standalone: true}" value="0">
                                                          <label for="css">Collect Response</label><br>
                                                    </div>


                                                    <div class="marg-t-20">
                                                        <label class="fsize16 clr_black font_bold_gilroy">Yes
                                                            Placeholder text *</label>
                                                        <!-- <input type="text" id="questions"
                                                            class="form-control input_bdr_clr "
                                                            [(ngModel)]="answer_placeholder_text"
                                                            [ngModelOptions]="{standalone: true}" /> -->

                                                        <ckeditor name="myckeditor"
                                                            [(ngModel)]="answer_placeholder_text"
                                                            [ngModelOptions]="{standalone: true}" debounce="500">
                                                        </ckeditor>
                                                    </div>
                                                </div>



                                                <div class="form-group marg-t-20" *ngIf="NoQuestion_ShowDiv">


                                                    <div class="row marg_0">

                                                        <input type="radio" (change)="responseNoquestionType($event)"
                                                            class="h-20 w-20" value="0" name="no"
                                                            [(ngModel)]="questionNoResponseRadioBtn"
                                                            [ngModelOptions]="{standalone: true}" />
                                                        <span class="marg-l-8">Get Response</span>

                                                        <input type="radio" (change)="responseNoquestionType($event)"
                                                            class="h-20 w-20 marg-l-10" value="1" name="no"
                                                            [(ngModel)]="questionNoResponseRadioBtn"
                                                            [ngModelOptions]="{standalone: true}" />
                                                        <span class="marg-l-8">Logic Question</span>

                                                    </div>

                                                    <div class="form-group marg-t-20" *ngIf="getResponseShowDiv">
                                                        <div class="form-group">
                                                            <label class="fsize16 clr_black font_bold_gilroy">Question
                                                                *</label>
                                                            <input type="text" id="questions"
                                                                class="form-control input_bdr_clr "
                                                                [(ngModel)]="responseQuestions"
                                                                [ngModelOptions]="{standalone: true}" />
                                                        </div>

                                                        <!-- <div class="marg-t-20">
                                                              <input type="radio" id="html" name="fav_language"
                                                                [(ngModel)]="showToastMessage" [ngModelOptions]="{standalone: true}"
                                                                value="1" (change)="checkboxchange($event)" >
                                                              <label for="html">Show Toast Message</label><br>
                                                              <input type="radio" id="css" name="fav_language" [(ngModel)]="collectResponse"
                                                                [ngModelOptions]="{standalone: true}" value="0" (change)="checkboxchange2($event)" >
                                                              <label for="css">Collect Response</label><br>
                                                        </div> -->
                                                        <!-- --------------------------------------------------------------------------------------------------- -->
                                                        <div class="marg-t-20">
                                                              <input type="radio" id="html" name="fav_language"
                                                                [(ngModel)]="no_radiobtn"
                                                                (change)="toastmsgChange($event)"
                                                                [ngModelOptions]="{standalone: true}" value="1">
                                                              <label for="html">Show Toast Message</label><br>
                                                              <input type="radio" id="css" name="fav_language"
                                                                [(ngModel)]="no_radiobtn"
                                                                (change)="collectResChange($event)"
                                                                [ngModelOptions]="{standalone: true}" value="0">
                                                              <label for="css">Collect Response</label><br>
                                                        </div>


                                                        <div class="marg-t-20">
                                                            <label class="fsize16 clr_black font_bold_gilroy">
                                                                Response Placeholder text *</label>


                                                            <ckeditor name="myckeditor"
                                                                [(ngModel)]="answer_placeholder_text"
                                                                [ngModelOptions]="{standalone: true}" debounce="500">
                                                            </ckeditor>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="logicQuestionShowDiv">

                                                        <!-- <div class="marg-t-20"> -->
                                                        <div class="form-group marg-t-20">
                                                            <label class="fsize16 clr_black font_bold_gilroy">Question
                                                                *</label>
                                                            <input type="text" id="questions"
                                                                class="form-control input_bdr_clr "
                                                                [(ngModel)]="responseQuestions"
                                                                [ngModelOptions]="{standalone: true}" />
                                                        </div>

                                                        <!-- </div> -->


                                                        <div class="marg-t-20">


                                                            <input type="radio" (change)="logicNoquestionType($event)"
                                                                class="h-20 w-20" value="1" name="logicno"
                                                                [(ngModel)]="logicNoRadiobtn"
                                                                [ngModelOptions]="{standalone: true}" />
                                                            <span class="marg-l-8">Yes</span>

                                                            <input type="radio" (change)="logicNoquestionType($event)"
                                                                class="h-20 w-20 marg-l-10" value="0" name="logicno"
                                                                [(ngModel)]="logicNoRadiobtn"
                                                                [ngModelOptions]="{standalone: true}" />
                                                            <span class="marg-l-8">No</span>


                                                        </div>



                                                        <div *ngIf="yesLogicQuestionDiv">
                                                            <!-- <div class="form-group">
                                                                <label class="fsize16 clr_black font_bold_gilroy">Question
                                                                    *</label>
                                                                <input type="text" id="questions"
                                                                    class="form-control input_bdr_clr "
                                                                    [(ngModel)]="no_LogicQuestion"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div> -->

                                                            <div class="marg-t-20">
                                                                  <input type="radio" id="html" name="fav_language"
                                                                    [(ngModel)]="no_radiobtn"
                                                                    (change)="toastmsgChange($event)"
                                                                    [ngModelOptions]="{standalone: true}" value="1">
                                                                  <label for="html">Show Toast Message</label><br>
                                                                  <input type="radio" id="css" name="fav_language"
                                                                    [(ngModel)]="no_radiobtn"
                                                                    (change)="collectResChange($event)"
                                                                    [ngModelOptions]="{standalone: true}" value="0">
                                                                  <label for="css">Collect Response</label><br>
                                                            </div>


                                                            <div class="marg-t-20">
                                                                <label class="fsize16 clr_black font_bold_gilroy">Yes
                                                                    Placeholder
                                                                    text *</label>
                                                                <ckeditor name="myckeditor"
                                                                    [(ngModel)]="no_logic_placeholder"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    debounce="500">
                                                                </ckeditor>


                                                            </div>

                                                        </div>

                                                        <div *ngIf="noLogicQuestionDiv">
                                                            <!-- <div class="form-group">
                                                                <label class="fsize16 clr_black font_bold_gilroy">Question
                                                                    *</label>
                                                                <input type="text" id="questions"
                                                                    class="form-control input_bdr_clr "
                                                                    [(ngModel)]="no_LogicQuestion"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div> -->

                                                            <div class="marg-t-20">
                                                                  <input type="radio" id="html" name="fav_language"
                                                                    [(ngModel)]="no_radiobtn"
                                                                    (change)="toastmsgChange($event)"
                                                                    [ngModelOptions]="{standalone: true}" value="1">
                                                                  <label for="html">Show Toast Message</label><br>
                                                                  <input type="radio" id="css" name="fav_language"
                                                                    [(ngModel)]="no_radiobtn"
                                                                    (change)="collectResChange($event)"
                                                                    [ngModelOptions]="{standalone: true}" value="0">
                                                                  <label for="css">Collect Response</label><br>
                                                            </div>


                                                            <div class="marg-t-20">
                                                                <label class="fsize16 clr_black font_bold_gilroy">No
                                                                    Placeholder
                                                                    text *</label>
                                                                <ckeditor name="myckeditor"
                                                                    [(ngModel)]="no_logic_placeholder"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    debounce="500">
                                                                </ckeditor>


                                                            </div>

                                                        </div>





                                                    </div>

                                                </div>

                                            </div>

                                        </form>
                                    </div>

                                    <div class="marg-t-20">

                                        <button type="button" id="btnSave" class="btn model_SaveBtn"
                                            (click)="sosQuestionSubmit()">Save</button>
                                        <button type="button" class="btn model_CancelBtn marg-l-16"
                                            data-dismiss="modal">Cancel</button>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>

    <!-- ----------------SOS - Question Table-------------------------- -->

    <div *ngIf="showaddQuestionBtn">
        <div class="marg-t-70 fsize27 font_bold_gilroy">
            SOS - Question
        </div>

        <div class="marg-t-30">
            <div class="" style="background-color: #fff; border-radius: 8px;">
                <table class="row-border hover table w-100" datatable *ngIf="sosGetQuestion_List.length > 0">
                    <thead>
                        <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                            <th>S.No</th>
                            <th>Question</th>
                            <th>Question
                                Type</th>
                            <th>Placeholder Text</th>
                            <!-- <th></th> -->

                            <th style="width: 150px; text-align:center;">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr class=" font_medium_gilroy" *ngFor="let item of sosGetQuestion_List;let i=index">
                            <td>{{i+1}}</td>
                            <td>
                                <span *ngIf="item.question1 !=null "> {{item.question1}}</span>
                                <span *ngIf="item.no_question !=null "> {{item.no_question}}</span>
                            </td>
                            <td>{{item.question_type}}</td>
                            <td>

                                <span *ngIf="item.answer_placeholder1 !=null "
                                    [innerHTML]="item.answer_placeholder1"></span>
                                <span *ngIf="item.answer_placeholder1 !=null " [innerHTML]="item.no_placeholder"></span>

                            </td>


                            <td style="text-align:center;">

                                <!-- <span class="cursor_pointer"><img height="22px" width="22px" class=""
                                        src="assets/View_icon.png" alt="" (click)="questionSos_view(item)"></span> -->


                                <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px" class=""
                                        src="assets/Edit_icon.png" alt="" (click)="questionSos_edit(item)"></span>

                                <span class="marg-l-10 ">

                                    <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                        data-target="#exampleModaldelete"> <img height="22px" width="22px" class=""
                                            src="assets/Delete_icon.png" alt="" (click)="delete_Question(item)">
                                    </button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="modal fade" id="exampleModaldelete" tabindex="-1" role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                                <div class="fsize16 marg-t-16">Are you sure you want to delete this question ?
                                </div>
                            </div>
                            <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                                <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                    (click)="questionDeleteSubmit()">Delete</button>
                                <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                    data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <!-- ----------------Exercise Table-------------------------- -->

    <div *ngIf="showExerciseBts">
        <div>
            <div class="marg-t-70 fsize27 font_bold_gilroy">
                SOS - Exercise
            </div>

            <div class="marg-t-30">
                <div class="" style="background-color: #fff; border-radius: 8px;">
                    <table class="row-border hover table w-100" datatable *ngIf="sosGetExercise_List.length > 0">
                        <thead>
                            <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                <th>S.No</th>
                                <!-- <th>Type</th> -->
                                <th>Question Label </th>
                                <th>Placeholder</th>
                                <th>Position</th>

                                <th style="width: 150px;">Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr class=" font_medium_gilroy" *ngFor="let item of sosGetExercise_List ; let i=index">
                                <td>{{i+1}}</td>
                                <!-- <td></td> -->
                                <td>{{item.answer_label}}</td>
                                <td [innerHTML]="item.answer_placeholder1"></td>
                                <td>{{item.position}}</td>

                                <td>
                                    <!-- <span class="cursor_pointer"><img height="22px" width="22px" class=""
                                            src="assets/View_icon.png" alt="" (click)="exerciseSos_view(item)"></span> -->


                                    <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px" class=""
                                            src="assets/Edit_icon.png" alt="" (click)="exerciseSos_edit(item)"></span>

                                    <span class="marg-l-10 ">

                                        <button type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                            data-target="#exercise_delete"> <img height="22px" width="22px" class=""
                                                src="assets/Delete_icon.png" alt="" (click)="delete_Exercise(item)">
                                        </button>
                                    </span>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    <div class="modal fade" id="exercise_delete" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-body">
                                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                                    <div class="fsize18 marg-t-16"> Are you sure you want to delete this exercise?
                                    </div>
                                </div>
                                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                        (click)="exerciseDeleteSubmit()">Delete</button>
                                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                        data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>






</div>