import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-blog-master-edit',
  templateUrl: './blog-master-edit.component.html',
  styleUrls: ['./blog-master-edit.component.scss']
})
export class BlogMasterEditComponent implements OnInit {
  isShown: boolean = false;
  getAllEmotion: any;
  emotionsId: any;
  blogEdit_id: any;
  article: any;
  blogTitle: any;
  blogStatus: any;
  type_Type: any;
  base64textString: any;
  registerForm: FormGroup;
  submitted = false;
  blogemotions:any;
  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  blogCancel_url = "/blog_master_list";

  ngOnInit(): void {




    this.blogEdit_id = this.ApiService.blogEditId;
    this.blogEdit_id = localStorage.getItem("blogEditIds")
    // 
    this.getlist_blogMasterApi();
    this.getEmotionListApi();
    this.registerForm = this.fb.group(
      {
        blogtitle: ["", Validators.required],
        blog_emotions: ["", Validators.required],
        // blogThumbnail: ["", Validators.required],
        blogarticle: ["", Validators.required],
        blogStatus: ["", Validators.required],
      },
    );
  }

  blogCancelBtn() {
    this.router.navigate([this.blogCancel_url]);
  }

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getAllEmotion = res.data
    });
  }

  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;
  }
  // --------------------

  async getlist_blogMasterApi() {
    var getapi = "/list_blog/" + this.blogEdit_id;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        // this.blogAllList = res.result;
        this.blogTitle = res.result.blog_title;
        this.article = res.result.blog_content;
        this.emotionsId = res.result.emotion_id;
        this.blogemotions = res.result.emotion_id;
        this.base64textString = res.result.blog_thumbnail;
        var blog_status = res.result.isactive;
        if (blog_status == 1) {
          this.blogStatus = "1"
        }
        if (blog_status == 2) {
          this.blogStatus = "2"
        }

      }
    });

  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    this.type_Type = file.type
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
  }

  // Update Blog-Master

  async blogMasterUpdate() {
    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }
    var putApi = "/add_and_update_blog/" + this.blogEdit_id;
    var exerciseUpdatedata = {
      "thumbnail": this.base64textString,
      "title": this.blogTitle,
      "content": this.article,
      "emotion": this.emotionsId,
      "isactive": this.blogStatus
    };

    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: res.message,
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.blogCancel_url]);
          }
          else if (res.status === 401) {

          }
        }
      });

  }






}
