import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
declare const $: any;
declare var window: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-happiness-expedition-edit',
  templateUrl: './happiness-expedition-edit.component.html',
  styleUrls: ['./happiness-expedition-edit.component.scss'],
})
export class HappinessExpeditionEditComponent implements OnInit {
  isShown: boolean = false;
  module: any;
  campName: any;
  campTitle: any;
  noOfquestions: any;
  questions: any;
  questionOrder: any;
  collectResponse = '0';
  previousCampsite = '0';
  status: any;
  getExpeditionList = [];
  campSiteList = [];
  moduleTitleList = [];
  camp_ListId: any;
  camp_Title: any;
  campAllTitle: any;
  moduleItemsId: any;
  expeditionEditAllId: any;
  expeditionAddAllId: any;
  question_deleteId: any;
  moduleAll_id: any;
  modulesIds: any;
  getExpeditionQuestion_List = [];
  getExpeditionByid_List = [];
  questionExpeditionEdit_url = '/question-Expedition-Edit';
  questionExpeditionView_url = '/question-Expedition-view';
  previousCampSite_Yes_Div = false;
  showExpeditionQuestionTable = false;
  previouscampSiteQuestion: any;
  previousCampsiteList = [];
  previousCampsiteList2 = [];
  campTitleId: any;
  answerPlaceholder: any;
  view_Beliefs = '0';
  descriptions: any;
  expeditionsCampid: any;
  // getExpeditionCamp_List = [];
  getallExpeditionList: any = [];
  expeditionModule_title: any;
  expeditionCamp_Title: any;
  cancelbtn_url = '/expendition-table';
  prvsQuestionId: any;
  prvsQuestionId2: any;
  prvsExpedition: any;
  prvsQuestionAnswer: any;
  redirectLogin_url = '/';
  campFilterId: any;
  campNameOne: any;
  campAllTitle1: any;
  campTitleId2: any;
  previouscampSiteQuestionTwo: any;
  feeling_wheel = '0';
  cognitive_bias = '0';
  registerForm: FormGroup;
  submitted = false;
  editRegForm: FormGroup;
  editsubmitted = false;
  formModal: any;
  options_question: any = '0';
  multiPlaceholderCamp13: any = [{ id: 0, name: '' }];
  countFlag: number = 1;

  //
  constructor(
    private ApiService: ApiService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.expeditionEditAllId = this.ApiService.expeditionEditId;
    this.expeditionModule_title = this.ApiService.expeditionModuleTitle;
    this.module = this.expeditionModule_title + ',' + this.expeditionEditAllId;
    this.expeditionAddAllId = this.ApiService.expeditionAdd_Id;
    this.expeditionsCampid = this.ApiService.expeditionCampId;
    this.expeditionCamp_Title = this.ApiService.expeditionscampTiltes;
    this.campName = this.expeditionCamp_Title + ',' + this.expeditionsCampid;
    this.campAllTitle = this.expeditionCamp_Title;

    // setTimeout(()=>{

    this.adminGetExpeditionCampApi();
    this.expeditionGetByIdListApi();
    this.getexpeditionListApi();
    this.getModuleListApi();
    this.getCampSiteListApi();
    this.expeditionGetQuestionApi();

    // },1500)

    // -----------------------------------------------------------------------

    this.registerForm = this.fb.group({
      question: ['', Validators.required],
      questionorder: ['', Validators.required],
      // answerplaceholders: ["", Validators.required],
    });
    // -----------------------------------------------------------------

    this.editRegForm = this.fb.group({
      camptitle: ['', Validators.required],
      descriptions: ['', Validators.required],
      noOfquestion: ['', Validators.required],
    });

    this.formModal = new window.bootstrap.Modal(
      document.getElementById('addExerciseModel')
    );

    console.log('======>>>>>>', this.ApiService);
  }
  async getexpeditionListApi() {
    var getapi = '/get_expedition';
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.getExpeditionList = res.data.reverse();
    });
  }

  async getCampSiteListApi() {
    var getapi = '/get_camp_site';
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.campSiteList = res.data.reverse();
      this.campFilterId = this.campSiteList.filter(
        (x) => x.id < this.expeditionsCampid
      );
    });
  }

  onChangeProp({ editor }){
    this.answerPlaceholder = editor.getData()
    console.log('this.answerPlaceholder',this.answerPlaceholder)
  }

  async getModuleListApi() {
    var getapi = '/get_expedition_title';
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.moduleTitleList = res.data;
    });
  }

  moduleItem(moduleitems) {
    this.moduleItemsId = moduleitems;
    var str = this.moduleItemsId;
    var myarray = str.split(',');
    for (var i = 0; i < myarray.length; i++) {
      this.moduleAll_id = myarray[1];
    }
  }

  addmultiPlaceholderCamp13() {
    this.multiPlaceholderCamp13.unshift({ id: this.countFlag++, name: '' });
  }
  removemultiPlaceholderCamp13(item:any){
    if(this.multiPlaceholderCamp13.length > 1){
      const data = this.multiPlaceholderCamp13.filter((row:any) => row.id != item.id)
      this.multiPlaceholderCamp13 = data;
    }
  }
  changeMultiPlaceholderCamp13(e: any, item: any) {
    this.multiPlaceholderCamp13 = this.multiPlaceholderCamp13.map(
      (row: any) => {
        if (row.id == item.id) {
          return { id: item.id, name: e.target.value };
        } else {
          return row;
        }
      }
    );
    console.log(this.multiPlaceholderCamp13);
    console.log('options_question', this.options_question);
  }

  camplistTitleFun(e: any) {
    this.campTitleId = e.target.value;
    // var str = this.camp_Title;
    // var myarray = str.split(',');

    this.getCampSitePreviousQuestion();

    // this.getCampSitePreviousQuestionTwo()
  }
  // ----------------------------------

  camplistTitleFunTwo(e: any) {
    this.campTitleId2 = e.target.value;
    // var str = this.camp_Title;
    // var myarray = str.split(',');
    // for (var i = 0; i < myarray.length; i++) {
    //   this.campAllTitle1 = myarray[0];
    //   this.campTitleId2 = myarray[1];
    // }

    this.getCampSitePreviousQuestionTwo();
  }

  camplistQuestion(campQuestion) {
    this.camp_Title = campQuestion;
    var str = this.camp_Title;
    var myarray = str.split(',');
    for (var i = 0; i < myarray.length; i++) {
      this.prvsQuestionId = myarray[0];
      this.prvsExpedition = myarray[1];
      this.prvsQuestionAnswer = myarray[2];
      // question prvsQuestion
      // this.getCampSitePreviousQuestion();
    }
  }

  camplistQuestionTwo(campQuestion) {
    this.camp_Title = campQuestion;
    var str = this.camp_Title;
    var myarray = str.split(',');
    for (var i = 0; i < myarray.length; i++) {
      this.prvsQuestionId2 = myarray[0];
      this.prvsExpedition = myarray[1];
      this.prvsQuestionAnswer = myarray[2];
      // question prvsQuestion
      // this.getCampSitePreviousQuestionTwo();
    }
  }

  async expeditionGetByIdListApi() {
    if (this.expeditionAddAllId != '') {
      var expeditionids = this.expeditionAddAllId;
    } else if (this.expeditionEditAllId != '') {
      var expeditionids = this.expeditionEditAllId;
    }
    var postapi = '/admin_single_expedition';
    var getQuestiondata = {
      exp_id: expeditionids,
    };
    (await this.ApiService.postApi(postapi, getQuestiondata)).subscribe(
      (res) => {
        if (res) {
          if (res.status === 200) {
            this.getExpeditionByid_List = res.data;
            if (this.getExpeditionByid_List.length > 0) {
              this.showExpeditionQuestionTable = true;
            } else if ((this.getExpeditionByid_List.length = 0)) {
              this.showExpeditionQuestionTable = false;
            }

            // for (let item of this.getExpeditionByid_List) {
            //   this.module = item.title + "," + item.stress_id;

            //   this.campName = item.camp_title + "," + item.camp_id;

            //   this.noOfquestions = item.no_of_question;
            //   this.campAllTitle = item.camp_title;
            //   this.descriptions = item.camp_description;
            // }
          }
        }
      }
    );
  }

  async adminGetExpeditionCampApi() {
    var postapi = '/admin_get_expedition_camp';
    var expeditionModuleId = {
      exp_id: this.expeditionEditAllId,
      camp_id: this.expeditionsCampid,
    };
    (await this.ApiService.postApi(postapi, expeditionModuleId)).subscribe(
      (res) => {
        if (res.status === 401) {
          this.router.navigate([this.redirectLogin_url]);

          swal(
            {
              icon: 'error',
              title: 'Oops...',
              text: 'Unauthorized access',
              type: 'error',
            },
            function () {
              alert('yo');
            }
          );
        }

        this.getallExpeditionList = res.data;
        this.noOfquestions = res.data.no_of_question;
        this.descriptions = res.data.camp_description;
        //  this.descriptions = res.data.camp_description;

        // for (let user of this.getallExpeditionList) {
        //   this.noOfquestions = user.no_of_question;
        // }

        // for (let item of this.getallExpeditionList) {
        //   this.noOfquestions = item.no_of_question;

        //   this.campAllTitle = item.camp_title;
        //   this.descriptions = item.camp_description;
        // }
      }
    );
  }

  async expeditionGetQuestionApi() {
    // var getapi = "/get_expedition_questions";
    // (await this.ApiService.getApi(getapi)).subscribe((res) => {
    // });

    if (this.expeditionAddAllId != '') {
      var expeditionids = this.expeditionAddAllId;
    } else if (this.expeditionEditAllId != '') {
      var expeditionids = this.expeditionEditAllId;
    }

    var postapi = '/get_expedition_questions';
    var getQuestiondata = {
      exp_id: expeditionids,
      camp_id: this.expeditionsCampid,
    };
    (await this.ApiService.postApi(postapi, getQuestiondata)).subscribe(
      (res) => {
        if (res) {
          if (res.status === 200) {
            this.getExpeditionQuestion_List = res.data.map(
              (item: any, index: number) => {
                if (item.options_question == 1) {
                  if (JSON.parse(item.answer_placeholder)) {
                    return {
                      ...item,
                      answer_placeholder: JSON.parse(item.answer_placeholder)
                        .map((row: any, index: any) => {
                          return Object.keys(row)[0];
                        })
                        .join('<br />'),
                    };
                  } else {
                    return item;
                  }
                } else {
                  return item;
                }
              }
            );

            if (this.getExpeditionQuestion_List.length > 0) {
              this.showExpeditionQuestionTable = true;
            } else if ((this.getExpeditionQuestion_List.length = 0)) {
              this.showExpeditionQuestionTable = false;
            }
          } else if (res.status === 401) {
            this.router.navigate([this.redirectLogin_url]);

            swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: 'Unauthorized access',
                type: 'error',
              },
              function () {
                alert('yo');
              }
            );
          }
        }
      }
    );
  }

  async expeditionQuestionSave() {
    var expeditionIds = this.ApiService.expeditionCampId;

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }

    var expeditionIds = this.ApiService.expeditionCampId;

    var nums = this.getExpeditionQuestion_List.some(
      (item) => item.question_order == this.questionOrder
    );
    if (nums) {
      swal(
        {
          title: 'error !',
          text: 'Order Numbers Should not be repeated ',
          type: 'error',
        },
        function () {
          alert('yo');
        }
      );
    } else {
      this.createQuestionApi();
    }

    // ----------------------------------------------------------------------
    // var postapi = "/create_expedition_questions";
    // var addQuestiondata = {
    //   "expedition_id": this.expeditionEditAllId,
    //   "question": this.questions,
    //   "question_order": this.questionOrder,
    //   "collect_res_user": this.collectResponse,
    //   "display_res_previous_camp": this.previousCampsite,
    //   "camp_id": this.expeditionsCampid,
    //   "camp_site_question": this.prvsQuestionAnswer,
    //   "answer_placeholder": this.answerPlaceholder,
    //   "view_beliefs": this.view_Beliefs,
    //   'prvs_camp_id': this.campTitleId,
    //   'prvs_question_id': this.prvsQuestionId,
    //   'prvs_camp_id_2' : this.campTitleId2,
    //   'prvs_question_id_2': this.prvsQuestionId2,
    // };

    // (await this.ApiService.postApi(postapi, addQuestiondata)).subscribe(
    //   res => {

    //     if (res) {
    //       if (res.status === 200) {
    //         swal({
    //           title: "Success!",
    //           text: "Expedition Question Added Successfully ",
    //           type: "success"
    //         }, function () {
    //           alert('yo');
    //         });
    //         this.questions = "";
    //         this.questionOrder = "";
    //         this.collectResponse = "";
    //         this.previousCampsite = "";
    //         this.answerPlaceholder = "";
    //         this.view_Beliefs = "";
    //       }
    //       else if (res.status === 401) {
    //         this.router.navigate([this.redirectLogin_url]);

    //         swal({
    //           icon: 'error',
    //           title: 'Oops...',
    //           text: "Unauthorized access",
    //           type: "error"
    //         }, function () {
    //           alert('yo');
    //         });
    //       }
    //     }
    //     this.expeditionGetByIdListApi();
    //     this.expeditionGetQuestionApi();

    //   });
  }

  async createQuestionApi() {
    var postapi = '/create_expedition_questions';
    var addQuestiondata = {
      expedition_id: this.expeditionEditAllId,
      question: this.questions,
      question_order: this.questionOrder,
      collect_res_user: this.collectResponse,
      display_res_previous_camp: this.previousCampsite,
      camp_id: this.expeditionsCampid,
      camp_site_question: this.prvsQuestionAnswer,
      answer_placeholder:
        this.options_question == 1
          ? this.multiPlaceholderCamp13.map((item:any) => { if(item.name != ''){ return item.name} }).filter(Boolean).join(',')
          : this.answerPlaceholder,
      view_beliefs: this.view_Beliefs,
      feeling_wheels: this.feeling_wheel,
      cognitive_bias: this.cognitive_bias,
      prvs_camp_id: this.campTitleId,
      prvs_question_id: this.prvsQuestionId,
      prvs_camp_id_2: this.campTitleId2,
      prvs_question_id_2: this.prvsQuestionId2,
      options_question: this.options_question,
    };
console.log('addQuestiondata==',addQuestiondata);
if(!addQuestiondata.answer_placeholder || addQuestiondata.answer_placeholder == ''){
  swal(
    {
      icon: 'error',
      title: 'Oops...',
      text: "Answer placeholder is required",
      type: 'error',
    },
    function () {
      alert('yo');
    }
  );
  return
}else{
  // if(this.multiPlaceholderCamp13.find((item:any) => item.name == '')){
  //   swal(
  //     {
  //       icon: 'error',
  //       title: 'Oops...',
  //       text: "Answer placeholder is required",
  //       type: 'error',
  //     },
  //     function () {
  //       alert('yo');
  //     }
  //   );
  //   return
  // }
}
    (await this.ApiService.postApi(postapi, addQuestiondata)).subscribe(
      (res) => {

        if (res) {

          if (res.status === 200) {
            swal(
              {
                title: 'Success!',
                // text: 'Expedition Question Added Successfully ',
                text: 'Saved Successfully ',
                type: 'success',
              },
              function () {
                alert('yo');
              }
            );

            $('#addExerciseModel .close').click();
            this.formModal.hide();
            this.questions = '';
            this.questionOrder = '';
            this.collectResponse = '';
            this.previousCampsite = '';
            this.answerPlaceholder = '';
            this.view_Beliefs = '';


          } else if (res.status === 401) {
            this.router.navigate([this.redirectLogin_url]);

            swal(
              {
                icon: 'error',
                title: 'Oops...',
                text: 'Unauthorized access',
                type: 'error',
              },
              function () {
                alert('yo');
              }
            );
          }
        }
        this.expeditionGetByIdListApi();
        this.expeditionGetQuestionApi();
      }
    );
  }

  //  -------------Edit Submit----------------------------

  async updateCampData() {
    this.editsubmitted = true;
    if (this.editRegForm.invalid) {
      return;
    }

    var str = this.campName;
    var myarray = str.split(',');
    for (var i = 0; i < myarray.length; i++) {
      this.camp_ListId = myarray[1];
    }

    var str = this.module;
    var myarrayModule = str.split(',');
    for (var i = 0; i < myarrayModule.length; i++) {
      this.modulesIds = myarrayModule[1];
    }

    if (this.moduleAll_id != '' || this.moduleAll_id != 'undefined') {
      var module_ids = this.moduleAll_id;
    }
    if (this.modulesIds != '' || this.modulesIds == 'undefined') {
      var module_ids = this.modulesIds;
    }

    if (this.expeditionAddAllId != '') {
      var expeditionids = this.expeditionAddAllId;
    } else if (this.expeditionEditAllId != '') {
      var expeditionids = this.expeditionEditAllId;
    }
    // if(this.noOfquestions && this.noOfquestions.length > 0){
    //   swal(
    //     {
    //       icon: 'error',
    //       title: 'Oops...',
    //       text: 'please enter the ',
    //       type: 'error',
    //     },
    //     function () {
    //       alert('yo');
    //     }
    //   );
    // }

    var postapi = '/create_expedition';
    if(this.noOfquestions > 0){

    var addCampdata = {
      // "id": expeditionids,
      no_of_question: this.noOfquestions ,
      stress_id: module_ids,
      camp_id: this.camp_ListId,
      camp_description: this.descriptions,
      camp_title: this.campAllTitle,

    };


    (await this.ApiService.postApi(postapi, addCampdata)).subscribe((res) => {
      if (res) {
        if (res.status === 200) {
          swal(
            {
              title: 'Success!',
              text: 'Expedition Added Successfully ',
              // text: 'Saved Successfully ',
              type: 'success',
            },
            function () {
              alert('yo');
            }
          );
        }
      }
    });
  }else{
    swal(
      {
        title: 'Error!',
        text: 'Number of questions must be greater than 0 ',
        // text: 'Saved Successfully ',
        type: 'error',
      },
      function () {
        alert('yo');
      }
    );
  }

  }

  questionExpeditionEdit(editItems: any) {
    this.ApiService.questionExpeditionEditId = editItems.id;
    this.ApiService.questionExpedition_ids = editItems.expedition_id;

    this.ApiService.questionCampIds = editItems.camp_id;
    this.router.navigate([this.questionExpeditionEdit_url]);
  }
  questionExpeditionView(viewItem: any) {
    this.ApiService.questionExpeditionViewId = viewItem.id;
    this.router.navigate([this.questionExpeditionView_url]);
  }
  questionDeleteItem(deleteitem: any) {
    this.question_deleteId = deleteitem.id;
  }

  async expeditionQuestionDeleteApi() {
    var postApi = '/delete_expedition_question';
    var deletedata = {
      id: this.question_deleteId,
    };

    (await this.ApiService.postApi(postApi, deletedata)).subscribe((res) => {
      if (res) {
        if (res.status === 200) {
          swal(
            {
              title: 'Success!',
              text: 'Expedition Question Deleted Successfully ',
              type: 'success',
            },
            function () {
              alert('yo');
            }
          );
        }
      }
      this.expeditionGetQuestionApi();
    });
  }

  responsePreviousCampSiteYes(e) {
    var campsiteYes = e.target.value;
    if (campsiteYes == '1') {
      this.previousCampSite_Yes_Div = true;
    } else if (campsiteYes == '0') {
      this.previousCampSite_Yes_Div = false;
    }
  }

  async getCampSitePreviousQuestion() {
    var postapi = '/get_camp_question';
    var campsiteQuestiondata = {
      camp_id: this.campTitleId,
      expedition_id: this.expeditionEditAllId,
    };

    (await this.ApiService.postApi(postapi, campsiteQuestiondata)).subscribe(
      (res) => {
        if (res) {
          if (res.status === 200) {
            this.previousCampsiteList = res.data;
          }
        }
      }
    );
  }

  async getCampSitePreviousQuestionTwo() {
    var postapi = '/get_camp_question';
    var campsiteQuestiondata = {
      camp_id: this.campTitleId2,
      expedition_id: this.expeditionEditAllId,
    };

    (await this.ApiService.postApi(postapi, campsiteQuestiondata)).subscribe(
      (res) => {
        if (res) {
          if (res.status === 200) {
            this.previousCampsiteList2 = res.data;
          }
        }
      }
    );
  }

  cancelBtn_expedtion() {
    this.router.navigate([this.cancelbtn_url]);
  }

  addQuestionPopup() {
    this.formModal.show();
  }
}
