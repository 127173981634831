import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
// import { NgbDate, NgbCalendar,NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
// import * as moment from 'moment';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../service/api.service';
declare var $: any;
import * as moment from 'moment';
import { Router } from '@angular/router';
declare var swal: any;

import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-addapointmentmanagement',
  templateUrl: './addapointmentmanagement.component.html',
  styleUrls: ['./addapointmentmanagement.component.scss'],
})
export class AddapointmentmanagementComponent implements AfterViewInit {
  myControl = new FormControl();
  options: string[] = ['Option 1', 'Option 2', 'Option 3']; // Replace with your options data

  filteredOptions: Observable<string[]>;
  // hoveredDate: NgbDate | null = null;
  // fromDate: NgbDate;
  // toDate: NgbDate | null = null;
  // myDateValue: any;
  // model:any;
  myForm: FormGroup;

  active_span_day = 'active_span';
  active_span_Select_single = 'active_span';
  active_span_Select_multiple = '';
  active_span_week = '';
  active_span_month = '';
  active_span_twentyFour_Hr = 'active_span';
  active_span_twell_Hr = '';
  active_span_no_Hr = '';
  show_30min_div = true;
  show_60min_div = false;
  show_both_div = false;
  allMinute: any;
  minutessetTime: any;
  userListAll: any = [];
  userListAllData: any = [];
  coachListAll: any = [];
  coachListAllData: any = [];
  availability: any;
  userId: any;
  slot_Time: any;
  halfHourSlotList: any = [];
  oneHourSlotList: any = [];
  halfHourSlotTimeList: any = [];
  oneHourSlotTimeList: any = [];
  appointment_userId: any;
  appointment_coachId: any;
  appointment_slotId: any;
  appointment_slotDate: any;
  appointment_slotTime: any;
  slotappointmentUrl = '/appointment';
  isShown: boolean = false;
  prevNextCOunt: any = 0;
  prevNexthalfHourSlotList: any = [];
  userDataSelect: any = {};
  coachDataSelect: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private ApiService: ApiService,
    private router: Router
  ) {
    this.myForm = this.formBuilder.group({
      txnSubCategory: new FormControl(''),
      txnSubCategory_coach: new FormControl(''),
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
    console.log('filteredOptions', this.filteredOptions);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  ngAfterViewInit(): void {
    // this.user_List();
    this.getCoachList();
    setTimeout(() => {
      $(this.el.nativeElement.querySelector('#txnSubCategory')).selectpicker();
    }, 200);

    setTimeout(() => {
      $(
        this.el.nativeElement.querySelector('#txnSubCategory_coach')
      ).selectpicker();
    }, 200);
  }

  ngOnInit(): void {
    $('.search-input').focus(function () {
      $('.search-result-data').show();
    });
    $('.search-input').blur(function () {
      setTimeout(() => {
        $('.search-result-data').hide();
      }, 500);
    });

    $('.search-input-coach').focus(function () {
      $('.search-result-data-coach').show();
    });
    $('.search-input-coach').blur(function () {
      setTimeout(() => {
        $('.search-result-data-coach').hide();
      }, 500);
    });
  }

  thirtyMin_div() {
    this.show_30min_div = true;
    this.show_60min_div = false;
    this.active_span_day = 'active_span';
    this.active_span_week = '';
    this.active_span_month = '';
    this.allMinute = $('#thirty').val();
    this.minutessetTime = '30';
  }

  sixtymin_div() {
    this.show_30min_div = false;
    this.show_60min_div = true;
    this.active_span_day = '';
    this.active_span_week = 'active_span';
    this.active_span_month = '';
    this.allMinute = $('#sixty').val();
    this.minutessetTime = '60';
  }

  async user_List(search) {
    var getapi = `/getusersdropdown?search=${search}`;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      // if(res.status == 200) {
      this.userListAll = res.result;
      console.log('this.userListAll', this.userListAll);
      // this.userListAllData = res.result.slice(0,0);
      this.userId = res.result.thusr_id;
      console.log('this.userId ==>>d', this.userId);
    });
  }


  // async userSearch(e: any) {
  //   var search = e.target.value;
  //   if (search != '') {
  //     this.userListAllData = this.userListAll
  //       .filter((item: any, index: any) => {
  //         if (item.thusr_name.includes(search) && item.thusr_email.includes(search)) {
  //           return item;
  //         }
  //       })
  //       .slice(0, 5);
  //   } else {
  //     this.userListAllData = [];
  //   }
  //   console.log('sss', e.target.value);
  // }

  async userSearch(e: any) {
    var search = e.target.value;
    if (search !== '') {
      this.userListAllData = this.user_List(search)
      this.userListAllData = this.userListAll
        .filter((item: any) => {
          // Check if the combination of thusr_name and thusr_email includes the search term
          const fullName = `${item.thusr_name} (${item.thusr_email})`;
          return fullName.toLowerCase();
        })
      // .slice(0, 5);
    } else {
      this.userListAllData = [];
    }
    console.log('sss', e.target.value);
  }

  // async coachSearch(e: any) {
  //   var search = e.target.value;
  //   if (search != '') {
  //     this.coachListAllData = this.coachListAll.filter(
  //       (item: any, index: any) => {
  //         if (
  //           item.thusr_name.includes(search) ||
  //           item.thusr_email.includes(search)
  //         ) {
  //           return item;
  //         }
  //       }
  //     );
  //   } else {
  //     this.coachListAllData = [];
  //   }
  //   console.log('sss', e.target.value);
  // }

  async coachSearch(e: any) {
    var search = e.target.value;
    if (search !== '') {
      this.coachListAllData = this.coachListAll
        .filter((item: any) => {
          // Check if the combination of thusr_name and thusr_email includes the search term
          const fullName = `${item.thusr_name} (${item.thusr_email})`;
          return fullName.toLowerCase().includes(search.toLowerCase());
        })
      // .slice(0, 5);
    } else {
      this.coachListAllData = [];
    }
    console.log('sss', e.target.value);
  }

  chooseField(item: any) {
    this.userDataSelect = item;
    $('.search-result-data').hide();
  }
  async chooseCoachField(item: any) {
    this.coachDataSelect = item;
    $('.search-result-data-coach').hide();
    await this.Availability(item.thcoach_user_id);
    console.log('this.coachDataSelect', this.coachDataSelect);
  }

  async getCoachList() {
    var getapi = '/getcoaches';
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.coachListAll = res.result;
    });
  }

  async coachId(e: any) {
    console.log(e.target.value);
    await this.Availability(e.target.value);
  }

  async Availability(coach_id: any = '') {
    var postapi = '/get_availability';
    var slot_UserId = {
      user_id: coach_id,
      // "user_id" : 5,
    };

    (await this.ApiService.postApi(postapi, slot_UserId)).subscribe((res) => {
      this.availability = res?.result;
      if (res?.result.length) {
        for (var row of res?.result) {
          if (row.duration == '30 mins') {
            this.halfHourSlotList.push(row);
          } else if (row.duration == '60 mins') {
            this.oneHourSlotList.push(row);
          }
        }
        const halfHR = this.halfHourSlotList.map(
          ({ slot_start_date }) => slot_start_date
        );
        this.halfHourSlotList = this.halfHourSlotList.filter(
          ({ slot_start_date }, index) =>
            !halfHR.includes(slot_start_date, index + 1)
        );

        if (this.halfHourSlotList.length > 7) {
          this.prevNexthalfHourSlotList = this.halfHourSlotList.slice(0, 6);
        }

        const oneHR = this.oneHourSlotList.map(
          ({ slot_start_date }) => slot_start_date
        );
        this.oneHourSlotList = this.oneHourSlotList.filter(
          ({ slot_start_date }, index) =>
            !oneHR.includes(slot_start_date, index + 1)
        );
      } else {
        this.halfHourSlotList = [];
        this.oneHourSlotList = [];
      }

      this.halfHourSlotTimeList = [];
      this.oneHourSlotTimeList = [];

      // }
    });
  }

  async nextPrevAction(type: any = 'next') {
    console.log(this.halfHourSlotList);
    console.log(this.prevNextCOunt);
    // this.prevNexthalfHourSlotList = this.halfHourSlotList.slice(this.prevNextCOunt+1,6+this.prevNextCOunt)
    // this.prevNextCOunt = this.prevNextCOunt+1

    if (
      type === 'next' &&
      this.prevNextCOunt + 6 < this.halfHourSlotList.length
    ) {
      this.prevNextCOunt += 1;
    } else if (type === 'prev' && this.prevNextCOunt > 0) {
      this.prevNextCOunt -= 1;
    }

    // Update the portion of data to display
    this.prevNexthalfHourSlotList = this.halfHourSlotList.slice(
      this.prevNextCOunt,
      this.prevNextCOunt + 6
    );
  }

  async filterSlotDateTIme(slot_start_date: any = '') {
    this.halfHourSlotTimeList = this.availability.filter(
      (item: any, ind: number) => item?.slot_start_date == slot_start_date
    );
    // console.log('this.halfHourSlotTimeList ',this.halfHourSlotTimeList )
  }

  async slotSelection(slot: any = '') {
    this.appointment_slotId = slot.id;
    this.appointment_slotDate = slot.slot_start_date;
    this.appointment_slotTime = slot.slot_start_time;
  }

  async filterSlotDateTImeOne(slot_start_date: any = '') {
    this.oneHourSlotTimeList = this.availability.filter(
      (item: any, ind: number) => item?.slot_start_date == slot_start_date
    );
  }

  async createSlot() {
    var postapi = '/adminSlotBooking';
    var slotCreation = {
      user_id: this.userDataSelect?.thusr_id,
      coach_id: this.coachDataSelect?.thcoach_user_id,
      date: this.appointment_slotDate,
      time: this.appointment_slotTime,
      message: '',
      slot_id: this.appointment_slotId,
    };
    // console.log(slotCreation);
    (await this.ApiService.postApi(postapi, slotCreation)).subscribe(
      (res) => {
        if (res.status == 200) {
          swal(
            {
              icon: 'Success!',
              text: res.message,
              type: 'success',
            },
            function () {
              alert('yo');
            }
          );
        } else if (res.status == 400) {
          alert("Appointment already booked")
        }
        this.router.navigate([this.slotappointmentUrl]);
      }

      //   if(res.status == 200){
      //     swal({
      //       icon: 'Success!',
      //       text: res.message,
      //       type: "success",

      //       html: `
      //       <span>  <button style="border: none;
      //       font-size: 14px;
      //       padding: 12px;
      //       width:100px;
      //       background: #4CD877;
      //       outline:none;
      //       border:1px solid #4CD877;
      // color: #fff;
      //       border-radius: 6px;" class="custom-button-class" id="custom-button" >Create</button> </span>
      //        <span>    <button class="ms-2" style="border: none;
      //        font-size: 14px;
      //        padding: 12px; width:100px;
      //        color: #fff;
      //        background: #FEB080;outline:none; border:1px solid #FEB080;
      //        border-radius: 6px;" id="cancel-button">Ok</button>  </span>

      //       </div>
      //     `,
      //     showCancelButton: false, // Hide the default Cancel button
      //     allowOutsideClick: false,
      //     showConfirmButton: false,

      //     }, function () {
      //       alert('yo');
      //     });
      //     document.getElementById('custom-button').addEventListener('click', () => {
      //       // Perform the desired action for the custom button

      //       swal.close(); // Close the dialog if needed
      //       window.location.reload();

      //       this.router.navigate([this.addapointmentUrl]);
      //     });

      //     document.getElementById('cancel-button').addEventListener('click', () => {
      //       // Perform the desired action for the cancel button

      //       swal.close(); // Close the dialog if needed

      //       this.router.navigate([this.slotappointmentUrl]);
      //     });

      //   }
    );
  }
}
