import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
@Component({
  selector: 'app-coach',
  templateUrl: './coach.component.html',
  styleUrls: ['./coach.component.scss']
})
export class CoachComponent implements OnInit {
  datas = [];
  licenceNo = '';
  roleData = [];
  constructor(private router: Router, private ApiService: ApiService) { }
  coachEdit_url = '/coach-edit-page';
  coach_View_url = '/coach-view-page';
  deleteCoachId: any;
  showLoaderDiv = false;
  coach_type: any

  coach_datas: any[] = [];
  coach_manage_list = '/coach-slot-management-list';



  async ngOnInit() {
    this.getCoachListApi();
  }
  async getCoachListApi() {
    this.showLoaderDiv = true;
    var getapi = "/getcoaches";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.datas = res.result.reverse();
      this.showLoaderDiv = false;
    });

  }



  edit_coach(editItem: any) {
    this.ApiService.adminCoachId = editItem.thcoach_id;
    localStorage.setItem("adminCoachId", editItem.thcoach_id);
    localStorage.setItem("adminUserCoachId", editItem.thcoach_user_id)
    this.router.navigate([this.coachEdit_url]);
    // localStorage.setItem('coach_EditId', JSON.stringify(editItem.thcoach_id));
  }

  coach_ViewPage(viewItem: any) {
    this.router.navigate([this.coach_View_url]);
    localStorage.setItem('coach_view', JSON.stringify(viewItem.thcoach_id));
  }

  onSelect(selectedItem: any) {

    localStorage.setItem('Selected item Id', JSON.stringify(selectedItem.thcoach_id));
    localStorage.setItem('Selected item Email', JSON.stringify(selectedItem.thcoach_email));
    localStorage.setItem('Selected item Password', JSON.stringify(selectedItem.thcoach_password));
    // You get the Id of the selected item here
  }
  delete_coach(deleteItems: any) {
    this.deleteCoachId = deleteItems.thcoach_user_id;

  }
  async deleteCoachApi() {
    var postapi = "/admin_delete_coach";
    var adminUserdata = {
      "id": this.deleteCoachId
    };
    (await this.ApiService.postApi(postapi, adminUserdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              icon: 'Success!',
              text: "Coach Deleted Succcessfully",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.getCoachListApi();
          }
        }
      });
  }

  async manageslot(item: any) {
    var postapi = '/admin_get_availability_coach';

    var user_id = {
      "user_id": item.thcoach_user_id
    };

    console.log("user_id", user_id);

    (await this.ApiService.postApi(postapi, user_id)).subscribe(
      res => {
        console.log('Full response:', res);  // Log the full response
        if (res) {
          if (res.status === "success") {
            this.coach_datas = res.result;
            localStorage.setItem('coach_datas', JSON.stringify(this.coach_datas));
            localStorage.setItem('user_id', item.thcoach_user_id);
            if (res.coach_type) {
              this.coach_type = res.coach_type;
              localStorage.setItem('coach_type', this.coach_type);
              console.log("coach_type:", this.coach_type);  // Log coach_type
            }
              console.log("this.coach_datas after setting localStorage:", this.coach_datas);
          } else {
            console.error('Error: Unexpected response status', res.status);
          }
        }
        this.router.navigate([this.coach_manage_list]);
      },
      error => {
        console.error('Error fetching coach availability:', error);
      }
    );
  }

}
