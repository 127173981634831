<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/coupon-Table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Coupon Add</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form [formGroup]="registerForm">

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Title *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="couponTitle"
                            formControlName="couponTitle" maxlength="150" />

                        <div *ngIf="submitted && registerForm.get('couponTitle').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('couponTitle').errors.required">Title is required</div>
                        </div>

                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="couponDescription"
                            formControlName="coupon_Descrip" />

                        <div *ngIf="submitted && registerForm.get('coupon_Descrip').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('coupon_Descrip').errors.required">Description is required
                            </div>
                        </div>

                    </div>
                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Promo code *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="couponCode"
                            formControlName="couponPromocode" maxlength="150" />

                        <div *ngIf="submitted && registerForm.get('couponPromocode').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('couponPromocode').errors.required">Promo code is required
                            </div>
                        </div>

                    </div>


                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Type *</label>
                        <!-- <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="coupenType"
                            [ngModelOptions]="{standalone: true}" /> -->
                        <div>

                            <span>
                                <input type="radio" id="vehicle1" [(ngModel)]="coupenType"
                                    [ngModelOptions]="{standalone: true}" name="vehicle1" value="Percentage">
                                <label class="marg-l-8"> Percentage </label>

                            </span>

                            <span>
                                <input class="marg-l-8" type="radio" id="vehicle1" [(ngModel)]="coupenType"
                                    [ngModelOptions]="{standalone: true}" name="vehicle1" value="Amount">
                                <label class="marg-l-8"> Amount</label>

                            </span>

                        </div>




                    </div>

                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">Value *</label>
                        <input type="text" class="form-control input_bdr_clr font_text" [(ngModel)]="coupenValue"
                            maxlength="150"
                            formControlName="couponValues"
                            />

                        <div *ngIf="submitted && registerForm.get('couponValues').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('couponValues').errors.required">Value is required
                            </div>
                        </div>

                    </div>




                    <div class="form-group">
                        <label class="fsize16 clr_black font_bold_gilroy">expiry *</label>
                        <input type="date" class="form-control input_bdr_clr font_text" [(ngModel)]="coupenExpiry"
                        formControlName="couponexpiry" />

                            <div *ngIf="submitted && registerForm.get('couponexpiry').errors" class="alert alert-danger">
                                <div *ngIf="registerForm.get('couponexpiry').errors.required">Expiry is required
                                </div>
                            </div>


                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr" (click)="submitAddCoupon()">Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="cancelAddCoupon()">Cancel</button>
                    </div>

                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>