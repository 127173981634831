<div class="padd-0-25 marg-t-70">

    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/assestment_results']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Assessment Results Edit</li>
        </ol>
    </nav>
    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">




                <div class="">
                    <form>
                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy"> Emotion*</label>
                            <select (change)="emotionItem($event)"
                                class=" form-control input_bdr_clr ">
                                <!-- <option [disabled]="true" [selected]="true">Select an Emotion</option> -->
                                <option [value]="[item.themotion_id]" [selected]="emotionsId == item.themotion_id"
                                    *ngFor="let item of getAllEmotion">
                                    {{item.themotion_name}} </option>

                            </select>
                        </div>
                        <div class="form-group marg-t-20">
                            <label class="fsize16 clr_black font_bold_gilroy">Result *</label>

                            <select (change)="resultItem($event)"
                                class=" form-control input_bdr_clr">
                                <!-- <option [disabled]="true" [selected]="true">Select an Result</option> -->
                                <option [value]="[item.id]" [selected]="resultId == item.id"
                                    *ngFor="let item of getResultAll">{{item.result}}
                                </option>

                            </select>


                        </div>


                        <table class="row-border hover table w-100" datatable *ngIf="assestment_List.slides.length > 0">
                            <thead>
                                <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                    <th>S.No</th>
                                    <th>Order No</th>
                                    <th>Description</th>
                                    <th style="width:62px;">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr class="font_medium_gilroy"
                                    *ngFor="let item of assestment_List.slides;let i = index">
                                    <td *ngIf="!item.delete">{{i+1}}</td>

                                    <!-- <td *ngIf="!item.delete"  >
                                        <input type="text" maxlength="10"  [(ngModel)]="item.no" [ngModelOptions]="{standalone: true}" style="width:30px">
                                    </td> -->

                                    <td *ngIf="!item.delete" [innerHTML]="item.no">

                                    </td>


                                    <td *ngIf="!item.delete" class="para_line" [innerHTML]="item.description">
                                    </td>

                                    <!-- data-toggle="modal" data-target="#exampleModal" -->

                                    <td *ngIf="!item.delete">
                                        <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px"
                                                class="bgr_black" src="assets/Edit_icon.png" (click)="edit_update(item)"
                                                alt=""></span>

                                        <span class="marg-l-10 cursor_pointer" data-toggle="modal"
                                            data-target="#exampleModalDelete" *ngIf="assestment_List.slides.length > 1"
                                            (click)="assestmentEditDelete(item)"> <button type="button"
                                                class="border_clr_none cursor_pointer"> <img height="22px" width="22px"
                                                    class="bgr_black" src="assets/Delete_icon.png" alt=""> </button>

                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div style=" float:right; margin-top: 12px;">
                            <button (click)="showModel()"  class="btn btn-primary">Add
                            </button>

                            <!-- data-toggle="modal" data-target="#exampleModalAdd" -->

                        </div>

                        <!-- -------------------------------------------------------- -->
                        <!--   -->
                        <div class="modal fade" id="exampleModalAdd" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">

                                    <div class="modal-body">
                                        <form [formGroup]="registerForm">
                                        <div class="marg-t-20">
                                            <label class="fsize16 clr_black font_bold_gilroy">Display Order *</label>
                                            <div>
                                                <input type="text" [(ngModel)]="orderNo" class="form-control input_bdr_clr"
                                                formControlName="displayorder" >
                                            </div>

                                            <div *ngIf="submitted && registerForm.get('displayorder').errors" class="alert alert-danger">
                                                <div *ngIf="registerForm.get('displayorder').errors.required"> Display Order is required</div>
                                            </div>

                                        </div>

                                        <div class="marg-t-20">
                                            <div class="fsize18 font_bold_gilroy "> Description* </div>
                                            <ckeditor name="description" [(ngModel)]="updateDescription"
                                            formControlName="description" debounce="500">
                                            </ckeditor>

                                            <div *ngIf="submitted && registerForm.get('description').errors" class="alert alert-danger">
                                                <div *ngIf="registerForm.get('description').errors.required"> Description is required</div>
                                            </div>


                                        </div>
                                    </form>
                                    </div>
                                    <div class=" row justify-content-center  font_bold_gilroy"
                                        style="margin-top:20px; margin-bottom:20px;">
                                        <button type="button" class="btn_submit_cancel bgr_clr_red" 
                                            (click)="add_submit()">Submit</button>
                                        <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                            data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- ------------------------------------------------------------------------------ -->

                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content" style="padding:0px 20px;">
                                    <div class="cursor_pointer"
                                        style="float:right; font-size: 24px; display: flex; justify-content:end; margin-top: 10px;"
                                        data-dismiss="modal">X</div>
                                    <div>
                                        <form [formGroup]="editRegForm">

                                        <div class="marg-t-20">
                                            <label class="fsize16 clr_black font_bold_gilroy">Display Order *</label>
                                            <div>
                                                <input type="text" [(ngModel)]="orderNo" class="form-control input_bdr_clr"
                                                formControlName="editdisplayorder"  >

                                                    <div *ngIf="editSubmitted && editRegForm.get('editdisplayorder').errors" class="alert alert-danger">
                                                        <div *ngIf="editRegForm.get('editdisplayorder').errors.required"> Display Order is required</div>
                                                    </div>
                                            </div>

                                        </div>

                                        <div class="marg-t-20">
                                            <div>
                                                <label class="fsize16 clr_black font_bold_gilroy">Description *</label>
                                            </div>
                                            <ckeditor  [(ngModel)]="updateDescription"
                                            formControlName="editdescription" debounce="500">
                                            </ckeditor>

                                            <div *ngIf="editSubmitted && editRegForm.get('editdescription').errors" class="alert alert-danger">
                                                <div *ngIf="editRegForm.get('editdescription').errors.required"> Description is required</div>
                                            </div>
                                         

                                        </div>

                                        </form>

                                    </div>
                                    <div class=" row justify-content-center  font_bold_gilroy"
                                        style="margin-top:20px; margin-bottom:20px; ">
                                        <button type="button" class="btn submit_btn_clr" 
                                            (click)="submitUpdate()">Save</button>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- ------------------------------------------------------ -->

                        <!-- ------------------------------------------- -->
                        <div class="modal fade" id="exampleModalDelete" tabindex="-1" role="dialog"
                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">

                                    <div class="modal-body">

                                        <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                                        <div>
                                            Do You Want to Delete ?
                                        </div>

                                    </div>
                                    <div class=" row justify-content-center  font_bold_gilroy"
                                        style="margin-top:20px; margin-bottom:20px;">
                                        <button type="button" class="btn_submit_cancel bgr_clr_red cursor_pointer" data-dismiss="modal"
                                            (click)="delete_submit()">Submit</button>
                                        <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue cursor_pointer"
                                            data-dismiss="modal">Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>






                        <div class="form-group marg-t-40">
                            <button type="submit" (click)="assestmentUpdate()"
                                class="btn  submit_btn_clr">Submit</button>
                            <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                                (click)="assestmentCancelBtn()">Cancel</button>
                        </div>
                    </form>
                </div>


                <div>

                </div>


            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>





    </div>

</div>