import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forum-feedlist',
  templateUrl: './forum-feedlist.component.html',
  styleUrls: ['./forum-feedlist.component.scss']
})
export class ForumFeedlistComponent implements OnInit {

  constructor(private router: Router) { }
  addFeedList_Url = '/forum-add-feedlist';

  ngOnInit(): void {
  }

  feedAddlist(){
    this.router.navigate([this.addFeedList_Url]);
  }

}
