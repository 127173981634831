import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare const $: any;
declare var swal: any;
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-coach-edit-page',
  templateUrl: './coach-edit-page.component.html',
  styleUrls: ['./coach-edit-page.component.scss']
})
export class CoachEditPageComponent implements OnInit {


  username: ""
  name: ""
  registerForm: FormGroup;
  submitted: boolean = false;
  form: FormGroup;
  fileToUploadblog: File | null = null;
  filePath: string;
  _count: number = 0;
  coachName = "";
  coach_LicenceNo = "";
  coach_areaOfExpertise: any;
  coach_language: any;
  coach_typeOfTherapy = "";
  introVideo= "";
  coach_Experience = "";
  coach_Accredting_Body = "";
  coachEdit_url = "/coach";
  type_Type: any;
  base64textString: any;
  coachIds: any;
  areaExpertiseList: any;
  languageList: any;
  refreshed = false;
  adminUserCoachId: any;
  arr: number[] = [];
  arr1: number[] = [];
  toppings: any;
  toppingsLang: any;
  writer: any;
  selectedObjects: any[];
  options: any;
  selectedOptions: any;
  coach_Type: any;
  first_fees: number;
  subs_fees: number;


  constructor(private fb: FormBuilder, private formBuilder: FormBuilder, private ApiService: ApiService, private router: Router) { }

  async ngOnInit() {

    this.options = ["op1", 'op2', 'op3', 'op4'];
    this.selectedOptions = new FormControl(this.options);



    // options: string[] = ["op1",'op2','op3','op4'];
    // selectedOptions = new FormControl(this.options);

    this.selectedObjects = [{ value: 'tomato', label: 'Tomato' }];

    // location.reload();
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;



    this.coachIds = this.ApiService.adminCoachId;
    this.coachIds = localStorage.getItem("adminCoachId");
    this.adminUserCoachId = localStorage.getItem("adminUserCoachId");
    this.registerForm = this.fb.group(
      {
        name: ["", Validators.required],

        // licenceNumber: ["", Validators.required],
        // areaOfExpertise: ["", Validators.required],
        methodology: ["", Validators.required],
        yearsOfExperience: ["", Validators.required],
        coachType: [""],
        firstFees: ["", Validators.required],
        subsFees: ["", Validators.required],
        // accreditingBody: ["", Validators.required],
        // uploadCertificate: ["", Validators.required],
        // coach_type: new FormControl [""]
      },
    );
    this.form = this.formBuilder.group({
      profile: ['']
    });
    var idworkout = JSON.parse(localStorage.getItem('coach_EditId'));
    var getapi = "/getcoaches";

    // 
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var coach_AllData = res.result;

      console.log(coach_AllData, 'coach_AllData');

      for (var i = coach_AllData.length; i--;) {
        var item = coach_AllData[i];

        var ids = item.thcoach_id
        if (ids == this.coachIds) {
          console.log('item: ', item)
          this.coachName = item.thusr_name;
          this.coach_LicenceNo = item.thcoach_license;
          var coach_areaOfExpertise1 = item.thcoach_area_expertise.split(',').map((item: any) => Number(item));
          var coach_language1 = item.thcoach_language.split(',').map((item: any) => Number(item));
          console.log(coach_language1, 'coach_language1===');

          // Initialize the arrays with default values from API response
          this.arr = item.thcoach_area_expertise.split(',').map((id: any) => Number(id));
          this.arr1 = item.thcoach_language.split(',').map((id: any) => Number(id));

          this.coach_areaOfExpertise = this.arr.join();
          this.coach_language = this.arr1.join();

          this.toppings = new FormControl(this.arr);
          this.toppingsLang = new FormControl(this.arr1);
          // console.log(this.coach_areaOfExpertise, ' this.coach_areaOfExpertise.......>>')
          this.coach_typeOfTherapy = item.thcoach_types_of_therapy;
          this.introVideo = item.thcoach_intro_video ?? "";
          this.coach_Experience = item.thcoach_experience;
          this.coach_Accredting_Body = item.thcoach_accredting_body;
          this.coach_Type = item.thcoach_type;
          this.first_fees = item.thcoach_first_fees;
          this.subs_fees = item.thcoach_subsequent_fees;

        }
        // console.log("thcoach_type", this.toppingsLang)
      }

    });

    this.expertiseGetApi();
    this.languageGetApi();




  }




  async onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    // var experiseId = $('#selectMultiple').val();
    // var experiseTotalId = experiseId.join();

    // this.coach_areaOfExpertise = experiseTotalId;

    // console.log(experiseTotalId, 'experiseTotalId.....>>>')


    // coach_areaOfExpertise

    
    var postapi = "/admin_edit_coach";
    var adminUserdata = {
      "user_id": this.coachIds,
      "thcoach_id": this.coachIds,
      "thcoach_user_id": this.adminUserCoachId,
      "name": this.coachName,
      "title": "test coach",
      "licence_number": this.coach_LicenceNo,
      "area_of_expertise": this.coach_areaOfExpertise,
      "language": this.coach_language,
      "types_of_therapy": this.coach_typeOfTherapy,
      "introVideo": this.introVideo,
      "years_of_experince": this.coach_Experience,
      "accrediting_body": this.coach_Accredting_Body,
      "upload_certificate": this.base64textString,
      "thcoach_type": this.coach_Type,
      "first_fees": this.first_fees,
      "subfees": this.subs_fees
    };

    {console.log('req: ', adminUserdata)}
    
    (await this.ApiService.postApi(postapi, adminUserdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {

            swal({
              icon: 'Success!',
              text: "Coach Update Succcessfully",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.coachEdit_url]);
          }

        }
      });
  }



  handleFileInput(files: FileList) {

    this.fileToUploadblog = files.item(0);

  }

  imagePreview(e) {

    const file = (e.target as HTMLInputElement).files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.filePath = reader.result as string;

    }
    reader.readAsDataURL(file)

  }
  handleFileSelect(evt) {
    var files = evt.target.files;

    var file = files[0];
    this.type_Type = file.type

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
  }

  // -------------------Area Of Expertise List Api--------------------------------------------------

  async expertiseGetApi() {
    var getapi = "/expertise_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res);



      this.areaExpertiseList = res.result;

      // $('.selectpicker').selectpicker();





    });
  }
  async languageGetApi() {
    var getapi = "/language_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      console.log(res);



      this.languageList = res.result;

      // $('.selectpicker').selectpicker();





    });
  }

  onSelectEvent($event: any, web: any) {
    if ($event.isUserInput) {
      if ($event.source.selected) {
        if (!this.arr.includes(web.id)) {
          this.arr.push(web.id);
        }
      } else {
        this.arr = this.arr.filter(id => id !== web.id);
      }
      this.coach_areaOfExpertise = this.arr.join();
    }
  }

  onSelectEventLan($event: any, web: any) {
    if ($event.isUserInput) {
      if ($event.source.selected) {
        if (!this.arr1.includes(web.id)) {
          this.arr1.push(web.id);
        }
      } else {
        this.arr1 = this.arr1.filter(id => id !== web.id);
      }
      this.coach_language = this.arr1.join();
    }
  }



}


