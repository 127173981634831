import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var swal: any;
@Component({
  selector: 'app-student-portal-edit',
  templateUrl: './student-portal-edit.component.html',
  styleUrls: ['./student-portal-edit.component.scss']
})
export class StudentPortalEditComponent implements OnInit {

  studentEditId: any;
  editList: any;
  // -----------------------------------
  isShown: boolean = false;
  collegeName: any;
  collegeAddress = "";
  college_code = "";
  is_active: any;
  collegeIds: any;
  collegeDepartment = "";
  batchYear: any;
  collegeDepartment1: any;
  newArray1: any = {};
  newArray2: any;
  studentPortaladd_url = "/student-portal-table";
  container: any[] = [1];
  newArray: any = [{ key: 1, values: '' }];
  getDepartment_list: any;
  deptDuration: any;
  deptId: any;
  departMentName: any;
  departMentIds: any;
  departmentShowDiv = false;
  editDepartMentName: any;
  editBatchDuration: any;
  departmentEditId: any;
  editMby: any;
  editCby: any;
  departmentDeleteId: any;
  registerForm: FormGroup;
  submitted = false;
  addAndRemoveBtn = false;

  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder) { }

  setObj(index: number) {

  }

  ngOnInit() {

    this.studentEditId = this.ApiService.adminStudent_id;
    this.studentEditId = localStorage.getItem("studentEditIds")
    // 
    this.getEditStusentListApi();
    this.getdepartmentList();

    const that = this;
    $(document).ready(function () {

      $("#addEmail").on("click", function () {
        if (!(that.collegeDepartment == "" || that.collegeDepartment == undefined)) {
          // that.addDepartments();
          $("#more-email").append("<div style='margin-top:20px 'class='raja'><label for='exampleInputEmail1' style = 'color:#000; font-weight: 500;' >Department</label><input type='text' [(ngModel)]='collegeDepartment' [ngModelOptions]='{standalone: true}'  onclick='setObj(event,  )'  class='form-control'  /></div><div style='margin-top:20px ' class='raja'><label for='exampleInputEmail1' style = 'color:#000; font-weight: 500;   ' >Batch Year</label><input type='text' class='form-control' id='btnExcel1' [(ngModel)]='batchYear' [ngModelOptions]='{standalone: true}'  /></div>  ");
        }


      });
      $("#removeEmail").on("click", function () {
        $("#more-email").children().last().remove();
      });

    });
    // -----------------------------------------

    this.registerForm = this.fb.group(
      {
        collegename: ["", Validators.required],
        collegeaddress: ["", Validators.required],
        collegecode: ["", Validators.required]
      },
    );
  }

  toggleIsActive() {
    this.is_active = !this.is_active
  }

  async submitadd() {


    var postapi = "/add_college";
    var addCampdata = {
      "name": this.collegeName,
      "address": this.collegeAddress,
      "college_code": this.college_code

    };

    (await this.ApiService.postApi(postapi, addCampdata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {


            swal({
              title: "Success!",
              text: "Add college Success ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.studentPortaladd_url]);

          }
          else if (res.status === 401) {

          }

        }
      });

  }


  toggleEnable(id) {
    let textbox: HTMLButtonElement = document.getElementById(id) as HTMLButtonElement | null;
    if (textbox.disabled) {
      // If disabled, do this 
      textbox.disabled = false;
    } else {
      // Enter code here
      textbox.disabled = true;
    }
  }


  async editCollege() {

    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }


    var postapi = "/edit_college";
    var addCampdata = {
      "id": this.studentEditId,
      "name": this.collegeName,
      "address": this.collegeAddress,
      "college_code": this.college_code,
      "is_active": this.is_active ? 1 : 0
    };

    (await this.ApiService.postApi(postapi, addCampdata)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            console.log("Edit_college", res)

            swal({
              title: "Success!",
              text: "Updated college Success ",
              type: "success"
            }, function () {
              alert('yo');
            });
            // this.router.navigate([this.studentPortaladd_url]);
          }
          else if (res.status === 401) {

          }

        }
      });

  }



  // ---------------
  async addDepartments() {

    this.collegeIds = this.ApiService.collegeId;
    let keyValue = this.container.length + 1;
    console.log(this.newArray[this.newArray.length - 1], 'keyvaluss');
    var adddept = this.newArray[this.newArray.length - 1];
    // console.log(rajaa.values, 'rajaa')


    this.newArray.push({ key: keyValue, values: '' });


    this.container.push(keyValue);
    var postapi = "/add_department";
    var dep = [
      {
        "cdate": "2021-01-01",
        "college_id": this.studentEditId,
        "duration": adddept.textValue,
        "department": adddept.values,
      },
      {
        "cdate": "2021-01-01",
        "college_id": this.studentEditId,
        "duration": adddept.textValue,
        "department": adddept.values,

      },
    ]
    dep[dep.length - 1];

    (await this.ApiService.postApi(postapi, dep[dep.length - 1])).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            // swal({
            //   title: "Success!",
            //   text: "Department Added success",
            //   type: "success"
            // }, function () {
            //   alert('yo');
            // });
            this.ApiService.departmentId = res.department_id;
            this.getdepartmentList();
          }
          else if (res.status === 401) {
          }

        }
      });

  }

  manage() {

    // var bt = document.getElementById('btSubmit');
    // document.getElementById("btnExcel").disabled = false;


  }

  // -------------------

  addContainer() {
    let keyValue = this.container.length + 1;

    // console.log(this.newArray , 'newArray123');

    console.log(this.newArray[this.newArray.length - 1], 'keyvaluss');
    var rajaa = this.newArray[this.newArray.length - 1];
    console.log(rajaa.values, 'rajaa')


    this.newArray.push({ key: keyValue, values: '' });


    this.container.push(keyValue);

    console.log(this.container, 'container');
  }




  async getEditStusentListApi() {
    var postapi = "/view_college";
    var editData = {
      "id": this.studentEditId,
    };

    (await this.ApiService.postApi(postapi, editData)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            this.collegeName = res.data[0].name;
            this.collegeAddress = res.data[0].address;
            this.college_code = res.data[0].college_code;
            this.is_active = res.data[0].is_active

          }

          else if (res.status === 401) {

          }

        }
      });





  }

  async getdepartmentList() {

    var postapi = "/department_list";
    var editData = {
      "college_id": this.studentEditId,
    };

    (await this.ApiService.postApi(postapi, editData)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            this.getDepartment_list = res.msg;

          }
          else if (res.status === 401) {

          }

        }
      });
  }
  studentDeptIds(items: any) {
    console.log('rrrr');

    console.log(items.duration, 'duration');


  }

  onChange(e: any) {
    var dept_Id = e.target.value;
    var myarray = dept_Id.split(',');


    for (var i = 0; i < myarray.length; i++) {
      this.departMentName = myarray[0];
      this.departMentIds = myarray[1];
      this.deptDuration = myarray[2];
      console.log(this.departMentName, 'rajj');
      console.log(this.departMentIds, 'pandd');

    }

  }

  addDepartMentBtn() {
    this.departmentShowDiv = true;
    this.addAndRemoveBtn = true;
  }

  editDept_Icon(editItems: any) {
    console.log(editItems, 'editItems');
    this.departmentEditId = editItems.id;
    this.editCby = editItems.cby;
    this.editMby = editItems.mby;
    this.editDepartMentName = editItems.department;
    this.editBatchDuration = editItems.duration
  }

  async subitedd() {
    //   this.collegeIds = this.ApiService.collegeId ;
    //   let keyValue = this.container.length + 1;
    // console.log(this.newArray[this.newArray.length-1],'keyvaluss');
    // var rajaa =  this.newArray[this.newArray.length-1] ;
    // console.log(rajaa.values,'rajaa');
    // var pandiyaa = rajaa.values ;
    // var pandu = rajaa.textValue;
    var postapi = "/edit_department";
    var studentedit = {
      "department_id": this.departmentEditId,

      "cdate": "2022-01-03",

      "ctime": "2022-08-18 12:49:27",

      "college_id": this.studentEditId,

      "department": this.editDepartMentName,

      "duration": this.editBatchDuration,
      "cby": this.editCby,
      "mby": this.editMby

    };

    // -----------------------------------------------------------

    (await this.ApiService.postApi(postapi, studentedit)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Department Added success",
              type: "success"
            }, function () {
              alert('yo');
            });

            this.ApiService.departmentId = res.department_id;
            console.log(this.ApiService.departmentId, 'departmentidss');
            this.getdepartmentList();

          }
          else if (res.status === 401) {
          }

        }
      });

  }


  async submitBtn() {


    this.collegeIds = this.ApiService.collegeId;
    // let keyValue = this.container.length + 1;
    console.log(this.newArray[this.newArray.length - 1], 'keyvaluss');
    var rajaa = this.newArray[this.newArray.length - 1];

    // this.newArray.push({ key: keyValue, values: '' });
    // this.container.push(keyValue);


    var postapi = "/add_department";
    var dep = [
      {
        "cdate": "2021-01-01",
        "college_id": this.studentEditId,
        "duration": rajaa.textValue,
        "department": rajaa.values,
      },
      {
        "cdate": "2021-01-01",
        "college_id": this.studentEditId,
        "duration": rajaa.textValue,
        "department": rajaa.values,

      },
    ]
    dep[dep.length - 1];

    (await this.ApiService.postApi(postapi, dep[dep.length - 1])).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Department Added success",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.getdepartmentList();
            this.departmentShowDiv = false;
          }
          else if (res.status === 401) {
          }

        }
      });


  }
  async removebtn(items: any) {
    this.departmentDeleteId = items.id;


  }

  async studentEditDeleteApi() {

    var postapi = "/delete_department";
    var removeDepartmentId = {
      "department_id": this.departmentDeleteId

    };
    (await this.ApiService.postApi(postapi, removeDepartmentId)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Department Deleted  success",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.getdepartmentList()
          }
          else if (res.status === 401) {
          }

        }
      });


  }





  cancelBtn() {
    this.router.navigate([this.studentPortaladd_url]);
  }

  async removemorebtn() {
    this.container.pop();
    var removeDeptId = this.ApiService.departmentId
    var postapi = "/delete_department";
    var removeDepartmentId = {
      "department_id": removeDeptId

    };
    (await this.ApiService.postApi(postapi, removeDepartmentId)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {


            this.addAndRemoveBtn = false;
            // swal({
            //   title: "Success!",
            //   text: " Removed Successfully",
            //   type: "success"
            // }, function () {
            //   alert('yo');
            // });
            //  this.getdepartmentList()
          }
          else if (res.status === 401) {
          }

        }
      });
  }
  // editDepartMentName

}
