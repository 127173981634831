<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/community-topic-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Community Topic Edit</li>
        </ol>
    </nav>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <div>
                        <form id="" [formGroup]="registerForm">
                            <div>

                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Topic Name</label>
                                    <input type="text" [(ngModel)]="topicsName" formControlName="topicname"
                                        id="questions" class="form-control input_bdr_clr" maxlength="150" />

                                    <div *ngIf="submitted && registerForm.get('topicname').errors"
                                        class="alert alert-danger">
                                        <div *ngIf="registerForm.get('topicname').errors.required">
                                            Topic Name is
                                            Required</div>
                                    </div>



                                </div>

                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Image</label>

                                    <div class="row m-0">
                                        <input  type="file" (change)="handleFileSelect($event)" 
                                        class="form-control input_bdr_clr padd-3 imgInput" />

                                        <div style="margin-left:12px;">
                                            <img height="40px" width="40px" src="{{eventimage}}" alt="">
                                           </div>
                                    </div>
                                 

                                    <!-- <div *ngIf="submitted && registerForm.get('topicImage').errors"
                                        class="alert alert-danger">
                                        <div *ngIf="registerForm.get('topicImage').errors.required">
                                            Image is
                                            Required</div>
                                    </div> -->


                                


                                </div>
                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Description</label>

                                    <!-- <textarea class="form-control input_bdr_clr h-90 "  [(ngModel)]="descriptions" [ngModelOptions]="{standalone: true}" ></textarea> -->
                                    <ckeditor name="myckeditor" [(ngModel)]="descriptions"
                                    formControlName="topicDescription"
                                        debounce="500">
                                    </ckeditor>

                                    <div *ngIf="submitted && registerForm.get('topicDescription').errors"
                                    class="alert alert-danger">
                                    <div *ngIf="registerForm.get('topicDescription').errors.required">
                                        Description is
                                        Required</div>
                                </div>

                                </div>

                                <!-- <div class="fsize16 clr_black font_bold_gilroy">
                                    Active | In Active
                                </div>

                                <div class="marg-t-20">
                                    <label class="switch">
                                        <input type="checkbox" checked (change)="changefun($event)" value="1">
                                        <span class="slider round"></span>
                                    </label>
                                </div> -->

                            </div>
                        </form>
                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr"
                            (click)="CommunityTopicUpdateSubmit()">Submit</button>
                        <button type="button" class="btn  cancelBtn_clr "
                            style="height: 40px;margin-left: 20px;font-size: 16px;"
                            (click)="cancelBtn()">Cancel</button>
                    </div>
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
    </div>
</div>