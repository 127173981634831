<p>community-topic-view works!</p>
<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/community-topic-table']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Community Topic View</li>
        </ol>
    </nav>

    <div class="marg-t-40">

        <div class="row marg-0">
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                <form>

                    <div>
                        <form id="">
                            <div>

                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Topic Name</label>
                                    <input type="text" [(ngModel)]="topicsName" [ngModelOptions]="{standalone: true}"  id="questions" class="form-control input_bdr_clr " />
                                </div>
    
                                 <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Image</label>
                                    <input type="file"    class="form-control input_bdr_clr padd-3 " />
                                </div>
                                <div class="form-group">
                                    <label class="fsize16 clr_black font_bold_gilroy">Description</label>
                                    
                                    <!-- <textarea class="form-control input_bdr_clr h-90 "  [(ngModel)]="descriptions" [ngModelOptions]="{standalone: true}" ></textarea> -->
                                    <ckeditor name="myckeditor" [(ngModel)]="descriptions"
                                    [ngModelOptions]="{standalone: true}" debounce="500">
                                </ckeditor> 
                              
                                </div>
    
                                <!-- <div class="fsize16 clr_black font_bold_gilroy" >
                                     Active | In Active

  
                                     <label class="switch">
                                        <input type="checkbox" checked  >
                                        <span class="slider round"></span>
                                      </label>

                                </div> -->
                                <!-- <div class="fsize16 clr_black font_bold_gilroy" >
                                    Active | In Active
                               </div>
   
                               <div class="marg-t-20">
                              
                                   <label class="switch">
                                       <input type="checkbox" checked  >
                                       <span class="slider round"></span>
                                     </label>
   
   
                               </div> -->

                               <div class="fsize16 clr_black font_bold_gilroy" >
                                Active | In Active
                           </div>

                           <div class="marg-t-20">
                          
                               <label class="switch">
                                   <input type="checkbox" checked  >
                                   <span class="slider round"></span>
                                 </label>


                           </div>

    
                            </div>
                            <div class="marg-t-40 fsize16 clr_black  font_bold_gilroy">
                                Total No of Users : 100
                            </div>
                        
                 


                        </form>
                    </div>

                    <div class="form-group marg-t-40">
                        <button type="submit" class="btn  submit_btn_clr"
                            >Submit</button>
                        <button type="button" class="btn  cancelBtn_clr "
                            style="height: 40px;margin-left: 20px;font-size: 16px;"  (click)="cancelBtn()"     >Cancel</button>
                    </div>
      
                </form>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
            </div>
        </div>
        <div class="marg-t-20">
            <div class="row marg-0">
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <button class="buttons_clr bgr_clr_orange" (click) = "feedlistPage()"> Feed </button>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                    <button class="buttons_clr bgr_clr_blue"  (click) = "userlistPage()"  > User List </button>
                </div>
    
                <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                </div>
    
            </div>
        </div>
    </div>
</div>