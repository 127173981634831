import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
declare var swal: any;

@Component({
  selector: 'app-happiness-test-edit-category-result',
  templateUrl: './happiness-test-edit-category-result.component.html',
  styleUrls: ['./happiness-test-edit-category-result.component.scss']
})
export class HappinessTestEditCategoryResultComponent implements OnInit {

  isShown: boolean = false;
  isEditing: boolean = false;
  isAdding: boolean = false;

  container: {
    id: any;
    description: string,
    range: "",
    category_range_id: string,
    showDropdown: boolean
    delete?: number
  }[] = [{ id: "", category_range_id: "", range: "", description: "", showDropdown: false }];
  keyValue: any;
  gethappinessresultcategory: any;
  categoryid: any;
  category_ID: any;
  happiness_test_list: any;
  happinessCategoryResultEditId: any;
  happinessresultDeleteId: any;
  getallcategory: any;
  happinessCategoryresultitems: any;
  category_range_Id: any;
  happinessresultBack_url = "/happiness-test-category-result"

  selectedDeleteIndex: number;


  hasError = false;

  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.happinessCategoryResultEditId = this.ApiService.happinessCategoryresultEditId;
    this.happinessCategoryResultEditId = sessionStorage.getItem("category_id")
    this.getCategoryresultListApi();
    this.getlist_happinessresultList();
    console.log("sssssssssssss", this.category_ID)
    console.log("category_ID", this.happinessCategoryResultEditId)
  }

  // ======================================================================== //


  async getCategoryresultListApi() {
    var getapi = "/listHappinesstestcategoriesrange";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.happinessCategoryresultitems = res.result
      this.category_range_Id = this.happinessCategoryresultitems[0].id
      console.log("list data ", this.happinessCategoryresultitems)
      console.log("category_range_id", this.category_range_Id)
    });
  }

  // ========================================================================= // 

  async getcategoryListApi() {
    var getapi = "/listHappinesstestcategories";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getallcategory = res.result
      console.log("adddd", this.getallcategory)
    });
  }


  // ========================================================================= //

  async happinessUpdateSubmit() {
    console.log('this.container');

    this.container = this.container.map((item) => {
      console.log(item.id);
      console.log("items from list", item);

      var data = { ...item };
      if (item.id === "") {
        delete data.id;
        delete data.range;
      } else {
        delete data.range;
      }
      return data;
    })

    var deletedata = this.container.every(item => "delete" in item)
    console.log("deleted_data", deletedata)

    if (deletedata) {
      swal({
        title: 'Error !',
        text: 'Any one of the data is mandatory',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return
    }

    const hasEmptyDescription = this.container.some(item => !item.delete && !item.description.trim());

    if (hasEmptyDescription) {
      swal({
        title: 'Error!',
        text: 'Every rows must have a Description',
        type: 'error'
      });
      return;
    }

    console.log('this.container', this.container);

    var putApi = "/addAndUpdateHapinessTestCategoryResult/" + this.happinessCategoryResultEditId;
    var exerciseUpdatedata = {
      "category_id": this.category_ID,
      "content": this.container
    };

    console.log(exerciseUpdatedata);
    (await this.ApiService.putApi(putApi, exerciseUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Result Updated Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.happinessresultBack_url]);
          }

          (error) => {
            // if (error.error.message.emotion_id != undefined || error.error.message.result_id != undefined) {
            var errMsg = '';
            if (typeof error.error.message === 'object') {
              for (var i in error.error.message) {
                errMsg += error.error.message[i] + '<br />';
              }
            } else {
              errMsg = error.error.message
            }

            swal({
              title: "error!",
              text: errMsg,
              type: "error",
            }, function () {
              alert('yo');
            });

            // }
            // else {
            //   swal({
            //     title: "error!",
            //     text: error.error.message,
            //     type: "error",
            //   }, function () {
            //     alert('yo');
            //   });
            // }
          }
        }
      });
  }


  // ====================================================================== //


  happinessitem(e: any) {
    console.log(e)
    this.category_ID = e.target.value;
    console.log("sd", this.category_ID)

  }


  // ======================================================================= //


  async addBenefits() {
    const data_remain = this.container.filter((data) => { return !data.hasOwnProperty('delete') })

      this.isAdding = true;
      this.container.push({ id: "", category_range_id: "", range: "", description: "", showDropdown: true }); // Set showDropdown to true for new data
      this.container.map((item) => {
        return { category_range_id: item.category_range_id, description: item.description }
      })
    
    
    // else {
    //   swal({
    //     title: 'Error !',
    //     text: 'Only Four data for per category',
    //     icon: 'error',
    //     confirmButtonText: 'OK'
    //   });
    // }
  }


  // ========================================================================= //


  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }
  }


  // ========================================================================== //


  async getlist_happinessresultList() {
    var getapi = "/listHappinesstestcategoryresult/" + this.happinessCategoryResultEditId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.container = res.result.content;
        this.categoryid = res.result.category_id;
        console.log("ddddddddddddd", this.categoryid)
        console.log("data", this.container)
      }
      this.category_ID = this.categoryid
      console.log(this.category_ID)
    });
  }


  // =========================================================================== //


  happinessEditDelete(item: any, index: number) {
    console.log(item)
    this.happinessresultDeleteId = item.id
    if (!item.id) {
      this.selectedDeleteIndex = index
    }
    console.log("Id for delete", this.happinessresultDeleteId)
  }

  // =========================================================================== //

  delete_submit() {
    // this.container = this.container.map((item) => {
    //   if (item.id == this.happinessresultDeleteId) {
    //     return { ...item, delete: this.happinessresultDeleteId };
    //   }

    //   else {
    //     return item;
    //   }
    // })
    if (this.selectedDeleteIndex !== null && this.selectedDeleteIndex >= 0 && this.selectedDeleteIndex < this.container.length) {
      this.container.splice(this.selectedDeleteIndex, 1);
    }

    this.container = this.container.map(item => {
      if (item.id === this.happinessresultDeleteId) {
        return { ...item, delete: this.happinessresultDeleteId };
      }
      return item;
    });

    console.log("The container value ", this.container)
  }

  // ========================================================================== //

  levelItem(event: any, index: number) {
    const selectedLevelID = event.target.value;
    let isDuplicate = false;


    // for (let i = 0; i < this.container.length; i++) {
    //   if (i !== index && this.container[i]?.category_range_id == selectedLevelID && !this.container[i].delete) {
    //     console.log("Tha ededjedjee", this.container[i])
    //     this.hasError = true;
    //     swal({
    //       title: 'Error!',
    //       text: 'You cannot select the same ID for multiple rows',
    //       type: 'error'
    //     });
    //     // Clear the selected ID in the newArray
    //     this.container[index].category_range_id = selectedLevelID;
    //     console.log("newArray after levelItem change:", this.container);
    //     return;
    //   }
    // }

    if (this.container[index]) {
      this.container[index].category_range_id = selectedLevelID;
    }
    console.log("newArray after levelItem change:", this.container);
  }

  // =========================================================================== //

  happinessBackUrl() {
    this.router.navigate([this.happinessresultBack_url]);
  }

}



