<div class="padd-0-25">


    <div *ngIf="showLoaderDiv">

        <div id="pause" class="d-flex align-items-center justify-content-center">
            <div id="spinner"></div>
        </div>

    </div>


    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Assessment Question
    </div>

    <div class="f-right ">
        <button class="add_btn" (click)="assestmentQuestionAdd()">Add</button>

    </div>

    <div class="marg-t-64">
        <div class="" style=" background-color: #fff; border-radius: 8px;">

            <table class="row-border hover table w-100" datatable *ngIf="assestmentQuestionList.length > 0">
                <thead>
                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>Emotion</th>
                        <!-- <th>Slides </th> -->
                        <th>Action</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="font_medium_gilroy" *ngFor="let item of assestmentQuestionList;let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.themotion_name}}</td>
                        <!-- <td>{{item.assessment.question}}</td> -->

                        <td>
                            <span class="marg-l-10 cursor_pointer" (click)="assestmentQuestionEdit(item)"><img height="22px"
                                    width="22px" class="bgr_black" src="assets/Edit_icon.png" alt=""></span>
                            <span class="marg-l-10 cursor_pointer" (click)="deleteAssestmentquestion(item)"> <button
                                    type="button" class="border_clr_none cursor_pointer" data-toggle="modal"
                                    data-target="#exampleModal"> <img height="22px" width="22px" class="bgr_black"
                                        src="assets/Delete_icon.png" alt=""> </button>

                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-body">

                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                    <div>Do You Want to Delete ?</div>

                </div>
                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                    <button type="button" class="btn_submit_cancel bgr_clr_red cursor_pointer" data-dismiss="modal"
                        (click)="assestmentDelete()">Submit</button>
                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue cursor_pointer"
                        data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>


</div>