import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
@Component({
  selector: 'app-question-sos-edit',
  templateUrl: './question-sos-edit.component.html',
  styleUrls: ['./question-sos-edit.component.scss']
})
export class QuestionSosEditComponent implements OnInit {
  question_Type: any;
  questions: any;
  answer_placeholder_text: any;
  answer_placeholder_text1: any;
  answer_placeholder_text2: any;
  questionYesRadioBtn: any;
  questionNoRadioBtn: any;
  yesQuestionText: any;
  showToastMessage: any;
  collectResponse: any;
  yesPlaceholderText: any;
  noPlaceholderText: any;
  noQuestionText: any;
  noQuestion_ShowDiv = false;
  yesQuestion_ShowDiv = false;
  isShown: boolean = false;
  show_questionText_div = false;
  show_questionImage_div = false;
  show_questionAudio_div = false;
  affirmation_div = false;
  grid_div = false;
  multi_response_div = false;
  review_div = false;
  Yes_or_No_div = false;
  sos_questionAddIds: any;
  sos_questionEditids: any;
  sos_addId: any;
  question_EditSubmit_url = "/sos-edit";
  question_Cancel_url = "/sos-edit";
  question_one: any;
  question_two: any;
  question_NoRadioBtn: any;
  question_No_YesRadioBtn: any;
  question_No_NoRadioBtn: any;
  no_no_question: any;
  no_radiobtn: any;
  no_no_answer_placeholder: any;
  showNo_NoquestionDiv = false;
  showYes_NoquestionDiv = false;
  showCognitive: any;

  getResponseShowDiv = false;
  logicQuestionShowDiv = false;
  yesLogicQuestionDiv = false;
  noLogicQuestionDiv = false;
  no_LogicQuestion: any;
  no_logic_placeholder: any;
  logicNo_YesRadiobtn: any;
  logicNo_NoRadiobtn:any;
  questionNoResponseRadioBtn: any;
  responseQuestions: any;
  no_responseAnswerPlaceholder: any;
  showtost:any;
  showcollect:any;
  base64textString:any;
  constructor(private ApiService: ApiService, private router: Router) { }

  async ngOnInit() {

    this.sos_questionAddIds = this.ApiService.sosQuestionAdd_Id;
    this.sos_questionEditids = this.ApiService.sosQuestionEdit_Id;
    this.getQuestionapi();

  }

  questionOne() {
    var questionValue = this.question_Type;
    if (questionValue == 'text') {
      this.show_questionText_div = true;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'image') {
      this.show_questionText_div = false;
      this.show_questionImage_div = true;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'audio') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = true;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'affirmation') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = true;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;

    }
    if (questionValue == 'grid') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = true;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'multi_response') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = true;
      this.review_div = false;
      this.Yes_or_No_div = false;
    }

    if (questionValue == 'review') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = true;
      this.Yes_or_No_div = false;
    }
    if (questionValue == 'yes_or_no') {
      this.show_questionText_div = false;
      this.show_questionImage_div = false;
      this.show_questionAudio_div = false;
      this.affirmation_div = false;
      this.grid_div = false;
      this.multi_response_div = false;
      this.review_div = false;
      this.Yes_or_No_div = true;
      this.yesQuestion_ShowDiv = false;
      this.noQuestion_ShowDiv = false;
    }

  }
  yesOrNo_questionType() {

    var questionValue = this.questionYesRadioBtn;
    if (questionValue == 'Yes') {
      this.yesQuestion_ShowDiv = true;
      this.noQuestion_ShowDiv = false;
    }
    if (questionValue == 'No') {
      this.yesQuestion_ShowDiv = false;
      this.noQuestion_ShowDiv = true;
    }
  }
  no_yesOrNo_YesquestionType() {

    var questionValue = this.question_No_YesRadioBtn;
    if (questionValue == '1') {
      this.showYes_NoquestionDiv = true;
      this.showNo_NoquestionDiv = false;
    }
  }
  // -------------------------------------------------------
  responseNoquestionType(e) {

    var questionValue = e.target.value;
    if (questionValue == '0') {
      this.getResponseShowDiv = true;
      this.logicQuestionShowDiv = false;
    }
    if (questionValue == '1') {
      this.getResponseShowDiv = false;
      this.logicQuestionShowDiv = true;
    }

  }

  toastmsgChange(e){
    var raja = e.target.value
     if(raja == 1){
       this.showtost = "1";
       this.showcollect ="0"
       }
     }
     collectResChange(e){
      var pandi = e.target.value
      if(pandi == 0){
        this.showtost = "0";
        this.showcollect ="1"
        }
  
     }
     logicNoquestionType(e){
      var questionValue = e.target.value;
      if (questionValue == '0') {
        this.noLogicQuestionDiv = true;
        this.yesLogicQuestionDiv = false;
  
      }
      if (questionValue == '1') {
        this.noLogicQuestionDiv = false;
        this.yesLogicQuestionDiv = true;
      }
    }
  


  // ---------------------------------------------------------------
  no_yesOrNo_NoquestionType() {
    var questionValue = this.question_No_YesRadioBtn;
    // if (questionValue == '1') {
    //   this.showYes_NoquestionDiv = true;
    //   this.showNo_NoquestionDiv = false;
    // }
    if (questionValue == '0') {
      this.showYes_NoquestionDiv = false;
      this.showNo_NoquestionDiv = true;
    }


  }

  async getQuestionapi() {


    var getapi = "/sos/get_questions_byId?sos_id=" + this.sos_questionAddIds + "&type=question&id=" + this.sos_questionEditids;

    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var myArray = res.data;
      for (let item of myArray) {
        var ids = item.id;
        if (ids == this.sos_questionEditids) {
          this.question_Type = item.question_type;
          this.questions = item.question1;
          this.question_one = item.question2;
          this.question_two = item.question3;
          this.answer_placeholder_text = item.answer_placeholder1;
          this.answer_placeholder_text1 = item.answer_placeholder2;
          this.answer_placeholder_text2 = item.answer_placeholder3;
          this.questionYesRadioBtn = item.selected_yes_no_type;
          this.question_No_YesRadioBtn = item.no_question_type;
          // this.no_no_question = item.no_question;
          this.no_no_answer_placeholder = item.no_placeholder;
          this.no_radiobtn = item.no_checkbox;
          this.no_logic_placeholder = item.no_placeholder;
          this.responseQuestions = item.no_question;
         this.base64textString = item.audio;

         var logicYesOrNoType = item.logic_yes_or_no;
          if(logicYesOrNoType == 0){
            this.logicNo_YesRadiobtn = "0";
            this.noLogicQuestionDiv = true;
            this.yesLogicQuestionDiv = false;
          }
          else if(logicYesOrNoType == 1){
            this.logicNo_YesRadiobtn = "1";
            this.noLogicQuestionDiv = false;
            this.yesLogicQuestionDiv = true;
          }
          // 
          var loginQuestionType = item.logic_question;
           if(loginQuestionType == 0){
            this.questionNoResponseRadioBtn = "0";
            this.getResponseShowDiv = true;
            this.logicQuestionShowDiv = false;
           }
           else if(loginQuestionType == 1){
            this.questionNoResponseRadioBtn = "1";
            this.getResponseShowDiv = false;
            this.logicQuestionShowDiv = true;
           }

          var show_Cognitive = item.show_cognitive_biases;

          if (show_Cognitive == 1) {
            this.showCognitive = "1"
          }
          else if (show_Cognitive == 0) {
            this.showCognitive = "0"
          }

          var showToastAndCollectRes = item.collect_response;
          
          if (showToastAndCollectRes == 0) {
            this.collectResponse = "0"
          }
          if (showToastAndCollectRes == 1) {
            this.collectResponse = "1"
          }
      

        }
      }
      this.questionOne();
      this.yesOrNo_questionType();
      this.no_yesOrNo_YesquestionType();
      this.no_yesOrNo_NoquestionType();
    });
  }
  async questionUpdateSubmit() {

    var putApi = "/sos/update_questions/" + this.sos_questionEditids

    var questionUpdatedata = {
      "sos_id": this.sos_questionAddIds,
      "question_type": this.question_Type,
      "question1": this.questions,
      "question2": "",
      "question3": "",
      "answer_placeholder1": this.answer_placeholder_text,
      "answer_placeholder2": this.answer_placeholder_text1,
      "answer_placeholder3": this.answer_placeholder_text2,
      "position": 1,
      "show_cognitive_biases": 1,
      "audio": this.base64textString,
      "selected_yes_no_type": this.questionYesRadioBtn,
      "show_toast": this.showToastMessage,
      "collect_response": this.collectResponse,
      "type": "question",
      "is_active": 1,
      "no_question_type": this.question_No_YesRadioBtn,
      "no_question": this.responseQuestions,
      "no_checkbox": this.no_radiobtn,
     "no_placeholder": this.no_logic_placeholder,
      "logic_question": this.questionNoResponseRadioBtn,
      "logic_yes_or_no": this.logicNo_YesRadiobtn
    };


    (await this.ApiService.putApi(putApi, questionUpdatedata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {


            swal({
              title: "Success!",
              text: "SOS Question Update Successfully ",
              type: "success"
            }, function () {
              alert('yo');
            });


            this.router.navigate([this.question_EditSubmit_url]);
          }
        }
      });
  }
  questionCancel() {
    this.router.navigate([this.question_Cancel_url]);
  }
}
