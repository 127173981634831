import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;

@Component({
  selector: 'app-happiness-test-management',
  templateUrl: './happiness-test-management.component.html',
  styleUrls: ['./happiness-test-management.component.scss']
})

export class HappinessTestManagementComponent implements OnInit {

  happinesstestlist: any;
  deletehappinesstestId: any;
  happinesstestAdd_url = "/happiness-test-addmanagement";
  happinesstestEdit_url = "/happiness-test-editmanagement";
  showLoaderDiv = false;
  CategoryId: any
  questioncount:any


  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.getlist_happinesstestApi();
  }

  // ---------------------------------------------------------

  async getlist_happinesstestApi() {
    this.showLoaderDiv = true;
    var getapi = "/listHappinesstest";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.happinesstestlist = res.result;
        this.CategoryId = res.result.category_id
        this.questioncount = res.result.question_count;
        this.showLoaderDiv = false;
      }
      console.log("aaaaaaaa", this.happinesstestlist);
    });

  }


  // --------------------------------------------------------------------

  happinesstestQuestionAdd() {
    this.router.navigate([this.happinesstestAdd_url]);
  }

  // -----------------------------------------------

  happinesstestQuestionEdit(item: any) {
    this.ApiService.happiness_managementId = item.category_id;
    localStorage.setItem("category_id", item.category_id)
    this.router.navigate([this.happinesstestEdit_url]);
  }

  // ------------------------------------------------

  deleteHappinesstestquestion(items: any) {
    this.deletehappinesstestId = items.id
  }

  // --------------------------------------------------

  async happinesstestDelete() {
    var deleteApi = "/deleteHappinesstest/" + this.deletehappinesstestId;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      if (res.status === 200) {
        this.getlist_happinesstestApi();
        location.reload();
      }
    });
    
  }
}



