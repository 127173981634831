import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-package-manager-add',
  templateUrl: './package-manager-add.component.html',
  styleUrls: ['./package-manager-add.component.scss']
})
export class PackageManagerAddComponent implements OnInit {
  packageSubmit_url = "/packageManager";
  packageName: any;
  noOfSession: any;
  perDiscount: any;
  packageSingleListData: any;
  validityNumber: any;
  selectValidityTime: any;
  statusActive: any;
  isShown: boolean = false;
  registerForm: FormGroup;
  submitted = false;
  description:any;
  session_duration : any;
  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  ngOnInit(): void {


    this.registerForm = this.fb.group(
      {
        packageName: ["", Validators.required],
        noOfsessions: ["", Validators.required],
        discount: ["", Validators.required],
        validitys: ["", Validators.required],
        validitytype: ["", Validators.required],
        sessionduration: ["", Validators.required],
        status: ["", Validators.required],
        description: ["", Validators.required],
        // sessionduration: ["", Validators.required],
      },
    );

  }

  async packageSubmit() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }


    var postapi = "/session/package/create";
    var couponAddData = {
      "package_name": this.packageName,
      "no_of_sessions": this.noOfSession,
      "discount": this.perDiscount,
      "validity": this.validityNumber,
      "validity_type": this.selectValidityTime,
      "status": this.statusActive,
      "session_duration": this.session_duration,
      "description": this.description,
    };
    (await this.ApiService.postApi(postapi, couponAddData)).subscribe(
      res => {

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: " Package Manager Added successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.packageSubmit_url]);
        }

      }, (error) => {
        // if (error.error.message.msg != undefined) {
        console.log('====', error);

        var errMsg = '';
        for (var i of error.error) {
          errMsg += i['msg'] + '<br />';
        }
        console.log(errMsg)
        swal({
          title: "error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });

        // }
        // else {
        //   swal({
        //     title: "error!",
        //     text: error.error.msg,
        //     type: "error",
        //   }, function () {
        //     alert('yo');
        //   });
        // }
      }
    );

  }

  packageCancel() {
    this.router.navigate([this.packageSubmit_url]);
  }



  selectValidity(e: any) {
    this.selectValidityTime = e.target.value;
  }
 
  selectSessionDuration (e:any) {
    this.session_duration = e.target.value
  }

  // --------------Update Package Manager--------------------------------------------------------------

  // async packageUpdateSubmit() {

  //   var postapi = "/session/package/create";
  //   var couponAddData = {
  //     "package_name": this.packageName,
  //     "no_of_sessions": this.noOfSession,
  //     "discount": this.perDiscount,
  //     "validity": this.validityNumber,
  //     "validity_type": "month",
  //     "status": 0
  //   };
  //   (await this.ApiService.postApi(postapi, couponAddData)).subscribe(
  //     res => {

  //       if (res.status === 200) {

  //         swal({
  //           title: "Success!",
  //           text: " Package Manager Added successfully",
  //           type: "success",
  //         }, function () {
  //           alert('yo');
  //         });
  //         this.router.navigate([this.packageSubmit_url]);
  //       }

  //     });









  // }
}
