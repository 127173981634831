import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
declare const $: any;
@Component({
  selector: 'app-exercise-workout-edit',
  templateUrl: './exercise-workout-edit.component.html',
  styleUrls: ['./exercise-workout-edit.component.scss']
})
export class ExerciseWorkoutEditComponent implements OnInit {
  isShown: boolean = false;
  answerLabel = "";
  place_holder = "";
  file_upload = "";
  position_Exercise = "";
  base64textString: String = "";
  workout_Exercise_Dayid: any;
  workout_exerciseId:any;
  redirectLogin_url = "/"
  constructor(private ApiService: ApiService ,private router: Router) { }

  async ngOnInit() {
    var id_edit = localStorage.getItem('exerciseEditId');
    var idworkout = localStorage.getItem('inserkeyId');

    this.workout_Exercise_Dayid = this.ApiService.workout_exercise_dayid;
    this.workout_exerciseId =  this.ApiService.workoutExerciseId

 
    var getapi = "/workout/get_questions?" + "day_id=" + this.workout_Exercise_Dayid + "&type=exercise"+"&id="+this.workout_exerciseId;
    // var getapi = "/workout/get_questions?day_id=1&type=question";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

       if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);

        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Unauthorized access",
          type: "error"
        }, function () {
          alert('yo');
        });
        }

      var myArray = res.data;
      for (var i = myArray.length; i--;) {
        var item = myArray[i];
        var ids = item.id
        if (ids == this.workout_exerciseId) {
          this.answerLabel = item.answer_label;
          this.place_holder = item.placeholder;
          // this.file_upload = item.placeholder;
          this.position_Exercise = item.position;
        }
      }
    });
  }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }


  async exerciseSubmit() {
    var id_edit = JSON.parse(localStorage.getItem('exerciseEditId'));
    var idworkout = localStorage.getItem('inserkeyId');
    var putApi = "/workout/update_questions/" + this.workout_exerciseId;
    var workOutEditdata = {
      "workout_day_id": this.workout_Exercise_Dayid,
      "placeholder": this.place_holder,
      "answer_label": this.answerLabel,
      "position": this.position_Exercise,
      "type": "exercise",
      "is_active": 1,
      "file": this.base64textString
    };
    (await this.ApiService.putApi(putApi, workOutEditdata)).subscribe(
      res => {
        if (res) {
          if (res.status === 200) {

          }
         else if (res.status === 401) {
            this.router.navigate([this.redirectLogin_url]);

            swal({
              icon: 'error',
              title: 'Oops...',
              text: "Unauthorized access",
              type: "error"
            }, function () {
              alert('yo');
            });
            }
        }
      });
  }


}
