import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-student-portal-view',
  templateUrl: './student-portal-view.component.html',
  styleUrls: ['./student-portal-view.component.scss']
})
export class StudentPortalViewComponent implements OnInit {
  isShown: boolean = false;
  collegeName: any;
  collegeAddress: any;
  studentEditId: any;
  studentViewId:any;
  constructor(private ApiService: ApiService, private router: Router) { }

  ngOnInit(){
   this.studentViewId= this.ApiService.adminStudent_viewid;
    this.getviewStusentListApi();
  }

  async getviewStusentListApi() {

    var postapi = "/view_college";
    var editData = {
      "id": this.studentViewId,
  
    };

    (await this.ApiService.postApi(postapi, editData)).subscribe(
      res => {

        if (res) {
          if (res.status === 200) {
    
            this.collegeName = res.data[0].name;
          
            this.collegeAddress = res.data[0].address;
         


          }
          else if (res.status === 401) {

          }

        }
      });





  }


}
