import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-community-topic-edit',
  templateUrl: './community-topic-edit.component.html',
  styleUrls: ['./community-topic-edit.component.scss']
})
export class CommunityTopicEditComponent implements OnInit {
  topicsName: any;
  descriptions: any;
  base64textString: any;
  isShown: boolean = false;
  getCommunityDataList = [];
  community_Ids: any;
  type_Type: any;
  selectedFile:any;

  eventimage: string | undefined;


  community_topicPage = "/community-topic-table";
  registerForm: FormGroup;
  submitted = false;
  constructor(private router: Router, private ApiService: ApiService, private fb: FormBuilder) { }

  ngOnInit() {
    this.community_Ids = this.ApiService.communityId_Edit;

    this.registerForm = this.fb.group(
      {
        topicname: ["", Validators.required],
        // topicImage: ["", Validators.required],
        topicDescription: ["", Validators.required],
      },
    );

    this.getcommunityDataListApi();

  }

  // handleFileSelect(evt) {
  //   var files = evt.target.files;
  //   var file = files[0];
  //   this.type_Type = file.type


  //   if (files && file) {
  //     var reader = new FileReader();
  //     reader.onload = this._handleReaderLoaded.bind(this);
  //     reader.readAsBinaryString(file);
  //   }
  // }

  handleFileSelect(evt) {
    var files = evt.target.files;
    var file = files[0];
    this.type_Type = file.type;

    if (files && file) {
      this.selectedFile = file;  // Store the selected file
      console.log("this.selectedFile", this.selectedFile);

      const reader = new FileReader();
      reader.onload = () => {
        this.eventimage = reader.result as string;
      };
      reader.readAsDataURL(file);  // Read the file as a data URL for display
    }
  }


  // _handleReaderLoaded(readerEvt) {
  //   var binaryString = readerEvt.target.result;
  //   this.base64textString = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);

  // }


  async CommunityTopicUpdateSubmit() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }


    var postapi = "/admin_edit_community";
    let formData: FormData = new FormData();
    formData.append('topic_name', this.topicsName);
    formData.append('description', this.descriptions);
    formData.append('image', this.selectedFile );
    formData.append('id', this.community_Ids );
    // var communityAdddata = {

    //   "id": this.community_Ids,
    //   "topic_name": this.topicsName,
    //   "description": this.descriptions,
    //   "image": this.base64textString,

    // };
    (await this.ApiService.postApi(postapi, formData)).subscribe(
      res => {


        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Community Updated Successfully ",
            type: "success"
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.community_topicPage]);
        }



      });
  }

  async getcommunityDataListApi() {
    var postapi = "/get_community";
    var communityGetdata = {
      "id": this.community_Ids
    };
    (await this.ApiService.postApi(postapi, communityGetdata)).subscribe(
      res => {
        this.getCommunityDataList = res.data;

        for (let item of this.getCommunityDataList) {
          this.topicsName = item.topic_name;
          this.descriptions = item.description;
          this.selectedFile = item.image;
        }

      });
  }
  changefun(e) {
    var rajal = e.target.value;

  }
  cancelBtn() {
    this.router.navigate([this.community_topicPage]);
  }

}
