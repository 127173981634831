<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">

  <nav aria-label="breadcrumb ">
            <ol class="breadcrumb fsize27 padd_0 ">
                <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                        [routerLink]="['/packageManager']">Back</a></li>
                <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Package Management - Edit</li>
            </ol>
        </nav>

    </div>



    <div style="padding:0px 25px;">
        <form [formGroup]="registerForm">
        
    <div style="margin-top:30px;">
        <div class="row">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label class="fsize16 clr_black font_bold_gilroy">Package name *</label>
                <input [(ngModel)]="packageName"formControlName="packageName" type="text"
                    class="form-control input_bdr_clr" />

                    <div *ngIf="submitted && registerForm.get('packageName').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('packageName').errors.required">Package name is required</div>
                    </div>


            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label class="fsize16 clr_black font_bold_gilroy">No. of sessions *</label>
                <input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    type="text" class="form-control input_bdr_clr" [(ngModel)]="noOfSession"
                    formControlName="noOfsessions" />

                    <div *ngIf="submitted && registerForm.get('noOfsessions').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('noOfsessions').errors.required">No. of sessions is required</div>
                    </div>

            </div>

        </div>

    </div>


    <div style="margin-top:30px;">
        <div class="row">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label class="fsize16 clr_black font_bold_gilroy">% Discount *</label>
                <input oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    type="text" class="form-control input_bdr_clr" [(ngModel)]="perDiscount"
                    formControlName="discount" />
                    <div *ngIf="submitted && registerForm.get('discount').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('discount').errors.required">Discount is required</div>
                    </div>

            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                <label class="fsize16 clr_black font_bold_gilroy">Validity of package *</label>
                <div class="row m-0">

                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                        <div>
                            <input type="text" [(ngModel)]="validityNumber"  formControlName="validitys" class="form-control"
                                 oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                        </div>
                        <div *ngIf="submitted && registerForm.get('validitys').errors" class="alert alert-danger">
                            <div *ngIf="registerForm.get('validitys').errors.required">Validity is required</div>
                        </div>
                        </div>

    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">

      
        <div style="margin-left: 20px;">
            <select  [(ngModel)]="selectValidityTime"  formControlName="validitysYears" class="form-control" name="cars" style="font-size:14px;" (change)="selectValidity($event)" >
                <option value="month">Month</option>
                <option value="year">Year</option>
            </select>
            <div *ngIf="submitted && registerForm.get('validitysYears').errors" class="alert alert-danger">
                <div *ngIf="registerForm.get('validitysYears').errors.required">Validity is required</div>
            </div>




        </div>

                        </div>

                </div>


            </div>

        </div>

    </div>

    <div style="margin-top:30px;">
        <div class="row">

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label class="fsize16 clr_black font_bold_gilroy">Status *</label>
                <div class="row m-0">
                    <div>
                        <input [(ngModel)]="statusActive"
                        formControlName="status" name="packageManage" value="0" type="radio">
                        <span style="margin-left:4px;">Active </span>
                        <input [(ngModel)]="statusActive"
                        formControlName="status" name="packageManage" value="1" style="margin-left:10px;" type="radio">
                        <span style="margin-left:4px;">In Active </span>
                       
                    </div>
                </div>

                <div *ngIf="submitted && registerForm.get('status').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('status').errors.required">Status is required</div>
                </div>

            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <select  [(ngModel)]="sessionDuration"  formControlName="validitySessionDuration" class="form-control" name="cars" style="font-size:14px;"
                 (change)="selectSessionDuration($event)" >
                    <option value="30">30 minutes</option>
                    <option value="60">60 minutes</option>
                </select>
                <div *ngIf="submitted && registerForm.get('validitySessionDuration').errors" class="alert alert-danger">
                    <div *ngIf="registerForm.get('validitySessionDuration').errors.required">Validity is required</div>
                </div>
    
               

            </div>


        </div>

    </div>
    <div style="margin-top:30px">
        <div class="row">
            <div class="col-6">
                <div class="fsize16 clr_black font_bold_gilroy">
                    Description
                    
                </div>
                <div class="mt-2">
                    <textarea name="" id="" cols="30" rows="10" [(ngModel)]="editDescription"formControlName="editDescription" type="text"
                    class="form-control input_bdr_clr"></textarea>
                    <!-- <input [(ngModel)]="editDescription"formControlName="editDescription" type="text"
                    class="form-control input_bdr_clr" /> -->

                    <div *ngIf="submitted && registerForm.get('editDescription').errors" class="alert alert-danger">
                        <div *ngIf="registerForm.get('editDescription').errors.required">Description is required</div>
                    </div>
                </div>
              

            </div>
            <div class="col-6"></div>
        </div>
    </div>


        </form>

    </div>








    <div style="margin-top:20px;padding:0px 25px;">

        <div class="row m-0" >
            <div>
                <button class="submitBtnClr" (click)="packageUpdateSubmit()">Save</button>
            </div>

            
            <div style="margin-left:20px;">
                <button class="cancelBtnClr" (click)="packageCancel()" > Cancel</button>
            </div>

        </div>

    </div>


</div>