import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import { HappyWorkoutEditComponent } from '../happy-workout-edit/happy-workout-edit.component';
declare var swal: any;
@Component({
  selector: 'app-happiness-workout-table',
  templateUrl: './happiness-workout-table.component.html',
  styleUrls: ['./happiness-workout-table.component.scss']
})
export class HappinessWorkoutTableComponent implements OnInit {
  @ViewChild(HappyWorkoutEditComponent) childReference;
  availabilty_url = '/workout-edit';
  availabilty_add = '/workout-add';
  workoutView_url = '/workout-view';
  workoutDayQuestionAll = [];
  day_Allid = "";
  deleteids = "";
  redirectLogin_url = "/"
  constructor(private router: Router, private ApiService: ApiService) { }

  ngAfterViewInit() {

    this.childReference.exampleChild = 'Hello I am Parent !';
  }




  async ngOnInit() {
    var getapi = "/workout/get_daydetails";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 401) {
        this.router.navigate([this.redirectLogin_url]);
        swal({
          icon: 'error',
          title: 'Oops...',
          text: "Your session has been expired!",
          type: "error"
        }, function () {
          alert('yo');
        });
      }
      this.workoutDayQuestionAll = res.data;
      var questionAlls = res.data;

      for (var item of this.workoutDayQuestionAll) {
        this.day_Allid = item.id;

        localStorage.setItem('day_id', item.id);
      }
      // -----------------------------
      // var str = questionAlls;
      // var myarray = str.split('<p>');
      // for (var i = 0; i < myarray.length; i++) {
      //  var raja = myarray[1];


      // }
    });
  }

  workout_ViewPage(selectedItem: any) {
    this.router.navigate([this.workoutView_url]);
    // localStorage.setItem('workoutId', JSON.stringify(selectedItem.id));
    localStorage.setItem('workoutId', selectedItem.id);
  }

  workOutAdd() {
    this.router.navigate([this.availabilty_add]);
  }
  edit_happyWorkOut(editItem: any) {
 this.ApiService.edit_id = editItem.id;
  localStorage.setItem('workout_EditId', editItem.id);
    this.router.navigate([this.availabilty_url]);
  }
  deleteDay(deleteItem: any) {
    this.deleteids = deleteItem.id
    //    var deleteApi = "/workout/remove_daydetails/" + ids;
    // (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
    // });

  }
  async deletedItem() {
    var deleteApi = "/workout/remove_daydetails/" + this.deleteids;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      window.location.reload();
    });
  }
}
