import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
declare var swal: any;

@Component({
  selector: 'app-edit-pre-assessment',
  templateUrl: './edit-pre-assessment.component.html',
  styleUrls: ['./edit-pre-assessment.component.scss']
})
export class EditPreAssessmentComponent implements OnInit {
  preAssesmentForm: FormGroup
  id: any;
  isShown: boolean = false;
  question: any;
  answer_input: any;
  isactive = '1';
  preassessmentBack_url = "/pre-assessment-list"

  getsinlereassessmentlist: any

  happinessresultDeleteId: any

  EditId: any;

  answer_type: string;
  inputTypes: string[] = ['option', 'input'];
  showPlaceholderText = true;

  constructor(private router: Router, private ApiService: ApiService, private http: HttpClient) { }

  ngOnInit(): void {

    this.preAssesmentForm = new FormGroup({
      input_type: new FormControl(''),
      question: new FormControl(''),
      placeholder_text: new FormControl(""),
      status: new FormControl("")
    })

    this.EditId = this.ApiService.preassessmentEditID
    this.EditId = localStorage.getItem("id")
    console.log("IDDDD", this.EditId)

    this.singlepreassessmentlist()
  }

  // ========================================================

  onchange(event: any, index: number) {
    console.log("aaaaaaaaasasa", event);
    this.isactive = event.checked ? "1" : "0";
  }


  // ===========================================================

  inputtype(event: any) {
    this.answer_type = event.target.value;
    if (this.answer_type === 'option') {
      this.answer_type = '';
    }
    this.showPlaceholderText = (this.answer_type === "input");

  }

  // ===========================================================

  // getStatus(event: any) {
  //   console.log("Statuschange", event,this.preAssesmentForm.controls["status"].value)
  //   this.isactive = event.target.value
  // }

  // ============================================================

  async preassessmentSubmit() {

    var postapi = "/addAndUpdatePreAssessment/" + this.EditId;
    var exercisesAdd = {
      "question": this.preAssesmentForm.value.question,
      "answer_type": this.preAssesmentForm.value.input_type,
      "answer_input": this.preAssesmentForm.value.placeholder_text,
      "isactive": this.preAssesmentForm.value.status
    };
    console.log("aaaaaaaaaaaa", exercisesAdd);
    (await this.ApiService.putApi(postapi, exercisesAdd)).subscribe(
      res => {

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: "Preassessment data Updated successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.preassessmentBack_url]);
        }

      },

      (error) => {
        var errMsg = '';
        if (typeof error.error.message === 'object') {
          for (var i in error.error.message) {
            errMsg += error.error.message[i] + '<br />';
          }
        } else {
          errMsg = error.error.message
        }
        swal({
          title: "error!",
          text: errMsg,
          type: "error",
        }, function () {
          alert('yo');
        });

      }
    )
  }

  // =================================================================

  async singlepreassessmentlist() {
    var getapi = "/listPreAssessment/" + this.EditId;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.getsinlereassessmentlist = res.result;
        const isActive = res.result.isactive
        console.log("shfvbhdfvbhkdf vhdfks", this.getsinlereassessmentlist);
        this.preAssesmentForm.controls['input_type'].setValue(res.result.answer_type)
        this.preAssesmentForm.controls['question'].setValue(res.result.question)
        this.preAssesmentForm.controls['placeholder_text'].setValue(res.result.answer_input)
        this.preAssesmentForm.controls['status'].setValue(res.result.isactive)

        if (isActive === 1) {
          this.preAssesmentForm.controls['status'].setValue("1");
        } else if (isActive === 0) {
          this.preAssesmentForm.controls['status'].setValue("0");
        }

        this.showPlaceholderText = res.result.answer_type === "input";
        console.log(this.showPlaceholderText)

      }
    });
  }

  // ==================================================================

  preassessmentBackUrl() {
    this.router.navigate([this.preassessmentBack_url]);
  }

}

