import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var swal: any;

@Component({
  selector: 'app-exercises-databank-add',
  templateUrl: './exercises-databank-add.component.html',
  styleUrls: ['./exercises-databank-add.component.scss']
})
export class ExercisesDatabankAddComponent implements OnInit {
  registerForm: FormGroup;
  isShown: boolean = false;
  exercises_Backurl = "/exercisesdatabank";
  showVideoDiv = false;
  showImageDiv = false;
  exercise_benefit = [];
  base64textString: any;
  base64textStringTwo: any;
  type_Type: any;
  questionType: any;
  exercis_videoUrl: any;
  emotionsId: any;
  getAllEmotion: any;
  container: any[] = [1];
  // newArray: any = [{ key: 1, values: '' }];
  newArray: any = [{

  }];
  getResultAll: any;
  emotion = "";
  keyValue: any;
  resultId: any;
  // WordCount:any;
  submitted = false;
  firstSessionFree: any;
  name: any;
  ordernoNew: any;
  exercise_name: any;
  categoryAllList: any;
  category_id: any;
  constructor(private ApiService: ApiService, private router: Router, private fb: FormBuilder,) { }



  ngOnInit() {

    // this.registerForm = this.fb.group(
    //   {
    //     name: ["", Validators.required],
    //     firstSessionFree: ["", Validators.required],

    //   },
    // );


    this.getEmotionListApi();
    this.getResultApi();
    this.categoryListApi();
  }

  exerciseBackBtn() {
    this.router.navigate([this.exercises_Backurl]);
  }

  questionOne(e) {
    this.questionType = e.target.value;

    if (this.questionType == '1') {
      this.showVideoDiv = false;
      this.showImageDiv = true;
    }
    if (this.questionType == '2') {
      this.showImageDiv = false;
      this.showVideoDiv = true;
    }
  }
  // --------------------------------------------------


  async submitAddExercise() {
    var benefitAdd: any;
    benefitAdd = $("#benefit").val();
    console.log('this.newArray', this.newArray);

    this.exercise_benefit = this.newArray.map((item) => {
      // if (item.values != undefined && item.values != '' || item.no != undefined && item.no != '') {
      return { benefit: item.values, no: item.no }
      // }
    })


    var orderArr = false;
    for (var item of this.exercise_benefit) {
      if (!item) {
        orderArr = true;
        break;
      }
      if (item.benefit == '' || item.no == '') {
        orderArr = true;
        break;
      }
    }

    if (this.emotionsId != undefined && this.resultId != undefined && this.ordernoNew != undefined && !orderArr) {

      var postapi = "/add_and_update_exercise";
      var exercisesAdd = {
        "emotion_id": this.emotionsId,
        "exercise_result": this.resultId,
        "no": this.ordernoNew,
        "exercise_name":this.exercise_name,
        "category_id": this.category_id,
        "exercise_thumbnail": this.base64textStringTwo,
        "exercise_type": this.questionType,
        "exercise_image": this.base64textString,
        "exercise_video": this.exercis_videoUrl,
        "exercise_benefits": this.exercise_benefit

      };

      (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
        res => {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Exercise Added successfully",
              type: "success",
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.exercises_Backurl]);
          }

        },

        (error) => {

          if (error.error.message.emotion_id != undefined || error.error.message.exercise_result != undefined || error.error.message.exercise_thumbnail != undefined || error.error.message.exercise_type != undefined || error.error.message.exercise_benefits != undefined) {
            var errMsg = '';
            for (var i in error.error.message) {
              errMsg += error.error.message[i] + '<br />';
            }
            swal({
              title: "error!",
              text: errMsg,
              type: "error",
            }, function () {
              alert('yo');
            });

          }
          else {
            swal({
              title: "error!",
              text: error.error.message,
              type: "error",
            }, function () {
              alert('yo');
            });
          }
        }

      );



    }

    else {
      swal({
        title: "error!",
        text: "Please Fill The All Details",
        type: "error",
      }, function () {
        alert('yo');
      });
    }





  }



  handleFileSelect(evt) {
    var files = evt.target.files;

    var file = files[0];
    this.type_Type = file.type

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
  }
  // ------------Thumbnail Image-------------------------
  handleFileSelectOne(evt) {
    var files = evt.target.files;

    var file = files[0];
    this.type_Type = file.type

    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoadedOne.bind(this);
      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoadedOne(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textStringTwo = 'data:' + this.type_Type + ';base64,' + btoa(binaryString);
  }

  // ----------------Emotion List Api---------------------------

  async getEmotionListApi() {
    var getapi = "/master/emotions";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.getAllEmotion = res.data
      }


    });
  }
  // --------------------

  // ----------------Result List Api---------------------------

  async getResultApi() {
    var getapi = "/list_range";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.getResultAll = res.result
      }


    });
  }
  // --------------------
  emotionItem(e: any) {
    this.emotionsId = e.target.value;
  }

  // ---------------------------------------------------------------------------

  resultItem(e: any) {
    this.resultId = e.target.value;
  }


  async addBenefits() {
    this.keyValue = this.container.length + 1;
    // this.newArray.push({ key: this.keyValue, values: '' });
    this.newArray.push({});
    this.exercise_benefit = this.newArray.map((item) => {
      if (item.values != undefined && item.values != '') {
        return { benefit: item.values, no: item.no }
      }
    })
    this.container.push(this.keyValue);
    console.log(' this.exercise_benefit ', this.exercise_benefit);


  }

  async removeBenefits() {
    if (this.container.length > 1) {
      this.container.pop();
    }

  }
  // --------------Exercise Catogory List------------------------------------------



  async categoryListApi() {
    var getapi = "/category_list/1";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      if (res.status === 200) {
        this.categoryAllList = res.result;
      }
    });
  }


  categoryItem(e: any) {
    this.category_id = e.target.value;
    console.log(this.category_id, 'this.category_id.....');

  }




}
