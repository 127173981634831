import { Component, OnInit } from '@angular/core';
// import $ from 'jquery';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
import * as moment from 'moment';
import * as $ from 'jquery';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dashboardAllCount: any;
  coachCount: any;
  userCount: any;
  dashboardAllData = [];
  filterdashboardData: any;
  todayAppointmentCount: any;
  upcomingAppointmentCount: any;
  dateAll: any;
  today_Dates: any;
  todaySessions: any[] = [];
  upCommingSessions: any[] = [];
  communityModeration: any;
  loading: boolean = false;

  dtOptions: DataTables.Settings = {
    paging: false,
    info: false,
    searching: false
  };
  upcommingAppointment: any[];
  currentPage: number = 1;
  entriesPerPage: number = 50;
  totalPages: number = 0;
  totalEntries: number = 0
  searchValue: string = ''
  appointment_url = '/appointment-details';

  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit(): void {
    this.getAdminDashboardApi();
    // this.getAdminSessionDashboard();
    this.getForumManagementApi();
    this.getAppointmentListApi();
    this.getDashboardReports();
    this.getCoachList();
    this.getWeeklyUtilization()

    // const arr = [1,0,2,0,3,0,4,5,6,7,8,9,10,11,12,13,14];
    // const filtered = arr.filter(function(item) {
    //   if (this.count < 5 && item > 0) {
    //     this.count++;
    //     return true;
    //   }
    //   return false;
    // }, {count: 0});




  }

  months = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];

  years = [
    { name: '2021', value: 1 },
    { name: '2022', value: 2 },
    { name: '2023', value: 3 },
    { name: '2024', value: 4 },
    { name: '2025', value: 5 },
    { name: '2026', value: 6 },
    { name: '2027', value: 7 },
    { name: '2028', value: 8 },
    { name: '2029', value: 9 },
    { name: '2030', value: 10 },
    { name: '2031', value: 11 },
    { name: '2032', value: 12 }
  ];

  coaches = [
  ];

  coachList = [
  ]

  weeklyData = [
  ];

  coachRetentionList = [
  ]

  today: Date = new Date();
  startDate: Date = this.today;
  endDate: Date = this.today;

  selectedMonth: number = new Date().getMonth() + 1;
  selectedYear: string = '2024';
  selectedCoachId: string= '';

  exportToExcel(): void {
    console.log(this.coaches);
    const downloadArr = this.coaches.map(
      (item: any, index: any) => {
        return {
          'Coach name': item.coach_name,
          'Available slots': item.available_slots,
          'Booked slots': item.booked_sessions,
          '30 min': item.thirty_min_sessions,
          '60 min': item.one_hour_sessions,
          'Unused slots': item.unused_slots,
          // 'Reschedule DateTime': item.reschedule_datetime,
          'Utilization': ((item.booked_sessions/item.available_slots)*100).toFixed(2)+ "%",
          'Revenue': "₹" + item.total_revenue,

        };
      }
    );
    const weeklyDataTable = this.weeklyData.map(
      (item: any, index: any) =>{
        return {
          'Week': item.week_number,
          'Available slots': item.available,
          'Booked slots': item.booked,
          'Unused slots': item.unused,
          'Utilization': ((item.booked/item.available)*100).toFixed(2) + "%",
        }
      }
    )
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(downloadArr);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `${moment(this.startDate).format('DD-MM-YYYY')} to ${moment(this.endDate).format('DD-MM-YYYY')}`);

    const ws_weekly: XLSX.WorkSheet = XLSX.utils.json_to_sheet(weeklyDataTable);
    XLSX.utils.book_append_sheet(wb, ws_weekly, `Month ${this.selectedMonth-1},${this.selectedYear}`);

    // Generate a timestamp
    const timestamp = new Date()
      .toISOString()
      .slice(0, 19)
      .replace(/[:T]/g, '-');

    // Append the timestamp to the filename
    const filename = `Thap_summary-${timestamp}.xlsx`;

    // Save the file with the timestamped filename
    XLSX.writeFile(wb, filename);
  }

  onDateChange(): void {
    console.log('Start Date:',  moment(this.startDate).format('DD-MM-YYYY'));
    console.log('End Date:', this.endDate);
    this.getDashboardReports();
    this.getCoachRetention();
  }
  


  inventory = {
    available: 60, booked: 55, unused: 5, utilization: '91.6%'
  }

  onMonthChange(event: any): void {
    console.log("Selected month:", this.selectedMonth);
    this.selectedMonth = event.target.value;
    this.getWeeklyUtilization()
  }

  onYearChange(event: any): void {
    console.log("Selected year:", this.selectedYear);
    this.selectedYear = event.target.value;
    this.getWeeklyUtilization()
  }

  onCoachNameChange(e: any): void{
    console.log(`Selected coach: ${JSON.stringify(e.target.value)}`);
    this.selectedCoachId = e.target.value;
    this.getCoachRetention();
  }

  async openMeetingLink(gmeet_link){
    window.open(gmeet_link);
  }

  async getWeeklyUtilization () {
    var getapi = `/admin_dashboard_weekly_utilization?month=${this.selectedMonth}&year=${this.selectedYear}`;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.weeklyData = res.data.weekly_utilization.filter(week=> week.week_number).map(week => {
        const utilization = week.available > 0 ? ((week.booked / week.available) * 100).toFixed(2) : 0;
      
        return {
          week_number: week.week_number,
          available: week.available,
          booked: week.booked,
          unused: week.unused,
          utilization: utilization // Add the calculated utilization
        };
      });
    });
  }

  async getCoachRetention() {
    var getapi = `/admin_dashboard_coach_retention?coachId=${this.selectedCoachId}&fromDate=${this.startDate}&toDate=${this.endDate}`;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.coachRetentionList = res.data.coach_retention;
    });
  }

  async getCoachList() {
    var getapi = '/getcoaches';
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.coachList = res.result;
      this.selectedCoachId = res.result[0].thcoach_user_id
      this.getCoachRetention();
    });
  }

  async getAppointmentListApi() {
    this.loading = true;
    // const search = this.upcomingappointment || "" ;
    const page = this.currentPage;
    const entries = this.entriesPerPage;
    const searchValue = this.searchValue
    var getapi = `/admin_appointment_list?upcomming=true&search=${searchValue}&page=${page}&limit=${entries}`;
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      var getAppointmentList = res.data;
      this.totalPages = res.pageCount;
      this.totalEntries = res.totalCount

      console.log(getAppointmentList);
      // Waiting
      // this.getAllAppointmentList = getAppointmentList.filter(
      //   (x) => x.appointment_status != 'Waiting'
      // );
      // console.log(this.getAllAppointmentList);
      // var today_Dates = moment();

      this.upcommingAppointment = getAppointmentList;
      console.log(this.upcommingAppointment);
      this.loading = false;
    },
      (error) => {
        console.error('Error fetching appointment list', error);
        this.loading = false;
      }


      // this.upcommingAppointment = this.getAllAppointmentList.filter(
      //   (person) =>
      //     moment(person.date + ' ' + person.time, 'DD-MM-YYYY HH:mm:ss') >=
      //     today_Dates
      // );

      // this.designersOverForty = this.getAllAppointmentList.filter((person) => {
      //   return moment(person.date,"DD-MM-YYYY") >= today_Dates ;
      // });

      // this.raja =  this.designersOverForty
    );
  }
  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.getAppointmentListApi();
    }
  }
  searchTable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue
    this.getAppointmentListApi()

  }
  onEntriesPerPageChange() {
    this.currentPage = 1;
    this.getAppointmentListApi();
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.getAppointmentListApi();
    }
  }


  async getAdminDashboardApi() {
    this.loading = true;

    var getapi = "/admin_dashboard";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.dashboardAllCount = res.data;
      for (let item of this.dashboardAllCount) {
        this.coachCount = item.coach_count;
        this.userCount = item.user_count;
        this.todayAppointmentCount = item.today_appoint_count;
        this.upcomingAppointmentCount = item.upcoming_appoint_count;
      }

    });
  }
  // --------------------------------------

  async getAdminSessionDashboard() {
    var getapi = "/admin_appointment_list";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.dashboardAllData = res.data;
      for (let item of this.dashboardAllData) {
        this.dateAll = item.date;

      }
      var today1 = new Date();
      this.today_Dates = moment(today1).format('DD-MM-YYYY');


      this.todaySessions = this.dashboardAllData.filter((obj) => {
        return obj.date === this.today_Dates;
      });



      this.upCommingSessions = this.dashboardAllData.filter((obj) => {
        return obj.date != this.today_Dates;
      });


      // --------------------------------------------------------------

      const todays = new Date().getTime();
      var today_Datess = moment(todays).format('DD-MM-YYYY');

      const filterByExpiration = this.dashboardAllData.filter((items) => {
        return new Date(items.date).getTime() > todays;
      })
      this.loading = false;
      // --------------------------------------------------------------------- 

    },
      (error) => {
        console.error('Error fetching appointment list', error);
        this.loading = false;
      });
  }


  async getForumManagementApi() {
    var getapi = "/community_moderation";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {
      this.communityModeration = res.data;
    });
  }

  async downloadlist(item: any) {
    console.log(item);
    var postapi = '/getinvoice';
    var upcomminglist = {
      "app_id": item?.id,
      "user_id": item?.user_id
    };
    (await this.ApiService.postApi(postapi, upcomminglist)).subscribe(
      (res) => {

        const Data = res?.data[0];
        const Base64pdf = 'data:application/pdf;base64,' + Data;
        const downloadLink = document.createElement("a");
        const fileName = item.app_number + ".pdf";

        console.log('sucesssssssss', Base64pdf)

        console.log("Success", Data)

        // const fileName = URL.createObjectURL(newBlob);
        downloadLink.href = Base64pdf;
        downloadLink.download = fileName;
        downloadLink.click();
        // window.open('data:application/pdf;base64,' + res?.data?.[0]);
      }
    )

  }

  appointment_detail_page(id: number) {
    this.router.navigate([this.appointment_url], { queryParams: { id } });
  }


  async getDashboardReports() {
    this.loading = true;
    (await this.ApiService.getApi(`/admin_dashboard_reports?fromDate=${this.startDate}&toDate=${this.endDate}`)).subscribe((res) =>{
      this.coaches=res.data.coach_utilization;
      this.inventory.available=res.data.inventory_summary[0].available;
      this.inventory.booked=res.data.inventory_summary[0].booked;
      this.inventory.unused=res.data.inventory_summary[0].unused;
      this.inventory.utilization=((res.data.inventory_summary[0].booked/res.data.inventory_summary[0].available)*100).toFixed(2).toString();
      this.loading = false;
    },
    (error) => {
      console.error('Error fetching appointment list', error);
      this.loading = false;
    })
  }
}

