<div class="padd-0-25">
    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Corporate Approval Process
    </div>

    <div class="marg-t-30">
        <!-- <div class="f-right marg-t-20 marg-b-20">
            <button class="add_btn"  >Add</button>

        </div> -->
        <div class="">
            <table class="row-border hover table w-100" datatable style=" background-color: #fff; border-radius: 8px;"
                *ngIf="corporateApproveList.length > 0">
                <thead>
                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Corporate Name </th>
                        <th>Corporate Address</th>

                        <th class="text-center">Action</th>

                    </tr>
                </thead>
                <tbody>
                    <tr class=" font_medium_gilroy" *ngFor="let item of corporateApproveList;let i=index">
                        <td>{{i+1}}</td>

                        <td> {{item.name}}</td>
                        <td>{{item.corporate_name}}</td>
                        <td>{{item.corporate_address}}</td>
                        <td class="text-center" style="width:280px;">
                            <button class="approveBtn_clr" (click)="corporateApproved(item)"> Approve</button>
                            <button style="margin-left: 20px;" (click)="corporateRejected(item)"    class="cancelBtn_clr">Cancel</button>

                        </td>
                    </tr>
                </tbody>
            </table>

            <div *ngIf="corporateApproveList.length == 0">
                <div class="text-center padd_10" style="background-color: #fff;">No Data Available in Table</div>
              </div>



        </div>
    </div>
</div>