import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;

@Component({
  selector: 'app-post-moderation-table',
  templateUrl: './post-moderation-table.component.html',
  styleUrls: ['./post-moderation-table.component.scss']
})
export class PostModerationTableComponent implements OnInit {
  isShown: boolean = false;
  getModerationList = [];
  authToken: any;
  deleteid: any;
  userDeleteId: any;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {

    this.getcommunityModerationApi();
  }

  async getcommunityModerationApi() {
    // this.authToken = localStorage.getItem('token');
    var getapi = "/admin_post_moduration_list_v2";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.getModerationList = res.data;
    });
  }

  

  deletitems(items: any) {
    this.deleteid = items.post_id;
   
  }

  async postcommunityDeleteApi() {
    var postapi = "/delete_post_moduration_v2";
    var communityFeeddata = {
      "post_id": this.deleteid,
      // "user_id": this.userDeleteId

    };
    (await this.ApiService.postApi(postapi, communityFeeddata)).subscribe(
      res => {

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: " Community Moderation Deleted Sucessfully ",
            type: "success"
          }, function () {
            alert('yo');
          });

        }
        this.getcommunityModerationApi();
      });
  }



}
