import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../service/api.service';
declare var swal: any;
@Component({
  selector: 'app-community-userlist-table',
  templateUrl: './community-userlist-table.component.html',
  styleUrls: ['./community-userlist-table.component.scss']
})
export class CommunityUserlistTableComponent implements OnInit {
  isShown: boolean = false;
  getUserList = [];
  postUserDates:any;
  community_Ids:any;
  deleteId:any;
  constructor(private router: Router, private ApiService: ApiService) { }

  ngOnInit() {
    this.community_Ids = this.ApiService.communityId_view;
    this.getcommunityUserListApi();
  }

  async getcommunityUserListApi() {
    var postapi = "/community_user_list";
    var communityUserData = {
      "community_id": this.community_Ids
    };
    (await this.ApiService.postApi(postapi, communityUserData)).subscribe(
      res => {
        this.getUserList = res.data;
       
        for (let item of this.getUserList) {
          var postdate = item.cdate.slice(0, 10);

          var date = postdate;
          var d = new Date(date.split("/").reverse().join("-"));
          var dd = d.getDate();
          var mm = d.getMonth() + 1;
          var yy = d.getFullYear();
  this.postUserDates = dd + "/" + mm + "/" + yy;
   }
           });
  }

  deletedItem(deleteitems:any){
    this.deleteId = deleteitems.id;
  
  }

  async getcommunityDeleteApi() {
    var postapi = "/community_user_delete";
    var communityFeeddata = {
      "id": this.deleteId
    };
    (await this.ApiService.postApi(postapi, communityFeeddata)).subscribe(
      res => {

        if (res.status === 200) {
          swal({
            title: "Success!",
            text: " Community Userlist Deleted Sucessfully ",
            type: "success"
          }, function () {
            alert('yo');
          });

        }
 this.getcommunityUserListApi();
      });
  }






}
