<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/community-topic-view']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Community Poster Moderation</li>
        </ol>
    </nav>
    <!-- <div class="f-right ">
        <button class="add_btn">Add</button>

    </div> -->

    <div class="marg-t-30">

        <div class="marg-t-70" style=" background-color: #fff; border-radius: 8px;">
            <table class="row-border hover table w-100" datatable *ngIf="getModerationList.length > 0">
                <thead>
                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <th>Poster Name</th>
                        <th>Topic</th>
                        <th>Post</th>
                        <th>Post Date</th>
                        <th class="text-center">Action</th>

                    </tr>
                </thead>


                <tbody>
                    <tr class=" font_medium_gilroy" *ngFor="let item of getModerationList ; let i= index">
                        <td>{{i+1}}</td>

                        <td> {{item.poster_name}}</td>
                        <td >{{item.topic_name}}</td>
                        <td >{{item.post}}</td>
                        <td >{{item.post_date}}</td>
                        <td class="text-center" > <button (click)="deletitems(item)"   data-toggle="modal"
                                style="background-color: none; border: none; background: none;outline: none; "
                                data-target="#exampleModaldelete"><img height="20px" src="assets/Delete_icon.png" alt=""></button> </td>
                    </tr>
                </tbody>
            </table>

            <div *ngIf="getModerationList.length == 0">
                <div class="text-center padd_10">No Data Available in Table</div>
            </div>


        </div>

    </div>

    <div class="modal fade" id="exampleModaldelete" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                    <div class="fsize16 marg-t-16">Are you sure you want to delete this Community Post Moderation ?
                    </div>
                </div>
                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal" (click)="postcommunityDeleteApi()"  >Delete</button>
                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                        data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>





</div>