<div class="padd-0-25 marg-t-70">
    <nav aria-label="breadcrumb ">
        <ol class="breadcrumb fsize27 padd_0 ">
            <li class="breadcrumb-item"><a [routerLinkActive]="['active']" (click)="isShown = false"
                    [routerLink]="['/exercisesdatabank']">Back</a></li>
            <li class="breadcrumb-item active font_bold_gilroy" aria-current="page">Exercise Recommendation Edit</li>
        </ol>
    </nav>

    <div class="marg-t-40">
        <div class="row marg-0">
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                <form>

                    <form [formGroup]="exerciseRegForm">

                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Emotion *</label>
                            <select (change)="emotionItem($event)" formControlName="emotion" [(ngModel)]="emotionsId"
                                class=" form-control input_bdr_clr  ">
                                <!-- <option [disabled]="true" [selected]="true">Select an Emotion</option> -->
                                <option [value]="[item.themotion_id]" [selected]="emotionsId == item.themotion_id"
                                    *ngFor="let item of getAllEmotion">{{item.themotion_name}}
                                </option>
                            </select>


                            <div *ngIf="exerciseSubmitted && exerciseRegForm.get('emotion').errors"
                                class="alert alert-danger">
                                <div *ngIf="exerciseRegForm.get('emotion').errors.required">
                                    Emotion is
                                    required</div>
                            </div>



                        </div>


                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Result *</label>
                            <select formControlName="result" (change)="resultItem($event)" [(ngModel)]="resultId"
                                class=" form-control input_bdr_clr ">
                                <option [value]="[item.id]" [selected]="resultId == item.id"
                                    *ngFor="let item of getResultAll">{{item.result}}
                                </option>
                            </select>

                            <div *ngIf="exerciseSubmitted && exerciseRegForm.get('result').errors"
                                class="alert alert-danger">
                                <div *ngIf="exerciseRegForm.get('result').errors.required">
                                    Result is
                                    required</div>
                            </div>

                        </div>

                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Display Order*</label>
                            <div>
                                <input type="text" class="form-control input_bdr_clr" formControlName="displayOrder"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    [(ngModel)]="ordernoNew" maxlength="10" />
                            </div>

                            <div *ngIf="exerciseSubmitted && exerciseRegForm.get('displayOrder').errors"
                                class="alert alert-danger">
                                <div *ngIf="exerciseRegForm.get('displayOrder').errors.required">
                                    Display Order is
                                    required</div>
                            </div>

                        </div>


                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy"> Excercise Name*</label>
                            <div>
                                <input type="text" class="form-control input_bdr_clr" formControlName="exerciseName"
                                    [(ngModel)]="exercise_name" maxlength="100" />
                            </div>

                            <div *ngIf="exerciseSubmitted && exerciseRegForm.get('exerciseName').errors"
                                class="alert alert-danger">
                                <div *ngIf="exerciseRegForm.get('exerciseName').errors.required">
                                     Exercise Name is
                                    required</div>
                            </div>

                        </div>


                        <div class="form-group ">
                            <label class="fsize16 clr_black font_bold_gilroy">Category *</label>
                            <select [(ngModel)]="category_id" formControlName="category" (change)="categoryItem($event)"
                                class=" form-control input_bdr_clr">
                                <option [disabled]="true" [selected]="true">Select an Category</option>
                                <option [value]="[item.id]" [selected]="category_id == item.id"
                                    *ngFor="let item of categoryAllList">
                                    {{item.category}} </option>
                            </select>


                            <div *ngIf="exerciseSubmitted && exerciseRegForm.get('category').errors"
                                class="alert alert-danger">
                                <div *ngIf="exerciseRegForm.get('category').errors.required">
                                    Display Order is
                                    required</div>
                            </div>


                        </div>


                        <div class="form-group ">

                            <div class="row m-0">
                                <div>
                                    <label class="fsize16 clr_black font_bold_gilroy">Exercise Thumbnail *</label>
                                    <div>
                                        <input type="file" accept="image/*" id="questions" class="input_bdr_clr"
                                            (change)="handleFileSelectOne($event)"
                                            style="width:250px; background-color:#fff;" />
                                    </div>
                                </div>

                                <div style="height:120px; width:120px; border:1px solid; margin-left:20px;">
                                    <img height="120px" width="120px" src="{{base64textStringTwo}}" alt="">
                                </div>
                            </div>

                        </div>



                        <div class="form-group">
                            <label class="fsize16 clr_black font_bold_gilroy">Exercise Type *</label>
                            <select class=" form-control input_bdr_clr" [(ngModel)]="questionType"
                                formControlName="exerciseType" (change)="questionOne($event)">
                                <option value="" disabled selected>Select Exercise Type</option>
                                <option value="1">Image</option>
                                <option value="2">Video</option>

                            </select>

                            <div *ngIf="exerciseSubmitted && exerciseRegForm.get('exerciseType').errors"
                                class="alert alert-danger">
                                <div *ngIf="exerciseRegForm.get('exerciseType').errors.required">
                                    Exercise Type is
                                    required</div>
                            </div>


                        </div>


                        <div class="form-group" *ngIf="showVideoDiv">

                            <form [formGroup]="videoUrlRegForm">

                                <label class="fsize16 clr_black font_bold_gilroy">Video url *</label>
                                <input type="text" [(ngModel)]="exercis_videoUrl" formControlName="videourl"
                                    class="form-control input_bdr_clr font_text" maxlength="150" />

                                <div *ngIf="exerciseSubmitted && videoUrlRegForm.get('videourl').errors"
                                    class="alert alert-danger">
                                    <div *ngIf="videoUrlRegForm.get('videourl').errors.required">
                                        Video url is
                                        required</div>
                                </div>

                            </form>

                        </div>

                        <div class="form-group" *ngIf="showImageDiv">

                            <div class="row m-0">

                                <div>
                                    <label class="fsize16 clr_black font_bold_gilroy">Exercise Image*</label>
                                    <div>
                                        <input type="file" accept="image/*" class=" input_bdr_clr font_text"
                                            (change)="handleFileSelect($event)" maxlength="150"
                                            style="width:250px; background-color:#fff;" />
                                    </div>

                                </div>



                                <div style="height:120px; width:120px; border:1px solid; margin-left:20px;">
                                    <img height="120px" width="120px" src="{{base64textString}}" alt="">
                                </div>

                            </div>


                        </div>


                    </form>














                    <!-- <div>

                        <div *ngFor="let ele of container; let i = index" style="margin-top:50px;">
                            <div>
                                <label class="fsize16 clr_black font_bold_gilroy">Benefits for exercise *</label>
                            </div>
                            <ckeditor name="myckeditor" [(ngModel)]="newArray[i].values"
                                [ngModelOptions]="{standalone: true}" debounce="500">
                            </ckeditor>
                        </div>

                        <div class="form-container" style="float: right; margin-top: 30px; margin-bottom: 20px; ">
                            <div class="form-group">
                                <button style="margin-right:20px;" class="btn btn-primary"
                                    (click)="addBenefits()">Add Benefit</button>

                                <button *ngIf="keyValue>1" (click)="removeBenefits()" class="btn btn-warning">remove</button>
                            </div>
                        </div>
                    </div> -->

                    <!-- -------------------------------------------------- -->

                    <div style="background-color: #fff !important;
                    padding: 10px;">

                        <table class="row-border hover table w-100" id="table_id"
                            *ngIf="exerciseAllList.benefits.length > 0">
                            <thead>
                                <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                                    <th>S.No</th>
                                    <th>Display Order</th>
                                    <th>Benefit </th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr class="font_medium_gilroy"
                                    *ngFor="let item of exerciseAllList.benefits;let i = index">


                                    <td *ngIf="!item.delete">{{i+1}}</td>

                                    <td *ngIf="!item.delete" class="" [innerHTML]="item.no">
                                    </td>

                                    <td *ngIf="!item.delete" class="para_line" [innerHTML]="item.benefit">
                                    </td>


                                    <td *ngIf="!item.delete">
                                        <!-- <span class="cursor_pointer"><img  height="22px"
                        width="22px" class="bgr_black" src="assets/View_icon.png" alt=""></span> -->


                                        <!-- data-toggle="modal" data-target="#exampleModal" -->

                                        <span class="marg-l-10 cursor_pointer"><img height="22px" width="22px"
                                                class="bgr_black" src="assets/Edit_icon.png" (click)="edit_update(item)"
                                                alt=""></span>

                                        <span class="marg-l-10 cursor_pointer" data-toggle="modal"
                                            *ngIf="exerciseAllList.benefits.length > 1"
                                            data-target="#exampleModalDelete" (click)="exerciseEditDelete(item)">
                                            <button type="button" class="border_clr_none cursor_pointer"> <img
                                                    height="22px" width="22px" class="bgr_black"
                                                    src="assets/Delete_icon.png" alt=""> </button>

                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                    <div style=" float:right; margin-top: 12px;">

                        <!-- data-toggle="modal" data-target="#exampleModalAdd" -->

                        <button (click)="addBenefitPopUp()" class="btn btn-primary">Add
                            Benefit</button>

                    </div>


                    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content" style="padding:0px 20px;">
                                <div style="float:right; font-size: 18px; display: flex; justify-content:end; margin-top: 10px;"
                                    data-dismiss="modal">X</div>
                                <div *ngFor="let ele of container; let i = index" style="margin-top:20px;">
                                    <form [formGroup]="editRegForm">
                                        <div>
                                            <label class="fsize16 clr_black font_bold_gilroy">Display Order
                                                *</label>
                                            <div>
                                                <input type="text" class="form-control input_bdr_clr"
                                                    [(ngModel)]="display_order" formControlName="editdisplayorder" />
                                            </div>

                                            <div *ngIf="editSubmitted && editRegForm.get('editdisplayorder').errors"
                                                class="alert alert-danger">
                                                <div *ngIf="editRegForm.get('editdisplayorder').errors.required">
                                                    Display Order is
                                                    required</div>
                                            </div>



                                        </div>


                                        <div class="marg-t-20">
                                            <label class="fsize16 clr_black font_bold_gilroy">Benefits for exercise
                                                *</label>
                                        </div>
                                        <ckeditor [(ngModel)]="benefitUpdate" formControlName="editbenefits"
                                            debounce="500">
                                        </ckeditor>

                                        <div *ngIf="editSubmitted && editRegForm.get('editbenefits').errors"
                                            class="alert alert-danger">
                                            <div *ngIf="editRegForm.get('editbenefits').errors.required">
                                                Benefits is
                                                required</div>
                                        </div>


                                    </form>



                                </div>
                                <div class=" row justify-content-center  font_bold_gilroy"
                                    style="margin-top:20px; margin-bottom:20px; ">
                                    <button type="button" class="btn submit_btn_clr"
                                        (click)="submitUpdate()">Save</button>
                                    <!-- <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                data-dismiss="modal">Cancel</button> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- --------------------------------------------------- -->


                    <div class="modal fade" id="exampleModalDelete" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">

                                <div class="modal-body">

                                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>

                                    <div>
                                        Do You Want to Delete ?
                                    </div>

                                </div>
                                <div class=" row justify-content-center  font_bold_gilroy"
                                    style="margin-top:20px; margin-bottom:20px;">
                                    <button type="button" class="btn_submit_cancel bgr_clr_red" data-dismiss="modal"
                                        (click)="delete_submit()">Submit</button>
                                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                        data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="modal fade" id="exampleModalAdd" tabindex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div style="float:right; font-size: 18px; display: flex; justify-content:end; margin-top: 10px; margin-right:20px;"
                                    data-dismiss="modal">X</div>
                                <div class="modal-body">

                                    <form [formGroup]="registerForm">

                                        <div class="form-group">
                                            <label class="fsize16 clr_black font_bold_gilroy">Display Order*</label>
                                            <div>
                                                <input type="text" class="form-control input_bdr_clr"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    [(ngModel)]="order_New" formControlName="displayorder"
                                                    maxlength="10" />
                                            </div>

                                            <div *ngIf="submitted && registerForm.get('displayorder').errors"
                                                class="alert alert-danger">
                                                <div *ngIf="registerForm.get('displayorder').errors.required">
                                                    Display Order is
                                                    required</div>
                                            </div>


                                        </div>



                                        <div class="fsize18 font_bold_gilroy "> Benefit* </div>

                                        <div>
                                            <ckeditor name="benefits" [(ngModel)]="benitAdd" formControlName="benefits"
                                                debounce="500">
                                            </ckeditor>

                                            <div *ngIf="submitted && registerForm.get('benefits').errors"
                                                class="alert alert-danger">
                                                <div *ngIf="registerForm.get('benefits').errors.required">
                                                    Benefit is
                                                    required</div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div class=" row justify-content-center  font_bold_gilroy"
                                    style="margin-top:20px; margin-bottom:20px;">
                                    <button type="button" class="btn_submit_cancel bgr_clr_red"
                                        (click)="add_submit()">Submit</button>
                                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue"
                                        data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>










                    <div class="form-group " style="margin-top:100px;">
                        <button type="button" class="btn  submit_btn_clr" (click)="exerciseUpdate()">Submit</button>
                        <button type="button" class=" marg-l-16 cancelBtn_clr fsize16"
                            (click)="exerciseBackBtn()">Cancel</button>
                    </div>

                </form>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
            </div>
        </div>
    </div>
</div>