import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
import { withModule } from '@angular/core/testing';
declare var swal: any;

@Component({
  selector: 'app-pre-assessment-list',
  templateUrl: './pre-assessment-list.component.html',
  styleUrls: ['./pre-assessment-list.component.scss']
})
export class PreAssessmentListComponent implements OnInit {

  preassessmentlist: any;
  preassessmentDelete_Id: any;
  preassessmentAdd_url = "/add-pre-assessment";
  preassessmentEdit_url = "/edit-pre-assessment";
  showLoaderDiv = false;


  constructor(private router: Router, private ApiService: ApiService) { }
  ngOnInit(): void {
    this.getPreassessmentlist_Api();

  }

  // =====================================================================

  async getPreassessmentlist_Api() {
    this.showLoaderDiv = true;
    var getapi = "/listPreAssessment";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      if (res.status === 200) {
        this.preassessmentlist = res.result;
        this.showLoaderDiv = false;
      }
      console.log("aaaaaaaa", this.preassessmentlist);
    });

  }


  // ========================================================================

  preassessmentAdd() {
    this.router.navigate([this.preassessmentAdd_url]);
  }

  // ========================================================================

  preassessmentQuestionEdit(item: any) {
    this.ApiService.preassessmentEditID = item.id;
    localStorage.setItem("id", item.id)
    this.router.navigate([this.preassessmentEdit_url]);
  }

  // =========================================================================

  deletepreassessmentQuestion(items: any) {
    this.preassessmentDelete_Id = items.id
  }

  // ==========================================================================

  async preassessmentDelete() {
    var deleteApi = "/deletePreAssessment/" + this.preassessmentDelete_Id;
    (await this.ApiService.deleteApi(deleteApi)).subscribe((res) => {
      if (res.status === 200) {
        this.getPreassessmentlist_Api();
        location.reload();
      }
    });
  }
}



