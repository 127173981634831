<div class="padd-0-25">

    <div *ngIf="showLoaderDiv">

        <div id="pause" class="d-flex align-items-center justify-content-center">
            <div id="spinner"></div>
        </div>

    </div>

    <div class="marg-t-70 fsize27 font_bold_gilroy">
        Tidbit Master
    </div>

    <div class="f-right ">
        <button (click)="blogAddMaster()" class="add_btn">Add</button>

    </div>

    <div class="marg-t-64">
        <div class="" style=" background-color: #fff; border-radius: 8px;">

            <table class="row-border hover table w-100" datatable *ngIf="tidbitList.length > 0">
                <thead>
                    <tr class=" font_bold_gilroy" style="border-bottom: 2px solid #dee2e6;">
                        <th>S.No</th>
                        <!-- <th>Image Upload</th> -->
                         <th>Emotion</th>
                        <th>Tidbit Count</th>
                        <!-- <th>Status</th> -->
                        <th style="text-align:center;">Action</th>
                    </tr>
                </thead>

                <tbody>
                    <tr class="font_medium_gilroy" *ngFor="let item of tidbitList ; let i = index">
                        <td>{{i+1}}</td>
                        <td>{{item.emotion}}</td>
                        <td>{{item.count}}</td>
                        <!-- <td>2</td> -->
                     

                        <td style="text-align:center;">
                            <!-- <span class="cursor_pointer"><img (click)="blogMasterView()" height="22px" width="22px"
                                    class="bgr_black" src="assets/View_icon.png" alt=""></span> -->
                                    
                         <span class="marg-l-10 cursor_pointer" (click)="blogMasterEdit(item)"><img height="22px"
                                    width="22px" class="bgr_black" src="assets/Edit_icon.png" alt=""></span>
                            <!-- <span class="marg-l-10"><img height="26px" width="26px" class="bgr_black" src="assets/Delete_icon.png" alt=""></span>   -->
                            <span class="marg-l-10 cursor_pointer" (click)="deleteItems(item)" > <button type="button"
                                    class="border_clr_none cursor_pointer" data-toggle="modal"
                                    data-target="#exampleModal"> <img height="22px" width="22px" class="bgr_black"
                                        src="assets/Delete_icon.png" alt=""> </button>

                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-body">

                    <div class="fsize18 font_bold_gilroy "> Confirmation ! </div>
                    <div>
                        Do you want to delete ?..
                    </div>

                </div>
                <div class="padd-b-30 row justify-content-center padd-b-30 font_bold_gilroy">
                    <button type="button" class="btn_submit_cancel bgr_clr_red cursor_pointer" data-dismiss="modal" (click)="tidbitDelete()" >Submit</button>
                    <button type="button" class="btn_submit_cancel marg-l-10 bgr_clr_blue cursor_pointer"
                        data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>


</div>