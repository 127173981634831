import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-coupon-add',
  templateUrl: './coupon-add.component.html',
  styleUrls: ['./coupon-add.component.scss']
})
export class CouponAddComponent implements OnInit {
  isShown: boolean = false;
  cancelCouponAdd_url = "/coupon-Table";
  couponTitle:any;
  couponDescription:any;
  couponCode:any;
  coupenValue:any;
  coupenType:any;
  coupenExpiry:any;
  couponUsers:any;
  registerForm: FormGroup;
  submitted = false;
  constructor(private ApiService: ApiService, private router: Router,private fb: FormBuilder ) { }

  ngOnInit() {

    this.registerForm = this.fb.group(
      {
        couponTitle: ["", Validators.required],
        coupon_Descrip: ["", Validators.required],
        couponPromocode: ["", Validators.required],
        couponValues: ["", Validators.required],
        couponexpiry: ["", Validators.required],
        // 
     
      },
    );


  }
  cancelAddCoupon() {
    this.router.navigate([this.cancelCouponAdd_url]);

  }

  async submitAddCoupon() {

    this.submitted = true;

    if (this.registerForm.invalid) {
      return;
    }


  var postapi = "/add_coupon";
  var couponAddData = {
      "title": this.couponTitle,
      "description": this.couponDescription,
      "code": this.couponCode,
      "value": this.coupenValue,
      "type":this.coupenType,
      "expiry": this.coupenExpiry
    };
    (await this.ApiService.postApi(postapi, couponAddData)).subscribe(
      res => {
   
     if (res.status === 200) {

          swal({
            title: "Success!",
            text: "Coupon Added successfully",
            type: "success",
          }, function () {
            alert('yo');
          });
          this.router.navigate([this.cancelCouponAdd_url]);
        }

      });

  }


}
