import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { Router } from '@angular/router';
declare var swal: any;

@Component({
  selector: 'app-happiness-add-test-result',
  templateUrl: './happiness-add-test-result.component.html',
  styleUrls: ['./happiness-add-test-result.component.scss']
})
export class HappinessAddTestResultComponent implements OnInit {

  constructor(private router: Router, private ApiService: ApiService) { }

  isShown: boolean = false;
  container: any[] = [1];
  newArray: {
    description: string,
  }[] = [{ description: "" }];
  keyValue: any;
  back_url = "/happiness-test-result";
  happinessresultitems: any[];
  rangeId: any;

  ngOnInit(): void {
    this.getEmotionListApi();
    // this.getResultApi();
  }

  // ==================================================================== //

  happinessresultCancelBtn() {
    this.router.navigate([this.back_url]);
  }

  // ==================================================================== //

  async addBenefits() {

    // if (this.newArray.length >= 6) {
    //   swal({
    //     title: 'Error!',
    //     text: 'Only Six datas for each category',
    //     type: 'error'
    //   });
    //   return;
    // }

    this.keyValue = this.container.length + 1;
    this.newArray.push({ description: "" });
    this.container.push(this.keyValue);
    var allTitle = this.newArray.map((item, index) => {
      if (item?.description != undefined && item?.description != '') {
        return { description: item?.description }
      }
    })
  }

  // ==================================================================== //

  async removeBenefits(index: any) {
    // if (this.container.length > 1) {
    //   this.container.pop();
    // }
    if (index >= 0 && index < this.container.length) {
      this.container.splice(index, 1);
      this.newArray.splice(index, 1);
    }
  }

  // ===================================================================== //

  async happinessresultSubmit() {

    var allTitle = this.newArray.map((item, index) => {
      if (item?.description != undefined && item?.description != '') {
        return { description: item?.description }
      }
    })
    var orderArr = false;
    for (var item of allTitle) {
      if (!item) {
        orderArr = true;
        break;
      }
      if (item?.description == '') {
        orderArr = true;
        break;
      }
    }

    // if (this.newArray.length < 6) {
    //   swal({
    //     title: 'Error!',
    //     text: 'Six datas are mandatory',
    //     type: 'error'
    //   });
    //   return;
    // }


    if (item?.description) {
      var postapi = "/addAndUpdateHapinessTestResult";
      var exercisesAdd = {
        "range_id": this.rangeId,
        "content": allTitle
      };
      (await this.ApiService.postApi(postapi, exercisesAdd)).subscribe(
        res => {
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: "Results Added successfully",
              type: "success",
            }, function () {
              alert('yo');
            });
            this.router.navigate([this.back_url]);
          }
        },

        (error) => {
          // if (error.error.message.emotion_id != undefined || error.error.message.result_id != undefined) {
          var errMsg = '';
          if (typeof error.error.message === 'object') {
            for (var i in error.error.message) {
              errMsg += error.error.message[i] + '<br />';
            }
          } else {
            errMsg = error.error.message
          }

          swal({
            title: "error!",
            text: errMsg,
            type: "error",
          }, function () {
            alert('yo');
          });

          // }
          // else {
          //   swal({
          //     title: "error!",
          //     text: error.error.message,
          //     type: "error",
          //   }, function () {
          //     alert('yo');
          //   });
          // }
        }
      )
    }

    else {
      swal({
        title: "error!",
        text: "Please Fill All the Details",
        type: "error",
      }, function () {
        alert('yo');
      });
    }
  }

  // ======================================================================= //

  async getEmotionListApi() {
    var getapi = "/listHappinesstestrange";
    (await this.ApiService.getApi(getapi)).subscribe((res) => {

      this.happinessresultitems = res.result
      console.log("list data ", this.happinessresultitems)
    });
  }

  // ======================================================================== //

  emotionItem(e: any) {
    this.rangeId = e.target.value;
  }

}

